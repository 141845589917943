import { z } from "zod"

export const ImageSchema = z.object({
  name: z.string(),
  file: z.any(),
  new: z.coerce.boolean().optional(),
  isThumbnail: z.coerce.boolean().optional()
})
export type IImage = z.infer<typeof ImageSchema>
export interface ImageWithId extends IImage {
  id?: string
  formId?: string
}

export const MediaSchema = z.array(ImageSchema)
export type IMediaSchema = z.infer<typeof MediaSchema>
