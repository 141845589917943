import { Separator } from "@/components/ui/separator"
import TypographyH1 from "@/components/ui/typography/h1"
import { Outlet, Link } from "react-router-dom"
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs"
import Icon from "@/components/custom/Icon"
import { cn } from "@/lib/utils"

const SettingsPage = ({ sideBarNavItems }) => {
  const tabItems = sideBarNavItems.map((item, i) => (
    <TabsTrigger key={i} value={item.title} asChild>
      <Link key={item.path} to={item.path} className={cn("justify-start")}>
        {item.title}
      </Link>
    </TabsTrigger>
  ))

  const vehicles = sideBarNavItems.find(
    (element) => element.title == "Vehicles"
  )

  const defaultValue =
    window.location.pathname === "/parker-dashboard/settings/vehicles" ?
      vehicles.title
    : sideBarNavItems[0].title

  return (
    <div className="space-y-6 p-10 pb-16">
      <div className="space-y-0.5">
        <TypographyH1 className="flex items-center">
          <Icon
            name="settings"
            size={24}
            className="lg:size-10 stroke-1 mr-3"
          />
          Settings
        </TypographyH1>
        <p>
          Manage your account settings and set e-mail preferences.
        </p>
      </div>
      <Separator className="my-6" />
      <div className="flex flex-col space-y-8 lg:flex-row lg:space-x-12 lg:space-y-0">
        <aside className="lg:w-1/5">
          <Tabs defaultValue={defaultValue}>
            <TabsList>{tabItems}</TabsList>
          </Tabs>
        </aside>
        <div className="flex-1 lg:max-w-2xl">
          <Outlet />
        </div>
      </div>
    </div>
  )
}

export default SettingsPage
