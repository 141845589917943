import { cn } from "@/lib/utils"
import { LoaderIcon } from "lucide-react"

const Loading = ({ className }) => {
  return (
    <div className={cn("flex justify-center items-center h-screen", className)}>
      <LoaderIcon className="animate-spin" />
    </div>
  )
}

export default Loading
