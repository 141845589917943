import { useLocation } from "react-router-dom"
import { ErrorBoundary } from "react-error-boundary"
import PublicHeader from "@/components/custom/PublicHeader"
import Footer from "@/components/custom/Footer"
import { logError } from "@/lib/logError"
import Fallback from "@/components/custom/Fallback"
import { cn } from "@/lib/utils"

const PublicLayout = ({ children, showFooter = true }: React.PropsWithChildren<{ showFooter?: boolean }>) => {
  const { pathname } = useLocation()

  const headerOffset = () => {
    if (pathname !== "/") {
      return `mt-16` // 64px
    }
  }

  const bg = "bg-primary"

  return (
    <div>
      <PublicHeader bg={(pathname !== "/") && bg} />
      <main className={cn("w-full h-full grow", headerOffset())}>
        <ErrorBoundary FallbackComponent={Fallback} onError={logError}>
          {children}
        </ErrorBoundary>
      </main>
      {showFooter && <Footer />}
    </div>
  )
}

export default PublicLayout
