import {
  api,
  AuthHeader,
  keysToSnakeCase,
  StandardApiResponse,
  StandardErrorHandler,
  StandardGetHandler
} from "../const"
import {
  ReservationRequest,
  ReservationResponse
} from "../types/internal/Reservation"

export const CreateReservationPath = "/v1/reservations/new/"

export interface CreateReservationResponse extends StandardApiResponse {
  reservation?: ReservationResponse
}

export const CreateReservation = (
  data: ReservationRequest,
  accessToken?: string
): Promise<CreateReservationResponse> =>
  api
    .post(
      CreateReservationPath,
      keysToSnakeCase(data),
      accessToken ? AuthHeader(accessToken) : undefined
    )
    .then(StandardGetHandler("reservation"))
    .catch(StandardErrorHandler)
