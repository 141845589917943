import { useMemo } from "react"
import { useQuery } from "@tanstack/react-query"
import { isSameDay, parseISO } from "date-fns"
import _ from "lodash"

import { useAuth } from "@/context/AuthContext"
import { getParker, getReservations } from "@/api/get"

export const useParkerReservations = () => {
  const { accessToken } = useAuth()
  const {
    data: parkerData,
    isPending: parkerIsPending,
    error: parkerError
  } = useQuery({
    queryKey: ["parker", accessToken],
    queryFn: () => getParker(accessToken)
  })

  const {
    data: reservationsData,
    isPending: reservationsIsPending,
    error: reservationsError
  } = useQuery({
    queryKey: ["parkerReservations", accessToken],
    queryFn: () => getReservations(accessToken)
  })

  const partitionByStatus = (o) => {
    return o.cancellationDatetime
  }

  const [cancelledReservations, validReservationsData] = useMemo(() => {
    if (!reservationsData) return [[], []]

    return _.partition(reservationsData, partitionByStatus)
  }, [reservationsData])

  const fullName = useMemo(() => {
    if (!parkerData || !parkerData.user) return ""
    return `${parkerData.user.firstName} ${parkerData.user.lastName}`
  }, [parkerData])

  const partitionByDate = (o) => {
    const checkinDateTime = parseISO(o.checkinDatetime)
    const currentDateTime = new Date()
    return (
      isSameDay(checkinDateTime, currentDateTime) ||
      checkinDateTime > currentDateTime
    )
  }

  const [upcomingReservations, previousReservations] = useMemo(() => {
    if (!validReservationsData) return [[], []]

    return _.partition(validReservationsData, partitionByDate)
  }, [validReservationsData])

  return {
    dataIsPending: parkerIsPending || reservationsIsPending,
    dataError: parkerError || reservationsError,
    upcomingReservations,
    previousReservations,
    cancelledReservations,
    parkerName: fullName
  }
}
