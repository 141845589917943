import React, { useState, createContext, useContext } from 'react';
import { Popover } from "@/components/ui/popover";

const PopoverContext = createContext();

export const usePopoverContext = () => {
  return useContext(PopoverContext);
}

export const ControlledPopover = ({ children }) => {
  const [open, setOpen] = useState(false);

  return (
    <PopoverContext.Provider value={{ open, setOpen }}>
      <Popover open={open} onOpenChange={setOpen}>
        {children}
      </Popover>
    </PopoverContext.Provider>
  );
}