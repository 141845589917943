import { useEffect, useMemo, useState } from "react"
import { Form } from "@/components/ui/form"
import { Header } from "./components/Header"
import {
  ReservationFormProvider,
  useReservationForm
} from "./context/ReservationFormContext"
import { ErrorHelpText } from "./ErrorHelpText"
import { CreateReservationSkeleton } from "./CreateReservationSkeleton"
import { Button } from "@/components/ui/button"
import { DollarSign, Loader2 } from "lucide-react"
import { ReservationDetails } from "./components/ReservationDetails/ReservationDetails"
import { useAuth } from "@/context/AuthContext"
import { getParker } from "@/api/get"
import { TypographyH3 } from "@/components/ui/typography/h3"
import { ParkerVehicleAccordion } from "@/pages/ReservationFlowRedo/CreateReservationPage/sections/ParkerVehicleAccordion/ParkerVehicleAccordion"
import { PacksAccordion } from "@/pages/ReservationFlowRedo/CreateReservationPage/sections/PacksAccordion/PacksAccordion"
import { DiscountCodeAccordion } from "@/pages/ReservationFlowRedo/CreateReservationPage/sections/DiscountCodeAccordion/DiscountCodeAccordion"
import { Separator } from "@/components/ui/separator"
import { getThumbnail } from "./const"
import { CardSwitch } from "@/components/custom/Forms/FormInputs"
import Loading from "@/components/custom/Loading"

export const CreateReservationPage = () => {
  const { accessToken, user } = useAuth()
  const [parker, setParker] = useState()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const fetchParker = async () => {
      if (user?.parkerId) {
        try {
          const parkerData = await getParker(accessToken)
          setParker(parkerData)
        } catch (error) {
          console.error('Error fetching parker data:', error)
        }
      }
      setIsLoading(false)
    }

    fetchParker()
  }, [accessToken, user?.parkerId])

  if (isLoading) return <Loading className="loading" />

  return (
    <ReservationFormProvider parker={parker}>
      <ReservationForm />
    </ReservationFormProvider>
  )
}

const addOnsSection = (addOns: any, control: any) => {
  if (addOns && addOns.length > 0) {
    return (
      <section className="pt-6 grid gap-3">
        <TypographyH3 className="text-md">
          Add-Ons
        </TypographyH3>
        {addOns?.map((addOn: any) => (
          <CardSwitch
            control={control}
            description={addOn.description ?? ""}
            price={`$${addOn.price}`}
            label={addOn.name}
            name={`addOns.${addOn.id}`}
            iconName={addOn.iconName}
            key={addOn.id}
          />
        ))}
      </section>
    )
  }
  return null; // Ensure a valid ReactNode is returned
}

export const ReservationForm = () => {
  const {
    isError,
    isLoading,
    control,
    form,
    submitIsPending,
    reservation,
    addOns,
    submitHandler
  } = useReservationForm()
  const thumbnail = useMemo(() => getThumbnail(reservation), [reservation])

  const tags = (reservation?.spot?.tags || reservation?.lot?.tags) ?? [] // Provide a default empty array

  form.watch('addOns')

  if (!form || !control || isError) {
    return <ErrorHelpText />
  }

  if (isLoading) {
    return <CreateReservationSkeleton />
  }

  return (
    <div className="mx-auto max-w-5xl grid md:m-12 lg:mx-auto md:p-4 py-0 md:my-6 lg:my-10">
      <div className="md:flex">
        {thumbnail && (
          <img
            role="figure"
            aria-label="Location Thumbnail"
            alt="Location Thumbnail"
            className="md:w-32 md:rounded square"
            src={thumbnail.url}
          />
        )}
        <Header tags={tags} />
      </div>
      <div className="px-4 md:px-0">
        {
          reservation?.facility?.description && (
            <p className="pt-4 text-sm">{reservation.facility.description}</p>
          )
        }
        <Form {...form}>
          <form onSubmit={submitHandler} className="flex flex-col">
            <ParkerVehicleAccordion />
            <Separator />
            {addOnsSection(addOns, control)}
            <PacksAccordion />
            <DiscountCodeAccordion />
            <Button
              type="submit"
              disabled={!form.formState.isValid || submitIsPending}
              className="w-full bg-accent-green mb-4">
              {submitIsPending && (
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              )}
              <DollarSign className="h-6 w-6 pr-2" />
              Proceed to Payment
            </Button>
          </form>
        </Form>
      </div>
      <ReservationDetails />
    </div>
  )
}
