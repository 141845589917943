import Root from "@/components/custom/Root"
import { OwnerRoutes } from "./pages/OwnerDashboard/OwnerRoutes"
import { ParkerRoutes } from "./pages/ParkerDashboard/ParkerRoutes"
import { PublicRoutes } from "./pages/PublicRoutes"
import NotFound404Page from "./pages/NotFound404Page"

const ShowOwnerDashboard =
  import.meta.env.VITE_SHOW_OWNER_DASHBOARD === true ||
  import.meta.env.VITE_SHOW_OWNER_DASHBOARD === "true"

export const routes = (auth: any) => [
  {
    path: "/",
    element: <Root />,
    children:
      ShowOwnerDashboard ?
        [
          ...PublicRoutes,
          ParkerRoutes("parker-dashboard/*", auth),
          OwnerRoutes("owner-dashboard/*", auth),
          {
            path: "*",
            element: <NotFound404Page />
          }
        ]
      : [
          ...PublicRoutes,
          ParkerRoutes("parker-dashboard/*", auth),
          {
            path: "*",
            element: <NotFound404Page />
          }
        ]
  }
]
