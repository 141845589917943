import parsePhoneNumberFromString, {
  isValidPhoneNumber
} from "libphonenumber-js"
import { z } from "zod"

export const PhoneNumberSchema = z
  .string()
  .refine((data) => {
    return isValidPhoneNumber(data, "US")
  }, "Please specify a valid phone number")
  .transform((arg, ctx) => {
    const phone = parsePhoneNumberFromString(arg, {
      defaultCountry: "US",
      extract: false
    })

    if (phone && phone.isValid()) {
      if (phone.country === "US") {
        return phone.formatNational()
      } else {
        return phone.formatInternational()
      }
    }

    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: "Phone Number is Invalid"
    })
    return z.NEVER
  })
