import { Badge } from "@/components/ui/badge"
import { Button } from "@/components/ui/button"
import { TypographyH2 } from "@/components/ui/typography/h2"
import { ChevronLeft, CircleXIcon } from "lucide-react"
import { FieldValues } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import { CustomAlert, ApiErrorAlertProps } from "../CustomAlert"
import { useState } from "react"
import { STANDARD_FORM_OUTSIDE } from "../Styles"

export interface UpdateFormHeaderChildren<T extends FieldValues> {
  onApiError?: (apiError: ApiErrorAlertProps["messages"]) => void
  clearApiError?: () => void
  // useful for refreshes, since updates rarely navigate back
  onSubmit?: () => void
  data: T
}

export interface UpdateFormHeaderProps<T extends FieldValues> {
  title?: string
  subtitle?: string | React.ReactNode
  badge?: string
  deleteText?: string
  data?: T
  onDeleteClicked?: () => void
  onSubmit?: UpdateFormHeaderChildren<T>["onSubmit"]
  render?: (props: UpdateFormHeaderChildren<T>) => React.ReactNode
}

export const UpdateFormHeader = <T extends FieldValues>({
  title,
  subtitle,
  badge,
  deleteText,
  data,
  render,
  onSubmit,
  onDeleteClicked
}: UpdateFormHeaderProps<T>) => {
  const navigate = useNavigate()
  const [apiError, setApiError] = useState<ApiErrorAlertProps["messages"]>()

  return (
    <main className={STANDARD_FORM_OUTSIDE}>
      <div className="flex justify-between items-center">
        <div className="flex gap-6 items-center">
          <Button
            onClick={() => navigate(-1)}
            variant="outline"
            size="icon"
            className="h-7 w-7">
            <ChevronLeft className="h-4 w-4" />
            <span className="sr-only">Back</span>
          </Button>
          <div className="flex flex-col justify-center">
            <div className="flex gap-3 items-center">
              {title && (
                <TypographyH2 className="text-xl p-0 font-semibold">
                  {title}
                </TypographyH2>
              )}
              {badge && (
                <Badge variant="" className="h-6">
                  Active
                </Badge>
              )}
            </div>
            {subtitle && typeof subtitle === "string" ?
              <p className="text-md text-muted-foreground">{subtitle}</p>
            : subtitle}
          </div>
        </div>
        {onDeleteClicked && (
          <Button onClick={() => onDeleteClicked()} variant="destructive">
            <CircleXIcon className="h-4 w-4 mr-2" />
            {deleteText}
          </Button>
        )}
      </div>
      <CustomAlert messages={apiError} />
      {render &&
        data &&
        render({
          onApiError: setApiError,
          clearApiError: () => setApiError(undefined),
          data,
          onSubmit
        })}
    </main>
  )
}
