import { Link } from "react-router-dom"
import { Button } from "@/components/ui/button"
import { Card, CardHeader, CardTitle, CardContent } from "@/components/ui/card"
import { searchAll } from "@/lib/formatDateForUrl"

const NoReservations = ({ message }) => {
  return (
    <Card>
      <CardHeader className="px-7">
        <CardTitle>{message}</CardTitle>
      </CardHeader>
      <CardContent>
        <Link to={searchAll()}>
          <Button>Find a Spot</Button>
        </Link>
      </CardContent>
    </Card>
  )
}

export default NoReservations
