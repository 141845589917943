const formatStringForMap = (str) => str.split(" ").join("+")

const googleMapsBaseUrl = "https://www.google.com/maps/place/"

export const addressToMapLink = (address) => {
  const { address1, address2, city, state, zipCode } = address;
  const addressIfExists = () => address2 ? `${address2}+` : ''
  const cityStateZip = `${city}+${state}+${zipCode}`

  return `${googleMapsBaseUrl}${formatStringForMap(address1)}+${addressIfExists()}${cityStateZip}`
}
