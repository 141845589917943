import { AlertCircle } from "lucide-react"
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert"
import Icon from "@/components/custom/Icon"
export interface ApiErrorAlertProps {
  messages?: string | string[] | undefined | null
  className?: string
  type?: 'error' | 'success'
}

export const CustomAlert = ({ messages, className, type }: ApiErrorAlertProps) => {
  if (!messages) {
    return <></>
  } else if (Array.isArray(messages)) {
    return (
      <Alert>
        <AlertCircle className="h-4 w-4" />
        <AlertTitle>{type === 'error' ? 'Error' : 'Success'}</AlertTitle>
        <AlertDescription>
          {messages.map((message) => (
            <p>{message}</p>
          ))}
        </AlertDescription>
      </Alert>
    )
  } else {
    return (
      <Alert className={className}>
        <Icon name={type === 'error' ? 'circle-alert' : 'circle-check'} className="h-4 w-4" />
        <AlertTitle>{type === 'error' ? 'Error' : 'Success'}</AlertTitle>
        <AlertDescription>{messages}</AlertDescription>
      </Alert>
    )
  }
}
