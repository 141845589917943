import {
  api,
  AuthHeader,
  StandardApiResponse,
  StandardErrorHandler,
  StandardGetHandler
} from "../const"
import { ShopOrder } from "../types/internal/ShopOrder"

export const GetShopOrderPath = (id: string) => `/v1/orders/${id}/shop/`
export const GetShopOrderKey = (id: string) => `shop-order-${id}`

export interface ShopOrderResponse extends StandardApiResponse {
  order?: ShopOrder
}

export const GetShopOrder = (
  id: string,
  accessToken: string
): Promise<ShopOrderResponse> =>
  api
    .get(GetShopOrderPath(id), AuthHeader(accessToken))
    .then(StandardGetHandler("order"))
    .catch(StandardErrorHandler)
