import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle
} from "@/components/ui/card"
import { useForm } from "react-hook-form"
import { z } from "zod"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from "@/components/ui/form"
import { Input } from "@/components/ui/input"
import { Button } from "@/components/ui/button"
import { zodResolver } from "@hookform/resolvers/zod"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "@/components/ui/select"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { addMember } from "@/api/post"
import { editMember } from "@/api/patch"
import { getOwner } from "@/api/get"
import { useAuth } from "@/context/AuthContext"
import MultiSelectFormField from "@/components/ui/multi-select"
import Loading from "@/components/custom/Loading"
import { contactSchema, requiredString } from "@/constants/formSchema"
import Fallback from "./Fallback"
import { useCustomAlert } from "@/hooks/useCustomAlert"

const schema = z.object({
  firstName: requiredString,
  lastName: requiredString,
  facilities: z.any(),
  role: requiredString
})

const teamFormSchema = contactSchema.merge(schema)

const defaultValues = {
  email: "",
  firstName: "",
  lastName: "",
  phone: "",
  facilities: [],
  role: "Member"
}

const TeamForm = ({
  actionType = "ADD",
  preExistingValues = defaultValues,
  setIsDialogOpen
}) => {
  const queryClient = useQueryClient()
  const { accessToken } = useAuth()
  const { facilities } = preExistingValues
  const { showMessage } = useCustomAlert()

  const { data, isPending, error } = useQuery({
    queryKey: ["owner", accessToken],
    queryFn: () => getOwner(accessToken)
  })

  const mutation = useMutation({
    mutationFn: (formData) => {
      if (actionType === "EDIT") {
        formData.id = preExistingValues.id
        return editMember(formData, accessToken)
      }

      return addMember(formData, accessToken)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["ownerTeam"] })
      setIsDialogOpen(false)
      showMessage("Team Members updated successfully!", "success")
    }
  })
  const assignedFacilityIDs = facilities.map((facility) => {
    return facility.id
  })

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    ...form
  } = useForm({
    resolver: zodResolver(teamFormSchema),
    defaultValues: {
      ...preExistingValues,
      facilities: assignedFacilityIDs ?? []
    }
  })

  if (isPending) return <Loading />

  if (error) return <Fallback />

  const possibleFacilities = data.facilities.map(({ id, name }) => {
    return {
      value: id,
      label: name
    }
  })

  const onSubmit = (formData) => mutation.mutate(formData)

  return (
    <Form {...form}>
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-8">
        <Card>
          <CardHeader className="space-y-1">
            <CardTitle className="text-lg font-semibold">
              {actionType === "ADD" ?
                "Add a team member"
              : "Edit a team member"}
            </CardTitle>
          </CardHeader>
          <CardContent className="grid gap-2">
            <FormField
              control={control}
              name="firstName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Member First Name</FormLabel>
                  <FormControl>
                    <Input placeholder="Member First Name" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={control}
              name="lastName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Member Last Name</FormLabel>
                  <FormControl>
                    <Input placeholder="Member Last Name" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="grid grid-cols-2 gap-3">
              <FormField
                control={control}
                name="email"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Email</FormLabel>
                    <FormControl>
                      <Input placeholder="Email" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={control}
                name="phone"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Phone Number</FormLabel>
                    <FormControl>
                      <Input placeholder="Phone Number" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <FormField
              control={control}
              name="facilities"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Select facilities</FormLabel>
                  <FormControl>
                    <MultiSelectFormField
                      options={possibleFacilities}
                      defaultValue={field.value}
                      onValueChange={field.onChange}
                      placeholder="Select facilities"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={control}
              name="role"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Assigned Role</FormLabel>
                  <FormControl>
                    <Select onValueChange={field.onChange} value={field.value}>
                      <SelectTrigger>
                        <SelectValue placeholder="Select a role" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="Member">Member</SelectItem>
                        <SelectItem value="Manager">Manager</SelectItem>
                      </SelectContent>
                    </Select>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </CardContent>
          <CardFooter>
            <Button disabled={isSubmitting}>Submit</Button>
          </CardFooter>
        </Card>
      </form>
    </Form>
  )
}

export default TeamForm
