import {
  AdvancedMarker,
  Map,
  useAdvancedMarkerRef,
  useMap
} from "@vis.gl/react-google-maps"
import { v4 as uuidv4 } from "uuid"
import { useCallback, useEffect, useMemo } from "react"

export interface DragMapProps {
  latitude: number
  longitude: number
  precision?: number
  onChange?: (latitude: number, longitude: number) => void
}

export const DragMap = ({
  latitude,
  longitude,
  precision = 4,
  onChange
}: DragMapProps) => {
  const mapId = useMemo(() => uuidv4(), [])
  const map = useMap(mapId)
  const [markerRef, marker] = useAdvancedMarkerRef()

  useEffect(() => {
    if (!map || !marker?.position) {
      return
    }
    if (!map.getBounds()?.contains(marker.position)) {
      map.panTo(marker.position)
    }
  }, [latitude, longitude])

  const onDrag = useCallback(
    (event: google.maps.MapMouseEvent) => {
      onChange?.(
        floatToPrecision(event.latLng?.lat() ?? latitude, precision),
        floatToPrecision(event.latLng?.lng() ?? longitude, precision)
      )
    },
    [precision]
  )

  const intLatitude = useMemo(() => ensureFloat(latitude), [latitude])
  const intLongitude = useMemo(() => ensureFloat(longitude), [longitude])

  return (
    <Map
      mapId={mapId}
      id={mapId}
      defaultCenter={{
        lat: intLatitude,
        lng: intLongitude
      }}
      gestureHandling={"greedy"}
      disableDefaultUI={true}
      defaultZoom={15}>
      <AdvancedMarker
        ref={markerRef}
        position={{
          lat: intLatitude,
          lng: intLongitude
        }}
        draggable
        onDragEnd={onDrag}
      />
    </Map>
  )
}

const floatToPrecision = (num: number, precision: number) =>
  // @ts-ignore
  Number(Math.round(`${num}e${precision}`) + "e-" + precision)

export const ensureFloat = (maybeFloat: any): number => {
  if (typeof maybeFloat === "number") {
    return maybeFloat
  } else if (typeof maybeFloat === "string" && parseFloat(maybeFloat)) {
    return parseFloat(maybeFloat)
  } else {
    return 0.0
  }
}
