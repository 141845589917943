import { DialogProps } from "@radix-ui/react-dialog"
import { ImageWithId } from "./CreateMediaStepper"
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from "@/components/ui/dialog"
import { useMemo } from "react"
import { Button } from "@/components/ui/button"

export interface DeleteDialogProps {
  image: ImageWithId
  open?: boolean
  onOpenChange?: DialogProps["onOpenChange"]
  onSubmit: () => void
}

export const DeleteDialog = ({
  onSubmit,
  image,
  onOpenChange,
  open
}: DeleteDialogProps) => {
  const imagePreviewSrc = useMemo(() => {
    if (image?.file) {
      try {
        return URL.createObjectURL(image.file)
      } catch (e) {
        return ""
      }
    } else {
      return ""
    }
  }, [image?.file])

  return (
    <Dialog onOpenChange={onOpenChange} open={open}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Are you sure you want to delete this image?</DialogTitle>
        </DialogHeader>
        <div className="my-6">
          <div className="flex gap-3 items-center justify-start">
            <img
              src={imagePreviewSrc}
              alt="Uploaded Image"
              className="max-h-32 max-w-48 rounded-md"
            />
            <div className="grid items-center gap-1">
              <p className="font-semibold text-accent-orange">{image?.name}</p>
            </div>
          </div>
        </div>
        <DialogFooter>
          <Button
            variant="outline"
            onClick={() => {
              onOpenChange?.(false)
            }}>
            Cancel
          </Button>
          <Button variant="destructive" onClick={() => onSubmit?.()}>
            Delete
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
