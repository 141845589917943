import { api, keysToCamelCase } from "./const"

export const OwnerFacilitiesPath = "v1/owner"

export interface GetOwnerResponse {
  owner?: Owner
  isError?: boolean
  error?: string
}

export const GetOwner = async (
  accessToken: string
): Promise<GetOwnerResponse> => {
  return api
    .get<ApiOwner>(OwnerFacilitiesPath, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })
    .then((response) => {
      if (response.status < 200 || response.status > 299) {
        return {
          isError: true,
          error: `Server responded with error code ${response.status}`
        }
      }
      return {
        owner: keysToCamelCase(response.data)
      }
    })
    .catch((error) => ({
      isError: true,
      error: error.message
    }))
}
