import { getOwnerTeam } from "@/api/get"
import Loading from "@/components/custom/Loading"
import { useAuth } from "@/context/AuthContext"
import { useQuery } from "@tanstack/react-query"
import { Separator } from "@/components/ui/separator"
import { TypographyH2 } from "@/components/ui/typography/h2"
import { Button } from "@/components/ui/button"
import TeamForm from "@/components/custom/TeamForm"
import { PlusCircle } from "lucide-react"
import SimpleDialog from "@/components/custom/SimpleDialog"
import { useState } from "react"
import TeamMember from "@/components/custom/TeamMember"
import Fallback from "@/components/custom/Fallback"
import { userRoles } from "@/constants/constants"

const OwnerTeamPage = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const { accessToken, user } = useAuth()
  const { data, isPending, error } = useQuery({
    queryKey: ["ownerTeam", accessToken],
    queryFn: () => getOwnerTeam(accessToken)
  })

  if (user?.role !== userRoles.CPO) return null

  if (isPending || !data) return <Loading />

  if (error) return <Fallback />

  const team = data.map((member, i) => <TeamMember member={member} key={i} />)

  const dialogTrigger = (
    <div className="flex justify-center transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 cursor-pointer bg-secondary text-secondary-foreground hover:bg-secondary/80 h-10 px-4 py-2">
      <PlusCircle />
      <span className="ml-2">Add a Team Member</span>
    </div>
  )

  return (
    <div className="space-y-6 p-10 pb-16">
      <div className="space-y-0.5">
        <TypographyH2>Team</TypographyH2>
        <p>Manage your team</p>
      </div>
      <Separator className="my-6" />
      <SimpleDialog
        dialogTrigger={dialogTrigger}
        isDialogOpen={isDialogOpen}
        setIsDialogOpen={setIsDialogOpen}>
        <TeamForm setIsDialogOpen={setIsDialogOpen} />
      </SimpleDialog>
      <div className="flex flex-col md:flex-row flex-wrap gap-4">{team}</div>
    </div>
  )
}

export default OwnerTeamPage
