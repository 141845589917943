import { LabelGeneric } from "./types/internal/Labels"
import { api } from "./const"

export const LabelsPath = "v1/labels"

export interface GetLabelsResponse {
  labels: LabelGeneric
  isError?: boolean
  error?: string
}

export const GetLabels = async (): Promise<GetLabelsResponse> => {
  return api
    .get<LabelGeneric>(LabelsPath)
    .then((response) => {
      if (response.status < 200 || response.status > 299) {
        return {
          labels: {},
          isError: true,
          error: `Server responded with error code ${response.status}`
        }
      }
      return {
        labels: response.data,
        isError: false
      }
    })
    .catch((error) => ({
      labels: {},
      isError: true,
      error: error.message
    }))
}
