import {
  api,
  AuthHeader,
  StandardErrorHandler,
  StandardPushHandler
} from "../const"

export const DeleteSpotMediaPath = (id?: string) => `/v1/spotmedia/${id}/`

export const DeleteSpotMedia = (accessToken: string, id: string) =>
  api
    .delete(DeleteSpotMediaPath(id), AuthHeader(accessToken))
    .then(StandardPushHandler())
    .catch(StandardErrorHandler)
