import DashboardFrame from "@/components/custom/DashboardFrame"
import { userRoles } from "@/constants/constants"
import { useAuth } from "@/context/AuthContext"
import { Navigate } from "react-router-dom"
import { ReservationsListPage } from "./Reservations/ReservationsListPage"
import { FacilityRoutes } from "./Facilities/FacilityRoutes"
import { SpotLotRoutes } from "./SpotsAndLots/SpotLotRoutes"
import { TeamRoutes } from "./Team/TeamRoutes"
import SupportPage from "../SupportPage"
import AccountSettingsPage from "../AccountSettingsPage"
import SettingsPage from "../SettingsPage"
import NotFound404Page from "../NotFound404Page"

const ownerSettingsItems = [
  {
    title: "Account",
    path: "/owner-dashboard/settings"
  }
  // {
  //   title: "Change Password",
  //   path: "/owner-dashboard/settings/change-password"
  // }
]

const isOwner = (userRole: string) => {
  return (userRole === userRoles.CPO || userRole === userRoles.PPO) || userRole === userRoles.TEAM_MANAGER
}

const getOwnerMenuItems = (userRole: string) => [
  {
    name: "Reservations",
    icon: "calendar-days",
    path: "/owner-dashboard",
    canDisplay: true
  },
  {
    name: "Spots and Lots",
    icon: "circle-parking",
    path: "/owner-dashboard/spots",
    canDisplay: isOwner(userRole)
  },
  {
    name: "Facilities",
    icon: "building",
    path: "/owner-dashboard/facilities",
    canDisplay: isOwner(userRole)
  },
  // {
  //   name: "Availability",
  //   icon: "calendar-check",
  //   path: "/owner-dashboard/",
  //   canDisplay: true
  // },
  {
    name: "Team",
    icon: "users",
    path: "/owner-dashboard/team",
    canDisplay: isOwner(userRole) && (userRole !== userRoles.TEAM_MANAGER)
  },
  {
    name: "Settings",
    icon: "settings",
    path: "/owner-dashboard/settings",
    canDisplay: true
  },
  {
    name: "Support",
    icon: "circle-help",
    path: "/owner-dashboard/support",
    canDisplay: true,
    divider: true
  }
]

const OwnerPrivateRoutes = () => {
  const { user } = useAuth()

  const spotOwnerRoles = [
    userRoles.CPO,
    userRoles.PPO,
    userRoles.TEAM_MEMBER,
    userRoles.TEAM_MANAGER,
    userRoles.STAFF
  ]

  return spotOwnerRoles.includes(user?.role) ?
      <DashboardFrame menuItems={getOwnerMenuItems(user?.role)} />
    : <Navigate to="/login" />
}

export const OwnerRoutes = (path: string, auth: any) => {
  return {
    path,
    element: <OwnerPrivateRoutes />,
    children: [
      {
        index: true,
        element: <ReservationsListPage />
      },
      SpotLotRoutes("spots/*"),
      FacilityRoutes("facilities/*"),
      TeamRoutes("team/*"),
      {
        path: "support",
        element: <SupportPage />
      },
      {
        path: "settings",
        element: <SettingsPage sideBarNavItems={ownerSettingsItems} />,
        children: [
          {
            index: true,
            element: <AccountSettingsPage
              isOwner={isOwner(auth?.user?.role)}
              isParker={auth?.user?.role === userRoles.PARKER}
              isTeamMember={auth?.user?.role === userRoles.TEAM_MEMBER}
            />
          }
          // {
          //   path: "change-password",
          //   element: <AccountChangePasswordPage />
          // }
        ]
      },
      {
        path: "*",
        element: <NotFound404Page />
      }
    ]
  }
}
