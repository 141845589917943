import {
  api,
  StandardApiResponse,
  StandardErrorHandler,
  StandardPushHandler
} from "../const"

export const RefreshAccessTokenPath = "/login/refresh/"

export interface RefreshAccessTokenResponse extends StandardApiResponse {
  tokens?: {
    refresh: string
    access: string
  }
}

export const RefreshAccessToken = async (refreshToken: string) => {
  console.log(refreshToken)
  return api
    .post(RefreshAccessTokenPath, {
      refresh: refreshToken
    })
    .then(StandardPushHandler("tokens"))
    .catch(StandardErrorHandler)
}
