import { Link } from "react-router-dom"
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import { Button } from "@/components/ui/button"
import { getFormattedDateTime } from "@/lib/utils"
import CancelReservationDialog from "./CancelReservationDialog"
import { TypographyH4 } from "../ui/typography/h4"

const ReservationCard = ({ reservationData, handleCancel }) => {
  if (!reservationData) return null

  const {
    id,
    checkinDatetime,
    checkoutDatetime,
    order,
    facility: {
      name: facilityName,
      address: { address1, address2, city, state, zipCode }
    },
    vehicle: { make, model, color, licenseNumber },
    spot,
    zone,
    images
  } = reservationData

  let spotName = spot ? spot?.name : zone?.name

  const [formattedCheckinTime, formattedCheckinDate] =
    getFormattedDateTime(checkinDatetime)
  const [formattedCheckoutTime, formattedCheckoutDate] =
    getFormattedDateTime(checkoutDatetime)

  return (
    <div>
      <Card className="sm:col-span-2 mb-10">
        <CardHeader className="pb-3 flex lg:flex-row justify-between">
          <CardTitle>{spotName}</CardTitle>
        </CardHeader>
        <CardContent className="pt-2">
          <div>
            <div className="lg:grid grid-cols-2 gap-8">
              <div>
                <img
                  alt={images[0]?.name}
                  className="aspect-square rounded-md object-cover m-auto lg:h-96 h-52 w-full"
                  src={images[0]?.file}
                />
              </div>
              <div>
                <p className="font-semibold mt-4">{facilityName}</p>
                <address className="font-medium not-italic tracking-wide">
                  <p>{address1}</p>
                  <p>{address2}</p>
                  <p>
                    {city}, {state} {zipCode}
                  </p>
                </address>
                <p className="text-secondary-foreground font-semibold mt-4">
                  ${order?.amount}/day
                </p>
                <TypographyH4 className="text-lg mt-4">
                  Reserved From:
                </TypographyH4>
                <p className="font-semibold">
                  {formattedCheckinDate} {formattedCheckinTime}
                </p>
                <TypographyH4 className="text-lg mt-4">Until:</TypographyH4>
                <p className="font-semibold">
                  {formattedCheckoutDate} {formattedCheckoutTime}
                </p>
                <div>
                  <p className="pt-4 text-muted-foreground">Vehicle: </p>
                  <p className="text-secondary-foreground font-semibold">
                    <span className="capitalize">{color.toLowerCase()}</span>{" "}
                    {make} {model} {licenseNumber}
                  </p>
                </div>
                <Link to={`/parker-dashboard/reservation/${id}`}>
                  <Button
                    className="self-end justify-self-end mt-4"
                    variant="secondary">
                    Details
                  </Button>
                </Link>
              </div>
            </div>
          </div>
          {handleCancel && (
            <div className="grid mt-4">
              <CancelReservationDialog id={id} handleCancel={handleCancel} />
            </div>
          )}
        </CardContent>
      </Card>
    </div>
  )
}

export default ReservationCard
