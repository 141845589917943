import spotSurferIcon from "@/assets/pngs/spotsurferLogoNamed.png"
import { TypographyH3 } from "@/components/ui/typography/h3"
import { Link } from "react-router-dom"
import Icon from "@/components/custom/Icon"
import { useLabels } from "@/context/LabelContext"
import AppleStoreLogo from "@/assets/svgs/appleStoreLogo.svg?react"
import GoogleStoreLogo from "@/assets/svgs/googleStoreLogo.svg?react"

const Footer = () => {
  const { getLabel } = useLabels()
  return (
    <footer className="h-fit w-full drop-shadow-md bg-primary text-white p-4 lg:p-10">
      <div className="max-w-[1400px] mx-auto flex flex-col justify-between">
        <div className="w-full flex lg:flex-col flex-col-reverse items-center">
          <div className="w-full flex lg:flex-row flex-col-reverse justify-between lg:items-start lg:gap-20 gap-16 p-4">
            <div className="flex flex-col gap-6 lg:w-1/4 lg:items-start lg:justify-start justify-center">
              <img
                className="h-20 w-20 lg:h-[100px] lg:w-[100px]"
                src={spotSurferIcon}
              />
              <div className="flex flex-row gap-4"> 
                <Link to={getLabel("footer.linkedin_url")} target="_blank">
                  <Icon name="linkedin" className="lg:size-5 stroke-1" />
                </Link>
                <Link to={getLabel("footer.instagram_url")} target="_blank">
                  <Icon name="instagram" className="lg:size-5 stroke-1" />
                </Link>
                <Link to={getLabel("footer.facebook_url")} target="_blank">
                  <Icon name="facebook" className="lg:size-5 stroke-1" />
                </Link>
              </div>
            </div>

            <div className="flex flex-col lg:w-2/4">
              <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
                <div>
                  <TypographyH3 className="text-sm pb-4 font-medium">
                    Company
                  </TypographyH3>
                  <div className="flex flex-col gap-2">
                    <Link className="text-sm font-extralight" to="/about">About Us</Link>
                    <Link className="text-sm font-extralight" to="/investors">Investors</Link>
                    <Link className="text-sm font-extralight" to="/media">Media Room</Link>
                    <Link className="text-sm font-extralight" to="/careers">Careers</Link>
                  </div>
                </div>

                <div>
                  <TypographyH3 className="text-sm pb-4 font-medium">
                    Support
                  </TypographyH3>
                  <div className="flex flex-col gap-2">
                    <Link className="text-sm font-extralight" to="/contact">Contact Us</Link>
                    <Link className="text-sm font-extralight" to="/help">Help Center</Link>
                    <Link className="text-sm font-extralight" to="/account/delete">Account Deletion</Link>
                    <Link className="text-sm font-extralight" to="/terms">Terms & Conditions</Link>
                  </div>
                </div>

                <div>
                  <TypographyH3 className="text-sm pb-4 font-medium">
                    Community
                  </TypographyH3>
                  <div className="flex flex-col gap-2">
                    <Link className="text-sm font-extralight" to="/shop">Shop</Link>
                    <Link className="text-sm font-extralight" to="/giveaways">Giveaways</Link>
                    <Link className="text-sm font-extralight" to="/community">Community Hub</Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col justify-center gap-6 lg:w-1/4 lg:items-end">
              <div className="flex flex-col gap-2">
                <Link to="https://apps.apple.com/us/app/spotsurfer/id6639622146" target="_blank">
                  <AppleStoreLogo />
                </Link>
                <Link to="https://play.google.com/store/apps/details?id=com.spotsurfer.mobile" target="_blank">
                  <GoogleStoreLogo />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <p className="text-xs font-extralight text-gray-400 mt-16 text-left px-4">
          © 2024 Hohenco Technologies LLC. All Rights Reserved
        </p>
      </div>
    </footer>
  )
}

export default Footer
