import Icon from "@/components/custom/Icon"
import { Badge } from "@/components/ui/badge"
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger
} from "@/components/ui/hover-card"
import { Label } from "@/components/ui/label"
import { Skeleton } from "@/components/ui/skeleton"
import { cn, getFormattedDateTime } from "@/lib/utils"
import { CheckCircle2, XCircle } from "lucide-react"
import { useMemo } from "react"

interface ZoneBadgesProps {
  badges: string[]
  className?: string
}

export const ZoneBadges = ({ badges, className }: ZoneBadgesProps) => {
  return (
    <div className={cn("flex gap-1 items-center content-center", className)}>
      {badges.map((badge) => (
        <Badge key={badge} className="whitespace-nowrap" variant="">
          {badge}
        </Badge>
      ))}
    </div>
  )
}

export interface ZoneItemProps {
  label: string
  content?: string | number | boolean
  isDate?: boolean
  toEnd?: boolean
}

export const ZoneItem = ({ label, content, isDate, toEnd }: ZoneItemProps) => {
  const parsedContent = useMemo(
    () => (isDate ? getFormattedDateTime(content)[1] : content),
    [isDate, content]
  )

  const checkboxContent = useMemo(
    () =>
      content ?
        <CheckCircle2 className="h-4 w-4" color="green" />
      : <XCircle className="h-4 w-4" color="red" />,
    [content]
  )

  return (
    <div className={cn("grid gap-1 content-start", toEnd && "text-right")}>
      <Label className="font-bold">{label}</Label>
      {typeof content === "boolean" ? checkboxContent : <p>{parsedContent}</p>}
    </div>
  )
}

export interface AmenitiesItem {
  amenities?: Amenity[]
  label: string
}

export const AmenitiesItem = ({ amenities, label }: AmenitiesItem) => {
  const iconArray = useMemo(
    () =>
      amenities ?
        amenities.map((amenity) => (
          <HoverCard key={amenity.id}>
            <HoverCardTrigger>
              <Icon name={amenity.iconName} className="shrink-0 h-4 w-4" />
            </HoverCardTrigger>
            <HoverCardContent side="top" className="w-fit">
              <div className="grid gap-1 w-fit">
                <p>{amenity.name}</p>
                {amenity.description && (
                  <p className="text-muted">{amenity.description}</p>
                )}
              </div>
            </HoverCardContent>
          </HoverCard>
        ))
      : <>
          <Skeleton className="h-4 w-4" />
          <Skeleton className="h-4 w-4" />
          <Skeleton className="h-4 w-4" />
        </>,
    []
  )

  return (
    <div className={cn("grid gap-2 content-start")}>
      <Label className="font-bold">{label}</Label>
      <div className="flex gap-1">{iconArray}</div>
    </div>
  )
}
