import {
  api,
  AuthHeader,
  StandardApiResponse,
  StandardErrorHandler,
  StandardGetHandler
} from "../const"

export const GetUserSpotParkingPacksPath = (id: string) =>
  `/v1/spots/${id}/my_parking_packs`
export const GetUserLotParkingPacksPath = (id: string) =>
  `/v1/lots/${id}/my_parking_packs/`

export const GetUserSpotParkingPacksKey = (id: string) =>
  `my-parking-packs-spot-${id}`
export const GetUserLotParkingPacksKey = (id: string) =>
  `my-parking-packs-lot-${id}`

export interface GetUserParkingPacksResponse extends StandardApiResponse {
  packs?: ParkingPackUsage[]
}

export const GetUserSpotParkingPacks = (accessToken: string, id: string) =>
  api
    .get(GetUserSpotParkingPacksPath(id), AuthHeader(accessToken))
    .then(StandardGetHandler("packs"))
    .catch(StandardErrorHandler)

export const GetUserLotParkingPacks = (accessToken: string, id: string) =>
  api
    .get(GetUserLotParkingPacksPath(id), AuthHeader(accessToken))
    .then(StandardGetHandler("packs"))
    .catch(StandardErrorHandler)
