import {
  DrawerHeader,
  DrawerTitle
} from "@/components/ui/drawer"
import { useReservationForm } from "../../context/ReservationFormContext"
import Icon from "@/components/custom/Icon"
import { Separator } from "@/components/ui/separator"
import { Calendar } from "lucide-react"
import { format } from "date-fns"
import { ScrollArea } from "@/components/ui/scroll-area"
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"

export const ReservationDetailsMobileDrawer = () => {
  const { reservation, pricing, form, facilityParkingPacks, parker } =
    useReservationForm()
  const isLot = reservation?.parkingLocationType === "LOT"
  const locationName = isLot ? reservation.lot?.name : reservation?.spot?.name

  return (
    <div className="mx-auto w-full pb-4 flex flex-col gap-6 pb-10">
      <DrawerHeader className="flex flex-col gap-6">
        <div className="flex items-start">
          <img
            className="h-12 w-12 rounded square"
            //@ts-ignore
            src={reservation?.images?.[0].url}
          />
          <DrawerTitle className="flex flex-col text-md font-semibold p-0 ml-4 leading-none gap-2">
            <p>{locationName}</p>
            <p className="flex text-black font-normal items-center">
              <Calendar className="h-5 w-5 mr-1" />
              <span className="relative top-[1px]">{format(form?.watch("checkinDate") ?? "", "MMM d y")}</span>
            </p>
          </DrawerTitle>
        </div>
      </DrawerHeader>
      <ScrollArea className="h-[64vh] px-5">
        <div className="flex flex-col gap-6">
          <Card>
            <CardHeader>
              <CardTitle className="text-lg text-center font-semibold">
                Summary
              </CardTitle>
            </CardHeader>
            <CardContent className="flex flex-col gap-3">
              {pricing?.items?.map((price, i) => (
                <div className="flex justify-between" key={`${price}-${i}`}>
                  <p>{price.name}</p>
                  <p>
                    {price.isNegative && "- "}${price.value}
                  </p>
                </div>
              ))}
              <div
                className={`flex justify-between font-semibold border-t border-black"}`}>
                <p>Total</p>
                <p>${pricing?.total}</p>
              </div>
            </CardContent>
          </Card>
          <Card className="flex flex-col p-4">
            <CardTitle className="text-lg text-center font-semibold">Complimentary Amenities</CardTitle>
            <div className="flex flex-wrap">
              {reservation?.facility?.amenities?.map((amenity) => (
                <div key={amenity.id} className="flex flex-2 items-center mr-2 py-1">
                  <Icon name={amenity.iconName} size={15} className="shrink-0" />
                  <div className="ml-1">
                    <p className="text-sm">{amenity.name}</p>
                  </div>
                </div>
              ))}
            </div>
          </Card>
          {facilityParkingPacks && facilityParkingPacks?.length > 0 && (
            <Card>
              <CardHeader>
                <CardTitle className="text-center text-lg font-semibold">
                  Packs Available
                </CardTitle>
              </CardHeader>
              <CardContent className="flex flex-col gap-6">
                {facilityParkingPacks?.map((pack) => (
                  <div className="flex flex-col gap-1" key={pack.id}>
                    <div className="flex gap-2 items-center">
                      {pack?.iconName && (
                        <div className="h-5 w-5">
                          <Icon name={pack.iconName} className="h-5 w-5" />
                        </div>
                      )}
                      <div className="flex grow justify-between items-center gap-6">
                        <p>{pack.name}</p>
                        <p>${pack.price}</p>
                      </div>
                    </div>
                    <p className="text-muted-foreground">{pack.description}</p>
                  </div>
                ))}
              </CardContent>
            </Card>
          )}
          <Card>
            <CardHeader>
              <CardTitle className="text-lg text-center font-semibold">
                Your Information
              </CardTitle>
            </CardHeader>
            <CardContent className="flex flex-col gap-3">
              <div className="flex justify-between">
                <p>Email</p>
                <p>{form?.watch("email")}</p>
              </div>
              <div className="flex justify-between">
                <p>Phone</p>
                <p>{form?.watch("phone")}</p>
              </div>
              {parker && parker?.user?.firstName && (
                <div className="flex justify-between">
                  <p>Name</p>
                  <p>
                    {parker?.user?.firstName} {parker?.user?.lastName}
                  </p>
                </div>
              )}
            </CardContent>
          </Card>
        </div>
      </ScrollArea>
    </div>
  )
}
