import { Camera } from "lucide-react"

export interface EmptyScreenProps {
  onClick?: () => void
}

export const EmptyMediaUploadScreen = ({ onClick }: EmptyScreenProps) => {
  return (
    <div className="border px-6 py-12 gap-6 flex items-center flex-col">
      <p className="text-xl">Would you like to upload any images?</p>
      <div
        className="flex items-center flex-col cursor-pointer"
        onClick={onClick}>
        <Camera size={64} className="text-muted-foreground" />
        <p className="text-lg text-muted-foreground">No images yet</p>
      </div>
      <p className="text-md">You can always upload images later on.</p>
    </div>
  )
}
