import { api, StandardApiResponse } from "../const"
import {
  CouponValidationRequest,
  CouponValidationResponse
} from "../types/internal/CouponValidation"

export const ValidateCouponPath = "/v1/coupons/validate/"

export const ValidateCoupon = (
  data: CouponValidationRequest
): Promise<CouponValidationResponse> =>
  api.post(ValidateCouponPath, data).then((data) => data.data)
