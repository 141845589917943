import { api, arrayToCamelCase, keysToCamelCase } from "./const"

export const AmenitiesPath = "v1/amenities"

export interface GetAmenitiesResponse {
  amenities: Amenity[]
  isError?: boolean
  error?: string
}

export const GetAmenities = async (
  accessToken: string
): Promise<GetAmenitiesResponse> => {
  return api
    .get<ApiAmenity[]>(AmenitiesPath, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })
    .then((response) => {
      if (response.status < 200 || response.status > 299) {
        return {
          amenities: [],
          isError: true,
          error: `Server responded with error code ${response.status}`
        }
      }
      return {
        amenities: arrayToCamelCase(response.data),
        isError: false
      }
    })
    .catch((error) => ({
      amenities: [],
      isError: true,
      error: error.message
    }))
}
