import { useEffect, useState } from "react"
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle
} from "@/components/ui/card"
import { z } from "zod"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from "@/components/ui/form"
import { Input } from "@/components/ui/input"
import { Button } from "@/components/ui/button"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "@/components/ui/select"
import { useAuth } from "@/context/AuthContext"
import { getParker } from "@/api/get"
import { useQuery } from "@tanstack/react-query"
import Loading from "@/components/custom/Loading"
import { Separator } from "@/components/ui/separator"
import {
  contactSchema,
  couponCodeSchema,
  vehicleDefaultValues,
  vehicleFormSchema
} from "@/constants/formSchema"
import { useReservationForm } from "@/hooks/useReservationForm"

import VehicleFormContent from "./VehicleFormContent"
import CouponFormContent from "./CouponFormContent"
import Fallback from "./Fallback"

const vehicleIdSchema = z.object({ vehicleId: z.string().optional() })
const optionalVehicleSchema = vehicleFormSchema.extend({
  make: z.string(),
  model: z.string(),
  color: z.string(),
  licenseNumber: z
    .string()
    .max(8, { message: "License number must be 8 characters or less" })
})

const reservationFormSchema = contactSchema
  .merge(couponCodeSchema)
  .merge(optionalVehicleSchema)
  .merge(vehicleIdSchema)
  .refine(({ code, email }) => (code?.length > 0 ? !!email : true), {
    message: "Email is required to apply coupon code",
    path: ["code"]
  })
  .superRefine(({ vehicleId, ...data }, ctx) => {
    if (vehicleId) return

    const requiredVehicleFields = ["make", "model", "licenseNumber", "color"]
    const hasAllRequireVehicleFields = requiredVehicleFields.every((field) => {
      return !!data[field]
    })

    if (!vehicleId && !hasAllRequireVehicleFields) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "You must select a vehicle.",
        path: ["vehicleId"]
      })
    }

    if (!hasAllRequireVehicleFields) {
      requiredVehicleFields.forEach((field) => {
        if (!data[field]) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Required",
            path: [field]
          })
        }
      })
    }
  })

const defaultValues = {
  email: "",
  phone: "",
  code: "",
  vehicleId: "",
  ...vehicleDefaultValues
}

const LoggedInReservationForm = ({ reservationData, createReservation }) => {
  const [showVehicleForm, setShowVehicleForm] = useState(false)
  const {
    parkingLocationType,
    spot,
    lot,
    searchQuery: { checkinDate }
  } = reservationData

  const { accessToken } = useAuth()
  const {
    data: parkerData,
    isPending,
    error
  } = useQuery({
    queryKey: ["parker", accessToken],
    queryFn: () => getParker(accessToken)
  })

  const { form, couponValid, onValidateCoupon, resetCoupon, ...remaining } =
    useReservationForm(reservationFormSchema, defaultValues)

  const {
    reset,
    resetField,
    control,
    handleSubmit,
    formState: { isSubmitting, errors: formStateErrors },
    getValues,
    trigger,
    setValue,
    watch,
    ...rest
  } = form

  useEffect(() => {
    if (parkerData) {
      const { email, phone } = parkerData
      reset({ ...defaultValues, email, phone: phone ?? "" })
    }
  }, [parkerData, reset])

  if (isPending) return <Loading />

  if (error) return <Fallback />

  const { vehicles } = parkerData
  const vehiclesById = vehicles.reduce((acc, vehicle) => {
    acc[vehicle.id] = vehicle
    return acc
  }, {})

  const onSubmit = (formData) => {
    const { vehicleId, email, phone, code, ...vehicleFormData } = formData
    const selectedVehicle =
      vehicleId ? vehiclesById[vehicleId] : vehicleFormData

    const dataToSubmit = {
      checkinDate,
      parkingLocationType,
      parkingLocationId: parkingLocationType === "SPOT" ? spot.id : lot.id,
      email,
      phone,
      code: code && couponValid ? code.trim().toUpperCase() : "",
      vehicle: selectedVehicle
    }

    createReservation(dataToSubmit)
  }

  const onRemoveCode = () => {
    setValue("code", "")
    resetCoupon()
  }

  const onApplyCode = async () => {
    // Trigger manual validation for code field
    await trigger("code")
    if (formStateErrors.code?.message) return

    const couponCode = getValues("code").trim().toUpperCase()
    const email = getValues("email")

    onValidateCoupon({ parker: email, code: couponCode })
  }

  const codeInput = watch("code", "")
  const isApplyDisabled = isSubmitting || codeInput.length === 0

  const vehicleSelectItems = vehicles.map((vehicle) => {
    const { id, make, model, color, licenseNumber } = vehicle
    return (
      <SelectItem key={id} value={id}>
        <div>
          <p>{`${color} ${make} ${model}`}</p>
          <p>License No: {licenseNumber}</p>
        </div>
      </SelectItem>
    )
  })

  return (
    <Form {...rest}>
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-8">
        <Card>
          <CardHeader className="space-y-1">
            <CardTitle className="text-lg font-semibold">
              Submit Reservation
            </CardTitle>
            <CardDescription>
              Select the vehicle you plan to park
            </CardDescription>
          </CardHeader>
          <CardContent>
            <FormField
              control={control}
              name="email"
              render={({ field }) => (
                <FormItem className="min-h-[100px]">
                  <FormLabel>Email</FormLabel>
                  <FormControl>
                    <Input placeholder="Email" {...field} disabled />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={control}
              name="phone"
              render={({ field }) => (
                <FormItem className="min-h-[100px]">
                  <FormLabel>Phone Number</FormLabel>
                  <FormControl>
                    <Input placeholder="Phone Number" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Separator className="my-6" />
            <CouponFormContent
              control={control}
              couponControl={{ couponValid, ...remaining }}
              onApplyCode={onApplyCode}
              onRemoveCode={onRemoveCode}
              isApplyDisabled={isApplyDisabled}
            />
            <Separator className="my-6" />
            {vehicles.length > 0 ?
              <>
                {!showVehicleForm && (
                  <>
                    <FormField
                      control={control}
                      name="vehicleId"
                      render={({ field }) => {
                        const { color, make, model } =
                          vehiclesById[field.value] || {}
                        return (
                          <FormItem className="min-h-[100px]">
                            <FormLabel>Vehicle</FormLabel>
                            <FormControl>
                              <Select
                                onValueChange={field.onChange}
                                value={field.value}>
                                <SelectTrigger>
                                  <SelectValue placeholder="Select a vehicle">
                                    {`${color} ${make} ${model}`}
                                  </SelectValue>
                                </SelectTrigger>
                                <SelectContent>
                                  {vehicleSelectItems}
                                </SelectContent>
                              </Select>
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )
                      }}
                    />
                    <Button
                      className="mt-3 w-full"
                      variant="secondary"
                      onClick={() => {
                        resetField("vehicleId")
                        setShowVehicleForm(true)
                      }}>
                      Use a different vehicle
                    </Button>
                  </>
                )}
                {showVehicleForm && (
                  <>
                    <Button
                      className="mb-3 w-full"
                      variant="secondary"
                      onClick={() => {
                        resetField("make")
                        resetField("model")
                        resetField("color")
                        resetField("licenseNumber")
                        setShowVehicleForm(false)
                      }}>
                      Go back to vehicle selection
                    </Button>
                    <VehicleFormContent control={control} />
                  </>
                )}
              </>
            : <VehicleFormContent control={control} />}
          </CardContent>
          <CardFooter>
            <Button type="submit" disabled={isSubmitting}>
              Proceed to Payment
            </Button>
          </CardFooter>
        </Card>
      </form>
    </Form>
  )
}

export default LoggedInReservationForm
