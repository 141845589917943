import { RegistrationAccordionItem } from "../../components/RegistrationAccordionItem"
import { ExistingPacksForm } from "./ExistingPacksForm"
import { AddParkingPacksForm } from "./AddParkingPacksForm"
import { useReservationForm } from "../../context/ReservationFormContext"
import { LoggedOutPacksForm } from "./LoggedOutPacksForm"
import { useEffect, useMemo, useState } from "react"

export const PacksAccordion = () => {
  const { isLoggedInParker, userParkingPacks, parker } = useReservationForm()
  const [activeForm, setActiveForm] = useState<JSX.Element | null>(null)

  const hasUserParkingPacks = useMemo(() => {
    return userParkingPacks && userParkingPacks.length > 0
  }, [userParkingPacks])

  const parkerHasCredits = useMemo(() => {
    return parker && parker.credits > 0
  }, [parker])

  useEffect(() => {
    if (!isLoggedInParker) setActiveForm(<LoggedOutPacksForm />);
    if ((isLoggedInParker && hasUserParkingPacks)) setActiveForm(<ExistingPacksForm parkerHasCredits={parkerHasCredits} />);
    if (isLoggedInParker && !hasUserParkingPacks) setActiveForm(<AddParkingPacksForm parkerHasCredits={parkerHasCredits} />);
  }, [isLoggedInParker, hasUserParkingPacks]);

  return (
    <RegistrationAccordionItem>
      {activeForm}
    </RegistrationAccordionItem>
  )
}
