import NotFound404Page from "@/pages/NotFound404Page"
import { EditLotPage } from "./EditLotPage/EditLotPage"
import { CreateLotPage } from "./CreateLotPage/CreateLotPage"

export const LotRoutes = (path: string) => {
  return {
    path,
    children: [
      {
        path: "new",
        element: <CreateLotPage />
      },
      {
        path: ":lotId",
        children: [
          {
            path: "edit",
            element: <EditLotPage />
          }
        ]
      },
      {
        path: "*",
        element: <NotFound404Page />
      }
    ]
  }
}
