import {
  FormSelectInput,
  FormTextInput
} from "@/components/custom/Forms/FormInputs"
import { VehicleType } from "@/constants/VehicleType"
import { USStatesSelectArray } from "@/constants/States"
import { useReservationForm } from "../../context/ReservationFormContext"

export const NewVehicleForm = () => {
  const { control, form } = useReservationForm()

  if (!form || !control) {
    return <></>
  }

  return (
    <>
      <FormTextInput
        control={control}
        name="vehicle.make"
        label="Vehicle Make *"
        placeholder="Honda"
      />
      <FormTextInput
        control={control}
        name="vehicle.model"
        label="Vehicle Model *"
        placeholder="Civic"
      />
      <FormSelectInput
        control={control}
        name="vehicle.type"
        label="Vehicle Type *"
        options={[...VehicleType]}
      />
      <FormTextInput
        control={control}
        name="vehicle.color"
        label="Color"
        placeholder="Color"
      />
      <FormTextInput
        control={control}
        name="vehicle.licenseNumber"
        label="License Plate *"
        placeholder=""
      />
      <FormSelectInput
        control={control}
        name="vehicle.registeredState"
        label="Registered State *"
        options={USStatesSelectArray}
      />
    </>
  )
}
