import {
  api,
  AuthHeader,
  keysToSnakeCase,
  StandardApiResponse,
  StandardErrorHandler,
  StandardPushHandler
} from "../const"

export const PostFacilityPath = "/v1/facilities/"

export interface PostFacilityResponse extends StandardApiResponse {
  facility?: Facility
}

export const CreateFacility = (
  accessToken: string,
  data: Facility
): Promise<PostFacilityResponse> =>
  api
    .post(PostFacilityPath, keysToSnakeCase(data), AuthHeader(accessToken))
    .then(StandardPushHandler<Facility>("facility"))
    .catch(StandardErrorHandler)
