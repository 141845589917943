import { AdvancedMarker, useAdvancedMarkerRef } from "@vis.gl/react-google-maps"
import { cn } from "@/lib/utils"

const MapMarker = ({ markerInfo, handleMarkerClick, isActive }) => {
  const [markerRef, marker] = useAdvancedMarkerRef()

  const { position: markerPosition, name, price } = markerInfo

  return (
    <>
      <AdvancedMarker
        ref={markerRef}
        onClick={() => handleMarkerClick?.(marker, markerInfo)}
        position={markerPosition}
        title={`Marker for ${name}`}>
        <div className={cn("flex justify-center items-center rounded-2xl w-12 h-8 px-4 py-2 relative bg-white shadow-md hover:scale-105 transition-all", isActive && "bg-black")}>
          <p className={cn("text-sm text-black font-bold", isActive && "text-white")}>${price}</p>
        </div>
      </AdvancedMarker>
    </>
  )
}

export default MapMarker
