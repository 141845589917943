import { AxiosResponse } from "axios"
import { api } from "../const"

export const DeleteSpotPath = (id: string) => `v1/spots/${id}/`
export const DeleteLotPath = (id: string) => `v1/lots/${id}/`

export interface DeleteSpotResponse {
  isError?: boolean
  error?: string
}

const handleResponse = (response: AxiosResponse) => {
  if (response.status < 200 || response.status > 299) {
    return {
      isError: true,
      error: `Server responded with error code ${response.status}`
    }
  }
  return {
    isError: false
  }
}

const handleError = (error: any) => ({
  isError: true,
  error: error?.message
})

export const DeleteSpot = async (
  accessToken: string,
  id: string
): Promise<DeleteSpotResponse> => {
  return api
    .delete(DeleteSpotPath(id), {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })
    .then(handleResponse)
    .catch(handleError)
}

export const DeleteLot = async (
  accessToken: string,
  id: string
): Promise<DeleteSpotResponse> => {
  return api
    .delete(DeleteLotPath(id), {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })
    .then(handleResponse)
    .catch(handleError)
}
