import tivoli from "@/assets/partnershipsImages/tivoli.png"
import police from "@/assets/partnershipsImages/police.webp"
import vailResorts from "@/assets/partnershipsImages/vail-resorts-logo.jpg"
import manor from "@/assets/partnershipsImages/manor.png"
import meierskis from "@/assets/partnershipsImages/meierskis.jpg"
import town from "@/assets/partnershipsImages/Town-Of-Vail-Logo.png"
import ceo from "@/assets/partnershipsImages/CEO.png"
import mtn from "@/assets/partnershipsImages/mtn.jpg"
import og from "@/assets/partnershipsImages/10thOG.webp"
import electra from "@/assets/partnershipsImages/Electra-logo-16copy.png"
import evergreen from "@/assets/partnershipsImages/evergreenvail_logo.webp"
import coSki from "@/assets/partnershipsImages/coski.jpg"
import summitChamber from "@/assets/partnershipsImages/summitChamber.png"
import tharp from "@/assets/partnershipsImages/tharp.png"
import bny from "@/assets/partnershipsImages/bny.png"
import birds from "@/assets/partnershipsImages/birds.jpg"
import summit from "@/assets/partnershipsImages/summit.png"
import ford from "@/assets/partnershipsImages/SummitFordLogo.png"
import daily from "@/assets/partnershipsImages/vail-daily-logo-2x.png"
import vvf from "@/assets/partnershipsImages/VVF-Logo.jpg"
import vvp from "@/assets/partnershipsImages/vvp-logo.png"
import goPro from "@/assets/partnershipsImages/goPro.jpeg"


export const allPartnershipImages = [
  tivoli,
  police,
  vailResorts,
  manor,
  meierskis,
  birds,
  town,
  ceo,
  mtn,
  og,
  electra,
  evergreen,
  coSki,
  summitChamber,
  tharp,
  bny,
  summit,
  ford,
  daily,
  vvf,
  vvp,
  goPro
]