import { format } from "date-fns"
import { CalendarIcon } from "lucide-react"
import { Button } from "@/components/ui/button"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from "@/components/ui/form"
import { ControlledPopover, usePopoverContext } from "@/components/custom/ControlledPopover";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover"
import { Calendar } from "@/components/ui/calendar"
import { cn, getYearMonthDay, sortFacilities } from "@/lib/utils"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "@/components/ui/select"
import { Card } from "@/components/ui/card"
import { TypographyH4 } from "@/components/ui/typography/h4"
import { TypographyH3 } from "@/components/ui/typography/h3"
import { TypographyP } from "@/components/ui/typography/p"
import Icon from "./Icon"
import {
  ALL_AVAILABLE_FACILITIES,
  useParkingSearchForm
} from "@/hooks/useParkingSearchForm"
import { useEffect, useRef, useState } from "react"

// TODO: Make this a uility
const DatePickerCalendar = ({ field, disableSelectionAvailability }) => {
  const { setOpen } = usePopoverContext();

  return (
    <Calendar
      initialFocus
      mode="single"
      selected={field.value}
      onSelect={(date) => {
        field.onChange(date);
        setOpen(false);
      }}
      disabled={disableSelectionAvailability}
    />
  );
}

const MobileParkSearchForm = ({
  searchQuery,
  handleSearchQuery,
  facilities
}) => {
  const { form, facilityOptions, disableSelectionAvailability } = useParkingSearchForm(searchQuery, sortFacilities(facilities))
  const [isEditOpen, setIsEditOpen] = useState(false)
  const triggerRef = useRef(null)
  const [popoverWidth, setPopoverWidth] = useState(0)


  const updatePopoverWidth = () => {
    if (triggerRef.current) {
      setPopoverWidth(triggerRef.current.offsetWidth)
    }
  }

  useEffect(() => {
    updatePopoverWidth()
    window.addEventListener('resize', updatePopoverWidth)
    return () => window.removeEventListener('resize', updatePopoverWidth)
  }, [])

  const onSubmit = async ({ facility, checkinDate }) => {
    const formattedDate = getYearMonthDay(checkinDate)
    const query = {
      facility,
      checkinDate: formattedDate
    }
    handleSearchQuery(query)
    setIsEditOpen(false)
  }

  const { facility = ALL_AVAILABLE_FACILITIES, checkinDate } = searchQuery
  const selectedfacilty = facilityOptions.find(({ id }) => id === facility)

  return (
    <Form {...form} >
      <form onSubmit={form.handleSubmit(onSubmit)} className="max-h-content flex m-2 gap-1" style={{ width: '100%' }}>
        <FormField
          control={form.control}
          name="facility"
          render={({ field }) => (
            <FormItem className="flex-1">
              <Select
                onValueChange={field.onChange}
                value={field.value}>
                <FormControl>
                  <SelectTrigger className="border-none rounded-l-full rounded-r-none">
                    <SelectValue placeholder="Select a parking facility" />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  {facilityOptions.map(({ id, name }) => (
                    <SelectItem value={id} key={id}>
                      {name}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />
        <ControlledPopover>
          <FormField
            control={form.control}
            name="checkinDate"
            render={({ field }) => (
              <FormItem>
                <PopoverTrigger asChild>
                  <FormControl>
                    <Button
                      variant={"outline"}
                      className={cn(
                        "pl-3 text-left font-normal border-none rounded-none",
                        !field.value && "text-muted-foreground"
                      )}>
                      {field.value ?
                        format(field.value, "P")
                        : <span>Pick a date</span>}
                      <CalendarIcon className="ml-auto h-4 w-4" />
                    </Button>
                  </FormControl>
                </PopoverTrigger>
                <PopoverContent className="w-auto p-0" align="start">
                  <DatePickerCalendar
                    field={field}
                    disableSelectionAvailability={disableSelectionAvailability}
                  />
                </PopoverContent>
                <FormMessage />
              </FormItem>
            )}
          />
        </ControlledPopover>
        <Button type="submit" variant="orange" className="rounded-l-none rounded-r-full hover:bg-accent-yellow">
          Search
        </Button>
      </form>
    </Form>
  )
}

export default MobileParkSearchForm
