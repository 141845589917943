// TODO: the component no longer makes sense, but is being used.
import { useRef } from "react"

export type RegistrationAccordionItemProps = React.PropsWithChildren<{}>

export const RegistrationAccordionItem = ({ children }: RegistrationAccordionItemProps) => {
  // TODO: fix this, useRef is invalid
  const triggerRef = useRef<HTMLDivElement | null>(null)

  return (
    <div ref={triggerRef} className="rounded-lg">
      {children}
    </div>
  )
}