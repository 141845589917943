import { GetLot, GetLotQueryKey, GetLotResponse } from "@/api/lots/GetLot"
import Fallback from "@/components/custom/Fallback"
import { UpdateFormHeader } from "@/components/custom/Forms/UpdateFormGenerics/UpdateFormHeader"
import { UpdateFormTabsWrapper } from "@/components/custom/Forms/UpdateFormGenerics/UpdateFormTabWrapper"
import Loading from "@/components/custom/Loading"
import { useAuth } from "@/context/AuthContext"
import { useQuery } from "@tanstack/react-query"
import { useParams } from "react-router-dom"
import { EditLotTabs } from "./const"

export const EditLotPage = () => {
  const { lotId } = useParams()
  const { accessToken } = useAuth()
  const { data, isPending, isError, refetch } = useQuery<GetLotResponse>({
    queryKey: [GetLotQueryKey(lotId)],
    queryFn: () => GetLot(accessToken, lotId)
  })

  if (isPending || !data || !data.lot) return <Loading className="" />
  if (isError || data.isError) return <Fallback />

  return (
    <UpdateFormHeader
      title={data.lot.name}
      subtitle={data.lot.facility?.name}
      badge={data.lot.status}
      data={data.lot}
      deleteText="Delete Lot"
      onDeleteClicked={() => {}}
      onSubmit={() => {
        console.log("submit")
        refetch()
      }}
      render={({ onSubmit, ...header }) => (
        <UpdateFormTabsWrapper
          onSubmitted={onSubmit}
          {...header}
          tabs={EditLotTabs}
        />
      )}
    />
  )
}
