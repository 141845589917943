import { GetParkerQueryKey, GetParkerResponse } from "@/api/parker/GetParker"
import {
  GetShopOrder,
  GetShopOrderKey,
  ShopOrderResponse
} from "@/api/parking-packs/GetShopOrder"
import Icon from "@/components/custom/Icon"
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle
} from "@/components/ui/card"
import { Separator } from "@/components/ui/separator"
import TypographyH1 from "@/components/ui/typography/h1"
import { useAuth } from "@/context/AuthContext"
import { useQuery } from "@tanstack/react-query"
import { format } from "date-fns"
import { useMemo } from "react"
import { useParams } from "react-router-dom"
import { ShopConfirmationSkeleton } from "./Skeleton"
import { HelpText } from "./HelpText"

export const ShopConfirmationPage = () => {
  const { orderId } = useParams()
  const { accessToken } = useAuth()
  const { data, isPending, isError } = useQuery<ShopOrderResponse>({
    queryKey: [GetShopOrderKey(orderId ?? "")],
    queryFn: () => GetShopOrder(orderId ?? "", accessToken ?? "")
  })

  const item = useMemo(() => {
    if (data?.order?.parkingPacks?.length ?? 0 > 0) {
      return data?.order?.parkingPacks?.at(0)?.parkingPack
    } else if (data?.order?.seasonPasses?.length ?? 0 > 0) {
      return data?.order?.seasonPasses?.at(0)
    } else {
      return undefined
    }
  }, [data])

  if (isError || data?.isError) {
    return <HelpText />
  }

  if (isPending) {
    return <ShopConfirmationSkeleton />
  }

  return (
    <div className="p-6 max-w-5xl mx-auto lg:py-10">
      <div className="flex flex-col gap-6">
        <hgroup className="flex flex-col gap-3">
          <TypographyH1 className="text-xl md:text-2xl lg:text-4xl">
            Purchase Confirmed!
          </TypographyH1>
          <p className="text-muted-foreground">
            Order Confirmation: {data?.order?.id}
          </p>
        </hgroup>

        <Card>
          <CardHeader>
            <CardTitle>Thank you for ordering with Spotsurfer.</CardTitle>
            <CardDescription>
              You can view your receipt, provided by Stripe,{" "}
              <a
                className="text-accent-foreground underline"
                href={data?.order?.receipt}>
                here
              </a>
              .
            </CardDescription>
          </CardHeader>
          <Separator />
          <CardContent>
            <div className="grid grid-cols-2 gap-6 pt-6">
              <div className="flex flex-col gap-6">
                <div className="flex flex-col gap-1">
                  <p className="font-semibold">Amount</p>
                  <p>${data?.order?.finalAmount}</p>
                </div>
                <div className="flex flex-col gap-1">
                  <p className="font-semibold">Order Date</p>
                  <p>
                    {format(
                      data?.order?.createdDatetime ?? "",
                      "MMMM dd, yyyy"
                    )}
                  </p>
                </div>
              </div>
              {item && (
                <div>
                  <Card>
                    <CardHeader className="relative">
                      <CardTitle className={item?.iconName ? "mr-6" : ""}>
                        {item?.name}
                      </CardTitle>
                      <CardDescription>{item?.description}</CardDescription>
                      {item?.iconName && (
                        <Icon
                          className="absolute top-3 right-3"
                          name={item?.iconName}
                        />
                      )}
                    </CardHeader>
                    <CardContent>
                      <div className="flex flex-col gap-6">
                        {item?.file && (
                          <img className="h-20 w-20" src={item?.file} />
                        )}
                        <div className="flex gap-12">
                          {item?.price && (
                            <div className="flex flex-col gap-1">
                              <p className="font-semibold">Price</p>
                              <p>${item?.price}</p>
                            </div>
                          )}
                          {item?.allowedParkings && (
                            <div className="flex flex-col gap-1">
                              <p className="font-semibold">Uses</p>
                              <p>{item?.allowedParkings}</p>
                            </div>
                          )}
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                </div>
              )}
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  )
}
