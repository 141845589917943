import { useMapsLibrary } from "@vis.gl/react-google-maps"
import { useEffect, useMemo, useState } from "react"
import { v4 as uuidv4 } from "uuid"
import { GoogleMapsPosition } from "./types"
import { StepperComponentProps } from "../Stepper/Stepper"
import { z } from "zod"
import { useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { TypographyH4 } from "@/components/ui/typography/h4"
import { Separator } from "@/components/ui/separator"
import { getLatLongFromAddress } from "@/lib/getLatLongFromAddress"
import { useQuery } from "@tanstack/react-query"
import { useParams } from "react-router-dom"
import { GetFacility } from "@/api/facility/GetFacility"
import { useAuth } from "@/context/AuthContext"
import { Alert } from "@/components/ui/alert"
import { AlertCircle } from "lucide-react"
import { Form } from "@/components/ui/form"
import { StandardStepperButtons } from "../Stepper/const"
import { FormLatLongInput } from "../Forms/FormInputs"
import { DragMap } from "../Forms/DragMap"

export interface LatLongSelectorProps {
  position: GoogleMapsPosition
  editorOpen: boolean
  onEditorSubmit: (position: GoogleMapsPosition) => void
  onEditorClose: () => void
}

const DefaultValues = {
  latitude: 0,
  longitude: 0
}

const LatLongStepperSchema = z.object({
  latitude: z.coerce.number().gte(-90).lte(90),
  longitude: z.coerce.number().gte(-180).lte(180)
})

export const LatitudeLongitudeStepper = ({
  backText,
  onBack,
  onSubmit,
  saveData,
  submitText
}: StepperComponentProps<z.infer<typeof LatLongStepperSchema>>) => {
  const [apiError, setApiError] = useState("")
  const { facilityId } = useParams()
  const { accessToken } = useAuth()
  const mapId = useMemo(() => uuidv4(), [])
  const geocodingLib = useMapsLibrary("geocoding")
  const { data, isPending, isError } = useQuery({
    queryKey: [`facility-${facilityId}`],
    queryFn: () => GetFacility(accessToken, facilityId ?? "")
  })

  const form = useForm({
    resolver: zodResolver(LatLongStepperSchema),
    values: saveData ?? DefaultValues
  })

  useEffect(() => {
    if (
      saveData ||
      form.watch("latitude") ||
      form.watch("longitude") ||
      !data?.facility?.address ||
      !geocodingLib
    ) {
      return
    }
    const geocoder = new geocodingLib.Geocoder()
    try {
      getLatLongFromAddress(geocoder, data?.facility?.address).then(
        (result) => {
          if (result.isError) {
            setApiError(result.error)
          } else {
            form.setValue("latitude", result.latitude)
            form.setValue("longitude", result.longitude)
          }
        }
      )
    } catch (e) {
      console.log(e)
    }
  }, [saveData, form.watch, data, geocodingLib])

  return (
    <Form {...form}>
      <form
        className="grid gap-6 py-3"
        onSubmit={form.handleSubmit(onSubmit, console.error)}>
        <div className="grid gap-3">
          {apiError && (
            <Alert variant="destructive">
              <AlertCircle className="w-4 h-4" /> {apiError}
            </Alert>
          )}
          <TypographyH4 className="text-base">
            Drag to a new location
          </TypographyH4>
          <div className="w-full rounded-xl border p-4 h-[25rem]">
            <DragMap
              latitude={form.watch("latitude")}
              longitude={form.watch("longitude")}
              precision={4}
              onChange={(latitude, longitude) => {
                form.setValue("latitude", latitude)
                form.setValue("longitude", longitude)
              }}
            />
          </div>
        </div>
        <Separator />
        <div className="grid gap-3">
          <TypographyH4 className="text-base">
            Or manually enter a location
          </TypographyH4>
          <div className="grid grid-cols-2 gap-3">
            <div className="grid gap-3">
              <FormLatLongInput
                control={form.control}
                name="latitude"
                label="Latitude (°)"
              />
            </div>
            <div className="grid gap-3">
              <FormLatLongInput
                control={form.control}
                name="longitude"
                label="Longitude (°)"
              />
            </div>
          </div>
        </div>
        <StandardStepperButtons
          backText={backText}
          onBack={() => onBack(form.watch())}
          submitText={submitText}
          isPending={form.formState.isSubmitting}
        />
      </form>
    </Form>
  )
}
