import { TypographyH3 } from "@/components/ui/typography/h3"
import { UseExistingText } from "./const"
import { AccordionFormClasses } from "../../const"
import { CardRadioGroup } from "@/components/custom/Forms/CardRadioGroup"
import { useReservationForm } from "../../context/ReservationFormContext"
import { Button } from "@/components/ui/button"
import { useEffect, useState } from "react"
import { toast } from "sonner"
import { PayViaCredits } from "./PayViaCredits"

export const ExistingPacksForm = ({ parkerHasCredits }: any) => {
  const [isPayViaCreditsChecked, setIsPayViaCreditsChecked] = useState(false)
  const { form, control, parker, userParkingPacks } = useReservationForm()
  const valueIsSelected = form?.watch("usingPack")

  const shouldShowDescription = (pack: any) => {
    if (!pack?.parkingPack?.allowUnlimitedParkings) {
      return `Available Reservations: ${pack.parkingPack.allowedParkings - pack.parkingsUsed}`
    }
    return undefined
  }

  useEffect(() => {
    if (form?.watch("usingPack") && form?.watch("code")) {
      form.setValue("code", undefined)
      toast.warning(
        "Your discount code has been cleared as a result of selecting a parking pass."
      )
    }
  }, [form?.watch("usingPack"), form?.watch("code"), form?.watch("payViaCredits")])

  if (!control) {
    return <></>
  }

  return (
    <section className={AccordionFormClasses}>
      <TypographyH3 className="text-muted-foreground text-md">
        {UseExistingText}
      </TypographyH3>
      <CardRadioGroup
        control={control}
        name="usingPack"
        label="Payment Packs"
        viewLabel={false}
        // onSelectionChange={(value, item) => {
        //   KEEPING HERE ON PURPOSE
        //   console.log('Selected value:', value);
        //   console.log('Selected item:', item);
        // }}
        items={
          userParkingPacks?.map((pack) => ({
            label: pack.parkingPack.name,
            value: pack.id,
            description: shouldShowDescription(pack),
            iconName: pack.parkingPack.iconName,
            hasUnlimitedParkings: pack?.parkingPack?.allowUnlimitedParkings,
            disabled: isPayViaCreditsChecked && pack?.parkingPack?.allowUnlimitedParkings
          })) ?? []
        }
      />
      Existing
      {parkerHasCredits && (
        <PayViaCredits control={control} parker={parker} onCheckedChange={(isChecked: boolean) => {
          setIsPayViaCreditsChecked(isChecked)
        }} />
      )}
      <div
        data-state={valueIsSelected ? "open" : "closed"}
        className="hidden data-[state=open]:flex">
        <Button
          type="button"
          onClick={() => {
            form?.setValue("usingPack", "")
          }}
          variant="outline">
          Cancel
        </Button>
      </div>
    </section>
  )
}
