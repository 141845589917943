import {
  api,
  AuthHeader,
  StandardApiResponse,
  StandardErrorHandler,
  StandardGetHandler
} from "../const"

export const GetFacilityPath = (id: string) => `v1/facilities/${id}`
export const GetFacilityQuery = (id: string) => `facility-${id}`

export interface GetFacilityResponse extends StandardApiResponse {
  facility?: Facility
}

export const GetFacility = async (
  accessToken: string,
  id: string
): Promise<GetFacilityResponse> =>
  api
    .get(GetFacilityPath(id), AuthHeader(accessToken))
    .then(StandardGetHandler("facility"))
    .catch(StandardErrorHandler)
