import {
  FormCheckbox,
  FormNumberInput,
  FormTextArea,
  FormTextInput
} from "@/components/custom/Forms/FormInputs"
import { StandardStepperButtons } from "@/components/custom/Stepper/const"
import { StepperComponentProps } from "@/components/custom/Stepper/Stepper"
import { Form } from "@/components/ui/form"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { CreateSpotDetailsSchema, IAssignedZoneDetails } from "../const"

export const AssignedZoneDetails = ({
  onSubmit,
  onBack,
  saveData,
  backText,
  submitText
}: StepperComponentProps<IAssignedZoneDetails>) => {
  const form = useForm<IAssignedZoneDetails>({
    resolver: zodResolver(CreateSpotDetailsSchema),
    values: saveData
  })

  return (
    <Form {...form}>
      <form
        className="grid gap-6"
        onSubmit={form.handleSubmit(onSubmit, console.error)}>
        <FormTextInput control={form.control} name="name" label="Name *" />
        <FormTextArea
          control={form.control}
          name="description"
          label="Description"
        />
        <div className="grid grid-cols-3 gap-6">
          <FormNumberInput
            control={form.control}
            name="maxHeightSupported"
            label="Max Vehicle Height (ft)"
          />
          <FormNumberInput control={form.control} name="floor" label="Floor" />
          <FormCheckbox
            control={form.control}
            name="hasSpaceForBiggerVehicles"
            label="Space for Bigger Vehicles?"
          />
        </div>
        <FormTextArea
          control={form.control}
          name="parkerInstructions"
          label="Parking Instructions"
        />
        <StandardStepperButtons
          backText={backText}
          onBack={() => onBack(form.watch())}
          submitText={submitText}
          isInvalid={!form.formState.isValid}
          isPending={form.formState.isSubmitting}
        />
      </form>
    </Form>
  )
}
