import { useState } from "react"
import { CustomAlert, ApiErrorAlertProps } from "../CustomAlert"
import { TypographyH2 } from "@/components/ui/typography/h2"
import { STANDARD_FORM_OUTSIDE } from "../Styles"

export interface CreateFormHeaderChildren {
  onApiError?: (apiError: string | string[] | undefined) => void
  clearApiError?: () => void
}

export interface CreateFormHeaderProps {
  title?: string
  subtitle?: string
  render?: (props: CreateFormHeaderChildren) => React.ReactNode
}

export const CreateFormHeader = ({
  title,
  subtitle,
  render
}: CreateFormHeaderProps) => {
  const [apiError, setApiError] = useState<ApiErrorAlertProps["messages"]>()

  return (
    <main className={STANDARD_FORM_OUTSIDE}>
      {title && <TypographyH2 className="font-semibold">{title}</TypographyH2>}
      {subtitle && (
        <p className="text-normal text-muted-foreground">{subtitle}</p>
      )}
      <CustomAlert messages={apiError} />
      {render &&
        render({
          onApiError: setApiError,
          clearApiError: () => setApiError(undefined)
        })}
    </main>
  )
}
