import {
  api,
  AuthHeader,
  StandardApiResponse,
  StandardErrorHandler,
  StandardGetHandler
} from "../const"

export const GetLotPath = (id?: string) => `v1/lots/${id}`
export const GetLotQueryKey = (id?: string) => `lots-${id}`

export interface GetLotResponse extends StandardApiResponse {
  lot?: UnassignedZone
}

export const GetLot = async (
  accessToken: string,
  id?: string
): Promise<GetLotResponse> =>
  api
    .get(GetLotPath(id), AuthHeader(accessToken))
    .then(StandardGetHandler("lot"))
    .catch(StandardErrorHandler)
