import { Outlet } from "react-router-dom"
import { LockProvider } from "@/context/LockContext"
import ScrollToTop from "./ScrollToTop"
import { Toaster } from "../ui/sonner"

const Root = () => {
  return (
    <LockProvider>
      <ScrollToTop />
      <Toaster />
      <Outlet />
    </LockProvider>
  )
}

export default Root
