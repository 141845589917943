import { cn } from "@/lib/utils"
import { ActiveToggle } from "./inputs"
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger
} from "@/components/ui/hover-card"
import { Button } from "@/components/ui/button"
import { Check, Pencil, X } from "lucide-react"
import { useState } from "react"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "@/components/ui/select"
import { FormField } from "@/components/ui/form"
import { LabelledInfoHover } from "../../Hoverable/LabelledInfoHover"

const PageLabel = "spotsandlots_edit"

const AvailabilityCardHeader = ({
  className,
  isOverride,
  watch,
  control,
  setValue,
  activeName,
  titleName,
  overrideTypeName,
  onDelete
}) => {
  return (
    <div
      className={cn(
        className,
        "grid grid-cols-[auto_auto_1fr_auto] content-center gap-6",
        isOverride && "grid-cols-[auto_auto_1fr_auto_auto]"
      )}>
      <ActiveToggle watch={watch} setValue={setValue} name={activeName} />
      <Title name={titleName} watch={watch} setValue={setValue} />
      <span />
      {isOverride && (
        <div className="flex gap-3 items-center">
          <div className="flex gap-1">
            <Label htmlFor="additive">Effect</Label>
            <LabelledInfoHover
              displayKeyOnError
              labelKey={`${PageLabel}.availability_override_effect`}
            />
          </div>
          <FormField
            name={overrideTypeName}
            control={control}
            render={({ field }) => (
              <Select
                value={field.value}
                onValueChange={field.onChange}
                onBlur={field.onBlur}>
                <SelectTrigger>
                  <SelectValue />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value={false}>Add New Spots</SelectItem>
                  <SelectItem value={true}>Cap Current Spaces</SelectItem>
                </SelectContent>
              </Select>
            )}
          />
        </div>
      )}
      <HoverCard>
        <HoverCardTrigger asChild>
          <Button variant="link" size="icon" onClick={onDelete}>
            <X size={20} className="text-slate-700" />
          </Button>
        </HoverCardTrigger>
        <HoverCardContent side="top">
          <p>Delete this entry</p>
        </HoverCardContent>
      </HoverCard>
    </div>
  )
}

const Title = ({ name, watch, setValue }) => {
  const [editingTile, setEditingTitle] = useState(false)
  const [editTitleState, setEditTitleState] = useState(watch(name))

  return editingTile ?
      <div className="flex relative">
        <Input
          className="pr-20"
          value={editTitleState}
          onChange={(event) => setEditTitleState(event.target.value)}
          autoFocus
        />
        <Button
          onClick={() => {
            setEditingTitle(false)
            setValue(name, editTitleState)
          }}
          className="text-green-700 absolute top-2 right-7 h-6 cursor-pointer"
          variant="link"
          size="icon"
          asChild>
          <Check size={16} className="mx-2" />
        </Button>
        <Button
          onClick={() => {
            setEditTitleState(watch(name))
            setEditingTitle(false)
          }}
          className="text-slate-700 absolute top-2 right-0 h-6 cursor-pointer"
          variant="link"
          size="icon"
          asChild>
          <X size={16} className="mx-2" />
        </Button>
      </div>
    : <Button variant="link" onClick={() => setEditingTitle(true)}>
        <p>{watch(name)}</p>
        <Pencil size={16} className="mx-2" />
      </Button>
}

export default AvailabilityCardHeader
