import {
  api,
  AuthHeader,
  StandardApiResponse,
  StandardErrorHandler,
  StandardGetHandler
} from "../const"

export const GetSpotPath = (id?: string) => `v1/spots/${id}`
export const GetSpotQueryKey = (id?: string) => `spots-${id}`

export interface GetSpotResponse extends StandardApiResponse {
  lot?: UnassignedZone
}

export const GetSpot = async (
  accessToken: string,
  id?: string
): Promise<GetSpotResponse> =>
  api
    .get(GetSpotPath(id), AuthHeader(accessToken))
    .then(StandardGetHandler("spot"))
    .catch(StandardErrorHandler)
