import { Label } from "@/components/ui/label"
import { SpotReservationsTimeChart } from "./SpotReservationsTimeChart"
import { useAuth } from "@/context/AuthContext"
import { useQuery } from "@tanstack/react-query"
import {
  GetSpotReservations,
  GetSpotReservationsQueryKey,
  GetSpotReservationsResponse
} from "@/api/spots/GetSpotReservations"
import MockSpot from "./mock.json"
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle
} from "@/components/ui/card"
import { AmenitiesItem, ZoneBadges, ZoneItem } from "./const"
import { Spot } from "@/api/types/internal/Spot"
import { Skeleton } from "@/components/ui/skeleton"

interface ParkingSpotSectionProps {
  spot: Spot
}

const getParkingSpotBadges = (spot: Spot) => {
  const badges = ["Assigned", spot.status]
  return badges
}

export const ParkingSpotSection = ({ spot }: ParkingSpotSectionProps) => {
  const { accessToken } = useAuth()
  const { data } = useQuery<GetSpotReservationsResponse>({
    queryKey: [GetSpotReservationsQueryKey(spot.id)],
    queryFn: () => GetSpotReservations(accessToken, spot.id)
  })

  return (
    <Card>
      <CardHeader>
        <div className="flex gap-3 content-center">
          <CardTitle className="text-lg font-normal">{spot.name}</CardTitle>
          <ZoneBadges badges={getParkingSpotBadges(spot)} />
        </div>
        <CardDescription>
          {spot ? spot.description : <Skeleton className="w-full h-12" />}
        </CardDescription>
      </CardHeader>
      <CardContent>
        <div className="grid grid-cols-[1fr_2fr] gap-6 items-center">
          <div className="grid grid-cols-2 gap-6 content-start">
            <ZoneItem
              label="Max Vehicle Height"
              content={`${MockSpot.max_height_supported} ft`}
            />
            <ZoneItem label="Floor" content={MockSpot.floor} />
            <ZoneItem
              label="Space For Bigger Vehicles"
              content={MockSpot.has_space_for_bigger_vehicles}
            />
            <AmenitiesItem
              label="Amenities"
              amenities={spot?.amenities.map((amenity) => ({
                ...amenity
              }))}
            />
          </div>
          <div className="grid gap-1 text-center">
            <Label className="font-bold">Today's Reservations</Label>
            <SpotReservationsTimeChart reservations={data?.reservations} />
          </div>
        </div>
      </CardContent>
    </Card>
  )
}

export const ParkingSpotSectionSkeleton = () => (
  <Card>
    <CardHeader>
      <div className="flex gap-3 items-center">
        <CardTitle className="text-lg font-normal">
          <Skeleton className="h-6 w-72" />
        </CardTitle>
        <div className="flex gap-1">
          <Skeleton className="h-4 w-16 rounded-full" />
          <Skeleton className="h-4 w-16 rounded-full" />
          <Skeleton className="h-4 w-16 rounded-full" />
        </div>
      </div>
      <CardDescription>
        <Skeleton className="w-full h-10" />
      </CardDescription>
    </CardHeader>
    <CardContent>
      <div className="grid grid-cols-[1fr_2fr] gap-6 items-center">
        <div className="grid grid-cols-2 gap-6">
          {Array.apply(null, Array(4)).map(() => (
            <div className="grid gap-1 content-start">
              <Label>
                <Skeleton className="w-16 h-4" />
              </Label>
              <Skeleton className="w-20 h-4" />
            </div>
          ))}
        </div>
        <div className="grid gap-1 text-center">
          <Label className="font-bold w-full flex justify-center">
            <Skeleton className="w-32 h-5" />
          </Label>
          <Skeleton className="h-full w-full max-h-48 min-h-32" />
        </div>
      </div>
    </CardContent>
  </Card>
)
