import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { Check, Pencil, X } from "lucide-react"
import { useState } from "react"
import { UseFormSetValue, UseFormWatch } from "react-hook-form"

export interface EditableTitleProps {
  name: string
  watch: UseFormWatch<any>
  setValue: UseFormSetValue<any>
}

export const Title = ({ name, watch, setValue }: EditableTitleProps) => {
  const [editingTile, setEditingTitle] = useState(false)
  const [editTitleState, setEditTitleState] = useState(watch(name))

  return editingTile ?
      <div className="flex relative">
        <Input
          className="pr-20"
          value={editTitleState}
          onChange={(event) => setEditTitleState(event.target.value)}
          autoFocus
        />
        <Button
          onClick={() => {
            setEditingTitle(false)
            setValue(name, editTitleState, {
              shouldDirty: true,
              shouldTouch: true,
              shouldValidate: true
            })
          }}
          className="text-green-700 absolute top-2 right-7 h-6 cursor-pointer"
          variant="link"
          size="icon"
          asChild>
          <Check size={16} className="mx-2" />
        </Button>
        <Button
          onClick={() => {
            setEditTitleState(watch(name))
            setEditingTitle(false)
          }}
          className="text-slate-700 absolute top-2 right-0 h-6 cursor-pointer"
          variant="link"
          size="icon"
          asChild>
          <X size={16} className="mx-2" />
        </Button>
      </div>
    : <Button variant="link" onClick={() => setEditingTitle(true)}>
        <p>{watch(name)}</p>
        <Pencil size={16} className="mx-2" />
      </Button>
}
