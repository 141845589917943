import { TypographyH3 } from "@/components/ui/typography/h3"
import { GetNewText, NoneAvailableText } from "./const"
import { AccordionFormClasses } from "../../const"
import { CardRadioGroup } from "@/components/custom/Forms/CardRadioGroup"
import { useReservationForm } from "../../context/ReservationFormContext"
import { FormCheckbox } from "@/components/custom/Forms/FormInputs"
import { Button } from "@/components/ui/button"
import { useEffect, useState } from "react"
import { toast } from "sonner"
import { PayViaCredits } from "./PayViaCredits"

export const AddParkingPacksForm = ({ parkerHasCredits }: any) => {
  const [isPayViaCreditsChecked, setIsPayViaCreditsChecked] = useState(false)
  const [isSeasonalPackSelected, setIsSeasonalPackSelected] = useState(false)
  const { control, form, facilityParkingPacks, parker } = useReservationForm()
  const valueIsSelected = form?.watch("purchasingPack")

  useEffect(() => {
    if (form?.watch("usingPurchasedPack") && form?.watch("code")) {
      form.setValue("code", undefined)
      toast.warning(
        "Your discount code has been cleared as a result of applying a parking pass."
      )
    }
  }, [form?.watch("usingPurchasedPack"), form?.watch("code")])

  if (!control) {
    return <></>
  }

  if ((!facilityParkingPacks || facilityParkingPacks.length === 0) && !parkerHasCredits) {
    return (
      <section className={AccordionFormClasses}>
        <TypographyH3 className="text-muted-foreground text-md">
          {NoneAvailableText}
        </TypographyH3>
      </section>
    )
  }

  return (
    <section className={AccordionFormClasses}>
      <TypographyH3 className="text-md">
        Discount Parking
      </TypographyH3>
      {
        (facilityParkingPacks && facilityParkingPacks?.length > 0) && (
          <div className="flex flex-col gap-1">
            <div
              data-state={valueIsSelected ? "open" : "closed"}
              className="gap-3 justify-between content-center hidden data-[state=open]:flex">
              <FormCheckbox
                control={control}
                name="usingPurchasedPack"
                side="right"
                label="Apply To This Transaction"
                flexAlign
              />
              <Button
                type="button"
                onClick={() => {
                  form?.setValue("purchasingPack", "")
                  form?.setValue("usingPurchasedPack", false)
                  form?.setValue("payViaCredits", false)
                  setIsSeasonalPackSelected(false)
                  setIsPayViaCreditsChecked(false)
                }}
                variant="outline">
                Cancel
              </Button>
            </div>
            <CardRadioGroup
              control={control}
              name="purchasingPack"
              viewLabel={false}
              onSelectionChange={(value, item) => {
                if (item.hasUnlimitedParkings) {
                  setIsSeasonalPackSelected(true)
                } else {
                  setIsSeasonalPackSelected(false)
                }
              }}
              items={facilityParkingPacks.map((pack) => ({
                label: pack.name,
                description: pack.description,
                price: `$${pack.price}`,
                value: pack.id,
                iconName: pack.iconName,
                hasUnlimitedParkings: pack.allowUnlimitedParkings,
                disabled: isPayViaCreditsChecked && pack.allowUnlimitedParkings
              }))}
            />
          </div>
        )
      }
      {parkerHasCredits && (
        <PayViaCredits control={control}
          parker={parker}
          disabled={isSeasonalPackSelected}
          onCheckedChange={(isChecked: boolean) => {
          setIsPayViaCreditsChecked(isChecked)
        }} />
      )}
    </section>
  )
}
