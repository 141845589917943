import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from "@/components/ui/form"
import { Input } from "@/components/ui/input"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "@/components/ui/select"
import { Checkbox } from "../ui/checkbox"
import { USStates, vehicleType } from "@/constants/constants"
import { v4 as uuidv4 } from "uuid"
import { LabelledInfoHover } from "./Hoverable/LabelledInfoHover"

const PageLabel = "reservation_create"

const VehicleFormContent = ({ control }) => {
  return (
    <>
      <FormField
        control={control}
        name="make"
        render={({ field }) => (
          <FormItem className="min-h-[100px]">
            <FormLabel>Vehicle Make</FormLabel>
            <FormControl>
              <Input placeholder="Honda" {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={control}
        name="model"
        render={({ field }) => (
          <FormItem className="min-h-[100px]">
            <FormLabel>Vehicle Model</FormLabel>
            <FormControl>
              <Input placeholder="Civic" {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={control}
        name="type"
        render={({ field }) => (
          <FormItem className="min-h-[100px]">
            <FormLabel>Vehicle Type</FormLabel>
            <FormControl>
              <Select onValueChange={field.onChange} value={field.value}>
                <SelectTrigger>
                  <SelectValue placeholder={vehicleType.SEDAN} />
                </SelectTrigger>
                <SelectContent>
                  {Object.keys(vehicleType).map((type) => (
                    <SelectItem key={uuidv4()} value={vehicleType[type]}>
                      {vehicleType[type]}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={control}
        name="color"
        render={({ field }) => (
          <FormItem className="min-h-[100px]">
            <FormLabel>Color</FormLabel>
            <FormControl>
              <Input placeholder="Color" {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={control}
        name="licenseNumber"
        render={({ field }) => (
          <FormItem className="min-h-[100px]">
            <FormLabel>License Plate</FormLabel>
            <FormControl>
              <Input placeholder="License Plate" {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={control}
        name="registeredState"
        render={({ field }) => (
          <FormItem className="min-h-[100px]">
            <FormLabel>Registration State</FormLabel>
            <FormControl>
              <Select onValueChange={field.onChange} value={field.value}>
                <SelectTrigger>
                  <SelectValue placeholder={USStates[field.value].full}>
                    {USStates[field.value].full}
                  </SelectValue>
                </SelectTrigger>
                <SelectContent>
                  {Object.keys(USStates).map((state) => (
                    <SelectItem
                      key={uuidv4()}
                      value={USStates[state].abbreviation}>
                      {USStates[state].full}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={control}
        name="isEv"
        render={({ field }) => {
          return (
            <FormItem className="flex flex-row items-center space-x-2 space-y-0 ">
              <FormControl>
                <Checkbox
                  checked={field.value}
                  onCheckedChange={field.onChange}
                />
              </FormControl>
              <div className="space-y-1 leading-none">
                <FormLabel className="flex items-center gap-3">
                  <p>Is an electric vehicle</p>
                  <LabelledInfoHover
                    displayKeyOnError
                    iconSize={16}
                    labelKey={`${PageLabel}.electric_vehicle`}
                  />
                </FormLabel>
              </div>
            </FormItem>
          )
        }}
      />
    </>
  )
}

export default VehicleFormContent
