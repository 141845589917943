import "./sentry"
import React from "react"
import ReactDOM from "react-dom/client"
import "@/index.css"
import { AuthContextProvider } from "@/context/AuthContext"
import App from "@/App"

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <AuthContextProvider>
      <App />
    </AuthContextProvider>
  </React.StrictMode>
)
