import { useState } from "react"
import { PlusCircle } from "lucide-react"
import { useQuery } from "@tanstack/react-query"
import { getParker } from "@/api/get"
import Loading from "@/components/custom/Loading"
import SimpleDialog from "@/components/custom/SimpleDialog"
import VehicleCard from "@/components/custom/VehicleCard"
import VehicleForm from "@/components/custom/VehicleForm"
import { Button } from "@/components/ui/button"
import { Separator } from "@/components/ui/separator"
import { useAuth } from "@/context/AuthContext"
import Fallback from "@/components/custom/Fallback"
import { blackButtonStyles } from "@/lib/utils"
import { useCustomAlert } from "@/hooks/useCustomAlert"

const AccountVehiclesPage = () => {
  const [dialogOpen, setDialogOpen] = useState(null)
  const { accessToken } = useAuth()
  const { showMessage } = useCustomAlert()
  const { data, isPending, error, refetch } = useQuery({
    queryKey: ["parker", accessToken],
    queryFn: () => getParker(accessToken)
  })

  const onSuccess = () => {
    setDialogOpen(null)
    refetch()
    showMessage("Your vehicles have been updated!", "success")
  }

  if (isPending) return <Loading />

  if (error)
    return (
      <Fallback message="There was an issue retrieving vehicle data. Please try again." />
    )

  const { vehicles: vehicleData } = data

  const vehicles = vehicleData?.map((vehicleDetails) => {
    const vehicleDialogElement = (
      <SimpleDialog
        isDialogOpen={dialogOpen === `edit-${vehicleDetails.id}`}
        setIsDialogOpen={() => {
          if (dialogOpen === `edit-${vehicleDetails.id}`) {
            setDialogOpen(null)
          } else {
            setDialogOpen(`edit-${vehicleDetails.id}`)
          }
        }}
        dialogTrigger={
          <span className={blackButtonStyles()}>
            Edit
          </span>
        }>
        <VehicleForm
          actionType="EDIT"
          preExistingValues={vehicleDetails}
          onSuccess={onSuccess}
        />
      </SimpleDialog>
    )
    return (
      <VehicleCard
        key={vehicleDetails.id}
        vehicleDetails={vehicleDetails}
        vehicleDialog={vehicleDialogElement}
        refetch={refetch}
      />
    )
  })

  const addDialogTrigger = (
    <Button variant="secondary" asChild>
      <span className="inline-flex items-center">
        <PlusCircle />
        <span className="ml-2">Add a Vehicle</span>
      </span>
    </Button>
  )

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <div>
          <h3 className="text-lg font-medium">Vehicles</h3>
          <p className="text-sm">Manage your vehicles</p>
        </div>
        <SimpleDialog
          isDialogOpen={dialogOpen === "add"}
          dialogTrigger={addDialogTrigger}
          setIsDialogOpen={() => {
            if (dialogOpen === "add") {
              setDialogOpen(null)
            } else {
              setDialogOpen("add")
            }
          }}>
          <VehicleForm onSuccess={onSuccess} />
        </SimpleDialog>
      </div>
      <Separator />
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">{vehicles}</div>
    </div>
  )
}

export default AccountVehiclesPage
