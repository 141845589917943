import { z } from "zod"

export const ZipCodeSchema = z
  .string()
  .length(5)
  .refine((str) => /^\d{5}$/.test(str))

export const AddressSchema = z.object({
  address1: z.string(),
  address2: z.coerce.string().optional(),
  city: z.string(),
  state: z.string().length(2),
  zipCode: ZipCodeSchema
})
