import { cn } from "@/lib/utils"

export function TypographyH4({ children, className }) {
  return (
    <h4
      className={cn(
        "scroll-m-20 text-xl font-semibold tracking-wide",
        className
      )}>
      {children}
    </h4>
  )
}
