import ResetPasswordForm from "@/components/custom/ResetPasswordForm"

const ResetPasswordPage = () => {
  return (
    <div className="px-4 lg:px-10 py-6">
      <ResetPasswordForm />
    </div>
  )
}

export default ResetPasswordPage
