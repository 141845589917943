import { PlusCircle } from "lucide-react"

import { Button } from "@/components/ui/button"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"
import { Link } from "react-router-dom"
import Loading from "@/components/custom/Loading"
import Fallback from "@/components/custom/Fallback"
import { useAuth } from "@/context/AuthContext"
import { useQuery } from "@tanstack/react-query"
import { GetOwner } from "@/api/GetOwner"
import { useMemo } from "react"
import { FacilitiesTabContent } from "./components/FacilitiesTabContent"

export const ListFacilitiesPage = () => {
  const { accessToken } = useAuth()
  const { data, isPending, isError, refetch } = useQuery({
    queryKey: [`owner`],
    queryFn: () => GetOwner(accessToken)
  })

  const categorized = useMemo(
    () => categorizeFacilities(data?.owner?.facilities ?? []),
    [data?.owner]
  )

  if (isPending || !data?.owner) return <Loading className="" />

  if (isError || data.isError) return <Fallback />

  return (
    <main className="grid flex-1 items-start gap-4 p-4 sm:px-6 sm:py-0 md:gap-8 mt-6">
      <Tabs defaultValue="all">
        <div className="flex items-center">
          <TabsList>
            <TabsTrigger value="all">All</TabsTrigger>
            <TabsTrigger value="active">Active</TabsTrigger>
            <TabsTrigger value="inactive" className="hidden sm:flex">
              Inactive
            </TabsTrigger>
          </TabsList>
          <div className="ml-auto flex items-center gap-2">
            <Link to="/owner-dashboard/facilities/new">
              <Button size="sm" className="h-8 gap-1">
                <PlusCircle className="h-3.5 w-3.5" />
                <span className="sr-only sm:not-sr-only sm:whitespace-nowrap">
                  Add Facility
                </span>
              </Button>
            </Link>
          </div>
        </div>
        <TabsContent value="all">
          <FacilitiesTabContent
            refetch={refetch}
            facilitiesArray={data.owner.facilities ?? []}
          />
        </TabsContent>
        <TabsContent value="active">
          <FacilitiesTabContent
            refetch={refetch}
            facilitiesArray={categorized.active}
          />
        </TabsContent>
        <TabsContent value="inactive">
          <FacilitiesTabContent
            refetch={refetch}
            facilitiesArray={categorized.inactive}
          />
        </TabsContent>
      </Tabs>
    </main>
  )
}

interface CategorizedFacilities {
  active: Facility[]
  inactive: Facility[]
}

const categorizeFacilities = (
  allFacilities: Facility[]
): CategorizedFacilities => ({
  active: allFacilities.filter((facility) => facility.status === "Active"),
  inactive: allFacilities.filter((facility) => facility.status === "Inactive")
})
