import { format } from "date-fns"
import { useState } from "react"
import { Button } from "@/components/ui/button"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from "@/components/ui/form"
import { ControlledPopover, usePopoverContext } from "@/components/custom/ControlledPopover";
import { PopoverContent, PopoverTrigger } from "@/components/ui/popover"
import { Calendar } from "@/components/ui/calendar"
import { cn, getYearMonthDay, sortFacilities } from "@/lib/utils"
import { CalendarIcon } from "lucide-react"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "@/components/ui/select"
import { useParkingSearchForm } from "@/hooks/useParkingSearchForm"

// TODO: Make this a utility (used in MobileParkSearchForm as well)
const DatePickerCalendar = ({ field, disableSelectionAvailability }) => {
  const { setOpen } = usePopoverContext();

  return (
    <Calendar
      initialFocus
      mode="single"
      selected={field.value}
      onSelect={(date) => {
        field.onChange(date);
        setOpen(false);
      }}
      disabled={disableSelectionAvailability}
    />
  );
}

const ParkSearchForm = ({
  searchQuery,
  handleSearchQuery,
  facilities,
  showTitle = true,
  labelClassName = "",
  isHomePage = false
}) => {
  const { form, facilityOptions, disableSelectionAvailability } = useParkingSearchForm(searchQuery, sortFacilities(facilities))

  const onSubmit = async ({ facility, checkinDate }) => {
    const formattedDate = getYearMonthDay(checkinDate)
    const query = {
      facility,
      checkinDate: formattedDate
    }

    handleSearchQuery(query)
  }

  const HomePageForm = () => {
    return (
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col md:flex-row gap-2 justify-between">
          {showTitle && (<h2>Book a Spot</h2>)}
          <FormField
            control={form.control}
            name="facility"
            render={({ field }) => (
              <FormItem className="w-full">
                <Select onValueChange={field.onChange} value={field.value}>
                  <FormControl className="border-none">
                    <SelectTrigger>
                      <SelectValue placeholder="Select a parking facility" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {facilityOptions.map(({ id, name }) => (
                      <SelectItem value={id} key={id}>
                        {name}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="checkinDate"
            render={({ field }) => (
              <FormItem className="w-full md:w-auto">
                <ControlledPopover>
                  <PopoverTrigger asChild>
                    <FormControl className="border-none">
                      <Button
                        variant={"outline"}
                        className={cn(!field.value && "text-muted-foreground", "w-full")}
                      >
                        {field.value ?
                          format(field.value, "PPP")
                          : <span>Pick a date</span>}
                        <CalendarIcon className="ml-1 h-4 w-4 opacity-50" />
                      </Button>
                    </FormControl>
                  </PopoverTrigger>
                  <PopoverContent className="w-auto p-0" align="start">
                    <DatePickerCalendar field={field} disableSelectionAvailability={disableSelectionAvailability} />
                  </PopoverContent>
                </ControlledPopover>
                <FormMessage />
              </FormItem>
            )}
          />
          <Button type="submit" variant="orange" className="w-full lg:w-[unset] hover:bg-white">
            Search
          </Button>
        </form>
      </Form>
    )
  }

  return (
    <>
      {
        isHomePage
          ? (<HomePageForm />)
          : (
            <Form {...form}>
              <form
                onSubmit={form.handleSubmit(onSubmit)}
                className="py-3 space-y-3 lg:space-y-6 lg:py-6 rounded-xl max-h-content">
                {showTitle && (
                  <h2 className="scroll-m-20 border-b pb-2 text-2xl font-semibold tracking-wide first:mt-0 lg:text-3xl">
                    Book a Spot
                  </h2>
                )}
                <div className="grid gap-3">
                  <FormField
                    control={form.control}
                    name="facility"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel className={labelClassName}>
                          Parking Facility
                        </FormLabel>
                        <Select onValueChange={field.onChange} value={field.value}>
                          <FormControl>
                            <SelectTrigger>
                              <SelectValue placeholder="Select a parking facility" />
                            </SelectTrigger>
                          </FormControl>
                          <SelectContent>
                            {facilityOptions.map(({ id, name }) => (
                              <SelectItem value={id} key={id}>
                                {name}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-3 items-center">
                  <div>
                    <FormField
                      control={form.control}
                      name="checkinDate"
                      render={({ field }) => (
                        <FormItem className="flex flex-col">
                          <FormLabel className={labelClassName}>Date</FormLabel>
                          <ControlledPopover>
                            <PopoverTrigger asChild>
                              <FormControl>
                                <Button
                                  variant={"outline"}
                                  className={cn(
                                    "pl-3 text-left font-normal",
                                    !field.value && "text-muted-foreground"
                                  )}>
                                  {field.value ?
                                    format(field.value, "PPP")
                                    : <span>Pick a date</span>}
                                  <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                                </Button>
                              </FormControl>
                            </PopoverTrigger>
                            <PopoverContent className="w-auto p-0" align="start">
                              <DatePickerCalendar field={field} disableSelectionAvailability={disableSelectionAvailability} />
                            </PopoverContent>
                          </ControlledPopover>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                </div>
                <Button type="submit" variant="orange" className="w-full lg:w-[unset]">
                  Search
                </Button>
              </form>
            </Form>
          )
      }
    </>
  )
}

export default ParkSearchForm
