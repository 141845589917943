import { Link } from "react-router-dom"
import { CircleXIcon, Edit, Loader2, MoreHorizontal } from "lucide-react"

import { Badge } from "@/components/ui/badge"
import { Button } from "@/components/ui/button"
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle
} from "@/components/ui/card"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from "@/components/ui/table"
import { AddressBlock } from "@/components/custom/Address/AddressBlock"
import {
  DeleteLot,
  DeleteSpot,
  DeleteSpotResponse
} from "@/api/spot-lot/DeleteSpotOrLot"
import { Spot } from "@/api/types/internal/Spot"
import { useCallback, useState } from "react"
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from "../ui/dialog"
import { Label } from "../ui/label"
import { useAuth } from "@/context/AuthContext"

const badgeVariant: Record<string, string> = {
  active: "",
  draft: "secondary",
  inactive: "outline"
}

const SpotsLotsTable = ({ spotsList, onDelete }: any) => {
  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead className="hidden w-[100px] sm:table-cell">
            <span className="sr-only">Image</span>
          </TableHead>
          <TableHead>Name</TableHead>
          <TableHead>Facility</TableHead>
          <TableHead>Description</TableHead>
          <TableHead>Status</TableHead>
          <TableHead className="hidden md:table-cell">Type</TableHead>
          <TableHead>
            <span className="sr-only">Actions</span>
          </TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {spotsList?.map((spotLot: any) => {
          const image = spotLot?.images?.find((img: any) => img.isThumbnail)

          return (
            <TableRow key={spotLot?.id}>
              <TableCell className="hidden sm:table-cell">
                {image && (
                  <img
                    alt={image.name}
                    className="aspect-square rounded-md object-cover"
                    height="64"
                    src={image.file}
                    width="64"
                  />
                )}
              </TableCell>
              <TableCell>
                <p>{spotLot?.name}</p>
              </TableCell>
              <TableCell>
                <p className="font-medium">{spotLot?.facility.name}</p>
                <AddressBlock {...spotLot?.facility.address} />
              </TableCell>
              <TableCell>
                <p>{spotLot?.description}</p>
              </TableCell>
              <TableCell>
                <Badge className="capitalize" variant={badgeVariant[status]}>
                  {spotLot?.status.toLowerCase()}
                </Badge>
              </TableCell>
              <TableCell className="hidden md:table-cell font-medium">
                {spotLot?.parkingLocationType === "SPOT" ? "Spot" : "Lot"}
              </TableCell>
              <TableCell>
                {spotLot?.status !== "Deleted" && (
                  <div className="flex gap-3 items-center justify-end">
                    <Button variant="outline" asChild>
                      <Link
                        to={`/owner-dashboard/facilities/${spotLot?.facility?.id}/${spotLot?.parkingLocationType === "SPOT" ? "spots" : "lots"}/${spotLot?.id}/edit`}>
                        <Edit className="mr-2 h-4 w-4" /> Edit{" "}
                        {spotLot?.parkingLocationType === "SPOT" ?
                          "Spot"
                        : "Lot"}
                      </Link>
                    </Button>
                    <Button
                      onClick={() =>
                        onDelete(
                          spotLot,
                          spotLot?.parkingLocationType === "SPOT"
                        )
                      }
                      variant="destructive">
                      <CircleXIcon className="h-4 w-4 mr-2" /> Delete
                    </Button>
                  </div>
                )}
              </TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  )
}

export const SpotLotTab = ({ spotsList, refetch }: any) => {
  const { accessToken } = useAuth()
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [spotLotToDelete, setSpotLotToDelete] = useState<any>(undefined)
  const [isSpotToDelete, setIsSpotToDelete] = useState(false)

  const deleteSpotLot = useCallback(
    (id: string, isSpot: boolean) =>
      isSpot ?
        DeleteSpot(accessToken, id).then(() => refetch?.())
      : DeleteLot(accessToken, id).then(() => refetch?.()),
    [spotLotToDelete]
  )

  return (
    <Card>
      <CardHeader>
        <CardTitle>Your Parking Spots and Lots</CardTitle>
        <CardDescription>
          Manage your spots and view their sales performance.
        </CardDescription>
      </CardHeader>
      <CardContent>
        <SpotsLotsTable
          spotsList={spotsList}
          onDelete={(spotLot: Spot | UnassignedZone, isSpot: boolean) => {
            setDeleteDialogOpen(true)
            setSpotLotToDelete(spotLot)
            setIsSpotToDelete(isSpot)
          }}
        />
      </CardContent>
      {spotLotToDelete && (
        <DeleteDialog
          open={deleteDialogOpen}
          isSpot={isSpotToDelete}
          data={spotLotToDelete}
          onSubmit={deleteSpotLot}
          onOpenChange={setDeleteDialogOpen}
        />
      )}
    </Card>
  )
}

interface DeleteDialogProps {
  data?: Spot | UnassignedZone
  isSpot: boolean
  open: boolean
  onOpenChange?: (open: boolean) => void
  onSubmit?: (id: string, isSpot: boolean) => Promise<DeleteSpotResponse>
}

const DeleteDialog = ({
  open,
  onOpenChange,
  onSubmit,
  data,
  isSpot
}: DeleteDialogProps) => {
  const [deleteIsPending, setDeleteIsPending] = useState(false)
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="px-10">
        <DialogHeader>
          <DialogTitle>
            Are you sure you want to delete this {isSpot ? "spot" : "lot"}?
          </DialogTitle>
        </DialogHeader>
        <div className="my-10 grid grid-cols-1 gap-6">
          <DialogDataRow label="Name" content={data?.name} />
          <DialogDataRow label="Status" content={data?.status} />
        </div>
        <DialogFooter>
          <Button variant="outline" onClick={() => onOpenChange?.(false)}>
            Cancel
          </Button>
          <Button
            disabled={deleteIsPending}
            variant="destructive"
            onClick={() => {
              setDeleteIsPending(true)
              onSubmit?.(data?.id ?? "", isSpot)
                .then(() => {
                  setDeleteIsPending(false)
                  onOpenChange?.(false)
                })
                .catch(() => setDeleteIsPending(false))
            }}>
            {deleteIsPending && (
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
            )}
            Delete
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

interface DialogDataRowProps {
  label: string
  content?: string | number
}

const DialogDataRow = ({
  label,
  content,
  children
}: React.PropsWithChildren<DialogDataRowProps>) => {
  return (
    <div className="grid grid-cols-[auto_auto] gap-3 items-center">
      <Label className="font-bold">{label}</Label>
      {content && <p className="text-right">{content}</p>}
      {children}
    </div>
  )
}
