import {
  api,
  AuthHeader,
  keysToSnakeCase,
  StandardApiResponse,
  StandardErrorHandler,
  StandardGetHandler,
  StandardPushHandler
} from "../const"
import { ReservationResponse } from "../types/internal/Reservation"

export const BuySeasonPassPath = "/v1/shop/buy/"

export interface BuySeasonPassRequest {
  id: string,
  productType: string
}

export interface BuySeasonPassResponse extends StandardApiResponse {
  reservation?: ReservationResponse
}

export const BuyParkingPackOrSeasonPass = (
  request: BuySeasonPassRequest,
  accessToken: string
) =>
  api
    .post(BuySeasonPassPath, keysToSnakeCase(request), AuthHeader(accessToken))
    .then(StandardGetHandler("reservation"))
    .catch(StandardErrorHandler)
