import { deepMapKeys } from "@/lib/deepMapKeys"
import { buildRequest } from "."
import { handleError, clearError } from '@/lib/errorHandler';
import { api } from '@/api/const'

// To override the default error message, pass a customError string to the function
export const patchData = async (uri, payload, accessToken, customError) => {
  const { url, headers, serializedPayload } = buildRequest(
    uri,
    accessToken,
    payload
  )

  try {
    clearError()
    const result = await api.patch(url, serializedPayload, {
      headers
    })

    if (result.data && result.data.length) {
      return result.data.map((item) => deepMapKeys(item))
    } else {
      return result.data && deepMapKeys(result.data)
    }
  } catch (error) {
    handleError({
      error: customError || error.message
    })
    throw error
  }
}

export const editVehicle = async (formData, accessToken) => {
  return await patchData("v1/parker/vehicle", formData, accessToken)
}

export const editOwner = async (id, formData, accessToken) => {
  return await patchData(`v1/owner/${id}`, formData, accessToken)
}

export const editParker = async (id, formData, accessToken) => {
  return await patchData(`v1/parker/${id}`, formData, accessToken)
}

export const editMember = async (formData, accessToken) => {
  return await patchData("v1/owner/team", formData, accessToken)
}

export const patchLot = async (id, data, accessToken) => {
  return await patchData(`v1/lots/${id}`, data, accessToken)
}
