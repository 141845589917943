import { UpdateFacility } from "@/api/facility/UpdateFacility"
import { GetOwner, GetOwnerResponse } from "@/api/GetOwner"
import { EditAmenitiesTab } from "@/components/custom/Forms/UpdateFormGenerics/EditAmenitiesTab"
import { UpdateFormTabsWrapperChildren } from "@/components/custom/Forms/UpdateFormGenerics/UpdateFormTabWrapper"
import Loading from "@/components/custom/Loading"
import { useAuth } from "@/context/AuthContext"
import { amenitiesToIntArray, IAmenitiesSchema } from "@/hooks/useAmenitiesForm"
import { useQuery } from "@tanstack/react-query"
import { useCallback } from "react"
import { useParams } from "react-router-dom"
import { useCustomAlert } from "@/hooks/useCustomAlert"

export const EditFacilityAmenities = ({
  data,
  ...tabProps
}: UpdateFormTabsWrapperChildren<Facility, any>) => {
  const { accessToken } = useAuth()
  const { facilityId } = useParams()
  const { showMessage } = useCustomAlert()
  const { data: ownerData, isPending: ownerIsPending } =
    useQuery<GetOwnerResponse>({
      queryKey: [`owner`],
      queryFn: () => GetOwner(accessToken)
    })

  const submitHandler = useCallback(
    (result: IAmenitiesSchema) => {
      if (facilityId && ownerData?.owner?.id) {
        return UpdateFacility(accessToken, facilityId, {
          ...data,
          amenities: amenitiesToIntArray(result),
          owner: ownerData.owner.id
        }).then(() => showMessage("Your Facility Amenities have been successfully updated!", "success"))
      }
    },
    [facilityId, ownerData?.owner, ownerIsPending, data, accessToken]
  )

  if (ownerIsPending) return <Loading className="" />

  return (
    <EditAmenitiesTab data={data} submitHandler={submitHandler} {...tabProps} />
  )
}
