import { UpdateFormTabsWrapperTab } from "@/components/custom/Forms/UpdateFormGenerics/UpdateFormTabWrapper"
import { EditLotLocation } from "./components/EditLotLocation"
import { EditLotDetails } from "./components/EditLotDetails"
import { EditLotAvailability } from "./components/EditLotAvailability"

export const EditLotTabs: UpdateFormTabsWrapperTab<
  UnassignedZone,
  UnassignedZone
>[] = [
  {
    tabName: "Details",
    tabId: "details",
    title: "Details",
    subtitle:
      "General information about the lot to help customers know they're in the right place.",
    Render: EditLotDetails
  },
  {
    tabName: "Location",
    tabId: "location",
    title: "Geographic Location",
    subtitle: "GPS location of this specific lot.",
    Render: EditLotLocation
  },
  {
    tabName: "Availability",
    tabId: "availability",
    title: "Availability & Overrides",
    subtitle:
      "Set pricing and availability based on days of the week, and set temporary overrides for sales, events, or any other need.",
    Render: EditLotAvailability
  }
]
