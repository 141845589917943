import { z } from "zod"

export const PacksSchema = z.object({
  usingPack: z.string().uuid().optional().or(z.literal("")),
  usingPurchasedPack: z.coerce.boolean(),
  purchasingPack: z.string().uuid().optional().or(z.literal(""))
})

export type PacksForm = z.infer<typeof PacksSchema>

export const UseExistingText =
  "Would you like to apply one of your applicable parking passes to this experience?"
export const GetNewText = "Would you like to purchase a parking pass today?"
export const NoneAvailableText =
  "Looks like no parking packs are available for this location yet. Check back soon!"
