import { useMemo, useState } from "react"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import { useForm } from "react-hook-form"
import { z } from "zod"

import { Button } from "@/components/ui/button"
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import { TypographyP } from "@/components/ui/typography/p"
import { Input } from "@/components/ui/input"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from "@/components/ui/form"
import { zodResolver } from "@hookform/resolvers/zod"
import { userRoles } from "@/constants/constants"
import { register } from "@/api/post"
import {
  requiredPassword,
  requiredString,
  contactSchema
} from "@/constants/formSchema"

const signUpFormSchema = contactSchema.merge(
  z.object({
    firstName: requiredString,
    lastName: requiredString,
    password: requiredPassword
  })
)

const defaultValues = {
  firstName: "",
  lastName: "",
  phone: "",
  email: "",
  password: ""
}

const SignUp = () => {
  const [error, setError] = useState(null)
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const promoCode = useMemo(() => {
    if (!searchParams.size) return "NEW20"

    return searchParams.get("promo") ?? ""
  }, [searchParams])

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    ...form
  } = useForm({
    resolver: zodResolver(signUpFormSchema),
    defaultValues
  })

  const onSubmit = async (formData) => {
    if (error) setError(null)

    try {
      // TODO: Assume all users are PARKER role for now
      const dataToSubmit = {
        ...formData,
        ...(promoCode ? { signupCode: promoCode.toUpperCase() } : {}),
        role: userRoles.PARKER
      }
      await register(dataToSubmit)

      navigate("/login")
    } catch (error) {
      if (error.response?.status === 400) {
        setError({
          ...error,
          message:
            "A user with this email already exists. Did you mean to log in?"
        })
      } else {
        setError({ ...error, message: "An error occurred. Please try again." })
      }
    }
  }

  return (
    <div className="px-4 lg:px-10 py-6">
      <Form {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Card className="mx-auto max-w-sm">
            <CardHeader>
              <CardTitle className="text-2xl">Sign up</CardTitle>
              <p>Create a free account.</p>
            </CardHeader>
            <CardContent>
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
                <FormField
                  control={control}
                  name="firstName"
                  render={({ field }) => (
                    <FormItem className="min-h-[100px]">
                      <FormLabel>First Name</FormLabel>
                      <FormControl>
                        <Input placeholder="Your First Name" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={control}
                  name="lastName"
                  render={({ field }) => (
                    <FormItem className="min-h-[100px]">
                      <FormLabel>Last Name</FormLabel>
                      <FormControl>
                        <Input placeholder="Your Last Name" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <FormField
                control={control}
                name="phone"
                render={({ field }) => (
                  <FormItem className="min-h-[100px]">
                    <FormLabel>Phone Number</FormLabel>
                    <FormControl>
                      <Input placeholder="Phone Number" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={control}
                name="email"
                render={({ field }) => (
                  <FormItem className="min-h-[100px]">
                    <FormLabel>Email</FormLabel>
                    <FormControl>
                      <Input placeholder="Your Email" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={control}
                name="password"
                render={({ field }) => (
                  <FormItem className="min-h-[100px]">
                    <FormLabel>Password</FormLabel>
                    <FormControl>
                      <Input
                        placeholder="Password"
                        type="password"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <div className="w-full py-4 min-h-16">
                {promoCode && (
                  <TypographyP className="text-sm italic">
                    Use promo code {promoCode.toUpperCase()} at checkout to get
                    a 20% discount on your order.
                  </TypographyP>
                )}
              </div>
              <div className="grid gap-4 mt-3">
                {error && error.message ?
                  <div>
                    <p className="text-red-500 text-sm">{error.message}</p>
                  </div>
                : null}
                <Button
                  disabled={isSubmitting}
                  type="submit"
                  className="w-full">
                  Sign up
                </Button>
              </div>
              <div className="mt-4 text-center text-sm">
                Already have an account?{" "}
                <Link to="/login" className="underline">
                  Log in
                </Link>
              </div>
            </CardContent>
          </Card>
        </form>
      </Form>
    </div>
  )
}

export default SignUp
