import { Skeleton } from "@/components/ui/skeleton"

export const CreateReservationSkeleton = () => {
  return (
    <div className="mx-auto max-w-5xl grid gap-6 md:m-12 lg:mx-auto p-4 py-0 my-6 lg:my-10">
      <Skeleton className="w-full h-16 my-2" />
      <div>
        <Skeleton className="w-full h-12 my-2" />
        <div className="grid gap-3 px-6">
          <Skeleton className="w-full h-12" />
          <Skeleton className="w-full h-12" />
          <Skeleton className="w-full h-12" />
        </div>
      </div>
      <div>
        <Skeleton className="w-full h-12 my-2" />
        <div className="grid gap-3 px-6">
          <Skeleton className="w-full h-12" />
          <Skeleton className="w-full h-12" />
          <Skeleton className="w-full h-12" />
        </div>
      </div><div>
        <Skeleton className="w-full h-12 my-2" />
        <div className="grid gap-3 px-6">
          <Skeleton className="w-full h-12" />
          <Skeleton className="w-full h-12" />
          <Skeleton className="w-full h-12" />
        </div>
      </div>
    </div>
  )
}