import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import { Separator } from "@/components/ui/separator"
import { Skeleton } from "@/components/ui/skeleton"

export const ShopConfirmationSkeleton = () => (
  <div className="p-6 max-w-5xl mx-auto lg:py-10">
    <div className="flex flex-col gap-6">
      <hgroup className="flex flex-col gap-3">
        <Skeleton className="w-full h-16" />
        <Skeleton className="w-full h-8" />
      </hgroup>
      <Card>
        <CardHeader>
          <Skeleton className="w-full h-12" />
          <Skeleton className="w-full h-8" />
        </CardHeader>
        <Separator />
        <CardContent>
          <div className="grid grid-cols-2 gap-6 pt-6">
            <div className="flex flex-col gap-6">
              <div className="flex flex-col gap-1">
                <Skeleton className="w-full h-8" />
                <Skeleton className="w-full h-8" />
              </div>
              <div className="flex flex-col gap-1">
                <Skeleton className="w-full h-8" />
                <Skeleton className="w-full h-8" />
              </div>
              <div className="flex flex-col gap-1">
                <Skeleton className="w-full h-8" />
                <Skeleton className="w-full h-8" />
              </div>
            </div>
            <div>
              <Card>
                <CardHeader>
                  <Skeleton className="w-full h-12" />
                  <Skeleton className="w-full h-8" />
                </CardHeader>
                <CardContent>
                  <div className="flex flex-col gap-6">
                    <Skeleton className="w-20 h-20" />
                    <div className="flex flex-col gap-1">
                      <Skeleton className="w-full h-8" />
                      <Skeleton className="w-full h-8" />
                    </div>
                  </div>
                </CardContent>
              </Card>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  </div>
)
