import { TypographyH2 } from "@/components/ui/typography/h2"
import { useQuery } from "@tanstack/react-query"
import Loading from "@/components/custom/Loading"
import Fallback from "@/components/custom/Fallback"
import { getSupport } from "@/api/get"
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger
} from "@/components/ui/accordion"

const FAQsPage = () => {
  const { data, isPending, error } = useQuery({
    queryKey: ["FAQs"],
    queryFn: () => getSupport()
  })

  if (isPending) return <Loading />

  if (error) return <Fallback />

  const { faq } = data

  const faqs = faq.map((faq, index) => {
    return (
      <AccordionItem key={faq.id} value={`item-${index}`}>
        <AccordionTrigger className="text-left">
          {faq.question}
        </AccordionTrigger>
        <AccordionContent>{faq.answer}</AccordionContent>
      </AccordionItem>
    )
  })
  return (
    <div className="m-auto px-4 mb-40 text-left h-fit max-w-screen-md">
      <TypographyH2 className="text-center my-4 pt-10">FAQs</TypographyH2>
      <Accordion>{faqs}</Accordion>
    </div>
  )
}

export default FAQsPage
