import {
  api,
  AuthHeader,
  StandardApiResponse,
  StandardErrorHandler,
  StandardGetHandler
} from "../const"
import { Reservation } from "../types/internal/Reservation"

export const GetSpotReservationsPath = (id?: string) =>
  `/v1/spots/${id}/reservations/`
export const GetSpotReservationsQueryKey = (id?: string) =>
  `spot-reservations-${id}`

export interface GetSpotReservationsResponse extends StandardApiResponse {
  reservations?: Reservation[]
}

export const GetSpotReservations = async (
  accessToken: string,
  id?: string
): Promise<GetSpotReservationsResponse> =>
  api
    .get(GetSpotReservationsPath(id), AuthHeader(accessToken))
    .then(StandardGetHandler("reservations"))
    .catch(StandardErrorHandler)
