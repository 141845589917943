import {
  api,
  arrayToCamelCase,
  AuthHeader,
  StandardApiResponse,
  StandardErrorHandler,
  StandardGetHandler
} from "../const"

import type { Reservation } from '../types/internal/Reservation'

export const ReservationsPath = `v1/owner/reservations/`
export const ReservationsQuery = `owner-reservations`

export interface GetOwnerReservationsResponse extends StandardApiResponse {
  reservations?: Reservation[]
}

export const GetOwnerReservations = async (
  accessToken: string,
  timePeriod?: string
): Promise<GetOwnerReservationsResponse> => {
  return api
    .get<ApiReservation[]>(`${ReservationsPath}?timeperiod=${timePeriod}`, AuthHeader(accessToken))
    .then(StandardGetHandler("reservations"))
    .catch(StandardErrorHandler)
}
