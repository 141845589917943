import { Separator } from "@/components/ui/separator"
import OwnerAccountForm from "@/components/custom/OwnerAccountForm"
import ParkerAccountForm from "@/components/custom/ParkerAccountForm"
import TeamMemberAccountForm from "@/components/custom/TeamMemberAccountForm"

const AccountSettingsPage = ({ isOwner = false, isParker = false, isTeamMember = false }) => {
  return (
    <div className="space-y-6">
      <div>
        <h3 className="text-lg font-medium">Account</h3>
        <p className="text-sm">
          Update your account settings.
        </p>
      </div>
      <Separator />
      {isOwner && (<OwnerAccountForm />)}
      {isParker && (<ParkerAccountForm />)}
      {isTeamMember && (<TeamMemberAccountForm />)}
    </div>
  )
}

export default AccountSettingsPage
