import { useState, useEffect, useMemo, useCallback } from "react"
import { useSearchParams, useNavigate } from "react-router-dom"
import { useQuery } from "@tanstack/react-query"
import { format } from "date-fns"

import { search } from "@/api/get"
import { useSearch } from "@/context/SearchContext"
import { serializeParams } from "@/lib/serializeParams"
import FacilityDetailsDialog from "@/components/custom/FacilityDetailsDialog"
import { DialogTrigger } from "@/components/ui/dialog"
import { Button } from "@/components/ui/button"
import { useHoldReservation } from "./useHoldReservation"
import { ALL_AVAILABLE_FACILITIES } from "./useParkingSearchForm"

export const useSearchFlow = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const { facilities, facilitiesLoading } = useSearch()
  const [searchQuery, setSearchQuery] = useState({})

  const { handleReservationHold } = useHoldReservation()

  useEffect(() => {
    if (!searchParams.size) return

    const query = {}
    searchParams.forEach((value, key) => {
      query[key] = value
    })

    if (query.facility && query.facility === ALL_AVAILABLE_FACILITIES) {
      delete query.facility
    }

    setSearchQuery(query)
  }, [searchParams])

  const {
    data: searchResults,
    isPending: isSearchPending,
    isSuccess
  } = useQuery({
    queryKey: ["spotSearchResults", searchQuery],
    queryFn: () => search(searchQuery),
    enabled: searchParams.size > 0
  })

  const reserveSpot = useCallback(
    async (id, data) => {
      // Begin reservation hold
      const formattedDate = format(data.checkinDate, "MM/dd/yyyy")
      await handleReservationHold({
        parkingLocationType: data.parkingLocationType === "SPOT" ? "SPOT" : "LOT",
        parkingLocationId: id,
        holdStart: formattedDate
      })

      return navigate(`/reservation/${id}`, {
        state: {
          id,
          data,
          searchQuery
        }
      })
    },
    [handleReservationHold, navigate, searchQuery]
  )

  const markers = useMemo(() => {
    if (!searchResults?.length) return []

    const dialogTrigger = (
      <DialogTrigger asChild>
        <Button variant="outline" className="w-full">
          More Info
        </Button>
      </DialogTrigger>
    )

    return searchResults.map(
      ({ images, parkingLocationType, lot, spot, facility, ...rest }) => {
        let location = parkingLocationType === "LOT" ? lot : spot

        const dataDetails = {
          images,
          parkingLocationType,
          lot,
          spot,
          facility,
          ...rest
        }

        const reserveParkingSpot = () => reserveSpot(location.id, dataDetails)

        return {
          position: {
            lat: parseFloat(location.latitude),
            lng: parseFloat(location.longitude)
          },
          name: location.name,
          type: location.type,
          price: parseInt(location.pricing, 10),
          images,
          facility,
          location,
          dialogElement: (
            <FacilityDetailsDialog
              dialogTrigger={dialogTrigger}
              data={dataDetails}
              reserveSpot={reserveParkingSpot}
            />
          ),
          reserveSpot: reserveParkingSpot
        }
      }
    )
  }, [reserveSpot, searchResults])

  const handleSearchQuery = (query) => {
    const params = serializeParams(query)
    setSearchQuery(query)
    navigate(`/search?${params.toString()}`)
  }

  return {
    facilities,
    facilitiesLoading,
    handleSearchQuery,
    searchQuery,
    searchLoading: isSearchPending,
    searchSuccess: isSuccess,
    searchResults,
    markers,
    reserveSpot
  }
}
