import { createContext, useCallback, useContext } from "react"
import { useLocalStorage } from "react-use"
import { makeLoginCall, makeLogoutCall } from "@/api/post"
import { getParker } from "@/api/get"
import {
  RefreshAccessToken,
  RefreshAccessTokenResponse
} from "@/api/auth/RefreshAccessToken"
import { StandardApiResponse } from "@/api/const"

export interface IAuthContext {
  accessToken: string
  refreshToken: string
  user?: any | null
  login: (formData: any) => Promise<any>
  logout: (formData: any) => Promise<any>
  refresh: () => Promise<StandardApiResponse>
  setTokens: React.SetStateAction<any>
  setUserInfo: React.SetStateAction<any>
  parker: Parker
}

const initialValue: IAuthContext = {
  accessToken: "",
  refreshToken: "",
  user: {},
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  refresh: () =>
    Promise.resolve({
      isError: true,
      error: "Refresh method was not successfully initialized."
    }),
  setTokens: () => { },
  setUserInfo: () => { },
  parker: {} as Parker
}

const AuthContext = createContext<IAuthContext>(initialValue)

export const AuthContextProvider = ({ children }: React.PropsWithChildren) => {
  const [tokens, setTokens, removeTokens] = useLocalStorage<any>("tokens", null)
  const [userInfo, setUserInfo, removeUserInfo] = useLocalStorage<any>("userId", null)

  const login = async (formData: any) => {
    const { access, refresh, role, userId, user: { firstName, lastName, isStaff }, parkerId } = await makeLoginCall(formData)
    setTokens({ access, refresh })

    setUserInfo({
      userId,
      role,
      firstName,
      lastName,
      parkerId: parkerId || null,
      parker: parkerId && await getParker(access),
      isStaff
    })

    return role
  }

  const logout = async () => {
    const result = await makeLogoutCall(tokens?.refresh)
    removeTokens()
    removeUserInfo()
    return result
  }

  const refresh = useCallback(
    () =>
      RefreshAccessToken(tokens.refresh).then((response) => {
        console.log(response.tokens)
        if (response.isError) {
          return response
        }
        setTokens(response.tokens)
        return {
          isError: false
        }
      }),
    [tokens]
  )

  const contextValue = {
    accessToken: tokens?.access,
    refreshToken: tokens?.refresh,
    user: userInfo,
    login,
    logout,
    refresh,
    setTokens,
    setUserInfo,
    parker: {} as Parker,
  }
  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  )
}

export const useAuth = () => {
  return useContext(AuthContext)
}
