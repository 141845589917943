import { useState } from "react"
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger
} from "@/components/ui/alert-dialog"

import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"

const CancelReservationDialog = ({ id, handleCancel }) => {
  const [reason, setReason] = useState("")

  const handleClick = async () => {
    handleCancel(reason, id)
  }

  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <Button variant="destructive">Cancel Reservation</Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
          <AlertDialogDescription>
            This action cannot be undone. This will cancel your reservation.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <Label htmlFor="Reason for canceling">Reason for Canceling</Label>
        <Input
          onChange={(e) => setReason(e.target.value)}
          placeholder="Enter reason for canceling...."
        />
        <AlertDialogFooter>
          <AlertDialogCancel>Close</AlertDialogCancel>
          <AlertDialogAction
            onClick={handleClick}
            className="bg-destructive text-destructive-foreground hover:bg-destructive/90">
            Yes Cancel
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}

export default CancelReservationDialog
