import { PlugZap } from "lucide-react"
import { Button } from "@/components/ui/button"
import { Card, CardContent, CardHeader } from "../ui/card"
import { Separator } from "@/components/ui/separator"
import { TypographyH4 } from "../ui/typography/h4"
import Icon from "@/components/custom/Icon"
import { Badge } from "@/components/ui/badge"

import { DialogTrigger } from "@radix-ui/react-dialog"
import FacilityDetailsDialog from "./FacilityDetailsDialog"
import { cn } from "@/lib/utils"

const ParkSearchResult = ({ data, hideBookingButton, reserveSpot }) => {
  const {
    facility: {
      name: facilityName,
      address: { address1, address2, city, state, zipCode },
      description: facilityDescription,
      amenities
    },
    parkingLocationType,
    images
  } = data

  let imgSrc, imgAlt, name, pricing, parkerInstructions

  const thumbnail = images.find((img) => img.isThumbnail)
  if (thumbnail) {
    imgSrc = thumbnail?.url
    imgAlt = thumbnail?.name
  } else {
    imgSrc = images[0]?.url
    imgAlt = images[0]?.name
  }

  if (parkingLocationType === "SPOT") {
    const {
      spot: {
        name: spotName,
        pricing: spotPricing,
        parkerInstructions: spotParkerInstructions,
        tags: spotTags
      }
    } = data
    name = spotName
    pricing = spotPricing
    parkerInstructions = spotParkerInstructions
  } else if (parkingLocationType === "LOT") {
    const {
      lot: {
        name: lotName,
        pricing: lotPricing,
        parkerInstructions: lotParkerInstructions,
        tags: lotTags
      }
    } = data
    name = lotName
    pricing = lotPricing
    parkerInstructions = lotParkerInstructions
  }

  const parkingId = parkingLocationType === "SPOT" ? data.spot.id : data.lot.id

  const amenitiesBlock = amenities.map((amenity, i) => (
    <div key={i} className="flex flex-row items-center">
      <Icon name={amenity.iconName} size={20} className="shrink-0" />
      <div className="ml-3">
        <p className="text-sm">{amenity.name}</p>
        <p className="text-sm">{amenity.description}</p>
      </div>
    </div>
  ))

  const dialogTrigger = (
    <DialogTrigger asChild>
      <Button variant="outline">More Info</Button>
    </DialogTrigger>
  )

  const lotTags = data?.lot?.tags.sort((a, b) => (a.displayOrder) - (b.displayOrder))
  const spotTags = data?.spot?.tags.sort((a, b) => (a.displayOrder) - (b.displayOrder))

  const badges = (tags) => {
    if (tags?.length === 0) return null
    return (
      <>
        {tags?.map((tag, i) => (
          <Badge key={`${tag.name}-${i}`} className="capitalize mr-1">{tag.name}</Badge>
        ))}
      </>
    )
  }

  return (
    <Card className="shadow-lg">
      <CardHeader
        className={cn("relative p-4 space-y-0 flex flex-row justify-between", {
          "lg:p-6": hideBookingButton
        })}>
        <div className="overflow-hidden">
          <TypographyH4 className="capitalize font-semibold text-2xl pb-2 truncate">
            {facilityName}
          </TypographyH4>
          {badges(spotTags)}
          {badges(lotTags)}
        </div>
        <div className="min-w-24 flex justify-end">
          <p className="text-secondary-foreground font-semibold text-2xl">
            <span className="text-sm align-super">$</span>
            {pricing}
            <span className="text-sm">/day</span>
          </p>
        </div>
      </CardHeader>
      <Separator />
      <CardContent
        className={cn("p-4 flex flex-col gap-3", {
          "lg:p-6": hideBookingButton
        })}>
        <div className="flex gap-2">
          <div className="w-24">
            <img
              alt={imgAlt}
              className="aspect-square rounded-md object-cover"
              src={imgSrc}
            />
          </div>

          <div className="flex flex-col text-md flex-auto gap-4">
            <div className="relative">
              <div>
                <address className="grid gap-0.5 not-italic tracking-wide text-sm">
                  <p>{address1}</p>
                  {address2 && <p>{address2}</p>}
                  <p>
                    {city}, {state} {zipCode}
                  </p>
                </address>
              </div>
            </div>
            {!hideBookingButton && (
              <div className="flex gap-2 items-center justify-end flex-auto">
                <FacilityDetailsDialog
                  dialogTrigger={dialogTrigger}
                  data={data}
                  reserveSpot={reserveSpot}
                />
                <Button onClick={() => reserveSpot(parkingId, data)}>
                  Book Spot
                </Button>
              </div>
            )}
          </div>
        </div>
        {hideBookingButton && (
          <div className="grid gap-4">
            {facilityDescription && (
              <>
                <Separator />
                <div className="grid gap-1">
                  <p className="text-sm">{facilityDescription}</p>
                </div>
              </>
            )}
            {amenitiesBlock.length > 0 && (
              <>
                <Separator />
                <div className="grid gap-3">
                  <p className="text-sm font-medium">Amenities</p>
                  <div className="grid grid-cols-3 gap-2">{amenitiesBlock}</div>
                </div>
              </>
            )}
            <Separator />
            <div className="grid gap-3">
              <p className="text-sm font-medium">Parking Instructions</p>
              <p className="text-sm">{parkerInstructions}</p>
            </div>
          </div>
        )}
      </CardContent>
    </Card>
  )
}

export default ParkSearchResult
