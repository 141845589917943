import { cn } from "@/lib/utils"

export function TypographyH2({ children, className }) {
  return (
    <h2
      className={cn(
        "scroll-m-20 pb-2 text-3xl font-semibold tracking-wide",
        className
      )}>
      {children}
    </h2>
  )
}
