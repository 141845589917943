import {
  api,
  AuthHeader,
  StandardApiResponse,
  StandardErrorHandler,
  StandardGetHandler
} from "../const"
import { SeasonPass } from "../types/internal/SeasonPass"

export const GetSeasonPassesPath = `/v1/shop`

export const GetSeasonPassesKey = `parking-packs-and-season-passes`

export interface GetSeasonPassesAndParkingPacksResponse
  extends StandardApiResponse {
  data?: {
    facilities: (Facility & {
      parkingPacks: ParkingPack[]
      seasonPasses: SeasonPass[]
    })[],
    spotsurfer: {
      creditPacks: []
    }
  },
}

export const GetSeasonPassesAndParkingPacks = (
  _accessToken: string
): Promise<GetSeasonPassesAndParkingPacksResponse> =>
  api
    .get(GetSeasonPassesPath)
    .then(StandardGetHandler("data"))
    .catch(StandardErrorHandler)
