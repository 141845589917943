import { useState } from "react"
import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from "@/components/ui/form"
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle
} from "@/components/ui/card"
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { completeResetPassword } from "@/api/post"
import { useSearchParams, useNavigate } from "react-router-dom"
import { requiredPassword } from "@/constants/formSchema"
import { useCustomAlert } from "@/hooks/useCustomAlert"

const newPasswordFormSchema = z
  .object({
    password1: requiredPassword,
    password2: requiredPassword
  })
  .refine(({ password1, password2 }) => password1 === password2, {
    message: "Passwords must match",
    path: ["password2"]
  })

const NewPasswordForm = () => {
  const { showMessage } = useCustomAlert()
  const [error, setError] = useState(null)
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const query = {}
  searchParams.forEach((value, key) => {
    query[key] = value
  })

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    ...form
  } = useForm({
    resolver: zodResolver(newPasswordFormSchema),
    defaultValues: {
      password1: "",
      password2: ""
    }
  })

  const onSubmit = async (formData) => {
    if (error) setError(null)

    try {
      await completeResetPassword(formData, query?.token)
      showMessage("Password successfully updated", "success")
      navigate("/login")
    } catch (error) {
      if (error.response?.status === 401) {
        setError({
          ...error,
          message: "Incorrect email"
        })
      } else {
        setError({ ...error, message: "An error occurred. Please try again." })
      }
    }
  }

  return (
    <Form {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card className="mx-auto max-w-sm">
          <CardHeader>
            <CardTitle className="text-2xl">New Password</CardTitle>
            <CardDescription>Create a new password.</CardDescription>
          </CardHeader>
          <CardContent>
            <FormField
              control={control}
              name="password1"
              render={({ field }) => (
                <FormItem className="min-h-[100px]">
                  <FormLabel>New Password</FormLabel>
                  <FormControl>
                    <Input
                      type="password"
                      placeholder="Create New Password"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={control}
              name="password2"
              render={({ field }) => (
                <FormItem className="min-h-[100px]">
                  <FormLabel>Confirm Password</FormLabel>
                  <FormControl>
                    <Input
                      type="password"
                      placeholder="Confirm Your New Password"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="grid gap-4 mt-3">
              {error && error.message ?
                <div>
                  <p className="text-red-500 text-sm">{error.message}</p>
                </div>
              : null}
              <Button type="submit" className="w-full" disabled={isSubmitting}>
                Update Password
              </Button>
            </div>
          </CardContent>
        </Card>
      </form>
    </Form>
  )
}

export default NewPasswordForm
