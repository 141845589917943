import { Button } from "@/components/ui/button"
import { Card } from "@/components/ui/card"
import { Checkbox } from "@/components/ui/checkbox"
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from "@/components/ui/form"
import { Input } from "@/components/ui/input"
import { Textarea } from "@/components/ui/textarea"
import { cn } from "@/lib/utils"
import { Camera, X } from "lucide-react"
import React, { useCallback, useMemo, useRef } from "react"
import {
  Control,
  FieldValue,
  FieldValues,
  Path,
  useController
} from "react-hook-form"
import Icon from "../Icon"
import { Switch } from "@/components/ui/switch"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "@/components/ui/select"
import DatePicker from "../DatePicker"
import PhoneInput, { parsePhoneNumber } from "react-phone-number-input/input"
import parsePhoneNumberFromString from "libphonenumber-js"

export interface FormTextInputProps<I extends FieldValues> {
  control: Control<I>
  name: Path<I>
  type?: React.InputHTMLAttributes<string>["type"]
  label?: string
  placeholder?: string
  readonly?: boolean
}

export const FormTextInput = <I extends FieldValues>({
  control,
  name,
  type,
  label,
  placeholder,
  readonly
}: FormTextInputProps<I>) => (
  <FormField
    control={control}
    name={name}
    render={({ field }) => (
      <FormItem>
        <div className="flex gap-3 justify-between items-center">
          {label && <FormLabel>{label}</FormLabel>}
          <FormMessage />
        </div>
        <Input
          type={type}
          placeholder={placeholder}
          {...field}
          value={field.value ?? ""}
          disabled={readonly}
        />
      </FormItem>
    )}
  />
)

export interface FormTextAreaProps<I extends FieldValues>
  extends FormTextInputProps<I> {
  type?: never
  rows?: React.TextareaHTMLAttributes<string>["rows"]
}

export const FormTextArea = <I extends FieldValues>({
  control,
  name,
  rows,
  label,
  placeholder
}: FormTextAreaProps<I>) => (
  <FormField
    control={control}
    name={name}
    render={({ field }) => (
      <FormItem>
        <div className="flex justify-between gap-3 items-center">
          {label && <FormLabel>{label}</FormLabel>}
          <FormMessage />
        </div>
        <Textarea
          rows={rows}
          placeholder={placeholder}
          {...field}
          value={field.value ?? ""}
        />
      </FormItem>
    )}
  />
)

export interface FormNumberInputProps<I extends FieldValues>
  extends FormTextInputProps<I> {
  type?: never
  step?: string
}

export const FormNumberInput = <I extends FieldValues>({
  control,
  name,
  step,
  label,
  placeholder
}: FormNumberInputProps<I>) => (
  <FormField
    control={control}
    name={name}
    render={({ field }) => (
      <FormItem>
        <div className="flex justify-between gap-3 items-center">
          {label && <FormLabel>{label}</FormLabel>}
          <FormMessage />
        </div>
        <Input
          type="number"
          step={step}
          placeholder={placeholder}
          {...field}
          value={field.value ?? ""}
        />
      </FormItem>
    )}
  />
)

export interface FormCheckboxInputProps<I extends FieldValues>
  extends FormTextInputProps<I> {
  type?: never
  placeholder?: never
  side?: "left" | "right" | "top" | "bottom"
  flexAlign?: boolean
}

export const FormCheckbox = <I extends FieldValues>({
  control,
  name,
  side = "right",
  label,
  flexAlign
}: FormCheckboxInputProps<I>) => {
  const flexOrder = useMemo(() => {
    if (side === "left") return "flex-row-reverse"
    else if (side === "top") return "flex-col-reverse"
    else if (side || side === "right") return "flex-row"
    else if (side === "bottom") return "flex-col"
  }, [side])

  return (
    <div className="grid gap-3">
      {!flexAlign && <span />}
      <FormField
        control={control}
        name={name}
        render={({ field }) => (
          <FormItem
            className={cn("flex items-center justify-center gap-3", flexOrder)}>
            <Checkbox
              checked={field.value}
              onCheckedChange={(checked) => field.onChange(checked)}
              onBlur={field.onBlur}
              disabled={field.disabled}
            />
            <FormLabel
              style={{
                marginTop: 0
              }}>
              {label}
            </FormLabel>
          </FormItem>
        )}
      />
    </div>
  )
}

export interface FormTimeInputProps<I extends FieldValues>
  extends FormTextInputProps<I> {
  type?: never
}

export const transformTimeInput = (rawInput: any) => {
  if (typeof rawInput !== "string") {
    return "00:00"
  }

  if (/^\d{2}:\d{2}$/.test(rawInput)) {
    return rawInput
  } else if (/^\d{2}:\d{2}:\d{2}$/.test(rawInput)) {
    return rawInput.slice(0, 5)
  } else if (/^\d{1,2}$/.test(rawInput)) {
    return rawInput.padStart(2, "0").concat(":00")
  } else {
    return "00:00"
  }
}

export const FormTimeInput = <I extends FieldValues>({
  control,
  name,
  label,
  placeholder
}: FormTimeInputProps<I>) => (
  <FormField
    control={control}
    name={name}
    render={({ field }) => (
      <FormItem>
        {label && <FormLabel>{label}</FormLabel>}
        <Input
          type="time"
          placeholder={placeholder}
          {...field}
          value={transformTimeInput(field.value)}
        />
      </FormItem>
    )}
  />
)

export interface SelectOption {
  value: string | FieldValue<FieldValues> | FieldValues
  label?: string | JSX.Element
}

export interface SelectInputProps<I extends FieldValues>
  extends FormTextInputProps<I> {
  type?: never
  options: string[] | SelectOption[]
  open?: boolean
  side?: "top" | "bottom"
}

export const FormSelectInput = <I extends FieldValues>({
  control,
  name,
  label,
  placeholder,
  options,
  open,
  side
}: SelectInputProps<I>) => {
  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem>
          <div className="flex justify-between gap-3 items-center">
            {label && <FormLabel>{label}</FormLabel>}
            <FormMessage />
          </div>
          <Select
            open={open}
            onValueChange={field.onChange}
            defaultValue={field.value}>
            <FormControl>
              <SelectTrigger>
                <SelectValue placeholder={placeholder} />
              </SelectTrigger>
            </FormControl>
            <SelectContent side={side}>
              {options.map((option, index) =>
                typeof option === "string" ?
                  <SelectItem key={option} value={option}>{option}</SelectItem>
                : <SelectItem
                    key={index}
                    value={option.value}
                    children={option.label}
                  />
              )}
            </SelectContent>
          </Select>
        </FormItem>
      )}
    />
  )
}

export interface FormLatLongProps<I extends FieldValues>
  extends FormTextInputProps<I> {
  type?: never
  precision?: number
}

export const floatStringToDigits = (
  floatString: string,
  precision: number = 4
): number => {
  if (floatString.length === 0 || !parseFloat(floatString)) {
    return 0.0
  }
  const parts = floatString.split(".")
  if (parts.length !== 2) {
    return parseFloat(floatString)
  } else {
    return parseFloat(parts[0] + "." + parts[1].slice(0, precision))
  }
}

export const FormLatLongInput = <I extends FieldValues>({
  control,
  name,
  label,
  precision = 4
}: FormLatLongProps<I>) => (
  <FormField
    control={control}
    name={name}
    render={({ field }) => (
      <FormItem>
        <FormLabel>{label}</FormLabel>
        <Input
          type="number"
          step="0.0001"
          onChange={(e) => {
            if (!isNaN(parseFloat(e.target.value))) {
              field.onChange(parseFloat(e.target.value))
            }
          }}
          value={field?.value ?? ""}
          onBlur={() => {
            field.onChange(floatStringToDigits(String(field.value), precision))
            field.onBlur()
          }}
        />
      </FormItem>
    )}
  />
)

export interface FormDateInputProps<I extends FieldValues>
  extends FormTextInputProps<I> {
  type?: never
  placeholder?: never
}

export const dateToYearMonthDay = (date: Date) =>
  date.toISOString().split("T")[0]

export const FormDateInput = <I extends FieldValues>({
  control,
  name,
  label
}: FormDateInputProps<I>) => {
  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem className="grid space-y-2">
          <FormLabel>{label}</FormLabel>
          <FormControl>
            <DatePicker
              value={field.value}
              onChange={(date) =>
                date && field.onChange(dateToYearMonthDay(date))
              }
            />
          </FormControl>
        </FormItem>
      )}
    />
  )
}

export interface FormFileUploadProps<I extends FieldValues>
  extends FormTextInputProps<I> {
  type?: never
  multiple?: boolean
}

export const FormFileUpload = <I extends FieldValues>({
  control,
  name,
  multiple
}: FormFileUploadProps<I>) => {
  const { field } = useController({
    name,
    control
  })
  const inputRef = useRef<HTMLInputElement>(null)

  const onClick = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.click()
    }
  }, [])

  const imagePreviewSrc = useMemo(() => {
    if (field.value) {
      try {
        return URL.createObjectURL(field.value)
      } catch (e) {
        return ""
      }
    } else {
      return ""
    }
  }, [field.value])

  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem>
          <Input
            className="hidden"
            ref={inputRef}
            hidden
            multiple={multiple}
            type="file"
            onChange={(event) => field.onChange(event.target.files?.[0])}
          />
          <div>
            {imagePreviewSrc ?
              <div className="w-full aspect-square max-h-64 flex justify-between items-center">
                <div
                  className="cursor-pointer flex gap-3 items-center justify-start hover:underline"
                  onClick={onClick}>
                  <img
                    src={imagePreviewSrc}
                    alt="Uploaded Image"
                    className="max-h-32 max-w-48 rounded-md"
                  />
                  <div className="grid items-center gap-1">
                    <p className="font-semibold text-accent-orange">
                      {field?.value?.name}
                    </p>
                    <p className="text-sm text-muted-foreground">Change</p>
                  </div>
                </div>
                <Button
                  variant="destructive"
                  size="icon"
                  className="w-6 h-6"
                  onClick={() => field.onChange(null)}>
                  <X className="w-4 h-4" />
                </Button>
              </div>
            : <div className="w-full m-auto max-w-64">
                <button
                  onClick={onClick}
                  className="w-full aspect-square border rounded p-6 flex flex-col justify-center items-center">
                  <Camera size={60} className="text-muted-foreground" />
                  <p>Browse...</p>
                </button>
              </div>
            }
          </div>
        </FormItem>
      )}
    />
  )
}

export interface CardSwitchProps<I extends FieldValues> {
  name: Path<I>
  label: string
  description: string
  price?: string
  iconName?: string
  control: Control<I>
  image?: string
}

export const CardSwitch = <I extends FieldValues>({
  name,
  label,
  description,
  price,
  iconName,
  control,
  image
}: CardSwitchProps<I>) => {
  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem>
          <Card
            className={cn(
              "duration-500 flex gap-3 justify-between p-6",
              field.value && "border-green-700"
            )}>
            <div className="grid gap-3 grid-cols-[auto_auto] items-center">
              {image && (
                <img src={image} className="max-h-32 max-w-32 rounded-md" />
              )}
              {iconName && !image && (
                <Icon name={iconName} size={20} className="shrink-0" />
              )}
              <div className="grid gap-1 items-center">
                <FormLabel className="font-semibold">{label}</FormLabel>
                {description && (
                  <p className="text-small text-muted-foreground">
                    {description}
                  </p>
                )}
              </div>
            </div>
            <div className="flex gap-3 justify-between items-center">
              {price && <p className="text-md font-semibold">{price}</p>}
              <FormControl className="text-right">
                <Switch
                  checked={field.value}
                  onCheckedChange={(checked) => {
                    console.log('CHECKED', {
                      field,
                      checked
                    })
                    field.onChange(checked)
                  }}
                />
              </FormControl>
            </div>
          </Card>
        </FormItem>
      )}></FormField>
  )
}

export const FormPhoneNumberInput = <I extends FieldValues>({
  control,
  name,
  label,
  placeholder,
  readonly
}: FormTextInputProps<I>) => {
  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem>
          <div className="flex justify-between gap-3 items-center">
            {label && <FormLabel>{label}</FormLabel>}
            <FormMessage />
          </div>
          {readonly ?
            <Input
              {...field}
              value={parsePhoneNumberFromString(
                field.value,
                "US"
              )?.formatNational()}
              disabled
            />
          : <PhoneInput
              className="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
              country="US"
              placeholder={placeholder}
              {...field}
              value={field.value}
              disabled={readonly}
            />
          }
        </FormItem>
      )}
    />
  )
}
