import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from "@/components/ui/form"
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle
} from "@/components/ui/card"
import { Input } from "@/components/ui/input"
import { Button } from "@/components/ui/button"
import { Separator } from "@/components/ui/separator"
import {
  contactSchema,
  couponCodeSchema,
  vehicleFormSchema,
  vehicleDefaultValues
} from "@/constants/formSchema"
import { useReservationForm } from "@/hooks/useReservationForm"
import VehicleFormContent from "./VehicleFormContent"
import CouponFormContent from "./CouponFormContent"

const reservationFormSchema = contactSchema
  .merge(vehicleFormSchema)
  .merge(couponCodeSchema)
  .refine(({ code, email }) => (code?.length > 0 ? !!email : true), {
    message: "Email is required to apply coupon code",
    path: ["code"]
  })

const defaultFormValues = {
  email: "",
  phone: "",
  code: "",
  ...vehicleDefaultValues
}

const ReservationForm = ({ reservationData, createReservation }) => {
  const {
    parkingLocationType,
    spot,
    lot,
    searchQuery: { checkinDate }
  } = reservationData

  const { form, couponValid, onValidateCoupon, resetCoupon, ...remaining } =
    useReservationForm(reservationFormSchema, defaultFormValues)

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, errors: formStateErrors },
    getValues,
    trigger,
    setValue,
    watch,
    ...rest
  } = form

  const onSubmit = (formData) => {
    const { email, phone, code, ...vehicleInfo } = formData
    const dataToSubmit = {
      checkinDate,
      parkingLocationType,
      parkingLocationId: parkingLocationType === "SPOT" ? spot.id : lot.id,
      email,
      phone,
      code: code && couponValid ? code.trim().toUpperCase() : "",
      vehicle: {
        ...vehicleInfo
      }
    }
    createReservation(dataToSubmit)
  }

  const onRemoveCode = () => {
    setValue("code", "")
    resetCoupon()
  }

  const onApplyCode = async () => {
    // Trigger manual validation for code field
    await trigger("code")
    if (formStateErrors.code?.message) return

    const couponCode = getValues("code").trim().toUpperCase()
    const email = getValues("email")

    onValidateCoupon({ parker: email, code: couponCode })
  }

  const codeInput = watch("code", "")
  const isApplyDisabled = isSubmitting || codeInput.length === 0

  return (
    <Form {...rest}>
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-8">
        <Card>
          <CardHeader className="space-y-1 p-4 lg:p-6">
            <CardTitle className="text-lg font-semibold">
              Submit Reservation
            </CardTitle>
            <CardDescription>
              Enter your details for your reservation
            </CardDescription>
          </CardHeader>
          <CardContent className="p-4 lg:p-6">
            <FormField
              control={control}
              name="email"
              render={({ field }) => (
                <FormItem className="min-h-[100px]">
                  <FormLabel>Email</FormLabel>
                  <FormControl>
                    <Input placeholder="Email" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={control}
              name="phone"
              render={({ field }) => (
                <FormItem className="min-h-[100px]">
                  <FormLabel>Phone Number</FormLabel>
                  <FormControl>
                    <Input placeholder="Phone Number" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Separator className="my-6" />
            <CouponFormContent
              control={control}
              couponControl={{ couponValid, ...remaining }}
              onApplyCode={onApplyCode}
              onRemoveCode={onRemoveCode}
              isApplyDisabled={isApplyDisabled}
            />
            <Separator className="my-6" />
            <VehicleFormContent control={control} />
          </CardContent>
          <CardFooter className="p-4 lg:p-6 flex justify-between">
            <Button type="submit" disabled={isSubmitting}>
              Proceed to Payment
            </Button>
          </CardFooter>
        </Card>
      </form>
    </Form>
  )
}

export default ReservationForm
