import { Switch } from "@/components/ui/switch"
import { cn } from "@/lib/utils"
import { Label } from "@/components/ui/label"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "@/components/ui/select"
import { useCallback } from "react"
import { Input } from "@/components/ui/input"
import DatePicker from "../../DatePicker"
import { FormField } from "@/components/ui/form"
import { LabelledInfoHover } from "../../Hoverable/LabelledInfoHover"

const PageLabel = "spotsandlots_edit"

export const ActiveToggle = ({ className, watch, setValue, name }) => {
  return (
    <div className={cn(className, "flex gap-2 items-center")}>
      <Switch
        name="active"
        checked={watch(name)}
        onCheckedChange={(checked) => setValue(name, checked)}
      />
      <LabelledInfoHover
        displayKeyOnError
        labelKey={`${PageLabel}.availability_active_toggle`}
      />
    </div>
  )
}

const DaysOfWeek = [
  "Everyday",
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday"
]

export const DayOfWeekSelection = ({ className, control, name }) => {
  return (
    <div className={className}>
      <Label htmlFor="option">Day of week</Label>
      <FormField
        name={name}
        control={control}
        render={({ field }) => (
          <Select
            value={field.value}
            onValueChange={field.onChange}
            onBlur={field.onBlur}>
            <SelectTrigger>
              <SelectValue />
            </SelectTrigger>
            <SelectContent>
              {DaysOfWeek.map((dow) => (
                <SelectItem key={dow.toUpperCase()} value={dow.toUpperCase()}>
                  {dow}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        )}
      />
    </div>
  )
}

export const SpotInput = ({ className, control, name }) => {
  return (
    <div className={className}>
      <Label htmlFor="spots" className="flex gap-1 items-center">
        <p>Spots</p>
        <LabelledInfoHover
          displayKeyOnError
          iconSize={16}
          labelKey={`${PageLabel}.availability_spots`}
        />
      </Label>
      <FormField
        name={name}
        control={control}
        render={({ field }) => (
          <Input
            onBlur={field.onBlur}
            onChange={field.onChange}
            value={field.value}
          />
        )}
      />
    </div>
  )
}

export const PriceInput = ({ className, control, name }) => {
  return (
    <div className={className}>
      <Label htmlFor="price">Price</Label>
      <div className="relative">
        <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
          $
        </div>
        <FormField
          name={name}
          control={control}
          render={({ field }) => (
            <Input
              className="pl-5"
              onBlur={field.onBlur}
              onChange={field.onChange}
              value={field.value}
            />
          )}
        />
      </div>
    </div>
  )
}

export const TimeInput = ({ label, className, watch, name, setValue }) => {
  const inputTime = useCallback(
    (time) => (time?.split(":").length == 3 ? time.slice(0, -3) : time),
    []
  )

  return (
    <div className={className}>
      <Label htmlFor="time">{label}</Label>
      <Input
        type="time"
        onChange={(e) => {
          setValue(name, e.target.value, {
            shouldDirty: true,
            shouldTouch: true,
            shouldValidate: true
          })
        }}
        value={inputTime(watch(name))}
      />
    </div>
  )
}

export const DateInput = ({ label, className, name, watch, setValue }) => {
  const dateDate = useCallback(
    (date) => (date ? new Date(date) : new Date()),
    []
  )

  return (
    <div className={className}>
      <Label htmlFor="date">{label}</Label>
      <DatePicker
        onChange={(date) =>
          setValue(name, date, {
            shouldDirty: true,
            shouldTouch: true,
            shouldValidate: true
          })
        }
        value={dateDate(watch(name))}
      />
    </div>
  )
}
