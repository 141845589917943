import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle
} from "@/components/ui/card"
import { useForm } from "react-hook-form"
import { Form } from "@/components/ui/form"
import { Button } from "@/components/ui/button"
import { zodResolver } from "@hookform/resolvers/zod"

import { useAuth } from "@/context/AuthContext"
import { useMutation } from "@tanstack/react-query"
import { addVehicle } from "@/api/post"
import { editVehicle } from "@/api/patch"
import { vehicleFormSchema, vehicleDefaultValues } from "@/constants/formSchema"
import VehicleFormContent from "./VehicleFormContent"

const VehicleForm = ({
  actionType = "ADD",
  preExistingValues = vehicleDefaultValues,
  onSuccess
}) => {
  const { make, model, type, licenseNumber, registeredState, color, isEv } =
    preExistingValues

  const { accessToken } = useAuth()
  const mutation = useMutation({
    mutationFn: (formData) => {
      if (actionType === "EDIT") {
        formData.id = preExistingValues.id
        return editVehicle(formData, accessToken)
      }

      return addVehicle(formData, accessToken)
    },
    onSuccess
  })

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isValid },
    ...form
  } = useForm({
    mode: "onChange",
    resolver: zodResolver(vehicleFormSchema),
    defaultValues: {
      make: make || "",
      model: model || "",
      type: type || "",
      color: color || "",
      licenseNumber: licenseNumber || "",
      registeredState: registeredState || "AK",
      isEv: isEv || false
    }
  })

  const onSubmit = (formData) => mutation.mutate(formData)

  return (
    <Form {...form}>
      <form onSubmit={handleSubmit(onSubmit)} className="relative">
        <Card>
          <CardHeader className="space-y-1">
            <CardTitle className="text-lg font-semibold">Add Vehicle</CardTitle>
          </CardHeader>
          <CardContent className="grid gap-4">
            <VehicleFormContent control={control} />
          </CardContent>
          <CardFooter>
            <Button disabled={!isValid || isSubmitting}>Submit</Button>
          </CardFooter>
        </Card>
      </form>
    </Form>
  )
}

export default VehicleForm
