import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"
import { getSpotsNLots } from "@/api/get"
import { useAuth } from "@/context/AuthContext"
import { useQuery } from "@tanstack/react-query"
import Loading from "@/components/custom/Loading"
import Fallback from "@/components/custom/Fallback"
import { SpotLotTab } from "@/components/custom/ParkingSpotTabContent"

const ParkingSpotInventoryPage = () => {
  const { accessToken } = useAuth()
  const { data, isPending, error, refetch } = useQuery({
    queryKey: ["ownerSpotsNLots", accessToken],
    queryFn: () => getSpotsNLots(accessToken)
  })

  if (isPending || !data) return <Loading />

  if (error) return <Fallback />

  const { spots, lots } = data

  const parkingSpots = spots.map(
    ({ id, facility, status, name, description, images, pricing }) => ({
      id,
      name,
      facility,
      status,
      description,
      images,
      pricing,
      isSpot: true
    })
  )

  const parkingLots = lots.map(
    ({ id, name, facility, status, description, pricing }) => ({
      id,
      name,
      facility,
      status,
      description,
      // No images at unassigned lot level
      images: [],
      pricing,
      isSpot: false
    })
  )

  const allList = [...parkingLots, ...parkingSpots]
  const activeList = allList.filter(({ status }) => status === "Active")
  const inactiveList = allList.filter(({ status }) => status === "Inactive")

  return (
    <main className="grid flex-1 items-start gap-4 p-4 sm:px-6 sm:py-0 md:gap-8 mt-6">
      <Tabs defaultValue="all">
        <div className="flex items-center">
          <TabsList>
            <TabsTrigger value="all">All</TabsTrigger>
            <TabsTrigger value="active">Active</TabsTrigger>
            <TabsTrigger value="inactive" className="hidden sm:flex">
              Inactive
            </TabsTrigger>
          </TabsList>
          <div className="ml-auto flex items-center gap-2"></div>
        </div>
        <TabsContent value="all">
          <SpotLotTab spotsList={allList ?? []} refetch={refetch} />
        </TabsContent>
        <TabsContent value="active">
          <SpotLotTab spotsList={activeList ?? []} refetch={refetch} />
        </TabsContent>
        <TabsContent value="inactive">
          <SpotLotTab spotsList={inactiveList ?? []} refetch={refetch} />
        </TabsContent>
      </Tabs>
    </main>
  )
}

export default ParkingSpotInventoryPage
