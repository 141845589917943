import { useCallback, useEffect, useReducer, useState } from "react"
import {
  UpdateFormTabWrapper
} from "./UpdateFormTabWrapper"
import { zodResolver } from "@hookform/resolvers/zod"
import { useFieldArray, useForm } from "react-hook-form"
import { Button } from "@/components/ui/button"
import SpotAvailabilityCard from "../../SpotAvailabilityForm/card/AvailabilityCard"
import { TypographyH4 } from "@/components/ui/typography/h4"
import { LabelledInfoHover } from "../../Hoverable/LabelledInfoHover"
import { Separator } from "@/components/ui/separator"
import { AvailabilityCard } from "../AvailabilityGenerics/AvailabilityCard"
import { OverrideCard } from "../AvailabilityGenerics/OverrideCard"
import {
  AvailabilitySchema
} from "@/constants/AvailabilitySchema"
import {
  AvailabilityDefault,
  OverrideDefault
} from "../CreateFormGenerics/Defaults"

const PageLabel = "spotsandlots_edit"

export const EditAvailabilityTab = ({
  data,
  submitHandler,
  ...tabProps
}) => {
  const [deleteId, setDeleteId] = useState(-1);
  const [deleteIsOverride, setDeleteIsOverride] = useState(false);
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);
  const [deletedItems, setDeletedItems] = useState([]);

  const form = useForm({
    resolver: zodResolver(AvailabilitySchema),
    defaultValues: data
  });

  const {
    fields: availabilityFields,
    append: appendAvailability,
    remove: removeAvailability
  } = useFieldArray({
    control: form.control,
    name: "availability",
    keyName: "formId"
  });

  const {
    fields: overrideFields,
    append: appendOverride,
    remove: removeOverride
  } = useFieldArray({
    control: form.control,
    name: "override",
    keyName: "formId"
  });

  const findDeletedItems = (newArray, defaultArray) => {
    // Initialize the deleted property
    const deleted = [];

    // Check for missing items
    defaultArray.forEach(item => {
      if (!newArray.some(newItem => newItem.id === item.id)) {
        deleted.push(item.id);
      }
    });

    // Return the result as an object
    return deleted
  }

  const onConfirmedDelete = useCallback((field, isOverride) => {
    isOverride ? removeOverride(field.formId) : removeAvailability(field.formId); // Remove after
  }, []);

  useEffect(() => {
    form.trigger();
  }, [overrideFields, availabilityFields, deletedItems]);

  return (
    <UpdateFormTabWrapper
      {...tabProps}
      form={form}
      onDiscard={() => form.reset()}
      onSubmit={(data) => submitHandler({
        ...data,
        availabilityFieldsDeleted: findDeletedItems(data.availability, form.formState.defaultValues.availability),
        overrideFieldsDeleted: findDeletedItems(data.override, form.formState.defaultValues.override)
      })}
      Render={({ control }) => (
        <div>
          <div>
            <div>
              <div className="flex gap-3 items-center">
                <TypographyH4 className="text-xl font-semibold leading-none tracking-wide">
                  Dates & Prices
                </TypographyH4>
                <LabelledInfoHover
                  displayKeyOnError
                  labelKey={`${PageLabel}.availability_dates_prices`}
                />
              </div>
              {availabilityFields.map((field, index) => {
                return (
                  <AvailabilityCard
                    key={field.formId}
                    control={control}
                    controlIndex={index}
                    watch={form.watch}
                    setValue={form.setValue}
                    onDelete={() => {
                      onConfirmedDelete(field, false); // Pass isOverride as false
                    }}
                  />
                );
              })}
              <Button
                variant="link"
                className="justify-start"
                type="button"
                onClick={() => {
                  forceUpdate();
                  appendAvailability(AvailabilityDefault());
                }}
              >
                + Add another available time
              </Button>
            </div>
            <Separator className="my-4" />
            <div className="grid gap-3">
              <div className="flex gap-3 items-center">
                <TypographyH4 className="text-xl font-semibold leading-none tracking-wide">
                  Overrides
                </TypographyH4>
                <LabelledInfoHover
                  displayKeyOnError
                  labelKey={`${PageLabel}.availability_overrides`}
                />
              </div>
              {overrideFields.map((field, index) => (
                <OverrideCard
                  key={field.formId}
                  className="divide-solid"
                  control={control}
                  controlIndex={index}
                  watch={form.watch}
                  setValue={form.setValue}
                  onDelete={() => {
                    onConfirmedDelete(field, true); // Pass isOverride as true
                  }}
                />
              ))}
              <Button
                variant="link"
                className="justify-start"
                type="button"
                onClick={() => {
                  forceUpdate();
                  appendOverride(OverrideDefault());
                }}
              >
                + Add another override
              </Button>
            </div>
          </div>
        </div>
      )}
    />
  );
};
