import { Button } from "@/components/ui/button"
import {
  Card,
  CardHeader,
  CardFooter,
  CardTitle,
  CardContent,
  CardDescription
} from "@/components/ui/card"
import TypographyH1 from "@/components/ui/typography/h1"
import { useQuery } from "@tanstack/react-query"
import Loading from "@/components/custom/Loading"
import Fallback from "@/components/custom/Fallback"
import { getPackages } from "@/api/get"

const PackagesPage = () => {
  const { data, isPending, error } = useQuery({
    queryKey: ["packages"],
    queryFn: () => getPackages()
  })

  if (isPending) return <Loading />

  if (error) return <Fallback />

  const packageDeals = data.map((packageDeal) => {
    const { id, file, name, description, amount, discountValue } = packageDeal

    const parsedAmount = parseFloat(amount)
    const parsedDiscount = parseFloat(discountValue)

    return (
      <Card key={id}>
        <CardHeader>
          <div className="grid gap-4">
            <div className="flex items-center justify-center">
              <img
                src={file}
                alt="Package image"
                className="aspect-square w-3/4 rounded-md object-cover"
              />
            </div>
            <CardTitle className="mt-3">{name}</CardTitle>
            <CardDescription>{description}</CardDescription>
          </div>
        </CardHeader>
        <CardContent>
          <div className="grid ">
            <p className="font-semibold tracking-wide text-xl">
              <span className="line-through">${amount}</span>
              <span className="text-sm text-muted-foreground ml-1">
                Without package discount
              </span>
            </p>
          </div>
          <div className="grid ">
            <p className="font-semibold tracking-wide text-xl">
              <span>${parsedAmount - parsedDiscount}</span>{" "}
              <span className="text-sm text-muted-foreground ml-1">
                With package discount
              </span>
            </p>
          </div>
        </CardContent>
        <CardFooter>
          <Button className="w-full">Buy Now</Button>
        </CardFooter>
      </Card>
    )
  })

  return (
    <div className="px-10 py-6">
      <TypographyH1>Packages and Deals</TypographyH1>
      <div className="grid grid-cols-3 gap-4 h-3/4 py-4">{packageDeals}</div>
    </div>
  )
}

export default PackagesPage
