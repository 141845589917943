import { Link, useNavigate } from "react-router-dom"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger
} from "@/components/ui/dropdown-menu"
import { useAuth } from "@/context/AuthContext"
import { useBreakpoint } from "@/hooks/useBreakpoint"
import Icon from "./Icon"

const UserDropdownMenu = () => {
  const { logout, user } = useAuth()
  const navigate = useNavigate()

  const desktopBreakpoints = ["lg", "xl", "2xl"]
  const breakpoint = useBreakpoint()
  const isDesktop = desktopBreakpoints.includes(breakpoint)

  const isOwner = user.role === "CPO" || user.role === "PPO"

  const handleLogout = async () => {
    await logout()
    navigate("/")
  }

  const parkerLinks = (
    <>
      <DropdownMenuItem>
        <Link
          to="/parker-dashboard"
          className="transition-colors hover:text-foreground">
          My Account
        </Link>
      </DropdownMenuItem>
      <DropdownMenuSeparator />
      <DropdownMenuItem>
        <Link
          to="/parker-dashboard"
          className="transition-colors hover:text-foreground">
          Reservations
        </Link>
      </DropdownMenuItem>
      <DropdownMenuItem>
        <Link
          to="/parker-dashboard/wallet"
          className="transition-colors hover:text-foreground">
          Wallet
        </Link>
      </DropdownMenuItem>
      <DropdownMenuItem>
        <Link
          to="/parker-dashboard/settings"
          className="transition-colors hover:text-foreground">
          Settings
        </Link>
      </DropdownMenuItem>
      <DropdownMenuItem>
        <Link
          to="/parker-dashboard/support"
          className="transition-colors hover:text-foreground">
          Support
        </Link>
      </DropdownMenuItem>
    </>
  )

  const ownerLinks = (
    <>
      <DropdownMenuItem>
        <Link
          to="/owner-dashboard"
          className="transition-colors hover:text-foreground">
          My Account
        </Link>
      </DropdownMenuItem>
      <DropdownMenuSeparator />
      {
        isOwner && (
          <>
            <DropdownMenuItem>
              <Link
                to="/owner-dashboard/spots"
                className="transition-colors hover:text-foreground">
                Spots and Lots
              </Link>
            </DropdownMenuItem>
            <DropdownMenuItem>
              <Link
                to="/owner-dashboard/facilities"
                className="transition-colors hover:text-foreground">
                Facilities
              </Link>
            </DropdownMenuItem>
            <DropdownMenuItem>
              <Link
                to="/owner-dashboard/team"
                className="transition-colors hover:text-foreground">
                Team
              </Link>
            </DropdownMenuItem>
          </>
        )
      }
      <DropdownMenuItem>
        <Link
          to="/owner-dashboard/settings"
          className="transition-colors hover:text-foreground">
          Settings
        </Link>
      </DropdownMenuItem>
      <DropdownMenuItem>
        <Link
          to="/owner-dashboard/support"
          className="transition-colors hover:text-foreground">
          Support
        </Link>
      </DropdownMenuItem>
    </>
  )

  const menuTrigger = (
    <DropdownMenuTrigger className="flex items-center text-lg text-primary-foreground transition-colors hover:text-muted-foreground pl-2 outline-none">
      {isDesktop ?
        <>
          <Icon
            name="user"
            className="stroke-2 text-white outline-none pl-0 hover:text-amber-200 size-6"
          />
          <span className="sr-only">Toggle user menu</span>
        </>
        : <div className="flex items-center">
          <span>Account</span>
          <Icon name="chevron-down" className="size-6 mt-1 ml-1" />
        </div>
      }
    </DropdownMenuTrigger>
  )

  return (
    <DropdownMenu>
      {menuTrigger}
      <DropdownMenuContent align="end">
        {user?.role === "PARKER" ? parkerLinks : ownerLinks}
        <DropdownMenuSeparator />
        <DropdownMenuItem className="cursor-pointer" onClick={handleLogout}>Logout</DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

export default UserDropdownMenu
