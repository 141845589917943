import NewPasswordForm from "@/components/custom/NewPasswordForm"

const NewPasswordPage = () => {
  return (
    <div className="px-4 lg:px-10 py-6">
      <NewPasswordForm />
    </div>
  )
}

export default NewPasswordPage
