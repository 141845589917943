import { deepMapKeys } from "@/lib/deepMapKeys"
import { buildRequest } from "."
import { api } from "@/api/const"

export const deleteData = async (uri, payload, accessToken) => {
  const { url, headers, serializedPayload } = buildRequest(
    uri,
    accessToken,
    payload
  )

  try {
    const result = await api({
      method: "delete",
      url,
      data: serializedPayload,
      headers
    })

    if (result.data && result.data.length) {
      return result.data.map((item) => deepMapKeys(item))
    } else {
      return result.data && deepMapKeys(result.data)
    }
  } catch (error) {
    console.log(`Error in Delete request: "${url}":`, error)
    throw error
  }
}

export const deleteVehicle = async (vehicleId, accessToken) => {
  return await deleteData("v1/parker/vehicle", { id: vehicleId }, accessToken)
}
