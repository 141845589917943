import { useQuery } from "@tanstack/react-query"
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import { CircleDollarSign } from "lucide-react"
import Icon from "@/components/custom/Icon"
import { Separator } from "@/components/ui/separator"
import TypographyH1 from "@/components/ui/typography/h1"
import { getParkerWallet } from "@/api/get"
import { format } from "date-fns"

interface ParkerProp {
  parker: {
    parkerId: string,
    accessToken: string
  }
}

const formatDateForURL = (date: Date) => {
  const mm = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-based, so add 1
  const dd = String(date.getDate()).padStart(2, '0');
  const yyyy = date.getFullYear();

  const formattedDate = `${mm}/${dd}/${yyyy}`;

  // URL-encode the date string
  return encodeURIComponent(formattedDate);
}

const shouldShowDescription = (pack: any) => {
  if (!pack?.parkingPack?.allowUnlimitedParkings) {
    console.log(pack.parkingPack.name) // Changed from pack.name to pack.parkingPack.name
    return `Available Reservations: ${pack.parkingPack.allowedParkings - pack.parkingsUsed}`
  }
  return undefined
}

const searchPath = (date: Date, facilityId: string) => `/search?facility=${facilityId}&checkinDate=${formatDateForURL(new Date())}`

const RedeemableCodes = (freeVouchers: any, signupCode?: string) => {
  const copyToClipboard = (voucherCode: string) => navigator.clipboard.writeText(voucherCode)

  const voucherNotRedeemed = (voucher: any) => {
    return (
      <div className="flex w-full justify-between items-center">
        <span className="text-primary">
          {voucher.code}
        </span>
        <button
          className="bg-primary text-white py-1 px-2 rounded-3xl text-xs ml-auto"
          onClick={() => copyToClipboard(voucher.code)}
        >
          Copy
        </button>
      </div>
    )
  }

  const voucherRedeemed = (voucher: any) => {
    return (
      <>
        <div className="flex w-full justify-between opacity-50 items-center">
          <span className="line-through">
            {voucher.code}
          </span>
          <small className="uppercase">
            <strong>{format(voucher.redeemedOnDatetime, "PPP")}</strong>
          </small>
        </div>
      </>
    )
  }

  const hasSignupCode = () => {
    return (
      <div className="flex w-full justify-between opacity-50 items-center">
        <span className="line-through">
          {signupCode}
        </span>
        <small className="uppercase">
          <strong>Signup Code</strong>
        </small>
      </div>
    )
  }

  return (
    <>
      {
        freeVouchers?.map((voucher: any, i: number) => (
          <div className="grid text-md" key={voucher.code}>
            <div className="flex justify-between">
              {!voucher.isRedeemed && (voucherNotRedeemed(voucher))}
              {voucher.isRedeemed && (voucherRedeemed(voucher))}
            </div>
            {i !== freeVouchers.length - 1 ? <Separator className="my-3" /> : null}
          </div>
        ))
      }
      {
        signupCode && <Separator className="my-3" />
      }
      <div className="grid text-md">
        <div className="flex justify-between">
          {signupCode ? hasSignupCode() : null}
        </div>
      </div>
    </>
  )
}

const ParkerWalletPage = ({ parker }: ParkerProp) => {

  const { data, isPending, isError } = useQuery({
    queryKey: ["parker-wallet"],
    queryFn: () => getParkerWallet(parker.parkerId, parker.accessToken)
  })

  return (
    <div className="space-y-6 p-10 pb-16">
      <div className="space-y-0.5">
        <TypographyH1 className="flex items-center">
          <Icon
            name="wallet"
            size={24}
            className="lg:size-8 stroke-1 mr-3"
          />
          Wallet
        </TypographyH1>
        <p>
          Manage your account wallet.
        </p>
      </div>
      <Separator className="my-6" />
      <div className="grid">
        <Card>
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-2xl font-bold">
              Spotsurfer Bucks
            </CardTitle>
          </CardHeader>
          <Separator className="my-6" />
          <CardContent>
            <div className="flex items-center text-2xl font-bold">
              <CircleDollarSign className="mr-2" />
              {data?.credits}
              <a className="flex bg-primary text-sm text-white my-1 p-2 uppercase self-start ml-auto" href="/parking-packs">Buy More</a>
            </div>
          </CardContent>
        </Card>
      </div>
      <div className="grid gap-4 md:grid-cols-2 md:gap-8">
        <Card>
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-2xl font-bold">Parking Packs</CardTitle>
          </CardHeader>
          <Separator className="my-6" />
          <CardContent>
            {
              data?.parkingPacks.map((pack: any, i: number) => (
                <div className="flex flex-col text-md font-bold p-3 even:bg-slate-100" key={pack.id}>
                  {pack.parkingPack.name}
                  <ul className="text-sm font-normal">
                    <li>
                      {shouldShowDescription(pack)}
                    </li>
                  </ul>
                  <a href={searchPath(new Date(), pack.parkingPack.facility.id)} className="flex bg-primary text-sm text-white my-1 p-2 uppercase self-start">Book Now</a>
                </div>
              ))
            }
          </CardContent>
        </Card>
        <Card>
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-2xl font-bold">Redeemable Codes</CardTitle>
          </CardHeader>
          <Separator className="my-6" />
          <CardContent>
          {data && RedeemableCodes(data?.freeVouchers, data?.signupCode)}
          </CardContent>
        </Card>
      </div>
    </div>
  )
}

export default ParkerWalletPage