import { FormField, FormItem } from "@/components/ui/form"
import { Checkbox } from "@/components/ui/checkbox"
import spotSurfer from "@/assets/pngs/spotSurfer.png"
import { cn } from "@/lib/utils"

export const PayViaCredits = ({ control, parker, ...props }) => {
  return (
    <>
      {props.disabled && "Spotsurfer Bucks cannot be applied to Seasonal Passes." }
      <div className={cn("rounded-lg border bg-card text-card-foreground shadow-sm w-full p-4", {
        "opacity-50": props.disabled,
        "pointer-events-none": props.disabled
      })}>
        <div className="space-y-2 flex justify-between gap-3 items-center">
          <div className="flex gap-3 items-center justify-start">
            <img className="max-h-[35px]" src={spotSurfer} />
            <div className="grid gap-1">
              <label htmlFor="applySpotsurferBucks" className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                Apply Spotsurfer Bucks
              </label>
              <p id=":r1l:-form-item-description" className="text-sm text-muted-foreground">Current Balance: {parker?.credits}</p>
            </div>
          </div>
          <div className="flex items-center space-x-2">
            <FormField
              control={control}
              name="payViaCredits"
              render={({ field }) => (
                <FormItem>
                  <Checkbox
                    checked={field.value || false}
                    onCheckedChange={(checked) => {
                      field.onChange(checked)
                      props.onCheckedChange?.(checked)
                    }}
                    onBlur={field.onBlur}
                    disabled={field.disabled}
                  />
                </FormItem>
              )}
            />
          </div>
        </div>
      </div>
    </>
  )
}