import ReservationCheckoutDetails from "@/components/custom/ReservationCheckoutDetails"
import { ChartContainer } from "@/components/ui/chart"
import { Skeleton } from "@/components/ui/skeleton"
import {
  add,
  areIntervalsOverlapping,
  eachHourOfInterval,
  endOfToday,
  format,
  startOfToday,
  sub
} from "date-fns"
import { useMemo } from "react"
import { Area, AreaChart, CartesianGrid, Tooltip, XAxis, YAxis } from "recharts"

export interface LotReservationsAreaChartProps {
  reservations?: Reservation[]
  parkingSpotCount: number
  period?: "DAILY"
  precision?: "HOURLY"
}

const chartConfig = {
  count: {
    label: "Occupancy",
    color: "hsl(var(--secondary-foreground))"
  }
}

export const LotReservationsAreaChart = ({
  reservations,
  parkingSpotCount
}: LotReservationsAreaChartProps) => {
  const chartData = useMemo(
    () =>
      reservations ?
        reservationsToSpotHours(filterReservationsByDay(reservations))
      : [],
    [reservations]
  )

  if (reservations === undefined) {
    return <Skeleton className="min-h-24 max-h-36 w-full" />
  }

  return (
    <ChartContainer config={chartConfig} className="min-h-24 max-h-36 w-full">
      <AreaChart accessibilityLayer data={chartData}>
        <Area dataKey="count" type="bump" />
        <XAxis
          dataKey="time"
          tickFormatter={(tick) => format(tick, "hh:mm aaa")}
        />
        <YAxis dataKey="count" domain={[0, parkingSpotCount]} />
        <CartesianGrid strokeDasharray="4" />
        <Tooltip
          formatter={(value, name) => [value, "Parkers"]}
          labelFormatter={(label) => format(label, "hh:mm aaa")}
        />
      </AreaChart>
    </ChartContainer>
  )
}

const reservationsToSpotHours = (
  reservations: Reservation[]
): { time: Date; count: number }[] =>
  eachHourOfInterval({
    start: startOfToday(),
    end: endOfToday()
  }).map((time) => ({
    time,
    count: reservations.filter((reservation) =>
      reservationIsBetweenTimes(
        reservation,
        sub(time, { minutes: 30 }),
        add(time, { minutes: 30 })
      )
    ).length
  }))

const filterReservationsByDay = (reservations: Reservation[]): Reservation[] =>
  reservations.filter((reservation) =>
    reservationIsBetweenTimes(reservation, startOfToday(), endOfToday())
  )

export const reservationIsBetweenTimes = (
  reservation: Reservation,
  start: Date,
  end: Date
) => {
  const reservationStartDate = new Date(reservation.checkinDatetime)
  const reservationEndDate = new Date(reservation.checkoutDatetime)

  return areIntervalsOverlapping(
    {
      start: reservationStartDate,
      end: reservationEndDate
    },
    {
      start,
      end
    }
  )
}
