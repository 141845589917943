import { CreateFacility } from "@/api/facility/CreateFacility"
import { useAuth } from "@/context/AuthContext"
import { useCallback } from "react"
import { useNavigate } from "react-router-dom"
import { CreateFacilitySteps, FORM_SUBTITLE, FORM_TITLE } from "./const"
import { Stepper } from "@/components/custom/Stepper/Stepper"
import {
  CreateFormHeader,
  CreateFormHeaderChildren
} from "@/components/custom/Forms/CreateFormGenerics/CreateFormHeader"
import { GetOwner, GetOwnerResponse } from "@/api/GetOwner"
import { useQuery } from "@tanstack/react-query"
import { amenitiesToIntArray } from "@/hooks/useAmenitiesForm"
import { UploadMultipleToFacility } from "@/api/facility/UploadToFacility"
import Loading from "@/components/custom/Loading"

export const CreateFacilityPage = () => {
  const navigate = useNavigate()
  const { accessToken } = useAuth()
  const { data: ownerData, isPending } = useQuery<GetOwnerResponse>({
    queryKey: [`owner`],
    queryFn: () => GetOwner(accessToken)
  })

  const handleSubmit = useCallback(
    async (
      data: any,
      imageData: any[],
      setApiError: CreateFormHeaderChildren["onApiError"]
    ) => {
      const result = await PostFacility(accessToken, data)
      if (result.isError) {
        setApiError?.(result.error)
        return
      }
      if (!result.facility) {
        setApiError?.("Facility was not created")
        return
      }
      // TODO: Fix the image upload for single and multiple images.
      const uploadResult = await UploadMultipleToFacility(
        accessToken,
        result.facility.id,
        imageData
      )
      if (result.isError) {
        setApiError?.(result.error)
        return
      }
      navigate(-1)
    },
    []
  )

  if (isPending) return <Loading className="" />

  return (
    <CreateFormHeader
      title={FORM_TITLE}
      subtitle={FORM_SUBTITLE}
      render={({ onApiError }) => (
        <Stepper
          onFinalSubmit={(dataArray) => {
            handleSubmit(
              {
                ...dataArray[0], // Details
                amenities: amenitiesToIntArray(dataArray[1]), //Amenities
                owner: ownerData?.owner?.id //Required Owner Field
              },
              dataArray[2].images[0],
              onApiError
            )
          }}
          steps={CreateFacilitySteps}
        />
      )}
    />
  )
}

const PostFacility = async (accessToken: string, data: Facility) =>
  CreateFacility(accessToken, data)
