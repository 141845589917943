import { UpdateFormTabsWrapperChildren } from "@/components/custom/Forms/UpdateFormGenerics/UpdateFormTabWrapper"
import { EditFacilityMediaForm } from "../const"
import { useAuth } from "@/context/AuthContext"
import { useParams } from "react-router-dom"
import {
  DataWithMedia,
  EditMediaTab
} from "@/components/custom/Forms/UpdateFormGenerics/EditMediaTab"
import { useCallback } from "react"
import {
  UploadMultipleToFacility,
  UploadToFacilityData
} from "@/api/facility/UploadToFacility"
import { ImageWithId } from "@/constants/MediaSchema"
import { DeleteFacilityMedia } from "@/api/facility/DeleteFacilityMedia"
import { useCustomAlert } from "@/hooks/useCustomAlert"

export const EditFacilityMedia = ({ data, ...tabProps }: UpdateFormTabsWrapperChildren<Facility, EditFacilityMediaForm>) => {
  const { accessToken } = useAuth()
  const { facilityId } = useParams()
  const { showMessage } = useCustomAlert()

  const submitHandler = useCallback(
    async (result: DataWithMedia) => {
      const newImages = result.images?.filter((image) => image.new)
      if (facilityId && newImages) {
        const response = await UploadMultipleToFacility(
          accessToken,
          facilityId,
          newImages as UploadToFacilityData[]
        );

        if (!response?.isError && response?.uploadedCount > 0) {
          showMessage("Your Facility Images have been successfully updated!", "success");
        }
        return response;
      }
      return { isError: false, data: null };
    },
    [facilityId, accessToken, showMessage]
  )

  const onApiDelete = useCallback(
    (result: ImageWithId) => {
      if (result.id) {
        return DeleteFacilityMedia(accessToken, result.id)
      }
    },
    [accessToken]
  )

  return (
    <EditMediaTab
      data={data}
      onApiDelete={onApiDelete}
      submitHandler={submitHandler}
      {...tabProps}
    />
  )
}
