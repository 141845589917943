import { useEffect, useRef } from "react"
import { Link, NavLink, useNavigate, useLocation } from "react-router-dom"
import { useAuth } from "@/context/AuthContext"
import { userRoles } from "@/constants/constants"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownMenuSeparator
} from "@/components/ui/dropdown-menu"
import { TypographyH2 } from "../ui/typography/h2"
import UserDropdownMenu from "@/components/custom/UserDropdownMenu"
import AboutUsDropdownMenu from "@/components/custom/AboutUsDropdownMenu"
import spotSurferIcon from "@/assets/pngs/spotsurferLogoPlain.png"
import Icon from "./Icon"
import { searchAll } from "@/lib/formatDateForUrl"
import { cn } from "@/lib/utils"
import SideNavigation from "@/components/custom/SideNavigation"
import { useBreakpoint } from "@/hooks/useBreakpoint"
import { NavLinks } from "@/constants/NavLinks"
import { NavAboutLinks } from "@/constants/NavAboutLinks"

const PublicHeader = ({ bg, dashBoard = false, dashboardNavItems = [] }) => {
  const { logout, user } = useAuth()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const header = useRef(null)
  const breakpoint = useBreakpoint()
  const permittedRoles = [userRoles.PARKER, userRoles.STAFF]

  useEffect(() => {
    if (pathname === '/') {
      header?.current?.classList?.add("drop-shadow-lg")
      const handleScroll = () => {
        if (window.scrollY > 50) {
          header?.current?.classList?.add("bg-primary")
        } else {
          header?.current?.classList?.remove("bg-primary")
        }
      };

      window.addEventListener('scroll', handleScroll);

      return () => {
        // Cleanup function to remove the event listener
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, [pathname, header])

  const handleLogout = async () => {
    await logout()
    navigate("/")
  }

  const getLinkProps = (link) => ({
    to: link.path,
    className: "transition-colors hover:text-accent-yellow text-white text-base",
    ...(link.openNewTab && { target: "_blank", rel: "noopener noreferrer" })
  })
  
  const getActiveClass = (isActive) => isActive ? "underline underline-offset-8" : ""

  const BuildDesktopNav = ({ children }) => {
    return (
      <nav className="hidden lg:flex items-center justify-center gap-6">
        {NavLinks({ user, breakpoint }).map((link, i) =>
          link.canDisplay && (
            <NavLink key={i} {...getLinkProps(link)} className={({ isActive }) =>
                `${getLinkProps(link).className} ${getActiveClass(isActive)}`
              }
            >
              {link.name}
            </NavLink>
          )
        )}
        {children}
      </nav>
    )
  }

  const BuildMobileNav = () => {
    return (
      <SideNavigation menuItems={NavLinks({ user, breakpoint, dashboardNavItems, additionalLinks: [...NavAboutLinks({ breakpoint })] })} />
    )
  }

  return (
    <header ref={header} className={cn("fixed top-0 left-0 right-0 flex items-center justify-between py-2 px-4 z-20 lg:px-10", bg)}>
      <div className="flex items-center">
        <Link to="/" className="mr-3">
          <img className="w-12" src={spotSurferIcon} />
        </Link>
        <a href="/" className="text-3xl font-semibold text-white uppercase">Spotsurfer</a>
      </div>
      {
        !dashBoard ? (
          <>
            <BuildDesktopNav>
              <AboutUsDropdownMenu />
              {user && <UserDropdownMenu />}
            </BuildDesktopNav>
            <BuildMobileNav />
          </>
        ) : (
          <BuildMobileNav />
        )
      }
    </header>
  )
}

export default PublicHeader
