import { Separator } from "@/components/ui/separator"
import { TypographyH3 } from "@/components/ui/typography/h3"
import { NewVehicleForm } from "./NewVehicleForm"
import {
  FormPhoneNumberInput,
  FormTextInput
} from "@/components/custom/Forms/FormInputs"
import { useReservationForm } from "../../context/ReservationFormContext"

export const AnonymousParkerForm = () => {
  const { control } = useReservationForm()

  if (!control) {
    return <></>
  }

  return (
    <>
      <FormTextInput
        control={control}
        name="email"
        type="email"
        label="Email *"
        placeholder="Your Email"
      />
      <FormPhoneNumberInput
        control={control}
        name="phone"
        type="phone"
        label="Phone *"
        placeholder="Phone Number"
      />
      <Separator className="mt-2"/>
      <TypographyH3 className="text-md font-semibold">Vehicle</TypographyH3>
      <NewVehicleForm />
    </>
  )
}
