import { UpdateFormTabsWrapperTab } from "@/components/custom/Forms/UpdateFormGenerics/UpdateFormTabWrapper"
import { EditFacilityDetails } from "./components/EditFacilityDetails"
import { EditFacilityAmenities } from "./components/EditFacilityAmenities"
import { FacilityDetailsSchema } from "../const"
import { AddressSchema } from "@/constants/AddressSchema"
import { z } from "zod"
import { MediaSchema } from "@/constants/MediaSchema"
import { EditFacilityMedia } from "./components/EditFacilityMedia"

export const EditFacilityTabs: UpdateFormTabsWrapperTab<Facility, Facility>[] =
  [
    {
      tabName: "Details & Address",
      tabId: "Details",
      title: "Details & Address",
      subtitle:
        "Edit general details about this facility. Change this facility's address.",
      Render: EditFacilityDetails
    },
    {
      tabName: "Amenities",
      tabId: "Amenities",
      title: "Amenities",
      subtitle:
        "Add or remove amenities for this facility. Amenities are shown when a user requests a reservation at your lots and spots.",
      Render: EditFacilityAmenities
    },
    {
      tabName: "Media",
      tabId: "media",
      title: "Media",
      Render: EditFacilityMedia
    }
  ]

export const EditFacilityDetailsSchema = FacilityDetailsSchema.extend({
  address: AddressSchema
})
export type EditFacilityDetailsForm = z.infer<typeof EditFacilityDetailsSchema>

export const EditfacilityMediaSchema = z.object({
  images: MediaSchema
})
export type EditFacilityMediaForm = z.infer<typeof EditfacilityMediaSchema>
