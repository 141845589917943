import {
  api,
  AuthHeader,
  StandardApiResponse,
  StandardErrorHandler,
  StandardGetHandler
} from "../const"

export const GetLotReservationsPath = (id?: string) =>
  `/v1/lots/${id}/reservations/`
export const GetLotReservationsQueryKey = (id?: string) =>
  `lot-reservations-${id}`

export interface GetLotReservationsResponse extends StandardApiResponse {
  reservations?: Reservation[]
}

export const GetLotReservations = async (
  accessToken: string,
  id?: string
): Promise<GetLotReservationsResponse> =>
  api
    .get(GetLotReservationsPath(id), AuthHeader(accessToken))
    .then(StandardGetHandler("reservations"))
    .catch(StandardErrorHandler)
