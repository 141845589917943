import { createContext, useContext, useEffect } from "react"
import { useLocalStorage } from "react-use"
import { useLocation } from "react-router-dom"
import { useMutation } from "@tanstack/react-query"
import { releaseReservationHold } from "@/api/post"

export interface LockContext {
  reservationLock: any
  setReservationLock: React.Dispatch<any>
  removeReservationLock: () => void
}

const LockContext = createContext<LockContext | null>(null)

export const LockProvider = ({ children }: React.PropsWithChildren) => {
  const location = useLocation()

  const [reservationLock, setReservationLock, removeReservationLock] =
    useLocalStorage<any>("lock", null)

  const releaseReservationMutation = useMutation<any, Error, { id: string }>({
    mutationFn: (payload) => releaseReservationHold(payload),
    onSuccess: () => removeReservationLock()
  })

  // Release reservation lock if user navigates away from reservation page
  useEffect(() => {
    if (!reservationLock || typeof location.pathname !== "string") return

    const currentPath = location.pathname
    if (currentPath.includes("/reservation")) return

    releaseReservationMutation.mutateAsync({ id: reservationLock.lockId })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, reservationLock])

  const contextValue = {
    reservationLock,
    setReservationLock,
    removeReservationLock
  }

  return (
    <LockContext.Provider value={contextValue}>{children}</LockContext.Provider>
  )
}

export const useLock = () => {
  return useContext(LockContext)
}
