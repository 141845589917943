import { TypographyH2 } from "@/components/ui/typography/h2"
import { TypographyP } from "@/components/ui/typography/p"
import { allPartnershipImages } from "@/assets/partnershipsImages/allPartnershipsImages"

const PartnershipsPage = () => {
  const images = allPartnershipImages.map((image, i) => {
    return (
      <div key={i}>
        <img
          className="w-44 h-auto m-auto object-cover"
          src={image}
          key={i}
          alt={image.replace("/src/assets/partnershipsImages/", "")}
        />
      </div>
    )
  })
  return (
    <div className="m-auto px-4 mb-20 text-left h-fit max-w-screen-lg">
      <TypographyH2 className="pt-10 text-center"> Community </TypographyH2>
      <TypographyP>
        We aren&apos;t just about parking management — we are focused on
        supporting economic development in local mountain communities by
        engaging with local leaders and Colorado brands that care about the
        future.
      </TypographyP>
      <TypographyP>
        We are building our partner and community network with those who are
        aligned in our vision to support education, foundations, sporting
        events, and economic development programs, as well as finding ways to
        reduce traffic congestion and pollution — all to make Colorado and each
        local community a better place to live.
      </TypographyP>
      <TypographyP>
        Below are some of the many organizations who support us. If you are
        interested in exploring joint opportunities, reach out to us at
        info@spotsurfer.com
      </TypographyP>
      <div className="flex flex-col pt-6 lg:grid grid-cols-4 gap-y-8  gap-x-2 justify-center items-center lg:pt-20">
        {images}
      </div>
    </div>
  )
}

export default PartnershipsPage
