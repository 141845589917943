import { Stepper } from "@/components/custom/Stepper/Stepper"
import { useAuth } from "@/context/AuthContext"
import { useCallback } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { CreateSpot } from "@/api/spot-lot/CreateSpot"
import { CreateSpotsSteps, FORM_SUBTITLE, FORM_TITLE } from "./const"
import {
  CreateFormHeader,
  CreateFormHeaderChildren
} from "@/components/custom/Forms/CreateFormGenerics/CreateFormHeader"
import { amenitiesToIntArray } from "@/hooks/useAmenitiesForm"
import { Spot } from "@/api/types/internal/Spot"
import { ImageWithId } from "@/constants/MediaSchema"
import { UpdateSpotAvailability } from "@/api/spots/UpdateSpotAvailability"
import { availabilityToBackendState } from "@/components/custom/Forms/AvailabilityGenerics/const"
import { AvailabilityForm } from "@/constants/AvailabilitySchema"
import {
  UploadMultipleMediaToSpot,
  UploadToSpotData
} from "@/api/spots/UploadMediaToSpot"

export const CreateSpotPage = () => {
  const navigate = useNavigate()
  const { accessToken } = useAuth()
  const { facilityId } = useParams()

  const handleSubmit = useCallback(
    async (
      data: Spot<number, string>,
      availability: AvailabilityForm,
      imageData: ImageWithId[],
      setApiError: CreateFormHeaderChildren["onApiError"]
    ) => {
      if (facilityId) {
        const result = await CreateSpot(accessToken, facilityId, data)
        if (result.isError || !result.spot?.id) {
          setApiError?.(result.error ?? "Spot was not created")
          return
        }
        const AvailResult = await UpdateSpotAvailability(
          accessToken,
          result.spot.id,
          availabilityToBackendState(availability)
        )
        if (AvailResult.isError) {
          setApiError?.(AvailResult.error ?? "Availability was not set.")
          return
        }
        const MediaResult = await UploadMultipleMediaToSpot(
          accessToken,
          result.spot.id,
          imageData as UploadToSpotData[]
        )
        if (MediaResult.isError) {
          setApiError?.(MediaResult.error ?? "Media was not created")
          return
        }
        navigate(-1)
      }
    },
    []
  )

  return (
    <CreateFormHeader
      title={FORM_TITLE}
      subtitle={FORM_SUBTITLE}
      render={({ onApiError }) => (
        <Stepper
          onFinalSubmit={(dataArray) => {
            handleSubmit(
              {
                ...dataArray[0], // Details
                ...dataArray[1], // Location
                amenities: amenitiesToIntArray(dataArray[3]) //Amenities
              } as Spot<number, string>,
              dataArray[2] as AvailabilityForm, // Availability
              dataArray[4].images, // Media
              onApiError
            )
          }}
          steps={CreateSpotsSteps}
        />
      )}
    />
  )
}
