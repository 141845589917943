import { useNavigate } from "react-router-dom"

import { TypographyH2 } from "../ui/typography/h2"
import { Button } from "@/components/ui/button"

import errorImage from "@/assets/pngs/errorImage.png"

export interface FallbackProps {
  error?: any
  resetErrorBoundary?: () => void
  message?: string
}

const Fallback = ({
  error,
  resetErrorBoundary,
  message = "Sorry, something went wrong."
}: FallbackProps) => {
  // Call resetErrorBoundary() to reset the error boundary and retry the render.
  const navigate = useNavigate()

  const handleClick = () => {
    resetErrorBoundary?.()
    navigate("/")
  }

  console.error({ error })

  return (
    <div className="flex justify-center items-center h-screen">
      <div className="flex flex-col-reverse md:flex-row justify-center items-center gap-8">
        <div className="md:max-w-56 text-center">
          <TypographyH2 className="">{message}</TypographyH2>
          <Button className="mt-4" variant="secondary" onClick={handleClick}>
            Take me home
          </Button>
        </div>
        <div className="h-52 w-52">
          <img
            alt="Error image"
            className="aspect-square w-full rounded-md object-cover"
            src={errorImage}
          />
        </div>
      </div>
    </div>
  )
}

export default Fallback
