import {
  api,
  AuthHeader,
  keysToSnakeCase,
  StandardApiResponse,
  StandardErrorHandler,
  StandardGetHandler,
  StandardPushHandler
} from "../const"
import { Pricing, PricingRequest } from "../types/internal/Pricing"

export const GetReservationPricingPath = (id: string) =>
  `v1/reservations/pricing/`

export interface GetReservationPricingResponse extends StandardApiResponse {
  pricing?: Pricing
}

export const GetReservationPricing = (
  id: string,
  data: PricingRequest,
  accessToken?: string
): Promise<GetReservationPricingResponse> =>
  api
    .post(
      GetReservationPricingPath(id),
      keysToSnakeCase(data),
      accessToken ? AuthHeader(accessToken) : undefined
    )
    .then(StandardGetHandler("pricing"))
    .catch(StandardErrorHandler)
