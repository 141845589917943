import { Dialog, DialogTrigger, DialogContent } from "@/components/ui/dialog"

const SimpleDialog = ({
  children,
  dialogTrigger,
  setIsDialogOpen,
  isDialogOpen
}) => {
  return (
    <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
      <DialogTrigger className="transition-all hover:text-primary">
        {dialogTrigger}
      </DialogTrigger>
      <DialogContent className="overflow-scroll max-h-full py-8">
        {children}
      </DialogContent>
    </Dialog>
  )
}

export default SimpleDialog
