import { cn } from "@/lib/utils"
import {
  DateInput,
  DayOfWeekSelection,
  PriceInput,
  SpotInput,
  TimeInput
} from "./inputs"
import AvailabilityCardHeader from "./AvailabilityCardHeader"
import { ApiAvailability, ApiOverride } from "@/api/types/internal/Spot"
import { Control, UseFormSetValue, UseFormWatch } from "react-hook-form"
import { Availability } from "@/constants/SpotSchema"

export interface SpotAvailabilityCardProps {
  className?: string
  isOverride?: boolean
  onDelete?: () => void
  control: Control<Availability>
  watch: UseFormWatch<Availability>
  setValue: UseFormSetValue<Availability>
  controlIndex: number
}

const SpotAvailabilityCard = ({
  className,
  isOverride,
  control,
  watch,
  setValue,
  controlIndex,
  onDelete
}: SpotAvailabilityCardProps) => {
  const SectionClassNames = "grid gap-1 grid-cols-1"
  const baseWrapperClasses =
    "grid grid-rows-[auto_auto] gap-6 rounded-md border my-3 p-3 duration-500"
  const baseControlPath = isOverride ? "override" : "availability"

  const isActive = watch(`${baseControlPath}.${controlIndex}.active`)
  const isActiveAdditional =
    (isActive && !isOverride) ||
    (isActive && !watch(`override.${controlIndex}.isUnavailable`))
  const isActiveSubtractive =
    isActive && isOverride && watch(`override.${controlIndex}.isUnavailable`)

  return (
    <div
      className={cn(
        className,
        baseWrapperClasses,
        isActive && "outline outline-1",
        isActiveAdditional &&
          "border-green-700 outline outline-green-700 outline-1",
        isActiveSubtractive &&
          "border-red-700 outline outline-red-700 outline-1"
      )}>
      <AvailabilityCardHeader
        className=""
        watch={watch}
        setValue={setValue}
        control={control}
        activeName={`${baseControlPath}.${controlIndex}.active`}
        titleName={`${baseControlPath}.${controlIndex}.description`}
        overrideTypeName={`${baseControlPath}.${controlIndex}.isUnavailable`}
        isOverride={isOverride}
        onDelete={onDelete}
      />
      <div
        className={cn(
          className,
          "py-3 grid gap-x-3 gap-y-6 grid-rows-2 grid-cols-[1.5fr_1fr_1fr_1fr_1fr]"
        )}>
        <DayOfWeekSelection
          className=""
          control={control}
          name={`${baseControlPath}.${controlIndex}.option`}
        />
        <TimeInput
          label="Opens at"
          className={SectionClassNames}
          watch={watch}
          setValue={setValue}
          name={`${baseControlPath}.${controlIndex}.openingTime`}
        />
        <TimeInput
          label="Closes at"
          className={SectionClassNames}
          watch={watch}
          setValue={setValue}
          name={`${baseControlPath}.${controlIndex}.closingTime`}
        />
        <PriceInput
          className={SectionClassNames}
          control={control}
          name={`${baseControlPath}.${controlIndex}.price`}
        />
        <SpotInput
          className={SectionClassNames}
          control={control}
          name={`${baseControlPath}.${controlIndex}.spots`}
        />
        <DateInput
          className={cn(SectionClassNames, "col-span-2")}
          label={isOverride ? "Override Starts" : "Lease starts"}
          watch={watch}
          name={`${baseControlPath}.${controlIndex}.leaseStartDate`}
          setValue={setValue}
        />
        <DateInput
          className={cn(SectionClassNames, "col-span-2 col-start-3")}
          label={isOverride ? "Override ends" : "Lease ends"}
          watch={watch}
          name={`${baseControlPath}.${controlIndex}.leaseEndDate`}
          setValue={setValue}
        />
      </div>
    </div>
  )
}

export default SpotAvailabilityCard
