import { AvailabilityBackendState } from "@/constants/AvailabilitySchema"
import {
  api,
  AuthHeader,
  keysToSnakeCase,
  StandardErrorHandler,
  StandardPushHandler
} from "../const"

export const UpdateLotAvailabilityPath = (lotId: string) =>
  `/v1/lots/${lotId}/availability/`

export const UpdateLotAvailability = (
  accessToken: string,
  id: string,
  data: AvailabilityBackendState
) =>
  api
    .put(
      UpdateLotAvailabilityPath(id),
      keysToSnakeCase(data),
      AuthHeader(accessToken)
    )
    .then(StandardPushHandler())
    .catch(StandardErrorHandler)
