import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"
import { useStepperReducer } from "./StepperReducer"
import React, { useCallback } from "react"
import { useNavigate } from "react-router-dom"
import { FieldValues } from "react-hook-form"

export interface Step<I extends FieldValues> {
  label: string
  render: StepperComponent<I>
}

export interface StepResult<I extends FieldValues> {
  label: string
  step: number
  data: I
}

export interface StepperComponentProps<I extends FieldValues> {
  saveData: I
  backText: string
  submitText: string
  onBack: (data: I) => void
  onSubmit: (data: I) => void
}

export type StepperComponent<I extends FieldValues> = React.FunctionComponent<
  StepperComponentProps<I>
>

export interface StepperProps {
  steps: Array<Step<any>>
  onFinalSubmit?: (data: FieldValues[]) => void
}

export const Stepper = ({ steps, onFinalSubmit }: StepperProps) => {
  const navigate = useNavigate()
  const [state, dispatch] = useStepperReducer(steps.length)

  const next = useCallback((data: FieldValues) => {
    dispatch({
      action: "step_forward",
      data
    })
  }, [])

  const previous = useCallback((data: FieldValues) => {
    dispatch({
      action: "step_backwards",
      data
    })
  }, [])

  return (
    <Tabs defaultValue="0" value={state.currentStep.toString()}>
      <TabsList className={`flex w-full bg-muted`}>
        {steps.map((step, index) => (
          <TabsTrigger
            className="grow data-[state=active]:bg-accent-orange data-[state=inactive]:color-black"
            key={step.label}
            disabled={index > state.currentStep}
            onClick={() =>
              dispatch({
                action: "step_to",
                data: {
                  to: index
                }
              })
            }
            value={index.toString()}>
            {index + 1}. {step.label}
          </TabsTrigger>
        ))}
      </TabsList>
      {steps.map((step, index) => (
        <TabsContent key={step.label} value={index.toString()}>
          <div className="my-10">
            <step.render
              saveData={state.data[index]}
              backText={getBackText(index)}
              submitText={getSubmitText(index, state.totalSteps)}
              onBack={index === 0 ? () => navigate(-1) : previous}
              onSubmit={
                index === state.totalSteps - 1 ?
                  (data: FieldValues) =>
                    onFinalSubmit?.(state.data.with(index, data))
                : next
              }
            />
          </div>
        </TabsContent>
      ))}
    </Tabs>
  )
}

const getBackText = (index: number) => (index === 0 ? "Cancel" : "Back")

const getSubmitText = (index: number, length: number) =>
  index === length - 1 ? "Submit" : "Next"
