import {
  FormTextArea,
  FormTextInput
} from "@/components/custom/Forms/FormInputs"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import {
  UpdateFormTabsWrapperChildren,
  UpdateFormTabWrapper
} from "@/components/custom/Forms/UpdateFormGenerics/UpdateFormTabWrapper"
import { TypographyH3 } from "@/components/ui/typography/h3"
import { Separator } from "@/components/ui/separator"
import { useCallback } from "react"
import { UpdateFacility } from "@/api/facility/UpdateFacility"
import { useAuth } from "@/context/AuthContext"
import { useParams } from "react-router-dom"
import _ from "lodash"
import { EditFacilityDetailsForm, EditFacilityDetailsSchema } from "../const"
import { useCustomAlert } from "@/hooks/useCustomAlert"

export const EditFacilityDetails = ({
  data,
  ...tabProps
}: UpdateFormTabsWrapperChildren<Facility, EditFacilityDetailsForm>) => {
  const { accessToken } = useAuth()
  const { facilityId } = useParams()
  const { showMessage } = useCustomAlert()


  const form = useForm<EditFacilityDetailsForm>({
    resolver: zodResolver(EditFacilityDetailsSchema),
    values: data
  })

  const submitHandler = useCallback(
    (result: EditFacilityDetailsForm) => {
      if (facilityId && data?.id) {
        const amenitiesIds = data.amenities?.map((amenity: any) => amenity?.id) || [];

        const updatedData = {
          ...data,
          ...result, // This spreads form changes into the existing data
          owner: data.ownerId,
          amenities: amenitiesIds,
        };

        return UpdateFacility(
          accessToken,
          facilityId,
          updatedData
        ).then(() => showMessage("Your Facility Details have been successfully updated!", "success"))
      }
    },
    [facilityId, data, accessToken]
  )

  return (
    <UpdateFormTabWrapper
      {...tabProps}
      form={form}
      onDiscard={() => form.reset()}
      onSubmit={submitHandler}
      Render={({ control }) => (
        <div className="flex flex-col gap-6">
          <div className="flex flex-col gap-3">
            <TypographyH3 className="text-lg font-semibold">
              Details
            </TypographyH3>
            <FormTextInput control={control} name="name" label="Name *" />
            <FormTextArea
              control={control}
              name="description"
              label="Description"
            />
          </div>
          <Separator />
          <div className="flex flex-col gap-3">
            <TypographyH3 className="text-lg font-semibold">
              Address
            </TypographyH3>
            <div className="grid gap-3 my-3">
              <FormTextInput
                control={form.control}
                name="address.address1"
                label="Address 1 *"
              />
              <FormTextInput
                control={form.control}
                name="address.address2"
                label="Address 2"
              />
              <div className="grid grid-cols-3 gap-6">
                <FormTextInput
                  control={form.control}
                  name="address.city"
                  label="City *"
                />
                <FormTextInput
                  control={form.control}
                  name="address.state"
                  label="State *"
                  placeholder="CO"
                />
                <FormTextInput
                  control={form.control}
                  name="address.zipCode"
                  label="Zip Code *"
                />
              </div>
            </div>
          </div>
        </div>
      )}
    />
  )
}
