import { VehicleForm } from "./const"
import { Separator } from "@/components/ui/separator"
import { useState } from "react"
import { TypographyH3 } from "@/components/ui/typography/h3"
import { ExistingVehicleDropdown } from "./ExistingVehicleDropdown"
import { Button } from "@/components/ui/button"
import { ChevronLeft, Plus } from "lucide-react"
import { NewVehicleForm } from "./NewVehicleForm"
import { InputSkeleton } from "@/components/custom/Forms/Skeletons"
import { Skeleton } from "@/components/ui/skeleton"
import { useReservationForm } from "../../context/ReservationFormContext"

export const ExistingParkerForm = () => {
  const [showNewVehicleForm, setShowNewVehicleForm] = useState(false)
  const { control, form, parker } = useReservationForm()

  if (!form || !control) {
    return <></>
  }

  return (
    <>
      <TypographyH3 className="text-md font-semibold">Vehicle *</TypographyH3>

      {showNewVehicleForm || !parker?.vehicles || parker.vehicles.length === 0 ?
        <>
          {!parker?.vehicles || parker.vehicles.length === 0 || (
            <Button
              type="button"
              className="w-fit"
              variant="outline"
              onClick={() => {
                form?.setValue("vehicle", {} as VehicleForm)
                setShowNewVehicleForm(false)
              }}>
              <ChevronLeft className="h-4 w-4" /> Use an existing vehicle
            </Button>
          )}
          <NewVehicleForm />
        </>
      : <div className="flex flex-wrap md:flex-nowrap gap-2">
          <ExistingVehicleDropdown />
          <Button
            type="button"
            variant="link"
            className="flex w-full md:w-auto underline"
            onClick={() => {
              form?.setValue("vehicle", {} as VehicleForm)
              setShowNewVehicleForm(true)
            }}>
            <Plus className="h-4 w-4" /> New Vehicle
          </Button>
        </div>
      }
    </>
  )
}

export const ExistingParkerSkeleton = () => (
  <>
    <Skeleton className="h-5 w-full" />
    <InputSkeleton />
    <InputSkeleton />
    <Separator />
    <Skeleton className="h-5 w-full" />
    <InputSkeleton />
    <Skeleton className="h-10 w-48" />
    <Skeleton className="h-5 w-full" />
  </>
)
