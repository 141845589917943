import { Card } from "@/components/ui/card"
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from "@/components/ui/form"
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group"
import { Control, FieldValues, Path } from "react-hook-form"
import { cn } from "@/lib/utils"
import Icon from "../Icon"

export interface CardRadioGroupItem {
  label: string
  description?: string
  iconName?: string
  value: string
  price?: string
  disabled?: boolean
  onChange?: (value: string) => void
  hasUnlimitedParkings?: boolean
}

export interface CardRadioGroupProps<I extends FieldValues> {
  name: Path<I>
  label?: string
  items: CardRadioGroupItem[]
  control: Control<I>
  viewLabel?: boolean
  disabled?: boolean
  onSelectionChange?: (value: string, item: CardRadioGroupItem) => void
}

export const CardRadioGroup = <I extends FieldValues>({
  control,
  label,
  items,
  name,
  viewLabel,
  onSelectionChange = () => {}
}: CardRadioGroupProps<I>) => {
  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem>
          {
            label && (
            <div className="flex justify-between gap-3 items-center">
              <FormLabel className={viewLabel ? "" : "sr-only"}>{label}</FormLabel>
              <FormMessage />
            </div>
            )
          }
          <FormControl>
            <RadioGroup
              className="flex flex-col gap-3"
              onValueChange={(value) => {
                field.onChange(value);
                const selectedItem = items.find(item => item.value === value);
                if (selectedItem) {
                  selectedItem.onChange?.(value);
                  onSelectionChange(value, selectedItem);
                }
              }}
              value={field.value}
            >
              {items.map((item) => (
                <Card key={item.value} className="w-full p-4">
                  <FormItem
                    className={cn("flex justify-between gap-3 items-center", {
                      "opacity-50": item.disabled,
                      "pointer-events-none": item.disabled
                    })}
                  >
                    <div className="flex gap-3 items-center justify-start">
                      {item.iconName && <Icon name={item.iconName} />}
                      <div className="grid gap-1">
                        <FormLabel className="text-md font-semibold">
                          {item.label}
                        </FormLabel>
                        {item?.description && (
                          <FormDescription className="text-muted-foreground">
                            {item.description}
                          </FormDescription>
                        )}
                      </div>
                    </div>
                    <div className="flex gap-3 justify-between items-center">
                      {item.price && (
                        <p className="text-md font-semibold">{item.price}</p>
                      )}
                      <FormControl>
                        <RadioGroupItem value={item.value} disabled={item.disabled} />
                      </FormControl>
                    </div>
                  </FormItem>
                </Card>
              ))}
            </RadioGroup>
          </FormControl>
        </FormItem>
      )}
    />
  );
};
