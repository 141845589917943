import { cn } from "@/lib/utils"

const TypographyH1 = ({ children, className = "" }) => {
  return (
    <h1
      className={cn(
        "scroll-m-20 text-3xl font-semibold tracking-wide lg:text-4xl mb-1",
        className
      )}>
      {children}
    </h1>
  )
}

export default TypographyH1
