export const userRoles = {
  PARKER: "PARKER",
  CPO: "CPO",
  PPO: "PPO",
  TEAM_MEMBER: "TEAM_MEMBER",
  TEAM_MANAGER: "TEAM_MANAGER",
  STAFF: "STAFF"
}

export const vehicleType = {
  SUV: "SUV",
  MPV: "MPV",
  TRUCK: "Truck",
  VAN: "VAN",
  MINIVAN: "Mini Van",
  SEDAN: "Sedan",
  HATCHBACK: "Hatchback",
  CAMPER: "Camper",
  STATION_WAGON: "Station Wagon"
}

export const USStates = {
  AK: {
    abbreviation: "AK",
    full: "Alaska"
  },
  AL: {
    abbreviation: "AL",
    full: "Alabama"
  },
  AR: {
    abbreviation: "AR",
    full: "Arkansas"
  },
  AZ: {
    abbreviation: "AZ",
    full: "Arizona"
  },
  CA: {
    abbreviation: "CA",
    full: "California"
  },
  CO: {
    abbreviation: "CO",
    full: "Colorado"
  },
  CT: {
    abbreviation: "CT",
    full: "Connecticut"
  },
  DE: {
    abbreviation: "DE",
    full: "Delaware"
  },
  FL: {
    abbreviation: "FL",
    full: "Florida"
  },
  GA: {
    abbreviation: "GA",
    full: "Georgia"
  },
  HI: {
    abbreviation: "HI",
    full: "Hawaii"
  },
  IA: {
    abbreviation: "IA",
    full: "Iowa"
  },
  ID: {
    abbreviation: "ID",
    full: "Idaho"
  },
  IL: {
    abbreviation: "IL",
    full: "Illinois"
  },
  IN: {
    abbreviation: "IN",
    full: "Indiana"
  },
  KS: {
    abbreviation: "KS",
    full: "Kansas"
  },
  KY: {
    abbreviation: "KY",
    full: "Kentucky"
  },
  LA: {
    abbreviation: "LA",
    full: "Louisiana"
  },
  MA: {
    abbreviation: "MA",
    full: "Massachusetts"
  },
  MD: {
    abbreviation: "MD",
    full: "Maryland"
  },
  ME: {
    abbreviation: "ME",
    full: "Maine"
  },
  MI: {
    abbreviation: "MI",
    full: "Michigan"
  },
  MN: {
    abbreviation: "MN",
    full: "Minnesota"
  },
  MO: {
    abbreviation: "MO",
    full: "Missouri"
  },
  MS: {
    abbreviation: "MS",
    full: "Mississippi"
  },
  MT: {
    abbreviation: "MT",
    full: "Montana"
  },
  NC: {
    abbreviation: "NC",
    full: "North Carolina"
  },
  ND: {
    abbreviation: "ND",
    full: "North Dakota"
  },
  NE: {
    abbreviation: "NE",
    full: "Nebraska"
  },
  NH: {
    abbreviation: "NH",
    full: "New Hampshire"
  },
  NJ: {
    abbreviation: "NJ",
    full: "New Jersey"
  },
  NM: {
    abbreviation: "NM",
    full: "New Mexico"
  },
  NV: {
    abbreviation: "NV",
    full: "Nevada"
  },
  NY: {
    abbreviation: "NY",
    full: "New York"
  },
  OH: {
    abbreviation: "OH",
    full: "Ohio"
  },
  OK: {
    abbreviation: "OK",
    full: "Oklahoma"
  },
  OR: {
    abbreviation: "OR",
    full: "Oregon"
  },
  PA: {
    abbreviation: "PA",
    full: "Pennsylvania"
  },
  RI: {
    abbreviation: "RI",
    full: "Rhode Island"
  },
  SC: {
    abbreviation: "SC",
    full: "South Carolina"
  },
  SD: {
    abbreviation: "SD",
    full: "South Dakota"
  },
  TN: {
    abbreviation: "TN",
    full: "Tennessee"
  },
  TX: {
    abbreviation: "TX",
    full: "Texas"
  },
  UT: {
    abbreviation: "UT",
    full: "Utah"
  },
  VA: {
    abbreviation: "VA",
    full: "Virginia"
  },
  VT: {
    abbreviation: "VT",
    full: "Vermont"
  },
  WA: {
    abbreviation: "WA",
    full: "Washington"
  },
  WI: {
    abbreviation: "WI",
    full: "Wisconsin"
  },
  WV: {
    abbreviation: "WV",
    full: "West Virginia"
  },
  WY: {
    abbreviation: "WY",
    full: "Wyoming"
  },
  DC: {
    abbreviation: "DC",
    full: "District of Columbia"
  },
  AS: {
    abbreviation: "AS",
    full: "American Samoa"
  },
  GU: {
    abbreviation: "GU",
    full: "Guam"
  },
  MP: {
    abbreviation: "MP",
    full: "Northern Mariana Islands"
  },
  PR: {
    abbreviation: "PR",
    full: "Puerto Rico"
  },
  VI: {
    abbreviation: "VI",
    full: "U.S. Virgin Islands"
  }
}
