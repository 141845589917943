import {
  AvailabilityBackendState,
  AvailabilityForm
} from "@/constants/AvailabilitySchema"

export const availabilityToBackendState = (
  availability: AvailabilityForm,
  deleted?: AvailabilityForm
) => {
  const backendState = {
    availability: {
      created: [],
      deleted: [],
      updated: []
    },
    override: {
      created: [],
      deleted: [],
      updated: []
    }
  } as AvailabilityBackendState
  availability.availability.map((avail) => {
    if (avail.new) {
      backendState.availability.created?.push(avail)
    } else {
      backendState.availability.updated?.push(avail)
    }
  })
  availability.override.map((over) => {
    if (over.new) {
      backendState.override.created?.push(over)
    } else {
      backendState.override.updated?.push(over)
    }
  })

  backendState.availability.deleted = backendState.availability.deleted?.concat(
    deleted?.availability ?? []
  )
  backendState.override.deleted = backendState.override.deleted?.concat(
    deleted?.override ?? []
  )
  return backendState
}
