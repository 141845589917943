import { cn } from "@/lib/utils"

export function TypographyH3({ children, className }) {
  return (
    <h3
      className={cn(
        "scroll-m-20 text-2xl font-semibold tracking-wide",
        className
      )}>
      {children}
    </h3>
  )
}
