import FacilityDetailsCard from "@/components/custom/FacilityDetailsCard"
import Loading from "@/components/custom/Loading"
import ReservationDetailsCard from "@/components/custom/ReservationDetailsCard"
import { TypographyH2 } from "@/components/ui/typography/h2"
import { useAuth } from "@/context/AuthContext"
import { useQuery } from "@tanstack/react-query"
import { Link, useParams } from "react-router-dom"
import { getReservationById } from "@/api/get"
import SpotLotDetailsCard from "@/components/custom/SpotLotDetailsCard"
import { Button } from "@/components/ui/button"
import { ChevronLeft } from "lucide-react"
import Fallback from "@/components/custom/Fallback"

const ReservationDetailsPage = () => {
  const { id } = useParams()
  const { accessToken } = useAuth()
  const {
    data: reservationData,
    isPending: reservationIsPending,
    error: reservationError
  } = useQuery({
    queryKey: [`parkerReservation-${id}`, accessToken],
    queryFn: () => getReservationById(id)
  })

  if (reservationIsPending) return <Loading />

  if (reservationError) return <Fallback />

  return (
    <main className="p-8 grid gap-4">
      <div className="flex items-center">
        <Link to="/parker-dashboard">
          <Button variant="outline" size="icon" className="h-7 w-7 mr-4">
            <ChevronLeft className="h-4 w-4" />
            <span className="sr-only">Back</span>
          </Button>
        </Link>
        <TypographyH2>Reservation</TypographyH2>
      </div>
      <ReservationDetailsCard data={reservationData} />
      <div className="lg:flex justify">
        <FacilityDetailsCard data={reservationData} />
        <SpotLotDetailsCard data={reservationData} />
      </div>
    </main>
  )
}

export default ReservationDetailsPage
