import { cn } from "@/lib/utils"
import { AvailabilityCardHeaderProps } from "./AvailabilityCardHeader"
import { ActiveToggle } from "./CustomInputs"
import { Title } from "./EditableTitle"
import { Label } from "@/components/ui/label"
import { LabelledInfoHover } from "../../Hoverable/LabelledInfoHover"
import { FormField } from "@/components/ui/form"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "@/components/ui/select"
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger
} from "@/components/ui/hover-card"
import { Button } from "@/components/ui/button"
import { X } from "lucide-react"

export interface OverrideCardHeaderProps extends AvailabilityCardHeaderProps {
  overrideTypeName: string
}

const PageLabel = "spotsandlots_edit"

export const OverrideCardHeader = ({
  className,
  watch,
  control,
  setValue,
  activeName,
  titleName,
  overrideTypeName,
  onDelete
}: OverrideCardHeaderProps) => {
  return (
    <div
      className={cn(
        className,
        "flex content-center gap-6 grid-cols-[auto_auto_1fr_auto_auto] justify-between"
      )}>
      {/* <ActiveToggle watch={watch} setValue={setValue} name={activeName} /> */}
      <Title name={titleName} watch={watch} setValue={setValue} />
      <span />

      <div className="flex gap-3 items-center">
        <div className="flex gap-1">
          <Label htmlFor="additive">Effect</Label>
          <LabelledInfoHover
            displayKeyOnError
            labelKey={`${PageLabel}.availability_override_effect`}
          />
        </div>
        <FormField
          name={overrideTypeName}
          control={control}
          render={({ field }) => (
            <Select value={field.value} onValueChange={field.onChange}>
              <SelectTrigger>
                <SelectValue />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value={false}>Add New Spots</SelectItem>
                <SelectItem value={true}>Cap Current Spaces</SelectItem>
              </SelectContent>
            </Select>
          )}
        />
      </div>

      <HoverCard>
        <HoverCardTrigger asChild>
          <Button variant="link" size="icon" onClick={onDelete}>
            <X size={20} className="text-slate-700" />
          </Button>
        </HoverCardTrigger>
        <HoverCardContent side="top">
          <p>Delete this entry</p>
        </HoverCardContent>
      </HoverCard>
    </div>
  )
}
