import { TypographyH2 } from "../ui/typography/h2"
import truckLogo from "@/assets/pngs/marketingLogoTruck.png"
import truck from "@/assets/pngs/marketingTruck.png"
import doingGood from "@/assets/pngs/doingGood.png"
import home1 from "@/assets/pngs/home/home-1.png"
import home2 from "@/assets/pngs/home/home-2.png"
import home3 from "@/assets/pngs/home/home-3.png"
import { useLabels } from "@/context/LabelContext"

const MarketingSections = () => {
  const { getLabel } = useLabels()
  return (
    <>
      <div className="p-10 lg:p-28 h-fit w-full bg-primary text-white">
        <p className="md:mx-10 text-2xl px-2 text-pretty font-light text-center">
          {getLabel("landing.marketing_description")}
        </p>
      </div>
      <section className="flex flex-col lg:flex-row py-10">
        <div className="flex flex-col text-center items-center">
          {/* <img src={truckLogo} className="h-24 mb-2 lg:h-28 w-auto lg:mb-6 lg:mt-10" /> */}
          <TypographyH2 className="mt-2 text-2xl uppercase text-pretty">
            {getLabel("landing.marketing_circle_title")}
          </TypographyH2>
          <p className="mb-5 mx-10 text-lg px-2 text-pretty font-light text-center">
            {getLabel("landing.marketing_circle_subtitle")}
          </p>
          <img src={home3} className="max-w-80 w-auto" />
        </div>
        <div className="flex flex-col text-center items-center">
          {/* <img src={truck} className="h-20 mb-2 lg:h-24 w-auto lg:mb-6 lg:mt-10" /> */}
          <TypographyH2 className="mt-8 lg:mt-2 text-2xl uppercase text-pretty">
            {getLabel("landing.marketing_car_title")}
          </TypographyH2>
          <p className="mb-5 mx-10 text-lg px-2 text-pretty font-light text-center">
            {getLabel("landing.marketing_car_subtitle")}
          </p>
          <img src={home2} className="max-w-80 w-auto"  />
        </div>
        <div className="flex flex-col text-center items-center">
          {/* <img src={doingGood} className="h-24 mb-2 lg:h-28 w-auto lg:mb-6 lg:mt-10" /> */}
          <TypographyH2 className="mt-8 lg:mt-2 text-2xl uppercase text-pretty">
            {getLabel("landing.marketing_lightbulb_title")}
          </TypographyH2>
          <p className="mb-5 mx-10 text-lg px-2 text-pretty font-light text-center">
            {getLabel("landing.marketing_lightbulb_subtitle")}
          </p>
          <img src={home1} className="max-w-80 w-auto" />
        </div>
      </section>
    </>
  )
}

export default MarketingSections
