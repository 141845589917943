import Fallback from "@/components/custom/Fallback"
import { CardSwitch } from "@/components/custom/Forms/FormInputs"
import Loading from "@/components/custom/Loading"
import { StandardStepperButtons } from "@/components/custom/Stepper/const"
import { StepperComponentProps } from "@/components/custom/Stepper/Stepper"
import { Form } from "@/components/ui/form"
import { IAmenitiesSchema, useAmenitiesForm } from "@/hooks/useAmenitiesForm"

export const AmenitiesStepper = ({
  backText,
  onBack,
  onSubmit,
  saveData,
  submitText
}: StepperComponentProps<IAmenitiesSchema>) => {
  const { form, isError, isPending, amenities } = useAmenitiesForm({
    saveData
  })

  if (isPending) return <Loading className="" />
  if (isError) return <Fallback />

  return (
    <Form {...form}>
      <form className="grid" onSubmit={form.handleSubmit(onSubmit)}>
        <div className="grid gap-3 grid-cols-2 my-6">
          {amenities &&
            amenities
              ?.sort((a, b) => (a.name > b.name ? 1 : -1))
              .map((amenity) => (
                <CardSwitch
                  key={amenity.id}
                  label={amenity.name}
                  name={amenity.id.toString()}
                  iconName={amenity.iconName}
                  description={amenity.description}
                  control={form.control}
                />
              ))}
        </div>
        <StandardStepperButtons
          backText={backText}
          onBack={() => onBack(form.watch())}
          submitText={submitText}
        />
      </form>
    </Form>
  )
}
