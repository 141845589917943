import { Control, useForm, UseFormReturn } from "react-hook-form"
import React, { createContext, useCallback, useContext, useMemo } from "react"
import {
  defaultValues,
  ReservationForm,
  ReservationSchema,
  ReservationLock,
  routingStateIsValid
} from "./const"
import { useLocation } from "react-router-dom"
import {
  Reservation,
  ReservationRequest
} from "@/api/types/internal/Reservation"
import { zodResolver } from "@hookform/resolvers/zod"
import { useAuth } from "@/context/AuthContext"
import { useHoldReservation } from "@/hooks/useHoldReservation"
import { useMutation, useQuery } from "@tanstack/react-query"
import {
  GetParker,
  GetParkerQueryKey,
  GetParkerResponse
} from "@/api/parker/GetParker"
import { AxiosError } from "axios"
import { addOnsToIdArray } from "../sections/AddOnsAccordion/const"
import {
  GetUserLotParkingPacks,
  GetUserLotParkingPacksKey,
  GetUserParkingPacksResponse,
  GetUserSpotParkingPacks,
  GetUserSpotParkingPacksKey
} from "@/api/parking-packs/GetUserRelevantParkingPacks"
import { Pricing, PricingRequest } from "@/api/types/internal/Pricing"
import { GetSpotPricingResponse
} from "@/api/spots/GetSpotPricing"
import { GetLotPricingResponse } from "@/api/lots/GetLotPricing"
import { CreateReservation } from "@/api/reservation/CreateReservation"
import { GetReservationPricing } from "@/api/reservation/GetReservationPricing"

export interface ReservationFormContext {
  form: UseFormReturn<ReservationForm>
  control: Control<ReservationForm>
  reservation?: Reservation
  pricing?: Pricing
  addOns?: PaidAmenity[]
  facilityParkingPacks?: ParkingPack[]
  isLoggedInParker?: boolean
  parker?: Parker
  userParkingPacks?: ParkingPackUsage[]
  submitHandler?: () => Promise<void>
  reservationLock?: ReservationLock
  isError: boolean
  isLoading: boolean
  priceIsLoading: boolean
  submitIsPending: boolean
  payViaCredits?: boolean
}

export const ReservationContextDefaultValues: ReservationFormContext = {
  form: {} as UseFormReturn<any>,
  control: {} as Control<any>,
  isError: false,
  isLoading: true,
  priceIsLoading: true,
  submitIsPending: false
}

const ReservationFormContext = createContext<ReservationFormContext>(
  ReservationContextDefaultValues
)

export const ReservationFormProvider = ({
  children,
  parker
}: React.PropsWithChildren<{ parker?: Parker }>) => {
  const { state } = useLocation()
  const routeIsValid =
    state.data && routingStateIsValid(state.data, state.searchQuery)
  const reservation = state.data as Reservation | undefined
  const parkingLocationType = reservation?.parkingLocationType
  const location =
    parkingLocationType === "LOT" ? reservation?.lot : reservation?.spot

  const { user, accessToken } = useAuth()
  const isLoggedInParker = useMemo(() => user?.role === "PARKER", [user?.role])

  const {
    reservationLock,
    lockExpiryInSeconds,
    handleRedirectToSearch,
    error
  } = useHoldReservation()

  const {
    data: getParkerResponse,
    isPending: getParkerPending,
    isError: getParkerResponseQueryError
  } = useQuery<GetParkerResponse | undefined, AxiosError>({
    queryKey: [GetParkerQueryKey, accessToken],
    queryFn: () => (isLoggedInParker ? GetParker(accessToken) : () => undefined)
  })

  const form = useForm<ReservationForm>({
    resolver: zodResolver(ReservationSchema),
    values: defaultValues(
      isLoggedInParker ?
        {
          email: parker?.user?.email,
          phone: `+1${ parker?.user?.phone}`,
          checkinDate: state.searchQuery.checkinDate
        }
      : { checkinDate: state.searchQuery.checkinDate }
    ),
    mode: "onChange"
  })

  const {
    data: getPricingResponse,
    isPending: getPricingPending,
    isError: getPricingError
  } = useQuery<
    GetSpotPricingResponse | GetLotPricingResponse | undefined,
    AxiosError
  >({
    queryKey: [
      "addOns",
      addOnsToIdArray(form.getValues("addOns")),
      "checkinDate",
      form.getValues("checkinDate"),
      "code",
      form.getValues("code"),
      "purchasingPack",
      form.getValues("purchasingPack"),
      "usingPack",
      form.getValues("usingPack"),
      "payViaCredits",
      form.getValues("payViaCredits"),
      "usingPurchasedPack",
      form.getValues("usingPurchasedPack"),
      "parkingLocationType",
      reservation?.parkingLocationType,
      "parkingLocationId",
      location?.id
    ],
    queryFn: () => {
      if (!location?.id || state?.searchQuery.checkinDate === undefined) {
        return {}
      }
      const pricingRequest: PricingRequest = {
        code: form.getValues("code") || null,
        addOns: addOnsToIdArray(form.getValues("addOns")),
        checkinDate: form.getValues("checkinDate"),
        purchasingPack: form.getValues("purchasingPack") || null,
        usingPack: form.getValues("usingPack") || null,
        usingPurchasedPack: form.getValues("usingPurchasedPack"),
        parkingLocationType: reservation?.parkingLocationType ?? "",
        parkingLocationId: location?.id ?? "",
        payViaCredits: form.getValues("payViaCredits") || false
      }
      return GetReservationPricing(location.id, pricingRequest, accessToken)
    }
  })

  const createReservationMutation = useMutation({
    mutationFn: (data: ReservationRequest) =>
      CreateReservation(data, accessToken),
    onSuccess: ({ reservation, isError, error }) => {
      if (isError) {
        console.error(error)
      } else if (reservation?.checkoutUrl) {
        window.location.replace(reservation?.checkoutUrl)
      } else {
        console.error("An error has occured")
      }
    },
    onError: (error) => {
      console.error(error)
    }
  })

  const {
    data: getUserParkingPacksResponse,
    isPending: getParkingPacksPending,
    isError: getParkingPacksQueryError
  } = useQuery<GetUserParkingPacksResponse | undefined, AxiosError>({
    queryKey:
      isLoggedInParker && accessToken ?
        parkingLocationType === "LOT" ?
          [GetUserLotParkingPacksKey(location?.id ?? "")]
        : [GetUserSpotParkingPacksKey(location?.id ?? "")]
      : [],
    queryFn: () => {
      if (isLoggedInParker && accessToken) {
        return parkingLocationType === "LOT" ?
            GetUserLotParkingPacks(accessToken, location?.id ?? "")
          : GetUserSpotParkingPacks(accessToken, location?.id ?? "")
      } else {
        return {}
      }
    }
  })


  const submitHandler = useMemo(
    () =>
      form.handleSubmit((data) => {
        if (!parkingLocationType || !location?.id || !reservationLock?.lockId) {
          console.error("An error has occured")
          return
        }

        createReservationMutation.mutate({
          ...data,
          parkingLocationType,
          parkingLocationId: location.id,
          lockId: reservationLock.lockId,
          code: data.code ?? "",
          addOns: addOnsToIdArray(data.addOns)
        })
      }, console.error),
    [form.handleSubmit, parkingLocationType, location, reservationLock]
  )

  const contextValue = useMemo(
    (): ReservationFormContext => ({
      form,
      control: form.control,
      isLoggedInParker,
      addOns: location?.paidAmenities,
      facilityParkingPacks: reservation?.facility.parkingPacks,
      parker,
      userParkingPacks: getUserParkingPacksResponse?.packs,
      reservation,
      pricing: getPricingResponse?.pricing,
      priceIsLoading: getPricingPending,
      payViaCredits: false,
      submitHandler,
      submitIsPending: createReservationMutation.isPending,
      reservationLock:
        reservationLock ?
          {
            id: reservationLock.id,
            expires: lockExpiryInSeconds,
            redirectHandler: handleRedirectToSearch,
            error
          }
        : undefined,
      isError:
        !routeIsValid ||
        !!(
          isLoggedInParker &&
          (getParkerResponse?.isError ||
            getParkerResponseQueryError ||
            getParkingPacksQueryError ||
            getUserParkingPacksResponse?.isError)
        ),
      isLoading:
        isLoggedInParker && (getParkerPending || getParkingPacksPending)
    }),
    [
      form,
      form.control,
      isLoggedInParker,
      getParkerResponse,
      reservation,
      location,
      getPricingResponse,
      getPricingPending,
      reservationLock,
      lockExpiryInSeconds,
      handleRedirectToSearch,
      error,
      routeIsValid,
      getParkerPending,
      getParkerResponseQueryError,
      getParkingPacksQueryError,
      getParkingPacksPending,
      getUserParkingPacksResponse,
      createReservationMutation,
      submitHandler
    ]
  )

  return (
    <ReservationFormContext.Provider value={contextValue}>
      {children}
    </ReservationFormContext.Provider>
  )
}

export const useReservationForm = () => useContext(ReservationFormContext)
