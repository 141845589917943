import { z } from "zod"

export const VehicleType = [
  "SUV",
  "MPV",
  "Truck",
  "VAN",
  "Mini Van",
  "Sedan",
  "Hatchback",
  "Camper",
  "Station Wagon"
] as const

export const VehicleTypeSchema = z.enum(VehicleType)
