import {
  api,
  AuthHeader,
  StandardApiResponse,
  StandardErrorHandler,
  StandardGetHandler
} from "../const"
import { Spot } from "../types/internal/Spot"

export const GetFacilitySpotsPath = (id?: string) =>
  `/v1/facilities/${id}/spots`
export const GetFacilitySpotsQuery = (id?: string) => `facility-spots-${id}`

export interface GetFacilitySpotsResponse extends StandardApiResponse {
  spots?: Spot[]
}

export const GetFacilitySpots = async (
  accessToken: string,
  id?: string
): Promise<GetFacilitySpotsResponse> =>
  api
    .get(GetFacilitySpotsPath(id), AuthHeader(accessToken))
    .then(StandardGetHandler("spots"))
    .catch(StandardErrorHandler)
