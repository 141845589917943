export interface LatLong {
  latitude: string
  longitude: string
  isError?: boolean
  error?: string
}

export const getLatLongFromAddress = (
  geocoder: google.maps.Geocoder,
  address: Address
) => {
  return geocoder
    .geocode({
      address: `${address.address1} ${address.address2} ${address.city} ${address.state} ${address.zipCode}`
    })
    .then((result) => {
      if (result.results.length == 0) {
        return {
          isError: true,
          error: "Could not map facility address to a geolocation",
          latitude: 0,
          longitude: 0
        }
      } else {
        return {
          latitude: result.results[0].geometry.location.lat(),
          longitude: result.results[0].geometry.location.lng()
        }
      }
    })
    .catch((error) => ({
      isError: true,
      error: `Could not map facility address to a geolocation: ${error.message}`,
      latitude: 0,
      longitude: 0
    }))
}
