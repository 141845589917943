import { Badge } from "@/components/ui/badge"
import { Label } from "@/components/ui/label"
import { Separator } from "@/components/ui/separator"
import { cn, getFormattedDateTime } from "@/lib/utils"
import { useMemo } from "react"
import { LotReservationsAreaChart } from "./LotReservationsAreaChart"
import { useQuery } from "@tanstack/react-query"
import {
  GetLotReservations,
  GetLotReservationsQueryKey,
  GetLotReservationsResponse
} from "@/api/lots/GetLotReservations"
import { useAuth } from "@/context/AuthContext"
import { ZoneBadges, ZoneItem } from "./const"
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle
} from "@/components/ui/card"

interface ParkingLotSectionProps {
  zone: UnassignedZone
}

const getParkingLotBadges = (zone: UnassignedZone): string[] => {
  const badges = ["Unassigned", zone.status]
  zone.isApproved ? badges.push("Approved") : badges.push("Not Approved")
  if (zone.hasSpaceForBiggerVehicles) badges.push("Large Vehicles")
  return badges
}

export const ParkingLotSection = ({ zone }: ParkingLotSectionProps) => {
  const { accessToken } = useAuth()
  const { data } = useQuery<GetLotReservationsResponse>({
    queryKey: [GetLotReservationsQueryKey(zone.id)],
    queryFn: () => GetLotReservations(accessToken, zone.id)
  })

  return (
    <Card>
      <CardHeader>
        <div className="flex gap-3 content-center">
          <CardTitle className="text-lg font-normal">{zone.name}</CardTitle>
          <ZoneBadges badges={getParkingLotBadges(zone)} />
        </div>
        <CardDescription>{zone.description}</CardDescription>
      </CardHeader>
      <CardContent>
        <div className="grid grid-cols-[1fr_2fr] gap-6 items-center">
          <div className="grid grid-cols-2 gap-6 content-start">
            <ZoneItem
              label="Max Vehicle Height"
              content={`${zone.maxHeightSupported} ft`}
            />
            <ZoneItem label="Floor" content={zone.floor} />
            <ZoneItem
              label="Space For Bigger Vehicles"
              content={zone.hasSpaceForBiggerVehicles}
            />
            <ZoneItem label="Spots" content={zone.spots} />
          </div>
          {
            <div className="grid gap-1 text-center">
              <Label className="font-bold">Today's Occupancy</Label>
              <LotReservationsAreaChart
                parkingSpotCount={zone.spots}
                reservations={data?.reservations}
              />
            </div>
          }
        </div>
      </CardContent>
    </Card>
  )
}
