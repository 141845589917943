import { TypographyP } from "@/components/ui/typography/p"
import { TypographyH3 } from "@/components/ui/typography/h3"
import { TypographyH2 } from "@/components/ui/typography/h2"

const AboutPage = () => {
  return (
    <div className="m-auto px-4 max-w-screen-md mb-20">
      <TypographyH2 className="pt-10">About Us</TypographyH2>
      <TypographyP>
        At Spotsurfer, we tackle the pressing issue of inefficient parking utilization at
        ski resorts and mountain communities. Our mission is to alleviate the
        frustration of day skiers and summer enthusiasts who struggle to find
        convenient parking, leading to wasted time and increased stress. By
        optimizing parking management, we aim to enhance the overall experience
        for both visitors and property owners.
      </TypographyP>
      <TypographyH3 className="pt-10">Our Hero: The Outdoor Adventure Enthusiasts</TypographyH3>
      <TypographyP>
        Our primary focus is on day skiers and summer outdoor enthusiasts seeking hassle-free
        parking solutions. We understand the external challenges they face,
        such as the difficulty of locating convenient parking spots at busy resorts.
        This often results in internal stress and anxiety about whether they will find a
        place to park upon arrival. Philosophically, we believe that parking should be
        easy and straightforward, allowing our users to spend more time enjoying
        their activities.
      </TypographyP>
      <TypographyH3 className="pt-10">Our Role: The Trusted Advisor</TypographyH3>
      <TypographyP>
        Spotsurfer positions itself as a trusted guide in this journey. We empathize
        with the frustrations of our users, knowing how stressful it can be to drive
        around searching for a parking spot when all they want is to hit the slopes or
        trails. Our innovative platform provides real-time parking availability and
        easy reservation options, making us the reliable solution for stress-free
        parking.
      </TypographyP>
      <TypographyH3 className="pt-10">Our Plan: Your Path to Success</TypographyH3>
        <ol className="list-decimal list-inside mt-6">
          We offer a simple three-step plan to enhance your parking experience:
          <li className="pl-5 m-2">Go to Spotsurfer: Get started by downloading the Spotsurfer app or
          visiting our website.</li>
          <li className="pl-5 m-2">Reserve Your Spot: Easily find and reserve a parking spot in advance or
          on the go.</li>
          <li className="pl-5 m-2">Enjoy Your Day: Arrive knowing your parking is secured, allowing you
          to focus on your activities.</li>
        </ol>
      <TypographyH3 className="pt-10">The Benefits of Choosing Spotsurfer</TypographyH3>
        <ul className="list-disc list-inside mt-6">
          By choosing Spotsurfer, you gain:
          <li className="pl-5 m-2">Convenience: Effortlessly find and reserve parking spots without
            hassle.
          </li>
          <li className="pl-5 m-2">Peace of Mind: Reduce stress and anxiety about parking, knowing your
            spot is secured.
          </li>
          <li className="pl-5 m-2">More Time for Fun: Spend less time searching for parking and more
            time enjoying your adventures.
          </li>
        </ul>
      <TypographyH3 className="pt-10">The Stakes of Inaction</TypographyH3>
      <TypographyP>
        Without Spotsurfer, users face continued frustration and wasted time driving
        around in search of parking. This leads to ongoing stress and missed
        opportunities for fun, as valuable time is lost in the parking hunt.
      </TypographyP>
      <TypographyH3 className="pt-10">For Our Partners: Clients and Property Owners</TypographyH3>
      <TypographyP>
        We serve ski resorts, lodges, towns, and businesses that seek to
        maximize their parking space utilization. Our parking partners include major corporate
        hosts like Vail Resorts, as well as local homeowners and renting out parking spaces through our platform.
      </TypographyP>
      <TypographyH3 className="pt-10">The Challenges We Address</TypographyH3>
      <TypographyP>
        Our clients grapple with external issues, such as inconsistent parking space
        occupancy and the challenges faced by day skiers in finding available spots.
        Internally, they worry about revenue loss and operational efficiency, while
        philosophically, they believe that parking should be convenient, efficient, and
        environmentally friendly.
      </TypographyP>
      <TypographyH3 className="pt-10">Our Commitment to Clients</TypographyH3>
      <TypographyP>
        Spotsurfer acts as a trusted advisor to our partners, understanding the
        complexities of managing parking spaces. Our dynamic parking
        management platform leverages real-time data analytics and mobile
        technology to streamline operations and enhance user satisfaction
      </TypographyP>
      <TypographyH3 className="pt-10">Our Path to Success for our Parking Partners</TypographyH3>
        <ol className="list-decimal list-inside mt-6">
           We provide a straightforward three-step plan:
          <li className="pl-5 m-2">Sign Up: Join Spotsurfer and start listing your parking spaces.</li>
          <li className="pl-5 m-2">List and Manage: Easily manage availability through our user-friendly
            platform.
          </li>
          <li className="pl-5 m-2">Optimize and Profit: Utilize our real-time data analytics to maximize
            occupancy and revenue.
          </li>
        </ol>
      <TypographyH3 className="pt-10">The Success We Deliver</TypographyH3>
      <ul className="list-disc list-inside mt-6">
        By partnering with Spotsurfer, our clients can expect:
        <li className="pl-5 m-2">Increased Revenue: Achieve near 100% parking occupancy rates.
        </li>
        <li className="pl-5 m-2">Operational Efficiency: Streamlined parking management  with real-time updates.
        </li>
        <li className="pl-5 m-2">Customer Satisfaction: Happy customers who can easily   find and reserve parking spots.
        </li>
      </ul>
      <TypographyH3 className="pt-10">Join Us on This Journey</TypographyH3>
      <TypographyP>
        Spotsurfer is dedicated to transforming the parking experience for both drivers
        and property owners. By reducing traffic congestion and enhancing customer
        satisfaction, we contribute to a greener environment while maximizing
        revenue for our partners.
      </TypographyP>
      <TypographyP>
        Ready to experience hassle-free parking? Reserve your spot today with Spotsurfer and enjoy the convenience we offer!
      </TypographyP>
      <TypographyP>
        For more info reach us at:
        {' '}
        <a href="tel:(970) 389-4230" className="text-blue-600 visited:text-purple-600">(970) 389-4230</a>
        {' '}
        or
        {' '}
        <a href="mailto:info@spotsurfer.com" className="text-blue-600 visited:text-purple-600">
          info@spotsurfer.com
        </a>
        .
      </TypographyP>
    </div>
  )
}

export default AboutPage
