import { useReducer } from "react"
import { FieldValues } from "react-hook-form"

export interface IStepperState {
  totalSteps: number
  currentStep: number
  currentData: FieldValues
  data: FieldValues[]
}

export type StepperAction = "step_forward" | "step_backwards" | "step_to"

export interface IStepperAction {
  action: string
  data?: FieldValues
}

const reducer = (
  state: IStepperState,
  action: IStepperAction
): IStepperState => {
  switch (action.action) {
    case "step_forward":
      if (state.currentStep === state.totalSteps - 1) return state

      return {
        ...state,
        currentStep: state.currentStep + 1,
        currentData: state.data[state.currentStep + 1],
        data: state.data.with(state.currentStep, action.data ?? {})
      }
    case "step_backwards":
      if (state.currentStep === 0) return state

      return {
        ...state,
        currentStep: state.currentStep - 1,
        currentData: state.data[state.currentStep - 1],
        data: state.data.with(state.currentStep, action.data ?? {})
      }
    case "step_to":
      if (
        action?.data?.to === undefined ||
        action.data.to < 0 ||
        action.data.to > state.totalSteps - 1
      )
        return state

      return {
        ...state,
        currentStep: action?.data?.to,
        currentData: state.data[action?.data?.to],
        data: state.data.with(state.currentStep, action.data.data)
      }
    default:
      return state
  }
}

export const useStepperReducer = (length: number) =>
  useReducer(reducer, {
    totalSteps: length,
    currentStep: 0,
    currentData: {},
    data: Array(length).map(() => ({}))
  })
