import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useAuth } from "@/context/AuthContext"
import { getParker } from "@/api/get"
import Cookies from 'js-cookie'

export const Impersonate = () => {
  const { user, setTokens, setUserInfo } = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    const impersonateAccessToken = Cookies.get('impersonate_accessToken')
    const impersonateRefreshToken = Cookies.get('impersonate_refreshToken')

    if ((impersonateAccessToken && impersonateRefreshToken) && user?.isStaff) {

      setTokens({
        access: impersonateAccessToken,
        refresh: impersonateRefreshToken
      })

      const parker = async () => {
        const impersonatedUser = await getParker(impersonateAccessToken)

        setUserInfo({
          role: "PARKER",
          isImpersonating: true,
          parker: impersonatedUser,
          firstName: impersonatedUser?.user?.firstName,
          lastName: impersonatedUser?.user?.lastName,
          parkerId: impersonatedUser?.id,
          userId: impersonatedUser?.user?.userId,
          admin: {
            ...user,
          }
        })
      }

      parker()

      // Clean up cookies
      Cookies.remove('impersonate_accessToken')
      Cookies.remove('impersonate_refreshToken')

      navigate('/')
    } else {
      // If no impersonation tokens found, redirect to home
      navigate('/')
    }

    // console.log('HIIIII', impersonateAccessToken, impersonateRefreshToken)
  }, [setTokens, navigate])
  return null
}
