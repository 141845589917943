import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from "@/components/ui/dialog"
import { Form } from "@/components/ui/form"
import { zodResolver } from "@hookform/resolvers/zod"
import { DialogProps } from "@radix-ui/react-dialog"
import { useForm } from "react-hook-form"
import { z } from "zod"
import { Button } from "@/components/ui/button"
import { FormFileUpload, FormTextInput } from "../../FormInputs"

const UploadImageSchema = z.object({
  name: z.string().min(1),
  file: z.any()
})

type UploadImage = z.infer<typeof UploadImageSchema>

export interface UploadDialogProps {
  open?: boolean
  onOpenChange?: DialogProps["onOpenChange"]
  onSubmit: (image: UploadImage) => void
}

export const UploadDialog = ({
  onOpenChange,
  onSubmit,
  open
}: UploadDialogProps) => {
  const form = useForm<UploadImage>({
    resolver: zodResolver(UploadImageSchema),
    values: {
      name: "",
      file: undefined
    }
  })

  return (
    <Dialog
      open={open}
      onOpenChange={() => {
        form.reset()
        onOpenChange?.(false)
      }}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Upload an image</DialogTitle>
        </DialogHeader>
        <div className="grid gap-6 my-6">
          <Form {...form}>
            <FormFileUpload control={form.control} name="file" />
            <FormTextInput control={form.control} name="name" label="Name *" />
          </Form>
        </div>
        <DialogFooter>
          <Button
            variant="outline"
            onClick={() => {
              form.reset()
              onOpenChange?.(false)
            }}>
            Cancel
          </Button>
          <Button
            className="bg-accent-orange"
            type="submit"
            onClick={form.handleSubmit((result) => {
              onSubmit(result)
              form.reset()
            })}>
            Upload
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
