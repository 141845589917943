import {
  FormCheckbox,
  FormNumberInput,
  FormTextArea,
  FormTextInput
} from "@/components/custom/Forms/FormInputs"
import { StandardStepperButtons } from "@/components/custom/Stepper/const"
import { StepperComponentProps } from "@/components/custom/Stepper/Stepper"
import { Form } from "@/components/ui/form"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { z } from "zod"

const UnassignedZoneDetailsSchema = z.object({
  name: z.string(),
  description: z.string().optional(),
  spots: z.coerce.number().int().min(1),
  maxHeightSupported: z.coerce.number().min(0).optional(),
  hasSpaceForBiggerVehicles: z.coerce.boolean(),
  floor: z.coerce.number().int().min(1).optional(),
  parkerInstructions: z.string().optional()
})

export type IUnassignedZoneDetails = z.infer<typeof UnassignedZoneDetailsSchema>

export const CreateLotDetailsStepper = ({
  onSubmit,
  onBack,
  saveData,
  backText,
  submitText
}: StepperComponentProps<IUnassignedZoneDetails>) => {
  const form = useForm<IUnassignedZoneDetails>({
    resolver: zodResolver(UnassignedZoneDetailsSchema),
    values: saveData
  })

  return (
    <Form {...form}>
      <form
        className="grid gap-6"
        onSubmit={form.handleSubmit(onSubmit, console.error)}>
        <FormTextInput control={form.control} name="name" label="Name" />
        <FormTextArea
          control={form.control}
          name="description"
          label="Description"
        />
        <div className="grid grid-cols-3 gap-6">
          <FormNumberInput
            control={form.control}
            name="spots"
            label="Number of Spots"
          />
          <FormNumberInput
            control={form.control}
            name="maxHeightSupported"
            label="Max Vehicle Height (ft)"
          />
          <FormCheckbox
            control={form.control}
            name="hasSpaceForBiggerVehicles"
            label="Space for Bigger Vehicles?"
          />
          <FormNumberInput control={form.control} name="floor" label="Floor" />
        </div>
        <FormTextArea
          control={form.control}
          name="parkerInstructions"
          label="Parking Instructions"
        />
        <StandardStepperButtons
          backText={backText}
          onBack={() => onBack(form.watch())}
          submitText={submitText}
          isPending={form.formState.isSubmitting}
        />
      </form>
    </Form>
  )
}
