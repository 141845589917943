import {
  FormTextArea,
  FormTextInput
} from "@/components/custom/Forms/FormInputs"
import { StandardStepperButtons } from "@/components/custom/Stepper/const"
import { StepperComponentProps } from "@/components/custom/Stepper/Stepper"
import { Form } from "@/components/ui/form"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import {
  CreateFacilityDetailsForm,
  CreateFacilityDetailsSchema
} from "../const"

export const FacilityDetails = ({
  onSubmit,
  onBack,
  saveData,
  backText,
  submitText
}: StepperComponentProps<CreateFacilityDetailsForm>) => {
  const form = useForm<CreateFacilityDetailsForm>({
    resolver: zodResolver(CreateFacilityDetailsSchema),
    values: saveData
  })

  return (
    <Form {...form}>
      <form
        className="grid gap-6"
        onSubmit={form.handleSubmit(onSubmit, console.error)}>
        <FormTextInput control={form.control} name="name" label="Name *" />
        <FormTextArea
          control={form.control}
          name="description"
          label="Description"
        />
        <div className="grid gap-3 my-3">
          <FormTextInput
            control={form.control}
            name="address.address1"
            label="Address 1 *"
          />
          <FormTextInput
            control={form.control}
            name="address.address2"
            label="Address 2"
          />
          <div className="grid grid-cols-3 gap-6">
            <FormTextInput
              control={form.control}
              name="address.city"
              label="City *"
            />
            <FormTextInput
              control={form.control}
              name="address.state"
              label="State *"
              placeholder="CO"
            />
            <FormTextInput
              control={form.control}
              name="address.zipCode"
              label="Zip Code *"
            />
          </div>
        </div>
        <StandardStepperButtons
          backText={backText}
          onBack={() => onBack(form.watch())}
          submitText={submitText}
          isInvalid={!form.formState.isValid}
          isPending={form.formState.isSubmitting}
        />
      </form>
    </Form>
  )
}
