import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription
} from "@/components/ui/dialog"
import { v4 as uuidv4 } from "uuid"

const SupportDialog = ({ dialogTrigger }) => {
  return (
    <Dialog key={uuidv4()}>
      {dialogTrigger}
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Contact Spotsurfer Support</DialogTitle>
          <DialogDescription>
            {
              "Whether you have questions, need assistance, or wish to explore partnership opportunities, we’re here for you. Drop us a message, and let’s make parking hassle-free together."
            }
          </DialogDescription>
        </DialogHeader>
        <div className="grid gap-4">
          <div className="grid gap-0.5">
            <p className="text-sm font-medium leading-none">Email:</p>
            <p className="text-sm text-muted-foreground">info@spotsurfer.com</p>
          </div>
          <div className="grid gap-0.5">
            <p className="text-sm font-medium leading-none">Phone:</p>
            <p className="text-sm text-muted-foreground">{"(970) 389-4230"}</p>
          </div>
          <div className="grid gap-0.5">
            <p className="text-sm font-medium leading-none">Address:</p>
            <address className="grid gap-0.5 not-italic text-sm text-muted-foreground">
              <span>Spotsurfer</span>
              <span>216 Main Street #230</span>
              <span> Edwards CO, 81632</span>
            </address>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default SupportDialog
