import TypographyH1 from "@/components/ui/typography/h1"

const OwnerSignupConfirmationPage = () => {
  return (
    <div className="flex flex-col p-36">
      <TypographyH1 className="mb-1">
        Thank you for expressing interest in joining our platform.
      </TypographyH1>
      <p className="text-2xl">An account manager will reach out to you shortly to discuss next steps.</p>
    </div>
  )
}

export default OwnerSignupConfirmationPage