import { useFieldArray, useForm } from "react-hook-form"
import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import { useCallback, useMemo, useState } from "react"
import { TypographyH3 } from "@/components/ui/typography/h3"
import { Camera, Plus, X } from "lucide-react"
import { Button } from "@/components/ui/button"
import { UploadDialog } from "./UploadDialog"
import { DeleteDialog } from "./DeleteDialog"
import { StepperComponentProps } from "@/components/custom/Stepper/Stepper"
import { StandardStepperButtons } from "@/components/custom/Stepper/const"

const ImageSchema = z.object({
  name: z.string(),
  file: z.any(),
  isThumbnail: z.boolean()
})
export type IImage = z.infer<typeof ImageSchema>
export interface ImageWithId extends IImage {
  id: string
}

export const MediaUploadSchema = z.object({
  images: z.array(ImageSchema).optional()
})
export type IMediaUploadSchema = z.infer<typeof MediaUploadSchema>

export const CreateMediaStepper = ({
  backText,
  onBack,
  onSubmit,
  saveData,
  submitText
}: StepperComponentProps<IMediaUploadSchema>) => {
  const [indexToDelete, setIndexToDelete] = useState<number>(-1)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false)
  const {
    control,
    watch,
    formState: { isSubmitting }
  } = useForm<IMediaUploadSchema>({
    resolver: zodResolver(MediaUploadSchema),
    values: saveData ?? {
      images: []
    }
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: "images"
  })

  const onConfirmedDelete = useCallback(() => {
    remove(indexToDelete)
  }, [indexToDelete, remove])

  return (
    <div className="grid gap-6">
      <div className="grid gap-6">
        <TypographyH3 className="text-2xl font-normal">
          Upload Images & Media
        </TypographyH3>
        {!fields || fields.length === 0 ?
          <EmptyMediaUploadScreen onClick={() => setUploadDialogOpen(true)} />
        : <MediaUploadList
            images={fields}
            onPlusClicked={() => setUploadDialogOpen(true)}
            onDeleteClicked={(index) => {
              setIndexToDelete(index)
              setDeleteDialogOpen(true)
            }}
          />
        }
      </div>
      <UploadDialog
        open={uploadDialogOpen}
        onOpenChange={setUploadDialogOpen}
        onSubmit={({ name, file }) => {
          append({ name, file, isThumbnail: false })
          setUploadDialogOpen(false)
        }}
      />
      <DeleteDialog
        image={fields[indexToDelete]}
        open={deleteDialogOpen}
        onSubmit={() => {
          onConfirmedDelete()
          setDeleteDialogOpen(false)
        }}
        onOpenChange={setDeleteDialogOpen}
      />
      <StandardStepperButtons
        backText={backText}
        onBack={() => onBack(watch())}
        onSubmit={() => onSubmit(watch())}
        submitText={submitText}
        isPending={isSubmitting}
      />
    </div>
  )
}

interface EmptyScreenProps {
  onClick?: () => void
}

const EmptyMediaUploadScreen = ({ onClick }: EmptyScreenProps) => {
  return (
    <div className="border px-6 py-12 gap-6 flex items-center flex-col">
      <p className="text-xl">Would you like to upload any images?</p>
      <div
        className="flex items-center flex-col cursor-pointer"
        onClick={onClick}>
        <Camera size={64} className="text-muted-foreground" />
        <p className="text-lg text-muted-foreground">No images yet</p>
      </div>
      <p className="text-md">You can always upload images later on.</p>
    </div>
  )
}

interface MediaUploadListProps {
  onPlusClicked?: () => void
  onDeleteClicked?: (index: number) => void
  images: ImageWithId[]
}

const MediaUploadList = ({
  onPlusClicked,
  onDeleteClicked,
  images
}: MediaUploadListProps) => {
  return (
    <div className="border px-6 py-12 flex gap-6 items-start justify-center flex-wrap">
      {images.map((image, index) => (
        <MediaItem
          key={image.id}
          image={image}
          onDeleteClicked={() => onDeleteClicked?.(index)}
        />
      ))}
      <button
        onClick={onPlusClicked}
        className="flex flex-col items-center justify-center rounded-md h-48 w-48 bg-gray-300">
        <Plus size={60} />
        <p>Add another</p>
      </button>
    </div>
  )
}

interface MediaItemProps {
  image: ImageWithId
  onDeleteClicked?: () => void
}

const MediaItem = ({ image, onDeleteClicked }: MediaItemProps) => {
  const imagePreviewSrc = useMemo(() => {
    if (image.file) {
      try {
        return URL.createObjectURL(image.file)
      } catch (e) {
        return ""
      }
    } else {
      return ""
    }
  }, [image.file])
  return (
    <div className="grid gap-1 items-center text-center justify-center">
      <div className="h-48 flex items-center">
        <div className="relative">
          <Button
            onClick={() => onDeleteClicked?.()}
            className="h-4 w-4 rounded-full absolute -top-2 -right-2"
            size="icon"
            variant="destructive">
            <X className="h-4 w-4" />
          </Button>
          <img
            src={imagePreviewSrc}
            alt="Uploaded Image"
            className="max-h-48 max-w-48 rounded-md"
          />
        </div>
      </div>
      <p className="font-semibold text-accent-orange">{image.name}</p>
    </div>
  )
}
