import {
  Card,
  CardTitle,
  CardContent,
  CardHeader,
  CardDescription
} from "@/components/ui/card"
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger
} from "@/components/ui/accordion"
import TypographyH1 from "@/components/ui/typography/h1"
import { useQuery } from "@tanstack/react-query"
import { getAuthenticatedSupport } from "@/api/get"
import { useAuth } from "@/context/AuthContext"
import Loading from "@/components/custom/Loading"
import { AddressBlock } from "@/components/custom/Address/AddressBlock"
import Fallback from "@/components/custom/Fallback"
import Icon from "@/components/custom/Icon"

const SupportPage = () => {
  const { accessToken } = useAuth()
  const { data, isPending, error } = useQuery({
    queryKey: ["authSupport", accessToken],
    queryFn: () => getAuthenticatedSupport(accessToken)
  })

  if (isPending) return <Loading />

  if (error) return <Fallback />

  const { id, email, phone, address, description, faq } = data

  const faqs = faq.map((faq, index) => {
    return (
      <AccordionItem key={`item-${index}`} value={`item-${index}`}>
        <AccordionTrigger className="text-left">
          {faq.question}
        </AccordionTrigger>
        <AccordionContent>{faq.answer}</AccordionContent>
      </AccordionItem>
    )
  })

  return (
    <main className="space-y-6 p-10 pb-16">
      <TypographyH1 className="flex items-center">
        <Icon
          name="circle-help"
          size={24}
          className="lg:size-8 stroke-1 mr-3"
        />
        Support
      </TypographyH1>
      <Card>
        <CardHeader>
          <CardTitle type="h2">Contact Spotsurfer Support</CardTitle>
          <CardDescription>{description}</CardDescription>
        </CardHeader>
        <CardContent>
          <div className="grid gap-4">
            <div className="grid gap-0.5">
              <p className="text-sm font-medium leading-none">Email:</p>
              <p className="text-sm">{email}</p>
            </div>
            <div className="grid gap-0.5">
              <p className="text-sm font-medium leading-none">Phone:</p>
              <p className="text-sm">{phone}</p>
            </div>
            <div className="grid gap-0.5">
              <p className="text-sm font-medium leading-none">Address:</p>
              <AddressBlock {...address} />
            </div>
          </div>
        </CardContent>
      </Card>
      <Card>
        <CardHeader>
          <CardTitle type="h2">Frequently Asked Questions</CardTitle>
        </CardHeader>
        <CardContent>
          <Accordion type="single" collapsible>
            {faqs}
          </Accordion>
        </CardContent>
      </Card>
    </main>
  )
}

export default SupportPage
