import { Button } from "@/components/ui/button"
import { cn } from "@/lib/utils"
import { Loader2 } from "lucide-react"

export interface StandardStepperButtonsProps {
  isPending?: boolean
  isInvalid?: boolean
  backText: string
  submitText: string
  onBack: (data: any) => void
  onSubmit?: () => void
}

export const StandardStepperButtons = ({
  backText,
  onBack,
  onSubmit,
  submitText,
  isInvalid,
  isPending
}: StandardStepperButtonsProps) => {
  return (
    <div className="flex justify-between">
      <Button className="min-w-32" variant="outline" onClick={onBack}>
        {backText}
      </Button>
      <Button
        className={cn(
          "min-w-32",
          submitText === "Submit" && "bg-accent-red hover:bg-accent-red-dark"
        )}
        variant={"default"}
        type="submit"
        onClick={() => onSubmit?.()}
        disabled={isPending || isInvalid}>
        {isPending && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
        {submitText}
      </Button>
    </div>
  )
}
