import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle
} from "@/components/ui/card"
import { Progress } from "@/components/ui/progress"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"
import { useAuth } from "@/context/AuthContext"
import { useQuery, useQueryClient, UseQueryOptions } from "@tanstack/react-query";
import Loading from "@/components/custom/Loading"
import Fallback from "@/components/custom/Fallback"
import { GetOwnerReservations, GetOwnerReservationsResponse } from "@/api/reservation/GetOwnerReservations";
import { ReservationTable } from "./components/ReservationTable"
import { ReservationSlider } from "./components/ReservationSlider"
import { useCallback, useRef, useState } from "react"
import { useProfits } from "./hooks/useProfits"
import { Sheet, SheetContent } from "@/components/ui/sheet"
import {
  reservationNewerThanMonth,
  reservationNewerThanWeek,
  reservationNewerThanYear
} from "./const"
import { TypographyH2 } from "@/components/ui/typography/h2"
import type { Reservation } from "@/api/types/internal/Reservation"

const tabs = [
  {
    name: "Today",
    id: "today",
    description: "Reservations today from all your parking spots and zones."
  },
  {
    name: "This Week",
    id: "week",
    description: "Reservations this week from all your parking spots and zones."
  },
  {
    name: "This Month",
    id: "month",
    description: "Reservations this month from all your parking spots and zones."
  },
  {
    name: "This Year",
    id: "year",
    description: "Reservations this year from all your parking spots and zones."
  }
]

const ownerFinancialInfo = (weeklyProfit: number, weekChange: number, monthlyProfit: number, monthChange: number) => (
  <>
    <div className="grid gap-1">
      <TypographyH2 className="text-2xl font-semibold">
        Your Reservations
      </TypographyH2>
      <p className="text-muted-foreground text-balance leading-relaxed">
        Introducing Our Dynamic Reservations Dashboard for Seamless Management
        and Insightful Analysis.
      </p>
    </div>
    <div className="grid gap-4 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-2 xl:grid-cols-4">
      <Card>
        <CardHeader className="pb-2">
          <CardDescription>This Week</CardDescription>
          <CardTitle className="text-4xl">${weeklyProfit}</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="text-xs text-muted-foreground">
            {weekChange > 0 ? "+" : "-"}
            {Math.abs(weekChange * 50).toFixed()}% from last week
          </div>
        </CardContent>
        <CardFooter>
          <Progress
            value={Math.min(weekChange * 50, 100)}
            aria-label="25% increase"
          />
        </CardFooter>
      </Card>
      <Card>
        <CardHeader className="pb-2">
          <CardDescription>This Month</CardDescription>
          <CardTitle className="text-4xl">${monthlyProfit}</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="text-xs text-muted-foreground">
            {monthChange > 0 ? "+" : "-"}
            {Math.abs(monthChange * 50).toFixed()}% from last month
          </div>
        </CardContent>
        <CardFooter>
          <Progress
            value={Math.min(monthChange * 50, 100)}
            aria-label="12% increase"
          />
        </CardFooter>
      </Card>
    </div>
  </>
)

export const ReservationsListPage = () => {
  const [activeTab, setActiveTab] = useState("today");
  const { accessToken, user } = useAuth();
  const queryClient = useQueryClient();
  const activeTabRef = useRef(activeTab);

  const queryOptions: UseQueryOptions<GetOwnerReservationsResponse, Error, GetOwnerReservationsResponse, string[]> = {
    queryKey: ["ownerReservations", accessToken, activeTab],
    queryFn: () => GetOwnerReservations(accessToken, activeTabRef.current),
    placeholderData: (previousData) => previousData,
  };

  const { data, isPending, error } = useQuery(queryOptions);

  const updateReservationTimePeriod = useCallback((newTimePeriod: string) => {
    setActiveTab(newTimePeriod);
    activeTabRef.current = newTimePeriod;
    queryClient.invalidateQueries({ queryKey: ["ownerReservations", accessToken, newTimePeriod] });
  }, [accessToken, queryClient]);

  const [detailsIsOpen, setDetailsIsOpen] = useState(false)
  const [detailsToShow, setDetailsToShow] = useState<Reservation | undefined>(
    undefined
  )

  const { monthChange, monthlyProfit, weekChange, weeklyProfit } = useProfits(data?.reservations)

  if (isPending) return <Loading className="" />

  if (error) return <Fallback />

  return (
    <main className="grid gap-6 p-4 m-6">
      {user.role !== "TEAM_MEMBER" && (ownerFinancialInfo(weeklyProfit, weekChange, monthlyProfit, monthChange))}
      <div className="grid auto-rows-max items-start gap-4">
        <div className="flex items-center"></div>
        <Sheet open={detailsIsOpen} onOpenChange={setDetailsIsOpen}>
          <Card>
            <CardHeader className="px-7">
              <CardTitle>Reservations</CardTitle>
              <CardDescription>
                Recent Reservations from all of your spots.
              </CardDescription>
            </CardHeader>
            <CardContent>
              <Tabs value={activeTab} onValueChange={updateReservationTimePeriod}>
                <TabsList>
                  {tabs.map((tab) => (
                    <TabsTrigger
                      key={tab.id}
                      value={tab.id}
                    >
                      {tab.name}
                    </TabsTrigger>
                  ))}
                </TabsList>
                {tabs.map((tab, i) => (
                  <TabsContent value={tab.id} key={i}>
                    <ReservationTable
                      onReservationClicked={(reservation) => {
                        if (detailsToShow?.id === reservation.id) {
                          setDetailsIsOpen(false);
                          setDetailsToShow(undefined);
                        } else {
                          setDetailsIsOpen(true);
                          setDetailsToShow(reservation);
                        }
                      }}
                      reservations={
                        data?.reservations?.filter(
                          (tab.id === "week" && reservationNewerThanWeek) ||
                          (tab.id === "month" && reservationNewerThanMonth) ||
                          reservationNewerThanYear
                        ) ?? []
                      }
                    />
                  </TabsContent>
                ))}
              </Tabs>
            </CardContent>
          </Card>
          <SheetContent>
            {detailsToShow && <ReservationSlider reservation={detailsToShow} />}
          </SheetContent>
        </Sheet>
      </div>
    </main>
  )
}
