import { createContext, useContext } from "react"
import { useQuery } from "@tanstack/react-query"
import { getFacilities } from "@/api/get"

const initialValue = {
  facilities: []
}

const SearchContext = createContext(initialValue)

export const SearchProvider = ({ children }) => {
  const { data, isLoading } = useQuery({
    queryKey: ["facilities"],
    queryFn: getFacilities
  })

  const contextValue = {
    facilities: data,
    facilitiesLoading: !data || isLoading
  }

  return (
    <SearchContext.Provider value={contextValue}>
      {children}
    </SearchContext.Provider>
  )
}

export const useSearch = () => {
  return useContext(SearchContext)
}
