export const NavAboutLinks = ({ breakpoint }) => [
	{
		name: "FAQs",
		path: "/FAQs",
		icon: "info",
		canDisplay: breakpoint === "sm" || breakpoint === "md",
		openNewTab: false,
	},
	{
		name: "News",
		path: "/news-happenings",
		icon: "newspaper",
		canDisplay: breakpoint === "sm" || breakpoint === "md",
		openNewTab: true
	},
	{
		name: "Investors",
		path: "/investors",
		icon: "users",
		canDisplay: false,
		openNewTab: false
	},
	{
		name: "Contact Us",
		path: "/contact-us",
		icon: "phone",
		canDisplay: breakpoint === "sm" || breakpoint === "md",
		openNewTab: false
	},
	{
		name: "Company",
		path: "/about",
		icon: "info",
		canDisplay: breakpoint === "sm" || breakpoint === "md",
		openNewTab: false
	}
]