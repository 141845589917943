import { cn } from "@/lib/utils"
import { DayOfWeekSelection } from "./CustomInputs"
import {
  DateInput,
  PriceInput,
  SpotInput,
  TimeInput
} from "../../SpotAvailabilityForm/card/inputs"
import { AvailabilityCardProps } from "./AvailabilityCard"
import { OverrideCardHeader } from "./OverrideCardHeader"
import { DaysOfWeek } from "@/constants/AvailabilitySchema"
import { FormSelectInput } from "../FormInputs"

export interface OverrideCardProps extends AvailabilityCardProps {}

export const OverrideCard = ({
  className,
  control,
  watch,
  setValue,
  controlIndex,
  onDelete
}: OverrideCardProps) => {
  const SectionClassNames = "grid gap-1 grid-cols-1"
  const baseWrapperClasses =
    "grid grid-rows-[auto_auto] gap-6 rounded-md border my-3 p-3 duration-500"
  const baseControlPath = "override"

  const isActive = watch(`${baseControlPath}.${controlIndex}.active`)
  const isActiveAdditional =
    isActive && !watch(`override.${controlIndex}.isUnavailable`)
  const isActiveSubtractive =
    isActive && watch(`override.${controlIndex}.isUnavailable`)

  return (
    <div
      className={cn(
        className,
        baseWrapperClasses,
        isActive && "outline outline-1",
        isActiveAdditional &&
          "border-green-700 outline outline-green-700 outline-1",
        isActiveSubtractive &&
          "border-red-700 outline outline-red-700 outline-1"
      )}>
      <OverrideCardHeader
        className=""
        watch={watch}
        setValue={setValue}
        activeName={`${baseControlPath}.${controlIndex}.active`}
        titleName={`${baseControlPath}.${controlIndex}.description`}
        overrideTypeName={`${baseControlPath}.${controlIndex}.isUnavailable`}
        onDelete={onDelete}
      />
      <div
        className={cn(
          className,
          "py-3 grid gap-x-3 gap-y-6 grid-rows-2 grid-cols-[1.5fr_1fr_1fr_1fr_1fr]"
        )}>
        <FormSelectInput
          control={control}
          label="Day Of Week"
          options={DaysOfWeek.map((dow) => ({
            value: dow,
            label: dow
          }))}
          name={`override.${controlIndex}.option`}
        />
        <TimeInput
          label="Opens at"
          className={SectionClassNames}
          watch={watch}
          setValue={setValue}
          name={`${baseControlPath}.${controlIndex}.openingTime`}
        />
        <TimeInput
          label="Closes at"
          className={SectionClassNames}
          watch={watch}
          setValue={setValue}
          name={`${baseControlPath}.${controlIndex}.closingTime`}
        />
        <PriceInput
          className={SectionClassNames}
          control={control}
          name={`${baseControlPath}.${controlIndex}.price`}
        />
        <SpotInput
          className={SectionClassNames}
          control={control}
          name={`${baseControlPath}.${controlIndex}.spots`}
        />
        <DateInput
          className={cn(SectionClassNames, "col-span-2")}
          label={"Override Starts"}
          watch={watch}
          name={`${baseControlPath}.${controlIndex}.leaseStartDate`}
          setValue={setValue}
        />
        <DateInput
          className={cn(SectionClassNames, "col-span-2 col-start-3")}
          label={"Override ends"}
          watch={watch}
          name={`${baseControlPath}.${controlIndex}.leaseEndDate`}
          setValue={setValue}
        />
      </div>
    </div>
  )
}
