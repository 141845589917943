import { Spot } from "@/api/types/internal/Spot"
import { UpdateFormTabsWrapperTab } from "@/components/custom/Forms/UpdateFormGenerics/UpdateFormTabWrapper"
import { EditSpotDetails } from "./components/EditSpotDetails"
import { EditSpotAmenities } from "./components/EditSpotAmenities"
import { MediaSchema } from "@/constants/MediaSchema"
import { z } from "zod"
import { EditSpotAvailability } from "./components/EditSpotAvailability"
import { EditSpotMedia } from "./components/EditSpotMedia"

export const EditSpotTabs: UpdateFormTabsWrapperTab<Spot, Spot>[] = [
  {
    tabName: "Details",
    tabId: "details",
    title: "Details",
    subtitle:
      "General information about the spot to help customers know they're in the right place.",
    Render: EditSpotDetails
  },
  {
    tabName: "Amenities",
    tabId: "amenities",
    title: "Amenities",
    subtitle:
      "Add or remove amenities for this spot. Amenities are shown when a user requests a reservation at your spot.",
    Render: EditSpotAmenities
  },
  {
    tabName: "Availability",
    tabId: "availability",
    title: "Availability",
    subtitle:
      "Set pricing and availability based on days of the week, and set temporary overrides for sales, events, or any other need.",
    Render: EditSpotAvailability
  },
  {
    tabName: "Media",
    tabId: "media",
    title: "Media",
    Render: EditSpotMedia
  }
]

export const EditSpotMediaSchema = z.object({
  images: MediaSchema
})
export type EditSpotMediaForm = z.infer<typeof EditSpotMediaSchema>
