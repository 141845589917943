import { GetAmenities, GetAmenitiesResponse } from "@/api/GetAmenities"
import { useAuth } from "@/context/AuthContext"
import { zodResolver } from "@hookform/resolvers/zod"
import { useQuery } from "@tanstack/react-query"
import _ from "lodash"
import { useCallback, useEffect, useMemo, useState } from "react"
import { FieldValues, useForm } from "react-hook-form"
import { z } from "zod"

export interface IUseAmenitiesForm {
  defaultValues?: Amenity[]
  saveData?: IAmenitiesSchema
}

export const AmenitiesSchema = z.record(z.string(), z.coerce.boolean())

export type IAmenitiesSchema = z.infer<typeof AmenitiesSchema>

export const useAmenitiesForm = ({
  defaultValues,
  saveData
}: IUseAmenitiesForm) => {
  const defaultAsObject = useMemo<Record<number, boolean> | undefined>(() => {
    return saveData ? saveData : (
        defaultValues?.reduce((prev, current) => {
          return {
            ...prev,
            [current.id.toString()]: true
          }
        }, {})
      )
  }, [defaultValues])

  const form = useForm<IAmenitiesSchema>({
    resolver: zodResolver(AmenitiesSchema),
    values: defaultAsObject ?? {}
  })

  const { accessToken } = useAuth()
  const { data, isPending, isError } = useQuery<GetAmenitiesResponse>({
    queryKey: [`amenities`],
    queryFn: () => GetAmenities(accessToken)
  })

  return {
    amenities: data?.amenities,
    isPending,
    isError,
    form
  }
}

export const amenitiesToIntArray = (
  amenities: Record<string, boolean>
): number[] => {
  const arr: number[] = []
  _.forIn(amenities, (value, key) => {
    value && arr.push(parseInt(key))
  })
  return arr
}
