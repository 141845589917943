import {
  api,
  AuthHeader,
  keysToSnakeCase,
  StandardApiResponse,
  StandardErrorHandler,
  StandardPushHandler
} from "../const"

export const PutLotPath = (id: string) => `/v1/lots/${id}/`

export const UpdateLot = async (
  accessToken: string,
  id: string,
  data: UnassignedZone<string>
): Promise<StandardApiResponse> =>
  api
    .put(PutLotPath(id), keysToSnakeCase(data), AuthHeader(accessToken))
    .then(StandardPushHandler())
    .catch(StandardErrorHandler)
