import { z } from "zod"
import { FacilityDetails } from "./components/Details"
import { AmenitiesStepper } from "@/components/custom/Forms/CreateFormGenerics/CreateAmenitiesStepper"
import { FacilityDetailsSchema } from "../const"
import { AddressSchema } from "@/constants/AddressSchema"
import { CreateMediaStepper } from "@/components/custom/Forms/CreateFormGenerics/MediaUpload/CreateMediaStepper"

export const FORM_TITLE = "Create a facility"
export const FORM_SUBTITLE =
  "Facilities are the main containers for owners. They can contain both unassigned lots and specfiic parking spots."

export const CreateFacilitySteps = [
  {
    label: "Details",
    render: FacilityDetails
  },
  {
    label: "Amenities",
    render: AmenitiesStepper
  },
  {
    label: "Media",
    render: CreateMediaStepper
  }
]

export const CreateFacilityDetailsSchema = FacilityDetailsSchema.extend({
  address: AddressSchema
})
export type CreateFacilityDetailsForm = z.infer<
  typeof CreateFacilityDetailsSchema
>
