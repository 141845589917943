import { Stepper } from "@/components/custom/Stepper/Stepper"
import { useCallback, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useAuth } from "@/context/AuthContext"
import { CreateLot } from "@/api/lots/CreateLot"
import { LotForm } from "@/constants/LotSchema"
import { AvailabilityForm } from "@/constants/AvailabilitySchema"
import { UpdateLotAvailability } from "@/api/lots/UpdateLotAvailability"
import { availabilityToBackendState } from "@/components/custom/Forms/AvailabilityGenerics/const"
import {
  CreateFormHeader,
  CreateFormHeaderChildren
} from "@/components/custom/Forms/CreateFormGenerics/CreateFormHeader"
import { CreateLotSteps, FORM_SUBTITLE, FORM_TITLE } from "./const"

export const CreateLotPage = () => {
  const [apiError, setApiError] = useState("")
  const navigate = useNavigate()
  const { accessToken } = useAuth()
  const { facilityId } = useParams()

  const handleSubmit = useCallback(
    async (
      data: LotForm,
      availability: AvailabilityForm,
      setApiError: CreateFormHeaderChildren["onApiError"]
    ) => {
      if (facilityId) {
        const result = await CreateLot(accessToken, facilityId, data)
        if (result.isError || !result.lot?.id) {
          setApiError?.(result.error ?? "Lot was not created.")
          return
        }
        const AvailResult = await UpdateLotAvailability(
          accessToken,
          result.lot.id,
          availabilityToBackendState(availability)
        )
        if (AvailResult.isError) {
          setApiError?.(AvailResult.error ?? "Availability was not set.")
        } else {
          navigate(-1)
        }
      }
    },
    [accessToken, facilityId]
  )

  return (
    <CreateFormHeader
      title={FORM_TITLE}
      subtitle={FORM_SUBTITLE}
      render={({ onApiError }) => (
        <Stepper
          onFinalSubmit={(dataArray) => {
            handleSubmit(
              {
                ...dataArray[0], // Details
                ...dataArray[1] // Location
              } as LotForm,
              dataArray[2] as AvailabilityForm, // Availability
              onApiError
            )
          }}
          steps={CreateLotSteps}
        />
      )}
    />
  )
}
