import { TypographyP } from "@/components/ui/typography/p"
import { TypographyH3 } from "@/components/ui/typography/h3"
import { TypographyH2 } from "@/components/ui/typography/h2"

const TermsAndConditions = () => {
  return (
    <div className="m-auto px-4 max-w-screen-md pb-12">
      <TypographyH2 className="pt-12 text-pretty">
        Terms of Service &amp; Privacy Policy
      </TypographyH2>
      <TypographyP>
        Welcome to using Spotsurfer Online platform and App! By using the
        Spotsurfer website or mobile application (collectively the “Sites” or
        “App”), you agree to be bound by the following terms and conditions (the
        “Terms of Use” or “Agreement”). As used in this Agreement, Spotsurfer
        will be referred to as “Spotsurfer,” “we,” “us,” or “our,” and you will
        be referred to as “you”. Spotsurfer and its associated websites and
        mobile applications are owned by Hohenco Technologies LLC, a Colorado
        limited liability company, and all rights of Spotsurfer are reserved on
        behalf of Hohenco Technologies LLC. This Agreement incorporates by
        reference the following policies and documents that may also be found on
        the Sites:
      </TypographyP>
      <TypographyH3 className="mt-6 text-pretty">
        General Terms of Use Acceptance
      </TypographyH3>
      <TypographyP>
        Your use of the information, materials, text, images and other content
        on the Sites is subject to the Terms of Use below, which we may revise
        from time to time without notice. Please read these Terms of Use
        carefully.
      </TypographyP>
      <TypographyP>
        BY ACCESSING THE SITES, YOU ACKNOWLEDGE AND AGREE THAT YOU HAVE READ,
        UNDERSTAND AND AGREE TO BE BOUND BY THESE GENERAL TERMS OF USE. PLEASE
        REVIEW THE FOLLOWING SECTIONS OF THESE TERMS OF USE CAREFULLY: (A)
        DISPUTE RESOLUTION, (B) CLASS ACTION WAIVER, (C) LIABILITY LIMIT, (D)
        INDEMNITY AND (E) RELEASE.
      </TypographyP>
      <TypographyP>
        IF YOU DO NOT AGREE TO THESE TERMS OF USE, IMMEDIATELY STOP USING THE
        SITES AND DO NOT USE ANY SPOTSURFER SERVICE, PARTICIPATE IN ANY PROGRAM
        OR PURCHASE ANY PRODUCT OR OTHER GOOD OR SERVICE OFFERED THROUGH THE
        SITES.
      </TypographyP>
      <TypographyP>
        If you fail to comply with these Terms of Use, your permission to use
        the Sites automatically terminates and we may disable your use of the
        Sites. The provisions of these Terms of Use apply equally to, and are
        for the benefit of, Spotsurfer, its subsidiaries, affiliates, Licensors,
        and its third-party content providers and licensors, and each shall have
        the right to assert and enforce such provisions directly.
      </TypographyP>
      <TypographyP>
        By visiting or using our Sites and their related services, contacting us
        through the Sites or making submissions to us or the Sites, you consent
        to our collection and use of personal information as discussed in our
        Privacy Policy, below, which is hereby incorporated into this Agreement.
      </TypographyP>
      <TypographyP>
        Spotsurfer makes no representation or warranty, and hereby disclaims any
        and all liability, with regard to the sufficiency of the security
        measures used for data handling and storage. Spotsurfer will not be
        responsible for any actual or consequential damages that result from a
        security breach or technical malfunction.
      </TypographyP>
      <TypographyH3 className="mt-6 text-pretty">
        Ownership and Reservation of Rights
      </TypographyH3>
      <TypographyP>
        The text, images, data, illustrations, files, audio and video clips,
        designs, documents and other materials and content (collectively, the
        “Content”) on the Sites is our property and may be protected by
        copyright and other restrictions. Copyrights and other proprietary
        rights in the Content may also be owned by parties other than us. You
        may not copy, modify, distribute or otherwise use any of the Content,
        except for your personal and non-commercial use. Please note that we may
        withdraw any Content from the Sites at any time in our sole discretion.
      </TypographyP>
      <TypographyP>
        Unauthorized commercial publication or exploitation of text, images,
        documents, materials or any other Content is prohibited without our
        express written consent. If you wish to use any of the Content for
        commercial use, publication, or any purpose other than personal use, you
        must obtain our express written permission prior to such use. We may, in
        our sole discretion, grant permission for such use on a case-by-case
        basis and we may charge a usage fee for such use. Without limitation of
        the foregoing, you agree that you may not sell, convey, license,
        sublicense, resell or attempt any of the foregoing. By way of example
        and not limitation, you may not resell any Reservation booked through
        Spotsurfer to a third party without Spotsurfer&apos;s express written
        permission. If such resale is discovered, your Spotsurfer User Account
        may be suspended or revoked, and any existing Reservations voided
        without refund.
      </TypographyP>
      <TypographyP>
        Names, titles, trademarks, service marks, and logos (collectively, the
        “Trademarks”) displayed on the Sites are our registered and/or
        unregistered common law trademarks or those of third parties. Nothing
        contained on the Sites should be construed as granting, by implication
        or otherwise, any license or right to use any Trademark displayed on the
        Sites without our express written permission or that of the appropriate
        third party that owns the Trademark. Except as permitted by these Terms
        of Use, any unauthorized use of the Trademarks is prohibited.
      </TypographyP>
      <TypographyP>
        By downloading, printing, or otherwise using any of the Content, you
        agree that you will (i) restrict your use of such Content to personal
        and non-commercial use, (ii) comply with all of these Terms of Use, and
        (iii) not violate our rights or the rights of any other person or
        entity. We do not warrant that use of any of the Content will not
        infringe the rights of third parties.
      </TypographyP>
      <TypographyH3 className="mt-6 text-pretty">
        Spotsurfer Services
      </TypographyH3>
      <TypographyP>
        Unless expressly stated otherwise herein, the services provided by
        Spotsurfer (the “Services”) are limited to informing users of the
        location and potential availability of certain parking spots (“Parking
        Spots”) and facilitating Reservations (as defined below) by displaying
        Parking Spots, processing Reservations and collecting fees on behalf of
        Licensors (as defined below). Any fees and/or consideration paid to
        Spotsurfer is paid to Spotsurfer in connection with these Services
        unless otherwise indicated. When Spotsurfer receives your payment, your
        obligation to pay the Licensor for the Reservation is fully satisfied;
        provided, that you will be responsible for any other charges to the
        extent you incur separate or additional costs outside the scope of
        Spotsurfer&apos;s services. We do not guarantee the availability of a
        specific Parking Spot. The Services and Sites are not intended for use
        by persons under the age of 13. If you are under 13, you may not use
        either the Services or the Sites. You agree to comply with all
        applicable laws when accessing or using our Services, and you may only
        access or use the Services for lawful purposes.
      </TypographyP>
      <TypographyP>
        Spotsurfer lists the Parking Spots on the Sites and, as such,
        facilitates the reservation of Parking Spots (“Reservations”) between
        users of the Sites (“Licensees” or “you”), and the independent owners,
        lessors, managers or operators of the Parking Spots (the “Licensors”).
        Spotsurfer does not operate Parking Spots or determine parking
        availability provided by Licensors. While Spotsurfer makes every effort
        to provide accurate information regarding the Parking Spots listed on
        the Sites, at times such information may become inaccurate and
        Spotsurfer shall not be liable for any such inaccuracy. In all cases,
        your utilization of a Parking Spot will be governed by the
        Licensor&apos;s customer agreements or policies in place from time to
        time, including, without limitation, all terms and conditions related to
        the use of any Parking Spot, any payment terms and conditions and all
        rules and restrictions imposed by the Licensor on the relevant parking
        facility and/or Parking Spot, as each may be entered into or effected in
        accordance with the Licensor&apos;s standard operating procedures (the
        “Licensor Terms and Conditions”).
      </TypographyP>
      <TypographyP>
        By making a Reservation, you agree in all cases to abide by the Licensor
        Terms and Conditions. In connection with our provision of the Services,
        Licensors may represent and warrant to us that they have full authority
        to list the Parking Spots for Reservations. We are not responsible for
        confirming whether any such representation or warranty is true and
        correct, nor are we responsible if any such representation or warranty
        becomes inaccurate or incomplete prior to your use of a Parking Spot. In
        addition, Spotsurfer will never be responsible for any agreement or
        other arrangements between you and any Licensor, regardless of whether
        such agreement or other arrangements are related to your booking and use
        of a Parking Spot.
      </TypographyP>
      <TypographyH3 className="mt-6 text-pretty">User Accounts</TypographyH3>
      <TypographyP>
        In order to access certain areas of the Sites, you will create a user
        account and login (a “User Account”). You agree that all information
        which you provide through the Sites in connection with creating your
        User Account or otherwise is current, accurate and complete. You are
        solely responsible for all activity that occurs with respect to your
        User Account. You are solely responsible for maintaining the
        confidentiality of your User Account and keeping the username and
        password to your User Account secure. You agree to notify us immediately
        of any security breach or unauthorized use of your User Account. We will
        not be liable for any costs, losses, claims or damages that you or any
        third party incur which are directly or indirectly caused by any
        unauthorized use of your User Account. You agree to never use another
        party&apos;s User Account without such party&apos;s express written
        authorization.
      </TypographyP>
      <TypographyH3 className="mt-6 text-pretty">Pricing</TypographyH3>
      <TypographyP>
        You are responsible for all charges, fees, duties, taxes, and
        assessments arising out of any Reservation, the use of a Parking Spot,
        the Services or the use of the Sites. You agree to pay to Spotsurfer all
        fees for Spotsurfer&apos;s provision of the Services, in accordance with
        the pricing and payment terms presented to you for such Services. Your
        payment of such fees constitute payment to the Licensor solely for
        purposes of making a Reservation. Where applicable, you will be billed
        using the billing method you select through your User Account or through
        an alternative payment method offered by Spotsurfer. Except as provided
        in these Terms of Use or when required by law, all fees paid by you are
        non-refundable and Spotsurfer may change the fees for any Services,
        including any Services billed pursuant to a Subscription (as defined
        below), at any time.
      </TypographyP>
      <TypographyH3 className="mt-6 text-pretty">
        Cancellations and Refunds
      </TypographyH3>
      <TypographyP>
        Reservations purchased through the Sites are cancellable (a) up to 24
        hours prior to the start of the Reservation (“Cancellation Policy”) or
        (b) any specific cancellation policies or restrictions stated in the
        Parking Spot details on the Sites or on your parking pass. In the event
        of any conflict between the Cancellation Policy and any statements in
        the Parking Spot details on the Sites or your parking pass, the specific
        policies and restrictions in the Parking Spot details or your parking
        pass shall control. Within 24 hours of the start of the reservation, a
        reservation may be transferred to a different facility on a case-by-case
        basis. Following the start of the Reservation, all Reservations are
        non-refundable, regardless of your use or non-use of the Reservation and
        regardless of any circumstance surrounding the use or non-use of a
        Reservation (i.e., traffic or weather delays, cancellation of a related
        event). Spotsurfer at its sole discretion reserves the right to refund
        or provide vouchers for appropriate monies paid in connection therewith
        on a case-by-case basis. If, for whatever reason, an error occurs in
        regard to any fee for Services, a Reservation, and/or a Parking Spot,
        Spotsurfer may, in its sole discretion, void a Reservation related to
        that error and refund appropriate monies paid in connection therewith.
      </TypographyP>
      <TypographyP>
        Spotsurfer may from time-to-time partner with third parties (“Partner”),
        and Reservations purchased on Partner websites are not refundable for
        any reason. Spotsurfer may utilize payment Partners to process payments,
        retain payment information and perform similar services and Spotsurfer
        shall not responsible for any action, inaction, omission or error made
        by any Partner and by election to use Spotsurfer you will have been
        deemed to waive any claim against Spotsurfer for any action, inaction,
        omission or error of any Partner. Due to the procedures and precautions
        employed by our third-party gateway provider, any customer refunds may
        take up to three or four business days to be credited to their original
        method of payment.
      </TypographyP>
      <TypographyH3 className="mt-6 text-pretty">Taxes</TypographyH3>
      <TypographyP>
        Licensors are responsible for remitting applicable taxes to the
        applicable taxing jurisdictions. Spotsurfer is not a vendor collecting
        and remitting taxes to the applicable taxing authorities nor a co-vendor
        associated with the vendor with whom Spotsurfer customers are submitting
        the reservation for the Parking Spots. If you are required by law to
        withhold any taxes from your payments to Spotsurfer, you must provide
        Spotsurfer with an official tax receipt or other appropriate
        documentation to support such payments. Unless otherwise stated, any
        overage fees incurred by you will be billed in arrears on a monthly
        basis. Overage fees which remain unpaid for 30 days after being billed
        are considered overdue. Failure to pay overage fees when due may result
        in the applicable service being limited, suspended, or terminated
        (subject to applicable legal requirements), which may result in a loss
        of your data associated with that service.
      </TypographyP>
      <TypographyH3 className="mt-6 text-pretty">User Submissions</TypographyH3>
      <TypographyP>
        You agree that any information you provide through the Sites will be
        truthful, accurate and complete. You represent and warrant that you own
        any information provided through the Sites or otherwise have appropriate
        authority to provide any such information through the Sites. All
        information that you submit to us through the Sites shall be deemed and
        remain our property and we may use, for any purpose, any ideas,
        concepts, know-how or techniques contained in such information. We have
        no obligations regarding such information or otherwise specifically
        agreed to in a separate writing or required by law. You acknowledge and
        agree that you will not provide any information or material to us or the
        Sites that is defamatory, threatening, obscene, harassing, or otherwise
        unlawful, or that incorporates the proprietary material of another
        person or entity.
      </TypographyP>
      <TypographyH3 className="mt-6 text-pretty">
        Links to Other Websites and Services
      </TypographyH3>
      <TypographyP>
        To the extent the Site contains links to outside services and resources,
        any such links are provided for your convenience only. We do not control
        those outside services or resource providers, and we are not responsible
        for their content or practices, including their privacy practices. We do
        not endorse the operators of those outside services or resource
        providers, nor do we endorse or make any representations with respect to
        the contents of their websites, or any products or services offered on
        those websites. Any concerns regarding such services or resources should
        be directed to the particular outside service or resource provider.
        Spotsurfer does not guarantee or warrant the accuracy or completeness of
        the information or content included on the websites of these outside
        services and resources.
      </TypographyP>
      <TypographyH3 className="mt-6 text-pretty">
        Uptime and Disclaimer
      </TypographyH3>
      <TypographyP>
        From time to time, the Sites may be inaccessible or inoperable for any
        reason, including equipment malfunctions, updates to the Sites,
        maintenance and repairs and causes beyond our control or those that are
        not foreseeable. We make no guarantees with respect to the availability
        or uptime of the Sites, the Content or access to Reservations. We may
        conduct maintenance on any of the foregoing at any time with or without
        notice to you.
      </TypographyP>
      <TypographyP>
        The information and descriptions contained on the sites are not intended
        to be complete descriptions of all terms, exclusions and conditions
        applicable to every product or service Spotsurfer offers but are
        provided for general informational purposes. Spotsurfer makes no
        representations or warranties that the content on the sites is suitable
        for your needs, is complete, timely or reliable. All text, images and
        other content on the sites are provided “as is” without warranty of any
        kind, either express or implied, including, but not limited to, the
        implied warranties of merchantability, fitness for a particular use,
        and/or non-infringement. Spotsurfer does not warrant that the sites will
        be uninterrupted or free of errors, viruses, or other harmful code, or
        that any errors or defects will be corrected.
      </TypographyP>
      <TypographyP>
        Spotsurfer assumes no responsibility, and shall not be liable for (i)
        any errors or omissions in the content on the sites, (ii) damage to or
        viruses that may infect your computer equipment or other property on
        account of your use of the sites or your downloading of any text, images
        or other content from the sites, or (iii) any damage arising in
        connection with any failure of performance, interruption, defect, delay
        in operation or transmission, or line or system failure.
      </TypographyP>
      <TypographyH3 className="mt-6 text-pretty">Liability Limit</TypographyH3>
      <TypographyP>
        Spotsurfer, its affiliates, members, managers, officers, employees,
        agents and representatives will have no liability for any damages,
        including, without limitation, direct, indirect, consequential,
        compensatory, special, punitive, or incidental damages (even if
        Spotsurfer has been advised of the possibility of such damages) arising
        out of or relating to the use of, reliance on or inability to use the
        sites or the content or services provided on or received from the sites.
        Please note that your use of the sites is at your sole risk.
      </TypographyP>
      <TypographyP>
        TO THE MAXIMUM EXTENT PERMITTED BY LAW, IN NO EVENT WILL
        SPOTSURFER&apos;S TOTAL LIABILITY ARISING OUT OF OR IN CONNECTION WITH
        THESE TERMS OF USE OR FROM THE USE OF OR INABILITY TO USE THE SERVICES
        EXCEED THE LESSER OF (a) $100, AND (b) THE TOTAL DOLLAR AMOUNT OF ALL
        PARKING BOOKED VIA SPOTSURFER IN THE 12 MONTHS PRIOR TO THE ACTION
        ALLEGEDLY GIVING RISE TO LIABILITY.
      </TypographyP>
      <TypographyP>
        If you are a California resident, you waive California civil code §1542,
        which says: “a general release does not extend to claims which the
        creditor does not know or suspect to exist in his favor at the time of
        executing the release, which if known by him must have materially
        affected his settlement with the debtor.”
      </TypographyP>
      <TypographyH3 className="mt-6 text-pretty">
        Disclaimer of Liability for Use of Services and Parking Spots:
      </TypographyH3>
      <TypographyP>
        Spotsurfer shall not be under any liability whatsoever for loss or
        damage to any vehicle or other property or any damage or injury to any
        person howsoever arising or for the prevention of ingress to or egress
        from the Parking Spots caused by the use or attempted use by any person
        of the Services and Parking Spots. Spotsurfer shall have no obligation
        to provide Services at the Parking Spots including security and
        utilities. In the event of an emergency, you shall be responsible to
        reach out to any health care, police, or other emergency services.
      </TypographyP>
      <TypographyH3 className="mt-6 text-pretty">Indemnity</TypographyH3>
      <TypographyP>
        You agree to indemnify and hold us, our managers, members, officers,
        employees, agents and representatives harmless from any loss, liability,
        claim or demand, including reasonable attorneys&apos; fees and costs,
        due to or arising out of (i) your use or misuse of the Sites; (ii) your
        breach of these Terms of Use; (iii) your breach of any Licensor Terms
        and Conditions; or (iv) your violation of any third party right.
      </TypographyP>
      <TypographyH3 className="mt-6 text-pretty">Release</TypographyH3>
      <TypographyP>
        You release us and our manager(s), officers, member(s), employees,
        agents, representatives and licensors from any and all claims, demands,
        losses and damages of every kind and nature, whether known or unknown,
        arising out of or in any way relating to the Sites, your use of the
        Sites, other users&apos; use of the Sites and any dispute or defense you
        have or claim to have against us or one or more users of the Sites.
      </TypographyP>
      <TypographyH3 className="mt-6 text-pretty">
        Copyright Complaints
      </TypographyH3>
      <TypographyP>
        If you believe that your work has been copied in a way that constitutes
        copyright infringement, please provide our agent with the following
        information: (a) a physical or electronic signature of the person
        authorized to act on behalf of the owner of an exclusive copyright that
        is allegedly infringed; (b) a description of the copyrighted work that
        you claim has been infringed; (c) a description of where the material
        that you claim is infringing is located on the Sites; (d) your address,
        telephone number, and email address and all other information reasonably
        sufficient to permit Spotsurfer to contact you; (e) a statement by you
        that you have a good faith belief that the disputed use is not
        authorized by the copyright owner, its agent, or the law; and (f) a
        statement by you, made under penalty of perjury, that the above
        information in your notice is accurate and that you are the copyright
        owner or authorized to act on behalf of the owner of an exclusive right
        that is allegedly infringed.
      </TypographyP>
      <TypographyH3 className="mt-6 text-pretty">Governing Law</TypographyH3>
      <TypographyP>
        These Terms of Use shall be governed by and interpreted in accordance
        with the laws of the State of Colorado, U.S.A., without regard to its
        provisions governing conflicts of law. If we allege that you have
        infringed or threatened to infringe our intellectual property rights,
        then, in addition to any other rights and remedies we may have, we may
        seek any preliminary or permanent injunctive relief from any court of
        competent jurisdiction. You submit to the exclusive jurisdiction of the
        state and federal courts located in the State of Colorado and waive any
        jurisdictional, venue, or inconvenient forum objections to such courts.
      </TypographyP>
      <TypographyH3 className="mt-6 text-pretty">
        Dispute Resolution
      </TypographyH3>
      <TypographyP>
        Spotsurfer is committed to customer satisfaction, so if you have an
        issue or dispute, we will try to resolve your concerns. But if we are
        unsuccessful, you may pursue claims as explained in this section. You
        agree to give us an opportunity to resolve any dispute, claim or
        controversy between you and Spotsurfer and/or its subsidiaries,
        affiliates, and/or any of their respective members, officers, directors,
        and employees (all such entities collectively referred to herein as the
        “Spotsurfer Entities”) arising out of, relating in any way to, or in
        connection with the Terms of Use, the Sites or your use of the Sites, or
        your Personal Information, including those that arose before you
        accepted these Terms of Use, regardless of whether prior versions
        thereof required arbitration (“Dispute(s)”) informally by contacting us
        as described below. If we are not able to resolve your Dispute within
        sixty (60) days after the day we receive your written notice, you may
        seek relief through arbitration or in small claims court. You agree that
        regardless of any statute or law to the contrary, any Dispute must be
        filed within one year after such claim arose or it will be forever
        banned.
      </TypographyP>
      <TypographyH3 className="mt-6 text-pretty">
        Jury Trial Waiver
      </TypographyH3>
      <TypographyP>
        You and Spotsurfer each acknowledge and agree that, if for any reason a
        Dispute proceeds in court rather than in arbitration, we each waive any
        right to a jury trial.
      </TypographyP>
      <TypographyH3 className="mt-6 text-pretty">
        Class Action Waiver
      </TypographyH3>
      <TypographyP>
        You and Spotsurfer each agree that we shall bring any Dispute against
        the other in our respective individual capacities and not as a plaintiff
        or class member in any purported class, representative proceeding or as
        an association. In addition, we each agree that Disputes shall be
        arbitrated only on an individual basis and not in a class, consolidated,
        or representative action and that the arbitrator may award relief
        (including injunctive relief) only on an individual basis. The
        arbitrator does not have the power to vary these provisions.
      </TypographyP>
      <TypographyH3 className="mt-6 text-pretty">Termination</TypographyH3>
      <TypographyP>
        We reserve the right to suspend or terminate your use of the Services
        and/or your User Account in the event you violate any provision of these
        Terms of Use or if you use Spotsurfer services in a fraudulent manner.
        Furthermore, we may terminate or suspend access to all or part of the
        Sites at any time, without notice or for any reason.
      </TypographyP>
      <TypographyH3 className="mt-6 text-pretty">
        Electronic Signatures and Contracts
      </TypographyH3>
      <TypographyP>
        Your use of the Sites includes the ability to enter into agreements
        and/or to make transactions electronically. You acknowledge that your
        electronic submissions constitute your agreement and intent to be bound
        by and to pay for such agreements and transactions. Your agreement and
        intent to be bound by electronic submissions applies to all records
        relating to all transactions you enter into on the sites, including
        notices of cancellation, policies, contracts, and applications.
      </TypographyP>
      <TypographyH3 className="mt-6 text-pretty">
        Disputed Charges and Reversals
      </TypographyH3>
      <TypographyP>
        If you believe that an unauthorized transaction has taken place under
        your User Account, you agree to notify us immediately. You are
        responsible for and agree to reimburse us for all reversals, claims,
        fees and other liability or expense incurred by us that were caused by
        payments that you authorized or accepted.
      </TypographyP>
      <TypographyH3 className="mt-6 text-pretty">SMS Messaging</TypographyH3>
      <TypographyP>
        Spotsurfer may use your mobile phone number or similar contact
        information to send text message(s) via automatic dialing technology.
        Spotsurfer uses such phone numbers and/or contact information to provide
        Services and to facilitate your use of Reservations. For example,
        Spotsurfer may send you a text message containing a parking pass or
        other information allowing you to access a Parking Spot or parking
        facility. Additionally, Spotsurfer may send you a text message
        containing a link to download a copy of its software application, based
        upon your request via a web form.
      </TypographyP>
      <TypographyP>Reservation of Rights</TypographyP>
      <TypographyP>
        We specifically reserve all rights that we do not expressly grant in
        these Terms of Use. Nothing on the Sites or in these Terms of Use grants
        any right or license to use any property of any third party.
      </TypographyP>
      <TypographyP>Severance and Waiver</TypographyP>
      <TypographyP>
        You acknowledge and agree that in the event any provision of these Terms
        of Use is held by a court to be invalid, unlawful, or unenforceable, the
        validity, legality, and/or enforceability of the remaining provisions
        contained in these Terms of Use will not in any way be affected or
        impaired. Our failure to exercise or enforce any right or provision
        under these Terms of Use will not constitute a waiver of such right or
        provision by us.
      </TypographyP>
      <TypographyP>Entire Agreement</TypographyP>
      <TypographyP>
        You agree that these Terms of Use constitute the entire agreement
        between you and us with respect to your use of the Sites. You agree that
        these Terms of Use supersede any other agreements between you and us
        with respect to your use of the Sites. No agency, partnership, joint
        venture, employee-employer or franchisee-franchisor relationship is
        intended or created by these Terms of Use, your use of the Sites, or any
        actions by any Licensor with respect to the Sites or associated software
        applications.
      </TypographyP>
      <TypographyP>Modification</TypographyP>
      <TypographyP>
        Spotsurfer may at any time modify these Terms of Use and Privacy Policy.
        If we make material changes we will notify you of such changes and
        provide you with the opportunity to review the modification. Your
        continued use of the Sites will be conditioned upon the terms and
        conditions in force at the time of your use. If you do not agree with
        any of the changes or modifications your only recourse is to cease use
        of the Sites and Services.
      </TypographyP>
      <TypographyP>Parking Users</TypographyP>
      <TypographyP>
        If you are utilizing any parking spaces located using the Spotsurfer app
        or provided by us (together, the “Spotsurfer parking”) you must comply
        with all posted signs and regulations proposed for such parking areas
        including, without limitation, all federal, state and local laws and all
        oral, written or electronic notices from us and other parties owning or
        managing such parking areas. This shall prohibit consumption of alcohol
        or any intoxicants, including marijuana on any Spotsurfer parking.
        Consuming of any intoxicants by you, any parties (including drivers) in
        violation of similar laws are absolutely prohibited. Vehicles parked at
        Spotsurfer property with a current and valid reservation for a spot must
        bear a license plate identifier that was given to Spotsurfer. All
        vehicles parked in Spotsurfer parking shall be maintained in safe
        condition with all required or recommended equipment such as snow tires
        and all-wheel drive or similar snow driving precautions. We make no
        representation as to the condition of any Spotsurfer parking
      </TypographyP>
      <TypographyP>
        We can install temporary signage in spaces to announce authorized
        parking and these signs may be taken down at the end of service period.
        Spotsurfer has the right to have unauthorized vehicles towed, in
        compliance with CO HB22-1314 (Towing Carrier Nonconsensual Tows).
        Spotsurfer is not responsible for any loss of service in connection with
        enforcing local and state parking ordinances. For any questions about
        these terms &amp; conditions, please contact Spotsurfer support and any
        queries will receive responses within 48 hours. Spotsurfer may capture
        and use a vehicle&apos;s license plate # to share with a licensed towing
        company. If you are using a different vehicle than the one registered in
        the Spotsurfer App, YOU are responsible for listing the replacement
        vehicle, whether this replacement is temporary or permanent.
      </TypographyP>
      <TypographyP>Privacy Policy</TypographyP>
      <TypographyP>
        Please read the following policy to understand how your personal
        information will be treated as you make use of our services. This policy
        may change from time to time so please check back periodically. This
        Privacy Policy (“Privacy Policy”) is incorporated into our Terms of Use
        and applies to Hohenco Technologies LLC, a Colorado limited liability
        company, <a href="http://www.spotsurfer.com/">www.spotsurfer.com</a>,
        the Spotsurfer mobile application, and any third party sites that allow
        you to make parking reservations via their platforms. As used in this
        Privacy Policy Spotsurfer will be referred to as “Spotsurfer,” “we,”
        “us,” or “our,” and you will be referred to as “you”. Please read the
        Privacy Policy as it describes how we collect, why we collect, how we
        protect and how we use any personal information you provide to us via
        the Sites. By using the Sites and Services you are agreeing to and
        accepting the Privacy Policy and Terms of Use.
      </TypographyP>
      <TypographyP>Collection of Information</TypographyP>
      <TypographyP>
        We may collect personal information, including but not limited to, your
        full name, email address, phone number and parking preferences when you
        make parking reservations using we and/or when you report a problem with
        one of our sites or services (collectively, together with any other
        information Spotsurfer may collect in relation to your use of the
        Services or the Sites, the “Personal Information”). We may also ask for
        your vehicle information and/or license plate for specific parking
        transactions that validate your reservation by that information.
      </TypographyP>
      <TypographyP>
        Some Personal Information may be automatically gathered so that we can
        monitor the use of the Sites in order to make improvements and better
        serve visitors. You can help ensure that your contact information and
        preferences are accurate, complete, and up to date by logging into your
        User Account or contacting us as described below. For other personal
        information of yours that we hold, we will provide you with access for
        any legal purpose, including to request that we correct the data if it
        is inaccurate or delete the data if Spotsurfer is not required to retain
        it by law or for legitimate business purposes, in all cases, in
        accordance with applicable laws and subject to any exceptions to access
        thereunder. We may decline to process requests that are
        frivolous/vexatious, jeopardize the privacy of others, are extremely
        impractical, or for which access is not otherwise required by local law.
        Access, correction, or deletion requests can be made by contacting the
        Support Agent referenced in the below section titled “Contact
        Information.”
      </TypographyP>
      <TypographyP>Use of Person Information</TypographyP>
      <TypographyP>
        As a general rule, Spotsurfer will not disclose any of your personally
        identifiable information; except when we have your permission or under
        special circumstances, such as when we believe in good faith that the
        law requires it or under the circumstances described below. The
        following are some ways that Spotsurfer may use your data to:
      </TypographyP>
      <ol className="list-decimal list-inside">
        <li>
          Provide, personalize, and improve your experience with the Services
          and our advertising made available on or outside the Service;
        </li>
        <li>
          Ensure technical functionality of the Services, develop new products
          and services, and analyze your use of the Services, including your
          interaction with applications, advertising, products and services that
          are made available, linked to, or offered through the Service;
        </li>
        <li>
          Communicate with you for Service-related or research purposes
          including via e-mails, notifications, text messages, or other
          messages, which you agree to receive;
        </li>
        <li>
          Communicate with you, either directly or through one of our partners,
          for marketing and promotional purposes via e-mails, notifications, or
          other messages consistent with any permissions you may have
          communicated to us;
        </li>
        <li>Facilitate payment processing or prevent or detect fraud;</li>
        <li>
          Identify location information, to calculate distance to available
          Parking Spots;
        </li>
        <li>
          Enforce this Privacy Policy, the Terms of Use, and any other terms
          that you have agreed to, including to protect the rights, property, or
          safety of Spotsurfer, its users, or any other person, or the
          copyright-protected content of the Services;
        </li>
        <li>
          Fulfill the purpose for which you submitted the information and for
          other uses consistent with that purpose; and
        </li>
        <li>Use as otherwise stated in this Privacy Policy.</li>
      </ol>
      <TypographyP>
        In general, we do not share Personal Information that is personally
        identifiable to you, except where we have your express permission or
        where required by law. At times, in order for us to provide products and
        services or market to customers, we may make certain Personal
        Information available to strategic partners with whom we work. We may
        also disclose Personal Information to individuals or organizations who
        are our advisors or consultants, as well as the following advertising,
        marketing and analytics services providers
      </TypographyP>
      <TypographyP>
        We may share Personal Information with parties who provide services such
        as information processing, payment processing, extending credit,
        fulfilling customer orders, delivering products, managing and enhancing
        customer data, providing customer service, assessing interest in our
        products and services, and conducting customer research or satisfaction
        surveys. Except as otherwise set forth in this Privacy Policy, we only
        share Personal Information to provide or improve our products, services
        and advertising, nor will we share your Personal Information with third
        parties for their marketing purposes. We provide certain Personal
        Information to Licensors in order for such Licensors to, among other
        things, provide their services in connection with facilitating
        Reservations, confirm your Reservation on-site, and for the
        Licensors&apos; own business uses, including marketing to you.
        Licensors&apos; use and sharing of your Personal Information will be
        subject to each Licensor&apos;s privacy policy. Spotsurfer is not
        responsible for any Licensor&apos;s use or sharing of your information.
        We may disclose any collected information, including personally
        identifiable information, if: (1) the disclosure is necessary or useful
        to our provision of services; (2) we believe in good faith that
        disclosure is necessary to protect our rights, interests, or property;
        (3) we are acting in good faith to protect your safety or the safety of
        others, to investigate fraud, or to respond to a government request; (4)
        we believe in good faith the disclosure is required by law, such as to
        comply with a subpoena, search warrant, court order, or similar legal or
        administrative process; and (5) a third party purchases or otherwise
        acquires us or the Sites, in which case your information will likely be
        among the assets transferred.
      </TypographyP>
      <TypographyH3 className="mt-6 text-pretty">
        Tracking Technologies and Cookies
      </TypographyH3>
      <TypographyP>
        We may also collect cookies, which are small pieces of information sent
        to your browser by a website that you visit. Cookies may be used in the
        administration of the Sites in order to keep track of movement of an
        individual user within pages on our Sites and may help to detect or
        resolve problems on our Sites or assist with customer support issues. We
        may use cookies to compile and provide aggregate statistics about the
        Sites&apos; visitors, traffic patterns, and related information to third
        parties, but such information will not identify you personally. Cookies
        may help us adjust the content of the Sites and provide better service
        to visitors. We may also use third-party analytics providers on the
        Sites that use cookies and similar technologies to collect and analyze
        information about use of our services, conversion metrics, and to report
        on activities and trends and may also collect information regarding the
        use of other websites, apps and online resources. We may also utilize
        third-party analytics
      </TypographyP>
      <TypographyH3 className="mt-6 text-pretty">Data Security</TypographyH3>
      <TypographyP>
        Unfortunately, no data transmission over the internet is guaranteed to
        be 100% secure. As a result, while we strive to protect your personal
        information, as effective as our reasonable security measures may be, no
        security system is impenetrable. We cannot guarantee the security of our
        database and cannot guarantee that information supplied to us will not
        be intercepted while being transmitted over the internet.
      </TypographyP>
      <TypographyP>California Privacy Rights</TypographyP>
      <TypographyP>
        Under the California Consumer Privacy Act (“CCPA”) signed into law June
        28, 2018, and entering into effect Jan. 1, 2020, California residents
        have certain rights and privileges. You can exercise any of the
        following rights by notifying us as described below:
      </TypographyP>
      <ul className="list-disc list-inside">
        <li>
          Informative Request. Upon request and when possible, we will disclose
          the categories and specific pieces of personal information we have
          collected about you. Additionally, you may also obtain the categories
          of information about you that we have sold, the categories of third
          parties to whom the information was sold, and the categories of
          personal information that we disclosed about you for business
          purposes. You may submit these requests by contacting us as described
          below. When such a request cannot be honored, we will advise you
          accordingly.
        </li>
        <li>
          Request to Delete. If you should wish to cease use of our Sites and
          have your personal data deleted from our Sites, then you may submit a
          request by contacting us as described below. Upon receipt of a
          verifiable request for deletion, we will confirm receipt and will
          confirm once your personal data has been deleted. To exercise this
          right via email, simply type the words “Personal Data Deletion” in the
          subject line of your email.
        </li>
        <li>
          Other Rights. You also have the right to sue in the event your
          nonencrypted or nonredacted personal information, is subject to an
          unauthorized access and exfiltration, theft, or disclosure as a result
          of our violation of the duty to implement and maintain reasonable
          security procedures and practices. Prior to exercising this right, you
          must provide us with 30 days&apos; written notice by contacting us as
          described below and providing us with an opportunity fix this
          violation.
        </li>
      </ul>
      <TypographyP>
        We reserve the right to ask for information verifying your identity and
        residency prior to disclosing any information to you or acting on your
        request. Should we ask for verification, the information you provide to
        verify your identity and residency will be used only for that purpose,
        and all copies of this information in our possession will be destroyed
        when the process is complete. We strive to respond to your request
        within 45 days of receiving a verifiable consumer request however, we
        reserve the right to respond within 90 days when reasonably necessary.
      </TypographyP>
      <TypographyP>
        Except as provided herein, we will not disclose or share your Personal
        Information with third parties for the purposes of third-party marketing
        to you without your prior consent.
      </TypographyP>
      <TypographyP>
        Under California&apos;s “Shine the Light” law, California residents who
        provide personal information in obtaining products or services for
        personal, family, or household use are entitled to request and obtain
        from us once a calendar year information about the customer information
        we shared, if any, with other businesses for their own direct marketing
        uses. If applicable, this information would include the categories of
        customer information and the names and addresses of those businesses
        with which we shared customer information for the immediately prior
        calendar year (e.g., requests made in 2023 will receive information
        regarding 2022 sharing activities). To obtain this information in
        connection with information collected through the Service, please
        contact us as described below.
      </TypographyP>
      <TypographyH3 className="mt-6 text-pretty">Law &amp; Order</TypographyH3>
      <TypographyP>
        We may disclose any of your information (including Personal Information)
        to third parties if we determine that such disclosure is reasonably
        necessary to (a) comply with the law; (b) protect any person from death
        or serious bodily injury; (c) prevent fraud or abuse of Spotsurfer or
        our users; or (d) protect our property rights.
      </TypographyP>
      <TypographyH3 className="mt-6 text-pretty">
        Information Access
      </TypographyH3>
      <TypographyP>
        You may correct your account information at any time by logging into
        your User Account. If you wish to close your account, please log into
        your User Account, and then follow the provided directions or contact us
        as described below. Please note that in some cases we may retain certain
        information about you as required by law, or for legitimate business
        purposes to the extent permitted by law. For instance, if you have a
        standing credit or debit on your account, or if we believe you have
        committed fraud or violated the Terms of Use, we may seek to resolve the
        issue before deleting your information. Spotsurfer will comply with an
        individual&apos;s requests regarding access, correction, and/or deletion
        of Personal Information it stores in accordance with applicable law.
      </TypographyP>
      <TypographyH3 className="mt-6 text-pretty">
        International Users
      </TypographyH3>
      <TypographyP>
        Spotsurfer is based in the United States of America (“U.S.”), and,
        regardless of where you use our Sites from or otherwise provide
        information to us, the information may be transferred to and maintained
        on servers located in the U.S. Please note that any information we
        obtain about you will be stored in accordance with U.S. privacy laws,
        regulations and standards, which may not be equivalent to the laws in
        your country of origin or residence. By using the Sites or by providing
        us with your information, you consent to this collection, transfer,
        storage, and processing of information to and in the U.S.
      </TypographyP>
      <TypographyH3 className="mt-6 text-pretty">
        General Privacy Law Compliance
      </TypographyH3>
      <TypographyP>
        Every single state within the U.S. has its own version of a data privacy
        law. Similarly, Canada conforms to the Personal Information Protection
        and Electronic Documents Act (PIPEDA). Spotsurfer strives to conform to
        all of these laws and regulations to the best of its commercially
        reasonable ability, reflected in its internal policies and procedures
        centered around collection, use and disclosure of personal information.
        In all endeavors, Spotsurfer employs best practices in protecting
        personal identifiable information for all customers.
      </TypographyP>
      <TypographyH3 className="mt-6 text-pretty">
        User Account Management
      </TypographyH3>
      <TypographyP>
        Users have the right to delete the account using the following Step 1 :
        Log in to the Spotsurfer platform. Step 2 : Navigate to the profile
        menu. Step 3 : Select the delete account button. Note: The users can
        register again using the Spotsurfer in the future.
      </TypographyP>
      <TypographyH3 className="mt-6 text-pretty">
        Spotsurfer Mascot Promotion
      </TypographyH3>
      <TypographyP>
        Spotsurfer promotions are <em>Marketing Giveaways </em>that are ongoing.
        Winners are selected <em>randomly</em> from a pool of eligible entrants,
        and no purchase is required to enter the Giveaways
      </TypographyP>
      <TypographyP>
        <em>Terms and conditions are subject to change. </em>
      </TypographyP>
      <TypographyH3 className="mt-6 text-pretty">
        Contact Information
      </TypographyH3>
      <TypographyP>
        If you wish to contact us regarding (i) information on our products and
        services, (ii) permission to reproduce or use any Content on the Sites,
        (iii) notices of claimed infringement under the DMCA, (iv) the Privacy
        Policy or (v) any other reason, please email to{" "}
        <a href="mailto:info@spotsurfer.com">info@spotsurfer.com</a>.
      </TypographyP>
    </div>
  )
}

export default TermsAndConditions
