import {
  UpdateFormTabsWrapperChildren,
  UpdateFormTabWrapperProps,
  UpdateFormTabWrapper
} from "./UpdateFormTabWrapper"
import { CardSwitch } from "@/components/custom/Forms/FormInputs"
import Loading from "../../Loading"
import Fallback from "../../Fallback"
import { useAmenitiesForm } from "@/hooks/useAmenitiesForm"

export interface DataWithAmenities {
  amenities?: Amenity[]
}

export type AmenitiesReturn = Record<string, boolean>

export interface EditAmenitiesProps
  extends UpdateFormTabsWrapperChildren<DataWithAmenities, AmenitiesReturn> {
  submitHandler: UpdateFormTabWrapperProps<
    DataWithAmenities,
    AmenitiesReturn
  >["onSubmit"]
}

export const EditAmenitiesTab = ({
  data,
  submitHandler,
  ...tabProps
}: EditAmenitiesProps) => {
  const { form, isError, isPending, amenities } = useAmenitiesForm({
    defaultValues: data.amenities
  })

  if (isPending) return <Loading className="" />
  if (isError) return <Fallback />

  return (
    <UpdateFormTabWrapper
      {...tabProps}
      form={form}
      onDiscard={() => form.reset()}
      onSubmit={submitHandler}
      Render={({ control }) => (
        <div className="grid gap-3 md:grid-cols-2 my-6">
          {amenities &&
            amenities
              ?.sort((a, b) => (a.name > b.name ? 1 : -1))
              .map((amenity) => (
                <CardSwitch
                  key={amenity.id}
                  label={amenity.name}
                  name={amenity.id.toString()}
                  iconName={amenity.iconName}
                  description={amenity.description}
                  control={control}
                />
              ))}
        </div>
      )}
    />
  )
}
