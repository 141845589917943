import { createContext, useState, useCallback, useRef } from 'react';
import { CustomAlert } from '@/components/custom/Forms/CustomAlert';

interface ErrorContextType {
  message: string | null;
  type: 'error' | 'success';
  showMessage: (message: string, type?: 'error' | 'success') => void;
  clearMessage: () => void;
}

export const CustomAlertContext = createContext<ErrorContextType | undefined>(undefined);

let globalShowMessage = (message: string, type: 'error' | 'success' = 'error') => {
  console.warn('AlertProvider not mounted yet');
};

let globalClearMessage = () => {
  console.warn('AlertProvider not mounted yet');
};

export const AlertProvider = ({ children }: { children: React.ReactNode }) => {
  const [message, setMessage] = useState<string | null>(null);
  const [type, setType] = useState<'error' | 'success'>('error');
  const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const clearMessage = useCallback(() => {
    setMessage(null);
    setType('error');
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }
  }, []);

  const showMessage = useCallback((message: string, type: 'error' | 'success' = 'error') => {
    setMessage(message);
    setType(type);
    
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    
    timerRef.current = setTimeout(() => {
      clearMessage();
    }, 5000);
  }, [clearMessage]);

  globalShowMessage = showMessage;
  globalClearMessage = clearMessage;

  return (
    <CustomAlertContext.Provider value={{ message, type, showMessage, clearMessage }}>
      {message && (
        <div className="flex justify-center items-center relative z-[1000]">
          <CustomAlert 
            messages={message} 
            type={type}
            className={`fixed rounded z-50 border-none top-0 right-[5px] md:max-w-[35%] ${
              type === 'success' ? 'bg-green-400' : 'bg-red-400'
            }`} 
          />
        </div>
      )}
      {children}
    </CustomAlertContext.Provider>
  );
};

// Export global message handlers
export const showGlobalMessage = (message: string, type: 'error' | 'success' = 'error') => {
  globalShowMessage(message, type);
}

export const clearGlobalMessage = () => globalClearMessage();