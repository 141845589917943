import { LatitudeLongitudeStepper } from "@/components/custom/LatitudeLongitudeSelector/LatitudeLongitudeStepper"
import { CreateLotDetailsStepper } from "./steps/CreeateLotDetailsStepper"
import { CreateAvailabilityStepper } from "@/components/custom/Forms/CreateFormGenerics/CreateAvailabilityStepper"

export const FORM_TITLE = "Create an unassigned lot"
export const FORM_SUBTITLE =
  "Unassigned lot contain non-unique spots with minimal differences in amenities. Examples include parking lots, street parking, and large residential lots."

export const CreateLotSteps = [
  {
    label: "Details",
    render: CreateLotDetailsStepper
  },
  {
    label: "Location",
    render: LatitudeLongitudeStepper
  },
  {
    label: "Availability",
    render: CreateAvailabilityStepper
  }
]
