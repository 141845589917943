import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle
} from "@/components/ui/card"
import { Trash2 } from "lucide-react"
import { useAuth } from "@/context/AuthContext"
import { deleteVehicle } from "@/api/delete"

const VehicleCard = ({ vehicleDetails, vehicleDialog, refetch }) => {
  const { accessToken } = useAuth()
  const { make, model, licenseNumber, registeredState, color, id } =
    vehicleDetails

  const handleDelete = async () => {
    await deleteVehicle(id, accessToken)
    refetch()
  }

  return (
    <Card className="relative max-w-100">
      <CardHeader className="grid grid-cols-2 p-1 justify-end justify-items-end">
        <CardTitle className="text-lg font-semibold w-full pl-1">
          {make}{" "} {model}
        </CardTitle>
      </CardHeader>
      <CardContent className="pl-3 pb-3">
        <div className="grid grid-cols-1 pb-1 text-sm">
          <div className="flex flex-col items-left">
            <p className="mb-1">
              License Plate:{" "}
              <span className="text-black font-semibold">
                {registeredState} <span className="uppercase">{licenseNumber}</span>
              </span>
            </p>
          </div>
          <div className="flex flex-col items-left">
            <p className="mb-1">
              {" "}
              Car Color:{" "}
              <span className="text-black font-semibold">
                {color.toLowerCase()}
              </span>
            </p>
          </div>
        </div>
      </CardContent>
      <CardFooter className="flex items-center p-4 pt-0">
        {vehicleDialog}
        <span className="cursor-pointer rounded-full bg-red-100 p-1 max-h-6 ml-auto">
          <Trash2 className="w-4 h-4" role={"button"} onClick={handleDelete} />
        </span>
      </CardFooter>
    </Card>
  )
}

export default VehicleCard
