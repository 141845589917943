import { searchAll } from "@/lib/formatDateForUrl"

const shopUrl = "https://shop.spotsurfer.com/"
const giveawaysUrl = "https://giveaways.spotsurfer.com"

export const NavLinks = ({ user, breakpoint, additionalLinks = [], dashboardNavItems = [] }) => {
  const baseLinks = [
    {
      name: "Reserve",
      path: searchAll(),
      icon: "square-parking",
      canDisplay: true,
      openNewTab: false
    },
    {
      name: "Discount Parking",
      path: "/parking-packs",
      icon: "ticket",
      canDisplay: true,
      openNewTab: false
    },
    {
      name: "List Your Spots",
      path: "/owner-signup",
      icon: "list",
      canDisplay: true,
      openNewTab: false
    },
    {
      name: "Sign Up",
      path: "/signup",
      icon: "user",
      canDisplay: !user,
      openNewTab: false
    },
    {
      name: "Log In",
      path: "/login",
      icon: "lock",
      canDisplay: !user,
      openNewTab: false
    },
    {
      name: "My Account",
      path: `/${user?.role === "PARKER" ? "parker" : "owner"}-dashboard/`,
      icon: "user",
      canDisplay: (user && (breakpoint === "sm" || breakpoint === "md")),
      openNewTab: false,
      divider: true
    },
    {
      name: "Shop",
      path: shopUrl,
      icon: "shopping-bag",
      canDisplay: false,
      openNewTab: true
    },
    {
      name: "Giveaways",
      path: giveawaysUrl,
      icon: "gift",
      canDisplay: false,
      openNewTab: true
    },
    {
      name: "About",
      path: "/about",
      icon: "info",
      canDisplay: false,
      openNewTab: false
    }
  ]

  return [...dashboardNavItems, ...baseLinks, ...additionalLinks]
}