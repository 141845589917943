import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle
} from "@/components/ui/dialog"
import { Form } from "@/components/ui/form"
import { requiredEmail, requiredString } from "@/constants/formSchema"
import { useAuth } from "@/context/AuthContext"
import { zodResolver } from "@hookform/resolvers/zod"
import { DialogProps } from "@radix-ui/react-dialog"
import { useState } from "react"
import { useForm } from "react-hook-form"
import { z } from "zod"
import { FormTextInput } from "../Forms/FormInputs"
import { Link } from "react-router-dom"
import { SmartSubmitButton } from "../Forms/SmartSubmitButton"

export interface LoginDialogProps {
  open?: boolean
  onOpenChange?: DialogProps["onOpenChange"]
  onSuccess: () => void
}

const loginFormSchema = z.object({
  email: requiredEmail,
  password: requiredString
})

export const LoginDialog = ({
  onSuccess,
  onOpenChange,
  open
}: LoginDialogProps) => {
  const [apiError, setApiError] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const { login } = useAuth()
  const form = useForm({
    resolver: zodResolver(loginFormSchema),
    defaultValues: {
      email: "",
      password: ""
    }
  })

  const submitHandler = async (formData: z.infer<typeof loginFormSchema>) => {
    if (apiError) setApiError("")
    setIsLoading(true)
    try {
      const userRole = await login(formData)
    } catch (error: any) {
      setIsLoading(false)
      if (error.response?.status === 401) {
        setApiError("Incorrect email or password. Please try again.")
      } else {
        setApiError("An error occurred. Please try again.")
      }
    }
    setIsLoading(false)
    onSuccess()
  }

  return (
    <Dialog onOpenChange={onOpenChange} open={open}>
      <DialogContent>
        <DialogHeader className="m-6">
          <DialogTitle>Log In</DialogTitle>
          <DialogDescription>
            You'll need to log in to purchase parking packs and season passes.
          </DialogDescription>
        </DialogHeader>
        <Form {...form}>
          <form
            className="m-6 flex flex-col gap-12 mt-6"
            onSubmit={form.handleSubmit(submitHandler)}>
            <div className="flex flex-col gap-6">
              <FormTextInput
                control={form.control}
                name="email"
                label="Email"
                placeholder="Your Email"
                type="email"
              />
              <FormTextInput
                control={form.control}
                name="password"
                label="Password"
                type="password"
              />
            </div>
            {apiError && <p className="text-red-500 text-sm">{apiError}</p>}
            <SmartSubmitButton
              isSubmitting={isLoading || form.formState.isSubmitting}
              className="w-full"
              submitText="Login"
            />
            <div className="mt-4 text-center text-sm">
              Don&apos;t have an account?{" "}
              <Link to="/signup" className="underline">
                Sign up
              </Link>
            </div>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}
