import { AccordionFormClasses, ReservationSectionProps } from "../../const"
import { AnonymousParkerForm } from "./AnonymousParkerForm"
import {
  ExistingParkerForm,
  ExistingParkerSkeleton
} from "./ExistingParkerForm"
import { TypographyH3 } from "@/components/ui/typography/h3"
import { useReservationForm } from "../../context/ReservationFormContext"

export const ParkerVehicleAccordion = ({ id }: ReservationSectionProps) => {
  const { isLoggedInParker, parker, isLoading } = useReservationForm()

  return (
    <div id={id}>
      <section className={AccordionFormClasses}>
        {isLoggedInParker ?
          isLoading || !parker ?
            <ExistingParkerSkeleton />
          : <>
              <ExistingParkerForm />
            </>

        : <>
            <TypographyH3 className="text-md">
              To reserve, we'll just need a bit more information from you.
            </TypographyH3>
            <AnonymousParkerForm />
          </>
        }
      </section>
    </div>
  )
}
