import { BACKEND_URI } from "@/api/const"
import axios from "axios"
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from "react"
import { DevLogin } from "./DevLogin"
import { useForm } from "react-hook-form"
import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"

export interface IDevAuthContext {
  devIsAuthorized?: boolean
  username: string
  password: string
}

const initialValue: IDevAuthContext = {
  devIsAuthorized: false,
  username: "",
  password: ""
}

const DevAuthorizerContext = createContext(initialValue)

const checkLoginStatus = async (
  username: string,
  password: string
): Promise<boolean> => {
  return axios
    .post(
      `${BACKEND_URI}/basic/`,
      {},
      {
        auth: {
          username,
          password
        }
      }
    )
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        return true
      }
      return false
    })
    .catch(() => false)
}

export const DevLoginSchema = z.object({
  username: z.string().email(),
  password: z.string()
})

export type DevLoginType = z.infer<typeof DevLoginSchema>

export const DevAuthProvider = ({
  children,
  username,
  password
}: React.PropsWithChildren<IDevAuthContext>) => {
  const [apiError, setApiError] = useState("")
  const form = useForm<DevLoginType>({
    resolver: zodResolver(DevLoginSchema)
  })

  const [internalIsAuthorized, setInternalIsAuthorized] = useState(() => {
    const storedAuth = localStorage.getItem("devAuth")
    return storedAuth ? JSON.parse(storedAuth).isAuthorized : false
  })

  const checkIsAuthorized = useCallback(
    ({ username, password }: DevLoginType) => {
      checkLoginStatus(username, password)
        .then((isAuthorized) => {
          setInternalIsAuthorized(isAuthorized)
          if (isAuthorized) {
            localStorage.setItem("devAuth", JSON.stringify({ isAuthorized, username, password }))
          } else {
            setApiError("This user is not authorized.")
          }
        })
        .catch(() => {
          setInternalIsAuthorized(false)
          setApiError("An error has occurred")
        })
    },
    [form.watch]
  )

  useEffect(() => {
    const storedAuth = localStorage.getItem("devAuth")
    if (storedAuth) {
      const { username, password } = JSON.parse(storedAuth)
      checkIsAuthorized({ username, password })
    }
  }, [])

  useEffect(() => {
    form.watch(() => setApiError(""))
  }, [form.watch])

  const value = {
    username,
    password,
    devIsAuthorized: internalIsAuthorized
  }

  return (
    <DevAuthorizerContext.Provider value={value}>
      {value.devIsAuthorized ?
        children
      : <DevLogin
          apiError={apiError}
          form={form}
          onSubmit={checkIsAuthorized}
        />
      }
    </DevAuthorizerContext.Provider>
  )
}

export const useDevLogin = () => {
  return useContext(DevAuthorizerContext)
}
