import { UpdateFormTabsWrapperChildren } from "@/components/custom/Forms/UpdateFormGenerics/UpdateFormTabWrapper"
import { EditSpotMediaForm } from "../const"
import { useAuth } from "@/context/AuthContext"
import { useParams } from "react-router-dom"
import { useQuery } from "@tanstack/react-query"
import { GetOwner, GetOwnerResponse } from "@/api/GetOwner"
import {
  DataWithMedia,
  EditMediaTab
} from "@/components/custom/Forms/UpdateFormGenerics/EditMediaTab"
import { Spot } from "@/api/types/internal/Spot"
import { useCallback } from "react"
import {
  UploadMultipleMediaToSpot,
  UploadToSpotData
} from "@/api/spots/UploadMediaToSpot"
import { ImageWithId } from "@/constants/MediaSchema"
import { DeleteSpotMedia } from "@/api/spots/DeleteSpotMedia"

export const EditSpotMedia = ({
  data,
  ...tabProps
}: UpdateFormTabsWrapperChildren<Spot, EditSpotMediaForm>) => {
  const { accessToken } = useAuth()
  const { spotId } = useParams()

  const submitHandler = useCallback(
    (result: DataWithMedia) => {
      const newImages = result.images?.filter((image) => image.new)
      if (spotId && newImages) {
        return UploadMultipleMediaToSpot(
          accessToken,
          spotId,
          newImages as UploadToSpotData[]
        )
      }
    },
    [spotId, accessToken]
  )

  const onApiDelete = useCallback(
    (result: ImageWithId) => {
      if (result.id) {
        return DeleteSpotMedia(accessToken, result.id)
      }
    },
    [accessToken]
  )

  return (
    <EditMediaTab
      data={data}
      onApiDelete={onApiDelete}
      submitHandler={submitHandler}
      {...tabProps}
    />
  )
}
