import { z } from "zod"

export const LatitudeSchema = z.coerce.number().gte(-90).lte(90)
export const LongitudeSchema = z.coerce.number().gte(-180).lte(180)

export const LatLongSchema = z.object({
  latitude: LatitudeSchema,
  longitude: LongitudeSchema
})

export type ILatLongSchema = z.infer<typeof LatLongSchema>
