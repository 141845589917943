import { Separator } from "@/components/ui/separator"
import { FormLatLongInput } from "../FormInputs"
import {
  UpdateFormTabsWrapperChildren,
  UpdateFormTabWrapper,
  UpdateFormTabWrapperProps
} from "./UpdateFormTabWrapper"
import { TypographyH4 } from "@/components/ui/typography/h4"
import { DragMap } from "../DragMap"
import { useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { LatLongSchema } from "@/constants/LatLongSchema"

export interface DataWithLatLongInfo {
  latitude: number
  longitude: number
}

const DefaultValues = {
  latitude: 0,
  longitude: 0
}

export interface EditLocationTabsProps
  extends UpdateFormTabsWrapperChildren<
    DataWithLatLongInfo,
    DataWithLatLongInfo
  > {
  submitHandler: UpdateFormTabWrapperProps<
    DataWithLatLongInfo,
    DataWithLatLongInfo
  >["onSubmit"]
}

export const EditLocationTab = ({
  data,
  submitHandler,
  ...tabProps
}: EditLocationTabsProps) => {
  const form = useForm({
    resolver: zodResolver(LatLongSchema),
    values: data.latitude && data.longitude ? data : DefaultValues,
    shouldUnregister: false
  })

  return (
    <UpdateFormTabWrapper
      {...tabProps}
      form={form}
      onDiscard={() => form.reset()}
      onSubmit={submitHandler}
      Render={({ control }) => (
        <div className="grid gap-6 py-3">
          <div className="grid gap-3">
            <TypographyH4 className="text-base">
              Drag to a new location
            </TypographyH4>
            <div className="w-full rounded-xl border p-4 h-[25rem]">
              <DragMap
                latitude={form.watch("latitude")}
                longitude={form.watch("longitude")}
                precision={4}
                onChange={(latitude, longitude) => {
                  form.setValue("latitude", latitude, {
                    shouldDirty: true,
                    shouldTouch: true,
                    shouldValidate: true
                  })
                  form.setValue("longitude", longitude, {
                    shouldDirty: true,
                    shouldTouch: true,
                    shouldValidate: true
                  })
                }}
              />
            </div>
          </div>
          <Separator />
          <div className="grid gap-3">
            <TypographyH4 className="text-base">
              Or manually enter a location
            </TypographyH4>
            <div className="grid grid-cols-2 gap-3">
              <div className="grid gap-3">
                <FormLatLongInput
                  control={form.control}
                  name="latitude"
                  label="Latitude (°)"
                />
              </div>
              <div className="grid gap-3">
                <FormLatLongInput
                  control={form.control}
                  name="longitude"
                  label="Longitude (°)"
                />
              </div>
            </div>
          </div>
        </div>
      )}
    />
  )
}
