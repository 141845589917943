import { AddressBlock } from "@/components/custom/Address/AddressBlock"
import { useAuth } from "@/context/AuthContext"
import { Badge } from "@/components/ui/badge"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from "@/components/ui/table"
import { getFormattedDateTime } from "@/lib/utils"
import { Phone } from "lucide-react"
import type { Reservation } from '@/api/types/internal/Reservation'

const badgeVariant: Record<string, string> = {
  fulfilled: "secondary",
  declined: "outline"
}

export interface ReservationTableProps {
  reservations: Reservation[]
  onReservationClicked?: (reservation: Reservation) => void
}

export const ReservationTable = ({ reservations, onReservationClicked }: ReservationTableProps) => {
  const { user } = useAuth()
  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>Customer</TableHead>
          <TableHead>Spot</TableHead>
          <TableHead className="hidden sm:table-cell">Type</TableHead>
          {/* <TableHead className="hidden sm:table-cell">Status</TableHead> */}
          <TableHead className="hidden md:table-cell">Reservation Date</TableHead>
          <TableHead className="hidden sm:table-cell">License Plate #</TableHead>
          <TableHead className="hidden sm:table-cell">Add-ons</TableHead>
          {
            (user.role === "CPO" || user.role === "PPO") && (
              <TableHead className="text-right">Amount</TableHead>
            )
          }
        </TableRow>
      </TableHeader>
      <TableBody>
        {reservations.map((reservation, i) => {
          return (
          <ReservationRow
            onClick={() => onReservationClicked?.(reservation)}
            reservation={reservation}
            key={i}
          />
        )})}
      </TableBody>
    </Table>
  )
}

export interface ReservationRowProps {
  reservation: Reservation
  onClick: () => void
}

export const ReservationRow = ({ reservation, onClick }: ReservationRowProps) => {

  const spotName = reservation.parkingLocationType === "SPOT" ? reservation?.spot?.name : reservation?.lot?.name
  const { user } = useAuth()

  return (
    <TableRow onClick={onClick} key={reservation.id} className="cursor-pointer">
      <TableCell className="grid gap-1">
        <div className="font-medium">
          {reservation?.parker?.user?.firstName}{" "}
          {reservation?.parker?.user?.lastName}
        </div>
        <div className="text-sm text-muted-foreground md:inline">
          <p>Email:</p>
          <span>{reservation?.parker?.email}</span>
        </div>
        {reservation?.parker?.user?.phone && (
          <div className="text-sm text-muted-foreground md:inline">
            <Phone className="h-4 w-4 inline mr-2" />
            <span>{reservation.parker.user.phone}</span>
          </div>
        )}
      </TableCell>
      <TableCell className="sm:table-cell grid gap-2">
        <p className="font-medium">{spotName}</p>
        <p className="font-medium mb-2">{reservation.facility.name}</p>
        <AddressBlock {...reservation.facility.address} />
      </TableCell>
      <TableCell className="hidden sm:table-cell capitalize">
        {reservation.parkingLocationType === "SPOT" ? "Parking Spot" : "Parking Lot"}
      </TableCell>
      {/* <TableCell className="hidden sm:table-cell">
        <Badge className="text-xs capitalize" variant={badgeVariant[status]}>
          {reservation.status.toLowerCase()}
        </Badge>
      </TableCell> */}
      <TableCell className="hidden md:table-cell">
        {getFormattedDateTime(reservation.checkinDatetime).map((portion, i) => (
          <p key={`portion-${i}`}>{portion}</p>
        ))}
      </TableCell>
      <TableCell className="hidden sm:table-cell">
        {reservation.vehicle.licenseNumber || 'N/A'}
      </TableCell>
      <TableCell className="hidden sm:table-cell">
        {reservation?.paidAmenities[0]?.name}
      </TableCell>
      {
        (user.role === "CPO" || user.role === "PPO") && (
          <TableCell className="text-right">
            ${reservation?.order?.finalAmount}
          </TableCell>
        )
      }
    </TableRow>
  )
}
