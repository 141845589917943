import { deepMapKeys } from "@/lib/deepMapKeys"
import { serializeParams } from "@/lib/serializeParams"
import { buildRequest } from "."
import { handleError, clearError } from '@/lib/errorHandler';
import { api } from '@/api/const'

// To override the default error message, pass a customError string to the function
export const getData = async (uri, accessToken, dropTrailingSlash = false, customError) => {
  const { url, headers } = buildRequest(uri, accessToken, undefined, dropTrailingSlash)
  try {
    clearError()
    const result =
      accessToken ?
        await api.get(url, {
          headers
        })
      : await api.get(url)

    if (result.data && result.data.length) {
      return result.data.map((item) => deepMapKeys(item))
    } else {
      return result.data && deepMapKeys(result.data)
    }
  } catch (error) {
    handleError({
      error: customError || error.message
    })
    return error
  }
}

export const getReservations = async (accessToken) => {
  return await getData("v1/reservations", accessToken)
}

export const getSpotsNLots = async (accessToken) => {
  return await getData("v1/owner/spotsnlots", accessToken)
}

export const getParker = async (accessToken) => {
  return await getData("v1/parker", accessToken)
}

export const getParkerWallet = async (id, accessToken) => {
  return await getData(`v1/parker/${id}/wallet`, accessToken)
}

export const getOwner = async (accessToken) => {
  return await getData("v1/owner", accessToken)
}

export const getOwnerTeam = async (accessToken) => {
  return await getData("v1/owner/team", accessToken)
}

export const getTeamMember = async (accessToken) => {
  return await getData("v1/member", accessToken)
}

export const getAuthenticatedSupport = async (accessToken) => {
  return await getData("v1/support", accessToken)
}

export const getPackages = async () => {
  return await getData("v1/packages")
}

export const getOrderById = async (id) => {
  return await getData(`v1/orders/${id}`)
}

export const getReservationById = async (id) => {
  return await getData(`v1/reservations/${id}`)
}

export const getFacilities = async () => {
  return await getData("v1/facilities")
}

export const getSupport = async () => {
  return await getData("v1/support")
}

export const getAmenities = async () => {
  return await getData("v1/amenities")
}

export const getLot = async (id) => {
  return await getData(`v1/lots/${id}`)
}

export const getSpot = async (id) => {
  return await getData(`v1/spots/${id}`)
}

// searchQuery is an object with `facility`, `event`, and `checkinDate` keys
export const search = async (searchQuery) => {
  const params = serializeParams(searchQuery)
  const path = `search?${params.toString()}`
  const data = await getData(path, undefined, true)

  return data.filter((facility) => facility.isAvailable === true)
}
