import Fallback from "@/components/custom/Fallback"
import { UpdateFormHeader } from "@/components/custom/Forms/UpdateFormGenerics/UpdateFormHeader"
import { UpdateFormTabsWrapper } from "@/components/custom/Forms/UpdateFormGenerics/UpdateFormTabWrapper"
import Loading from "@/components/custom/Loading"
import { useAuth } from "@/context/AuthContext"
import { useQuery } from "@tanstack/react-query"
import { useParams } from "react-router-dom"
import { EditSpotTabs } from "./const"
import { GetSpot, GetSpotQueryKey, GetSpotResponse } from "@/api/spots/GetSpot"

export const EditSpotPage = () => {
  const { spotId } = useParams()
  const { accessToken } = useAuth()
  const { data, isPending, isError, refetch } = useQuery<GetSpotResponse>({
    queryKey: [GetSpotQueryKey(spotId)],
    queryFn: () => GetSpot(accessToken, spotId)
  })

  if (isPending || !data || !data.spot) return <Loading className="" />
  if (isError || data.isError) return <Fallback />

  return (
    <UpdateFormHeader
      title={data.spot.name}
      subtitle={data.spot.facility?.name}
      badge={data.spot.status}
      data={data.spot}
      deleteText="Delete Spot"
      onDeleteClicked={() => {}}
      onSubmit={() => {
        refetch()
      }}
      render={({ onSubmit, ...header }) => (
        <UpdateFormTabsWrapper
          onSubmitted={onSubmit}
          {...header}
          tabs={EditSpotTabs}
        />
      )}
    />
  )
}
