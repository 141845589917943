import { cn } from "@/lib/utils"
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger
} from "@/components/ui/hover-card"
import { Button } from "@/components/ui/button"
import { X } from "lucide-react"
import { UseFormSetValue, UseFormWatch } from "react-hook-form"
import { Title } from "./EditableTitle"
import { ActiveToggle } from "./CustomInputs"
import { AvailabilityForm } from "@/constants/AvailabilitySchema"

const PageLabel = "spotsandlots_edit"

export interface AvailabilityCardHeaderProps {
  className?: string
  onDelete?: () => void
  watch: UseFormWatch<AvailabilityForm>
  setValue: UseFormSetValue<AvailabilityForm>
  activeName: string
  titleName: string
}

export const AvailabilityCardHeader = ({
  className,
  watch,
  setValue,
  activeName,
  titleName,
  onDelete
}: AvailabilityCardHeaderProps) => {
  return (
    <div
      className={cn(
        className,
        "flex grid-cols-[auto_auto_1fr_auto] content-center gap-6 justify-between"
      )}>
      {/* <ActiveToggle watch={watch} setValue={setValue} name={activeName} /> */}
      <Title name={titleName} watch={watch} setValue={setValue} />
      <span />
      <HoverCard>
        <HoverCardTrigger asChild>
          <Button variant="link" size="icon" onClick={onDelete} type="button">
            <X size={20} className="text-slate-700" />
          </Button>
        </HoverCardTrigger>
        <HoverCardContent side="top">
          <p>Delete this entry</p>
        </HoverCardContent>
      </HoverCard>
    </div>
  )
}
