import { Badge } from "@/components/ui/badge"
import { Button } from "@/components/ui/button"
import { ImageWithId } from "@/constants/MediaSchema"
import { Plus, X } from "lucide-react"
import { useMemo } from "react"
import { ImageToSrcUrl } from "./const"

export interface MediaUploadListProps {
  onPlusClicked?: () => void
  onDeleteClicked?: (index: number) => void
  images: ImageWithId[]
}

export const MediaUploadList = ({
  onPlusClicked,
  onDeleteClicked,
  images
}: MediaUploadListProps) => {
  return (
    <div className="border px-6 py-12 flex gap-6 items-start justify-center flex-wrap">
      {images.map((image, index) => (
        <MediaItem
          key={image.formId}
          image={image}
          onDeleteClicked={() => onDeleteClicked?.(index)}
        />
      ))}
      <button
        onClick={onPlusClicked}
        className="flex flex-col items-center justify-center rounded-md h-48 w-48 bg-gray-300">
        <Plus size={60} />
        <p>Add another</p>
      </button>
    </div>
  )
}

interface MediaItemProps {
  image: ImageWithId
  onDeleteClicked?: () => void
}

const MediaItem = ({ image, onDeleteClicked }: MediaItemProps) => {
  const imagePreviewSrc = useMemo(() => ImageToSrcUrl(image), [image])
  return (
    <div className="grid gap-1 max-w-48 items-center text-center justify-center">
      <div className="h-48 flex items-center">
        <div className="relative">
          <Button
            onClick={() => onDeleteClicked?.()}
            className="h-4 w-4 rounded-full absolute -top-2 -right-2"
            size="icon"
            variant="destructive">
            <X className="h-4 w-4" />
          </Button>
          <img
            src={imagePreviewSrc}
            alt="Uploaded Image"
            className="w-48 h-48 object-cover rounded-md"
          />
        </div>
      </div>
      {image.new ?
        <div className="grid grid-cols-[1fr_auto_1fr] gap-3 items-start">
          <span />
          <p className="font-semibold text-accent-orange">{image.name}</p>
          <Badge className="w-fit" variant="">
            New!
          </Badge>
        </div>
      : <p className="font-semibold text-accent-orange text-wrap">
          {image.name}
        </p>
      }
    </div>
  )
}
