import {
  UpdateFormTabsWrapperChildren,
  UpdateFormTabWrapperProps,
  UpdateFormTabWrapper
} from "./UpdateFormTabWrapper"
import { useFieldArray, useForm } from "react-hook-form"
import { EditMediaForm, EditMediaSchema } from "../MediaGenerics/const"
import { zodResolver } from "@hookform/resolvers/zod"
import { useCallback, useState } from "react"
import { DeleteDialog } from "../MediaGenerics/DeleteDialog"
import { UploadDialog } from "../MediaGenerics/UploadDialog"
import { EmptyMediaUploadScreen } from "../MediaGenerics/EmptyMediaScreen"
import { MediaUploadList } from "../MediaGenerics/MediaUploadList"
import { IImage, ImageWithId } from "@/constants/MediaSchema"
import { StandardApiResponse } from "@/api/const"

export interface DataWithMedia {
  images?: ImageWithId[]
}

export interface EditMediaProps
  extends UpdateFormTabsWrapperChildren<DataWithMedia, EditMediaForm> {
  submitHandler?: UpdateFormTabWrapperProps<
    DataWithMedia,
    EditMediaForm
  >["onSubmit"]
  onApiDelete?: (image: IImage) => Promise<StandardApiResponse> | undefined
}

export const EditMediaTab = ({
  data,
  submitHandler,
  onApiDelete,
  ...tabProps
}: EditMediaProps) => {
  const [indexToDelete, setIndexToDelete] = useState<number>(-1)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false)
  const form = useForm<EditMediaForm>({
    resolver: zodResolver(EditMediaSchema),
    values: {
      images: data.images ?? []
    }
  })

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "images",
    keyName: "formId"
  })

  const onConfirmedDelete = useCallback(() => {
    const image = fields.at(indexToDelete)
    if (!image) {
      return
    }

    if (image.new) {
      remove(indexToDelete)
    } else {
      onApiDelete?.(image)?.then((result) => {
        if (result.isError) {
          tabProps.onApiError?.(`Image could not be deleted:\n${result.error}`)
        } else {
          remove(indexToDelete)
        }
      })
    }
  }, [indexToDelete, remove])

  return (
    <>
      <UpdateFormTabWrapper
        {...tabProps}
        memoizeRender={false}
        onDiscard={() => form.reset()}
        onSubmit={submitHandler}
        form={form}
        Render={() => {
          return (
            <div className="grid gap-6">
              <div className="grid gap-6">
                {!fields || fields.length === 0 ?
                  <EmptyMediaUploadScreen
                    onClick={() => setUploadDialogOpen(true)}
                  />
                : <MediaUploadList
                    images={fields}
                    onPlusClicked={() => setUploadDialogOpen(true)}
                    onDeleteClicked={(index) => {
                      setIndexToDelete(index)
                      setDeleteDialogOpen(true)
                    }}
                  />
                }
              </div>
            </div>
          )
        }}
      />
      <UploadDialog
        open={uploadDialogOpen}
        onOpenChange={setUploadDialogOpen}
        onSubmit={({ name, file }) => {
          append({ name, file, new: true, isThumbnail: false })
          setUploadDialogOpen(false)
        }}
      />
      <DeleteDialog
        image={fields[indexToDelete]}
        open={deleteDialogOpen}
        onSubmit={() => {
          onConfirmedDelete()
          setDeleteDialogOpen(false)
        }}
        onOpenChange={setDeleteDialogOpen}
      />
    </>
  )
}
