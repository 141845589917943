import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import { Separator } from "@/components/ui/separator"
import TypographyH1 from "@/components/ui/typography/h1"
import { TypographyH2 } from "@/components/ui/typography/h2"

const AccountDeletionRequest = () => {
  return (
    <div className="space-y-6 p-10 pb-16">
      <Card className="border-none">
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
          <div className="flex flex-col mb-4">
            <CardTitle className="text-4xl font-bold mb-1">
              How to Delete Your Spotsurfer Account
            </CardTitle>
            <p>If you wish to delete your Spotsurfer account and all associated data, follow the steps below. Please note that deleting your account is a permanent action and cannot be undone.</p>
          </div>
        </CardHeader>
        <CardContent>
          <TypographyH2>Steps to Request Account Deletion:</TypographyH2>
          <ol className="list-decimal ml-8">
            <li>Email <a className="underline" href="mailto:info@spotsurfer.com">info@spotsurfer.com</a> from the email address associated with your Spotsurfer account.</li>
            <li>In your email, please include the subject line "Account Deletion Request."</li>
            <li>Once your request is received, our team will process your request and delete your account permanently.</li>
          </ol>
          <Separator className="my-6" />
          <TypographyH2>What Happens When Your Account is Deleted:</TypographyH2>
          <ul className="list-disc ml-8">
            <li>Your account information, including your name, email, address, and license plate number, will be permanently deleted, unless required for business purposes (e.g., fraud detection).</li>
            <li>You will permanently lose access to past parking reservations. Active or upcoming reservations will not be canceled, but you can still access them via your confirmation email.</li>
            <li>Any Spotsurfer Credits and Parking Packs will be forfeited upon deletion, unless prohibited by law.</li>
          </ul>
          <Separator className="my-6" />
          <TypographyH2>Data Retention:</TypographyH2>
          <p>Certain data may be retained for a period of time to comply with legal obligations or for business purposes, such as fraud prevention or detection. After that period, your data will be fully deleted from our systems. For more information, please refer to our <a className="underline" href="/terms-and-conditions">Terms & Conditions</a>.</p>
        </CardContent>
      </Card>
    </div>
  )
}

export default AccountDeletionRequest