import { Link, useNavigate } from "react-router-dom"
import { Button } from "@/components/ui/button"
import Icon from "@/components/custom/Icon"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"
import TypographyH1 from "@/components/ui/typography/h1"
import { useParkerReservations } from "@/hooks/useParkerReservations"
import Loading from "@/components/custom/Loading"
import Fallback from "@/components/custom/Fallback"
import NoReservations from "@/components/custom/NoReservations"
import ReservationCard from "@/components/custom/ReservationCard"
import { useState } from "react"
import { cancelReservation } from "@/api/post"
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle
} from "@/components/ui/alert-dialog"

const ParkerReservationsPage = () => {
  const navigate = useNavigate()
  const [cancelSuccess, setCancelSuccess] = useState(false)
  const [cancelError, setCancelError] = useState(null)

  const {
    dataIsPending,
    dataError,
    upcomingReservations,
    previousReservations,
    cancelledReservations,
    parkerName
  } = useParkerReservations()

  const handleCancel = async (reason, id) => {
    const payload = { cancellationReason: reason }
    try {
      const results = await cancelReservation(payload, id)

      if (results) {
        setCancelSuccess(true)
      }
    } catch (error) {
      if (
        error.response.data.detail ===
        "Cannot cancel a reservation less than a day before checkin"
      ) {
        setCancelError({
          ...error,
          message:
            "Cannot cancel a reservation less than 24 hours before checkin. If you have question or concerns contact us at: (970) 389-4230 or info@spotsurfer.com"
        })
      } else {
        setCancelError({
          ...error,
          message:
            "An error occurred. Please try canceling again. If the error persists contact us at: (970) 389-4230 or info@spotsurfer.com"
        })
      }
    }
  }

  const handleAlertClose = () => {
    setCancelSuccess(false)
    navigate(0)
  }

  if (dataIsPending) return <Loading />

  if (dataError) return <Fallback />

  const cancelErrorAlert = (
    <AlertDialog open={cancelError} onOpenChange={setCancelError}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Error</AlertDialogTitle>
          <AlertDialogDescription>
            {cancelError ? cancelError.message : null}
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogAction>Close</AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )

  const cancelSuccessAlert = (
    <AlertDialog open={cancelSuccess} onOpenChange={handleAlertClose}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Cancelled</AlertDialogTitle>
          <AlertDialogDescription>
            Your reservation was cancelled
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogAction>Close</AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )

  const upcoming = upcomingReservations
    .sort((a, b) => new Date(a.checkinDatetime) - new Date(b.checkinDatetime))
    .map((reservation) => (
      <ReservationCard
        reservationData={reservation}
        handleCancel={handleCancel}
        title=""
        key={reservation.id}
      />
    ))

  const previous = previousReservations
    .sort((a, b) => new Date(a.checkinDatetime) - new Date(b.checkinDatetime))
    .map((reservation) => (
      <ReservationCard
        reservationData={reservation}
        title=""
        key={reservation.id}
      />
    ))

  const cancelled = cancelledReservations
    .sort((a, b) => new Date(a.checkinDatetime) - new Date(b.checkinDatetime))
    .map((reservation) => (
      <ReservationCard
        reservationData={reservation}
        title="cancelled"
        key={reservation.id}
      />
    ))

  const welcome = (
    <div className="space-y-0.5">
      <TypographyH1 className="flex items-center pb-2">
        <Icon
          name="calendar-days"
          size={24}
          className="lg:size-8 stroke-1 mr-3  "
        />
        Reservations
      </TypographyH1>
    </div>
  )

  const allReservations = (
    <Tabs defaultValue="upcoming">
      <div className="flex items-center">
        <TabsList>
          <TabsTrigger value="upcoming">Upcoming</TabsTrigger>
          <TabsTrigger value="previous">Previous</TabsTrigger>
          <TabsTrigger value="cancelled">Cancelled</TabsTrigger>
        </TabsList>
      </div>
      <TabsContent value="upcoming">
        {upcoming.length > 0 ?
          upcoming
        : <NoReservations message="No Upcoming Reservations" />}
      </TabsContent>
      <TabsContent value="previous">
        {previous.length > 0 ?
          previous
        : <NoReservations message="No Previous Reservations" />}
      </TabsContent>
      <TabsContent value="cancelled">
        {cancelled.length > 0 ?
          cancelled
        : <NoReservations message="No cancelled Reservations" />}
      </TabsContent>
    </Tabs>
  )

  return (
    <main className="space-y-6 p-10 pb-16">
      {cancelError && cancelErrorAlert}
      {cancelSuccess && cancelSuccessAlert}
      <div className="flex flex-col justify-center">
        {welcome}
        {allReservations}
      </div>
    </main>
  )
}

export default ParkerReservationsPage
