import { useNavigate } from "react-router-dom"

import { TypographyH2 } from "@/components/ui/typography/h2"
import { Button } from "@/components/ui/button"

const NotFound404Page = () => {
  const navigate = useNavigate()
  return (
    <div className="flex flex-col justify-center items-center h-screen">
      <TypographyH2> 404 Page Not Found </TypographyH2>
      <Button
        className="mt-4"
        variant="secondary"
        onClick={() => navigate("/")}>
        Take me home
      </Button>
    </div>
  )
}

export default NotFound404Page
