import { RegistrationAccordionItem } from "../../components/RegistrationAccordionItem"
import { useReservationForm } from "../../context/ReservationFormContext"
import { Button } from "@/components/ui/button"
import { Check, Loader2 } from "lucide-react"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useMutation } from "@tanstack/react-query"
import { ValidateCoupon } from "@/api/coupons/ValidateCoupon"
import { CouponValidationResponse } from "@/api/types/internal/CouponValidation"
import { AxiosError } from "axios"
import { cn } from "@/lib/utils"
import { Input } from "@/components/ui/input"

export const DiscountCodeAccordion = () => {
  const [internalCode, setInternalCode] = useState("")
  const { control, form } = useReservationForm()
  const [coupon, setCoupon] = useState<CouponValidationResponse | undefined>()
  const [couponError, setCouponError] = useState("")

  const validateCodeMutation = useMutation({
    mutationFn: ValidateCoupon,
    onSuccess: (data) => {
      setCoupon(data)
      form.setValue("code", internalCode)
    },
    onError: (error: AxiosError) => {
      if (error.response?.status === 400) {
        setCoupon({
          valid: false
        } as CouponValidationResponse)
      } else {
        setCouponError("An error has occured. Please try again.")
      }
    }
  })
  const validateHandler = useCallback(
    () =>
      validateCodeMutation.mutate({
        parker: form?.watch("email") ?? "",
        code: internalCode
      }),
    [internalCode, form?.watch("email")]
  )

  const clearHandler = useCallback(() => {
    setCoupon(undefined)
    form.setValue("code", undefined)
    setInternalCode("")
  }, [])

  useEffect(() => {
    if (!form?.watch("code") && coupon && coupon.valid) {
      setCoupon(undefined)
    }
  }, [form?.watch("code"), coupon])

  const couponText = useMemo(() => {
    if (!coupon) {
      return ""
    }
    if (coupon.valid) {
      if (coupon.type === "PERCENTAGE") {
        return `Your ${coupon.value}% discount will be applied at checkout. Please note that coupon codes cannot be applied to parking packs at
          this time. We apologize for the inconvenience.`
      } else if (coupon.type === "VALUE") {
        return `Your $${coupon.value} discount will be applied at checkout. Please note that coupon codes cannot be applied to parking packs at
          this time. We apologize for the inconvenience.`
      } else {
        return `Your coupon will be applied at checkout. Please note that coupon codes cannot be applied to parking packs at
          this time. We apologize for the inconvenience.`
      }
    } else {
      return "Uknown coupon code."
    }
  }, [coupon])

  if (!control) {
    return <></>
  }

  return (
    <RegistrationAccordionItem>
      <section className="pb-6 grid gap-3">
        <div className="flex flex-col gap-3">
          <div className="flex gap-3 items-end">
            <div className="space-y-2 w-full">
              {/* <Label>Code</Label> */}
              <Input
                placeholder="Discount Code"
                value={internalCode}
                onChange={(e) => setInternalCode(e.target.value.toUpperCase())}
              />
            </div>
            <Button
              disabled={coupon?.valid || validateCodeMutation.isPending}
              className={cn(coupon?.valid && "bg-accent-green")}
              type="button"
              onClick={validateHandler}>
              {validateCodeMutation.isPending && (
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              )}
              {coupon?.valid && <Check className="w-4 h-4 mr-2" />}Apply
            </Button>
            {(form.watch("code") || coupon) && (
              <Button
                variant="link"
                type="button"
                onClick={() => clearHandler()}>
                Clear
              </Button>
            )}
          </div>
          {couponText && (
            <p
              className={`text-md text-foreground-muted ${!coupon?.valid && "text-accent-red"}`}>
              {couponText}
            </p>
          )}
          {couponError && (
            <p className="text-md text-accent-red">{couponError}</p>
          )}
        </div>
      </section>
    </RegistrationAccordionItem>
  )
}
