import { createBreakpoint } from "react-use"

const defaultTWBreakpoints = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  "2xl": 1536
}

export const useBreakpoint = createBreakpoint(defaultTWBreakpoints)
