import type { Address } from '@/api/types/internal/Facility'

export const AddressBlock = ({ address1, address2, city, state, zipCode }: Address) => {
  return (
    <address className="grid gap-0.5 not-italic text-sm">
      <p>{address1}</p>
      <p>{address2}</p>
      <p>
        {city}, {state} {zipCode}
      </p>
    </address>
  )
}
