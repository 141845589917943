import _ from "lodash"

// Recursively call function on object keys
export function deepMapKeys(originalObject, callback = _.camelCase) {
  if (!_.isPlainObject(originalObject)) {
    return originalObject
  }

  return Object.keys(originalObject || {}).reduce((newObject, key) => {
    const newKey = callback(key)
    const originalValue = originalObject[key]
    let newValue = originalValue

    if (Array.isArray(originalValue)) {
      newValue = originalValue.map((item) => deepMapKeys(item, callback))
    } else if (typeof originalValue === "object") {
      newValue = deepMapKeys(originalValue, callback)
    }

    return {
      ...newObject,
      [newKey]: newValue
    }
  }, {})
}
