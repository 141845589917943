import { Link } from "react-router-dom"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from "@/components/ui/dropdown-menu"
import Icon from "./Icon"

const AboutUsDropdownMenu = () => {
  const links = [
    {
      name: "FAQs",
      to: "/FAQs",
      canDisplay: true,
      openNewTab: false
    },
    {
      name: "News",
      to: "/news-happenings",
      canDisplay: true,
      openNewTab: true
    },
    {
      name: "Investors",
      to: "/investors",
      canDisplay: false,
      openNewTab: false
    },
    {
      name: "Contact Us",
      to: "/contact-us",
      canDisplay: true,
      openNewTab: false
    },
    {
      name: "Company",
      to: "/about",
      canDisplay: true,
      openNewTab: false
    }
  ]

  const menuItems = links.map((link, i) =>
    link.canDisplay ?
      <DropdownMenuItem key={i}>
        <Link
          className="text-md transition-colors"
          to={link.to}
          {...(link.openNewTab ?
            { target: "_blank", rel: "noopener noreferrer" }
            : {})}>
          {link.name}
        </Link>
      </DropdownMenuItem>
      : null
  )

  const menuTrigger = (
    <DropdownMenuTrigger className="flex items-center text-base text-primary-foreground transition-colors hover:text-accent-yellow pl-2">
      <span className="mr-1">About Us</span>
      <Icon name="chevron-down" className="size-6" />
      <span className="sr-only">Toggle about us menu</span>
    </DropdownMenuTrigger>
  )

  return (
    <DropdownMenu tabindex="0">
      {menuTrigger}
      <DropdownMenuContent align="end">{menuItems}</DropdownMenuContent>
    </DropdownMenu>
  )
}

export default AboutUsDropdownMenu
