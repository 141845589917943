import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle
} from "@/components/ui/alert-dialog"

const ReservationExpiredAlert = ({
  open,
  handleOpenChange,
  redirectToSearch
}) => {
  return (
    <AlertDialog
      open={open}
      onOpenChange={handleOpenChange}
      className="outline-none">
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Your cart has expired</AlertDialogTitle>
          <AlertDialogDescription>
            Sorry, but your cart has expired. Please start a new search.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogAction onClick={redirectToSearch}>
            Continue
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}

export default ReservationExpiredAlert
