import { ListFacilitiesPage } from "@/pages/OwnerDashboard/Facilities/ListFacilitiesPage/ListFacilitiesPage"
import { CreateFacilityPage } from "./CreateFacilityPage/CreateFacilityPage"
import { SpotRoutes } from "./Spots/SpotRoutes"
import { LotRoutes } from "./Lots/LotRoutes"
import NotFound404Page from "@/pages/NotFound404Page"
import { EditFacilityPage } from "./EditFacilityPage/EditFacilityPage"

export const FacilityRoutes = (path: string) => {
  return {
    path,
    children: [
      {
        index: true,
        element: <ListFacilitiesPage />
      },
      {
        path: "new",
        element: <CreateFacilityPage />
      },
      {
        path: ":facilityId",
        children: [
          {
            path: "edit",
            element: <EditFacilityPage />
          },
          SpotRoutes("spots/*"),
          LotRoutes("lots/*")
        ]
      },
      {
        path: "*",
        element: <NotFound404Page />
      }
    ]
  }
}
