import { Button } from "@/components/ui/button"
import { TypographyH4 } from "@/components/ui/typography/h4"
import { useCallback, useState } from "react"
import SpotAvailabilityCard from "../../SpotAvailabilityForm/card/AvailabilityCard"
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from "@/components/ui/dialog"
import { CircleAlert, Loader2 } from "lucide-react"
import { Separator } from "@/components/ui/separator"
import { useFieldArray, useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { LabelledInfoHover } from "../../Hoverable/LabelledInfoHover"
import { StepperComponentProps } from "../../Stepper/Stepper"
import {
  AvailabilityForm,
  AvailabilityItemForm,
  AvailabilitySchema,
  OverrideItemForm
} from "@/constants/AvailabilitySchema"
import { AvailabilityDefault, OverrideDefault } from "./Defaults"

const PageLabel = "zone_create"

export const CreateAvailabilityStepper = ({
  saveData,
  backText,
  submitText,
  onBack,
  onSubmit
}: StepperComponentProps<AvailabilityForm>) => {
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [deleteId, setDeleteId] = useState(-1)
  const [deleteIsOverride, setDeleteIsOverride] = useState(false)

  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
    watch,
    setValue
  } = useForm<AvailabilityForm>({
    resolver: zodResolver(AvailabilitySchema),
    values: saveData
  })

  const {
    fields: availabilityFields,
    append: appendAvailability,
    remove: removeAvailability
  } = useFieldArray({
    control,
    keyName: "formId",
    name: "availability"
  })

  const {
    fields: overrideFields,
    append: appendOverride,
    remove: removeOverride
  } = useFieldArray({
    control,
    keyName: "formId",
    name: "override"
  })

  const onConfirmedDelete = useCallback(() => {
    deleteIsOverride ? removeOverride(deleteId) : removeAvailability(deleteId)
  }, [deleteId, deleteIsOverride])

  return (
    <div>
      <div>
        <div className="flex gap-3 items-center">
          <TypographyH4 className="text-xl font-semibold leading-none tracking-wide">
            Dates & Prices
          </TypographyH4>
          <LabelledInfoHover
            displayKeyOnError
            labelKey={`${PageLabel}.availability_dates_prices`}
          />
        </div>
        {availabilityFields.map((field, index) => (
          <SpotAvailabilityCard
            key={field.formId}
            control={control}
            controlIndex={index}
            watch={watch}
            setValue={setValue}
            onDelete={() => {
              setDeleteId(index)
              setDeleteIsOverride(false)
              setDeleteDialogOpen(true)
            }}
          />
        ))}
        <Button
          variant="link"
          className="justify-start"
          onClick={() => appendAvailability(AvailabilityDefault())}>
          + Add another available time
        </Button>
      </div>
      <Separator className="my-4" />
      <div className="grid gap-3">
        <div className="flex gap-3 items-center">
          <TypographyH4 className="text-xl font-semibold leading-none tracking-wide">
            Overrides
          </TypographyH4>
          <LabelledInfoHover
            displayKeyOnError
            labelKey={`${PageLabel}.availability_overrides`}
          />
        </div>
        {overrideFields.map((field, index) => (
          <SpotAvailabilityCard
            key={field.formId}
            isOverride
            className="divide-solid"
            control={control}
            controlIndex={index}
            watch={watch}
            setValue={setValue}
            onDelete={() => {
              setDeleteId(index)
              setDeleteIsOverride(true)
              setDeleteDialogOpen(true)
            }}
          />
        ))}
        <Button
          variant="link"
          className="justify-start"
          onClick={() => appendOverride(OverrideDefault())}>
          + Add another override
        </Button>
      </div>
      <Separator className="my-4" />
      <div className="flex justify-between">
        <Button variant="outline" onClick={() => onBack(watch())}>
          {backText}
        </Button>
        <Button
          variant="default"
          type="submit"
          disabled={isSubmitting}
          onClick={handleSubmit(onSubmit, console.error)}>
          {isSubmitting && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
          {submitText}
        </Button>
      </div>
      <DeleteDialog
        open={deleteDialogOpen}
        onConfirm={() => {
          onConfirmedDelete()
          setDeleteDialogOpen(false)
        }}
        onCancel={() => setDeleteDialogOpen(false)}
        onOpenChange={setDeleteDialogOpen}
      />
    </div>
  )
}

export interface DeleteDialogProps {
  open: boolean
  onConfirm: () => void
  onCancel: () => void
  onOpenChange: (open: boolean) => void
}

const DeleteDialog = ({
  open,
  onConfirm,
  onCancel,
  onOpenChange
}: DeleteDialogProps) => {
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeader>
          <div className="flex items-center gap-3">
            <CircleAlert />
            <DialogTitle>Delete a price mode</DialogTitle>
          </div>
        </DialogHeader>
        <p>Are you sure you want to delete this price mode?</p>
        <DialogFooter>
          <Button type="submit" variant="destructive" onClick={onConfirm}>
            Delete
          </Button>
          <Button variant="secondary" onClick={onCancel}>
            Cancel
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
