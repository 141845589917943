import { LatitudeLongitudeStepper } from "@/components/custom/LatitudeLongitudeSelector/LatitudeLongitudeStepper"
import { AssignedZoneDetails } from "./steps/Details"
import { z } from "zod"
import { AmenitiesStepper } from "@/components/custom/Forms/CreateFormGenerics/CreateAmenitiesStepper"
import { CreateAvailabilityStepper } from "@/components/custom/Forms/CreateFormGenerics/CreateAvailabilityStepper"
import { CreateMediaStepper } from "@/components/custom/Forms/CreateFormGenerics/MediaUpload/CreateMediaStepper"

export const FORM_TITLE = "Create a spot"
export const FORM_SUBTITLE =
  "Assigned spots are unique parking locations that are labeled by landmarks, numbers, or otherwise. Examples include driveways, private parking spots, and special spots within facilities like EV ports."

export const CreateSpotsSteps = [
  {
    label: "Details",
    render: AssignedZoneDetails
  },
  {
    label: "Location",
    render: LatitudeLongitudeStepper
  },
  {
    label: "Availability",
    render: CreateAvailabilityStepper
  },
  {
    label: "Amenities",
    render: AmenitiesStepper
  },
  {
    label: "Media",
    render: CreateMediaStepper
  }
]

export const CreateSpotDetailsSchema = z.object({
  name: z.string(),
  description: z.string().optional(),
  maxHeightSupported: z.coerce.number().min(0).optional(),
  hasSpaceForBiggerVehicles: z.coerce.boolean(),
  floor: z.coerce.number().int().min(1).optional(),
  parkerInstructions: z.string().optional()
})
export type IAssignedZoneDetails = z.infer<typeof CreateSpotDetailsSchema>
