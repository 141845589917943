import { GetLabels } from "@/api/GetLabels"
import { LabelGeneric } from "@/api/types/internal/Labels"
import { useQuery } from "@tanstack/react-query"
import React, { createContext, useCallback, useContext } from "react"

export interface ILabelContext {
  labels?: LabelGeneric
  getLabel: (label: string, defaultLabel?: string) => string | undefined
  isLoading?: boolean
  isError?: boolean
}

const initialValue: ILabelContext = {
  labels: undefined,
  getLabel: () => undefined,
  isLoading: true,
  isError: false
}

const LabelContext = createContext(initialValue)

const getDescendants = (object: any, descending: string) => {
  const array = descending.split(".")
  if (object === undefined) {
    return undefined
  }
  while (array.length && (object = object[array.shift() ?? ""]));
  return object
}

export const LabelProvider = ({
  children
}: React.PropsWithChildren<ILabelContext>) => {
  const { data, isLoading } = useQuery({
    queryKey: ["labels"],
    queryFn: () => GetLabels()
  })

  const getLabel = useCallback(
    (label: string, defaultLabel?: string): string | undefined =>
      getDescendants(value.labels, label) ?? defaultLabel ?? "",
    [data?.labels]
  )

  const value = {
    labels: data?.labels,
    getLabel,
    isLoading,
    isError: data?.isError
  }

  return <LabelContext.Provider value={value}>{children}</LabelContext.Provider>
}

export const useLabels = () => {
  return useContext<ILabelContext>(LabelContext)
}
