import { TypographyH3 } from "@/components/ui/typography/h3"
import { Link } from "react-router-dom"

export const ErrorHelpText = () => (
  <div className="p-2 max-w-4xl mx-auto lg:py-10 flex justify-center">
    <div
      className="flex flex-col gap-6"
      role="complementary"
      aria-label="Direct Navigation Error Message">
      <TypographyH3 className="text-md font-semibold">
        It looks like a mistake has happened.
      </TypographyH3>
      <div className="flex flex-col gap-1">
        <p>
          If you were sent this link, or tried to navigate here directly, we'll
          just need you to indicate where you'd like to reserve.
        </p>
        <p>
          You can do that{" "}
          <Link
            className="underline"
            role="link"
            aria-label="Search Return Link"
            to="/search?facility=all">
            here
          </Link>
          .
        </p>
      </div>
      <p>If this issue persists, please contact support.</p>
    </div>
  </div>
)
