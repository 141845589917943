import { cn } from "@/lib/utils"

export interface AddressRowProps {
  address: Address
  className?: string
}

export const AddressRow = ({
  className,
  address: { address1, address2, city, state, zipCode }
}: AddressRowProps) => {
  return (
    <address className={cn("flex gap-1 not-italic text-sm", className)}>
      <p>{address1},</p>
      {address2 && <p>{address2},</p>}
      <p>
        {city} {state}, {zipCode}
      </p>
    </address>
  )
}
