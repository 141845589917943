import { useEffect } from "react"
import { useMutation, useQuery } from "@tanstack/react-query"
import { useForm } from "react-hook-form"
import { z } from "zod"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from "@/components/ui/form"
import { Input } from "@/components/ui/input"
import { Button } from "@/components/ui/button"
import Loading from "@/components/custom/Loading"
import { zodResolver } from "@hookform/resolvers/zod"
import { contactSchema, requiredString } from "@/constants/formSchema"
import { useAuth } from "@/context/AuthContext"
import { getOwner } from "@/api/get"
import { editOwner } from "@/api/patch"
import Fallback from "./Fallback"

const nameSchema = z.object({
  firstName: requiredString,
  lastName: requiredString
})

const accountFormSchema = contactSchema.merge(nameSchema)

const defaultValues = {
  firstName: "",
  lastName: "",
  email: "",
  phone: ""
}

const OwnerAccountForm = () => {
  const { accessToken } = useAuth()
  const {
    data: ownerData,
    isPending,
    error,
    refetch
  } = useQuery({
    queryKey: ["owner", accessToken],
    queryFn: () => getOwner(accessToken)
  })

  // TODO: Need specific mutation for editing an owner user
  const mutation = useMutation({
    mutationFn: (formData) => {
      const ownerId = ownerData?.id
      return ownerId && editOwner(ownerId, formData, accessToken)
    },
    onSuccess: () => refetch()
  })

  const {
    reset,
    control,
    handleSubmit,
    formState: { isSubmitting },
    ...form
  } = useForm({
    resolver: zodResolver(accountFormSchema),
    defaultValues
  })

  useEffect(() => {
    if (ownerData) {
      const {
        user: { firstName, lastName, email, phone }
      } = ownerData
      reset({
        ...defaultValues,
        email,
        firstName: firstName ?? "",
        lastName: lastName ?? "",
        phone: phone ?? ""
      })
    }
  }, [ownerData, reset])

  if (isPending) return <Loading />

  if (error) return <Fallback />

  const onSubmit = ({ firstName, lastName, phone }) =>
    mutation.mutate({ firstName, lastName, phone })

  return (
    <Form {...form}>
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-8">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
          <FormField
            control={control}
            name="firstName"
            render={({ field }) => (
              <FormItem className="min-h-[100px]">
                <FormLabel>First Name</FormLabel>
                <FormControl>
                  <Input placeholder="Your First Name" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={control}
            name="lastName"
            render={({ field }) => (
              <FormItem className="min-h-[100px]">
                <FormLabel>Last Name</FormLabel>
                <FormControl>
                  <Input placeholder="Your Last Name" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
          <FormField
            control={control}
            name="email"
            render={({ field }) => (
              <FormItem className="min-h-[100px]">
                <FormLabel>Email</FormLabel>
                <FormControl>
                  <Input placeholder="Email" {...field} disabled />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={control}
            name="phone"
            render={({ field }) => (
              <FormItem className="min-h-[100px]">
                <FormLabel>Phone Number</FormLabel>
                <FormControl>
                  <Input placeholder="Phone Number" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <Button type="submit" disabled={isSubmitting}>
          Update account
        </Button>
      </form>
    </Form>
  )
}

export default OwnerAccountForm
