import { useForm } from "react-hook-form"
import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from "@/components/ui/form"
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle
} from "@/components/ui/card"
import { requiredEmail } from "@/constants/formSchema"
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { resetPassword } from "@/api/post"
import { useState } from "react"
import { TypographyH4 } from "../ui/typography/h4"

const resetPasswordFormSchema = z.object({
  email: requiredEmail
})

const ResetPasswordForm = () => {
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(false)

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    ...form
  } = useForm({
    resolver: zodResolver(resetPasswordFormSchema),
    defaultValues: {
      email: ""
    }
  })

  const onSubmit = async (formData) => {
    if (error) setError(null)

    try {
      const results = await resetPassword(formData)
      if (results) {
        setSuccess(true)
      }
    } catch (error) {
      if (error.response?.status === 401) {
        setError({
          ...error,
          message: "Incorrect email"
        })
      } else {
        setError({ ...error, message: "An error occurred. Please try again." })
      }
    }
  }
  if (success) {
    return (
      <div className="max-w-[500px] m-auto">
        <TypographyH4 className="px-6 font-normal">
          Email Sent! Check your email and follow the instructions to reset your
          password.
        </TypographyH4>
      </div>
    )
  }
  return (
    <Form {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card className="mx-auto max-w-sm">
          <CardHeader>
            <CardTitle className="text-2xl">Password Reset</CardTitle>
            <CardDescription>
              Enter your account email address to receive a link to reset your
              password.
            </CardDescription>
          </CardHeader>
          <CardContent>
            <FormField
              control={control}
              name="email"
              render={({ field }) => (
                <FormItem className="min-h-[100px]">
                  <FormLabel>Email</FormLabel>
                  <FormControl>
                    <Input placeholder="Your email" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="grid gap-4 mt-3">
              {error && error.message ?
                <div>
                  <p className="text-red-500 text-sm">{error.message}</p>
                </div>
              : null}
              <Button type="submit" className="w-full" disabled={isSubmitting}>
                Send Reset Link
              </Button>
            </div>
          </CardContent>
        </Card>
      </form>
    </Form>
  )
}

export default ResetPasswordForm
