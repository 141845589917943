import ParkSearchResult from "./ParkSearchResult"

const ParkSearchResults = ({ searchResults, reserveSpot }) => {
  const parkSearchResults = searchResults.map((data, i) => (
    <ParkSearchResult data={data} key={i} reserveSpot={reserveSpot} />
  ))

  return (
    <div className="flex flex-col gap-4 bg-[#FFFFFFC4]">
      {parkSearchResults}
    </div>
  )
}

export default ParkSearchResults
