import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger
} from "@/components/ui/hover-card"
import { CircleHelp } from "lucide-react"

export interface InfoHoverProps {
  message: string
  iconSize?: number
  className?: string
}

export const InfoHover = ({ message, iconSize, className }: InfoHoverProps) => {
  return (
    <HoverCard openDelay={300}>
      <HoverCardTrigger asChild>
        <CircleHelp size={iconSize ?? 20} />
      </HoverCardTrigger>
      <HoverCardContent side="top" className={className}>
        {message}
      </HoverCardContent>
    </HoverCard>
  )
}
