import { TypographyH3 } from "@/components/ui/typography/h3"
import { GetNewText, NoneAvailableText } from "./const"
import { AccordionFormClasses } from "../../const"
import { CardRadioGroupItem } from "@/components/custom/Forms/CardRadioGroup"
import { useReservationForm } from "../../context/ReservationFormContext"
import { useState } from "react"
import { LoginDialog } from "@/components/custom/LoginDialog/LoginDialog"
import { Card } from "@/components/ui/card"
import Icon from "@/components/custom/Icon"
import { Label } from "@/components/ui/label"
import { Input } from "@/components/ui/input"

export const LoggedOutPacksForm = () => {
  const [loginDialogIsOpen, setLoginDialogIsOpen] = useState(false)
  const { facilityParkingPacks } = useReservationForm()

  if (!facilityParkingPacks || facilityParkingPacks.length === 0) {
    return (
      <section className={AccordionFormClasses}>
        <TypographyH3 className="text-muted-foreground text-md">
          {NoneAvailableText}
        </TypographyH3>
      </section>
    )
  }

  return (
    <section className={AccordionFormClasses}>
      <TypographyH3 className="text-md">
        {GetNewText}
      </TypographyH3>
      <div className="flex flex-col gap-1">
        <CardRadioButtons
          onClick={() => setLoginDialogIsOpen(true)}
          items={facilityParkingPacks.map((pack) => ({
            label: pack.name,
            description: pack.description,
            price: `$${pack.price}`,
            value: pack.id,
            iconName: pack.iconName
          }))}
        />
        <LoginDialog
          open={loginDialogIsOpen}
          onOpenChange={setLoginDialogIsOpen}
          onSuccess={() => {}}
        />
      </div>
    </section>
  )
}

export const CardRadioButtons = ({
  items,
  onClick
}: {
  items: CardRadioGroupItem[]
  onClick: () => void
}) => (
  <div>
    {items.map((item, i) => (
      <Card className="w-full p-4" onClick={() => onClick()} key={`item-${i}`}>
        <div className="flex justify-between gap-3 items-center">
          <div className="flex gap-3 items-center justify-start">
            {item.iconName && <Icon name={item.iconName} />}
            <div className="grid gap-1">
              <Label className="text-md font-semibold">{item.label}</Label>
              <p className="text-muted-foreground">{item.description}</p>
            </div>
          </div>
          <div className="flex gap-3 justify-between items-center">
            {item.price && (
              <p className="text-md font-semibold">{item.price}</p>
            )}
            <Input type="radio" value={item.value} />
          </div>
        </div>
      </Card>
    ))}
  </div>
)
