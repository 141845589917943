import ParkSearchForm from "@/components/custom/ParkSearchForm"
import MarketingSections from "@/components/custom/MarketingSections"
import { useSearchFlow } from "@/hooks/useSearchFlow"
import Loading from "@/components/custom/Loading"
import TypographyH1 from "@/components/ui/typography/h1"
import { TypographyH2 } from "@/components/ui/typography/h2"
import { TypographyP } from "@/components/ui/typography/p"
import { useLabels } from "@/context/LabelContext"

const Home = () => {
  const { getLabel, labels } = useLabels()
  const { facilities, facilitiesLoading, handleSearchQuery, searchQuery } =
    useSearchFlow()

  if (facilitiesLoading) {
    return <Loading />
  }

  return (
    <div className="w-full relative">
      <section className="relative h-fit py-60 px-3 flex items-center justify-center bg-center min-[650px]:bg-hero max-[650px]:bg-heroMobile bg-cover bg-no-repeat xl:bg-top">
        <div className="top-0 bottom-0 left-0 right-0 bg-gray-800 opacity-30 absolute" />
        <div className="w-full max-w-2xl z-10 p-9">
          <TypographyH1 className="text-white text-pretty lg:mb-2 text-center [text-shadow:_1px_1px_2px_#111729]">
            {getLabel(
              "landing.hero_title",
              "Secure Your Parking Spot With Ease"
            )}
          </TypographyH1>
          <TypographyP className="text-xl text-white [&:not(:first-child)]:mt-0 text-pretty mb-8 text-center [text-shadow:_1px_1px_2px_#111729]">
            {getLabel(
              "landing.hero_subtitle",
              "Avoid the hassle and reserve your parking spot in seconds with Spotsurfer's real-time reservation system."
            )}
          </TypographyP>

          <div className="mt-3">
            <ParkSearchForm
              searchQuery={searchQuery}
              handleSearchQuery={handleSearchQuery}
              facilities={facilities}
              showTitle={false}
              labelClassName="text-white"
              isHomePage={true}
            />
          </div>
        </div>
      </section>
      <div className="h-2 bg-accent-red w-full" />
      <div className="h-4 bg-accent-orange w-full" />
      <div className="h-6 bg-accent-yellow w-full" />
      <MarketingSections />
    </div>
  )
}

export default Home
