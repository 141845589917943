import { Fragment } from "react"
import { Link, useNavigate } from "react-router-dom"
import { Menu, LogOut } from "lucide-react"
import {
  Sheet,
  SheetContent,
  SheetClose,
  SheetTitle,
  SheetDescription,
  SheetTrigger
} from "@/components/ui/sheet"
import { Button } from "@/components/ui/button"
import spotSurferIcon from "@/assets/pngs/spotSurfer.png"
import Icon from "@/components/custom/Icon"
import { useAuth } from "@/context/AuthContext"

const linkClassName = "mx-[-0.65rem] flex items-center gap-4 rounded-xl px-3 py-2 text-white hover:underline"

const SideNavigation = ({ menuItems }) => {
  const { user, logout } = useAuth()
  const navigate = useNavigate()

  const handleLogout = async () => {
    await logout()
    navigate("/")
  }

  return (
    <Sheet>
      <SheetTrigger asChild>
        <Button
          size="icon"
          className="shrink-0 h-auto lg:hidden text-white bg-transparent hover:bg-transparent">
          <Menu className="h-7 w-7" />
          <span className="sr-only">Toggle navigation menu</span>
        </Button>
      </SheetTrigger>
      <SheetContent side="left" className="flex flex-col w-full bg-primary border-none p-0">
        <SheetTitle className="sr-only">Navigation menu</SheetTitle>
        <SheetDescription className="sr-only">
          Navigation menu for mobile devices
        </SheetDescription>
        <nav className="grid gap-2 text-lg font-medium p-4">
          <Link to="/">
            <img className="w-12" src={spotSurferIcon} />
            {/* SPOTSURFER */}
          </Link>
          {
            menuItems.map(({ name, icon, path, canDisplay, divider }, i) =>
              canDisplay ?
                <Fragment key={i}>
                  <SheetClose asChild key={i}>
                    <Link
                      to={path}
                      className={linkClassName}>
                      <Icon name={icon} className="h-5 w-5 text-white" />
                      {name}
                    </Link>
                  </SheetClose>
                  {divider ? <hr className="my-2" /> : null}
                </Fragment>
                : null
            )
          }
          {
            user && (
              <>
                <hr className="my-2" />
                <SheetClose asChild>
                  <Link onClick={handleLogout} className={linkClassName} to="">
                    <LogOut className="h-5 w-5" />
                    Log out
                  </Link>
                </SheetClose>
              </>
            )
          }
        </nav>
      </SheetContent>
    </Sheet>
  )
}

export default SideNavigation