import {
  api,
  AuthHeader,
  keysToSnakeCase,
  StandardApiResponse,
  StandardErrorHandler,
  StandardPushHandler
} from "../const"

export const UploadToFacilityPath = (id?: string) => `/v1/facilities/${id}/upload/`

export interface UploadToFacilityData {
  name: string
  file: File
  isThumbnail?: boolean
}

export const UploadToFacility = async (
  accessToken: string,
  id?: string,
  data?: UploadToFacilityData
): Promise<StandardApiResponse> =>
  api
    .postForm(
      UploadToFacilityPath(id),
      keysToSnakeCase(data),
      AuthHeader(accessToken)
    )
    .then(StandardPushHandler())
    .catch(StandardErrorHandler)

export const UploadMultipleToFacility = async (
  accessToken: string,
  facilityId: string,
  data: UploadToFacilityData[]
): Promise<StandardApiResponse> => {
  const promises: Promise<StandardApiResponse>[] = []
  let uploadedCount = 0;
  
  data.map((image) => {
    if (image.file) {
      promises.push(
        UploadToFacility(
          accessToken,
          facilityId,
          image as UploadToFacilityData
        ).then((result) => {
          if (result.isError) {
            return {
              isError: true,
              error: `Error on uploading image "${image.name}" to facility:\n${result.error}`
            }
          }
          uploadedCount++;
          return {
            isError: false
          }
        })
      )
    }
  })
  return Promise.all(promises).then(() => ({
    isError: false,
    uploadedCount
  }))
}
