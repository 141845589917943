import { UpdateSpot } from "@/api/spots/UpdateSpot"
import { Spot } from "@/api/types/internal/Spot"
import { EditAmenitiesTab } from "@/components/custom/Forms/UpdateFormGenerics/EditAmenitiesTab"
import { UpdateFormTabsWrapperChildren } from "@/components/custom/Forms/UpdateFormGenerics/UpdateFormTabWrapper"
import { useAuth } from "@/context/AuthContext"
import { amenitiesToIntArray, IAmenitiesSchema } from "@/hooks/useAmenitiesForm"
import { useCallback } from "react"
import { useParams } from "react-router-dom"

export const EditSpotAmenities = ({
  data,
  ...tabProps
}: UpdateFormTabsWrapperChildren<Spot, IAmenitiesSchema>) => {
  const { accessToken } = useAuth()
  const { spotId } = useParams()

  const submitHandler = useCallback(
    (result: IAmenitiesSchema) => {
      if (spotId) {
        console.log({
          ...data,
          facility: data.facility.id,
          amenities: amenitiesToIntArray(result)
        })
        return UpdateSpot(accessToken, spotId, {
          ...data,
          facility: data.facility.id,
          amenities: amenitiesToIntArray(result)
        })
      }
    },
    [spotId, data, accessToken]
  )

  return (
    <EditAmenitiesTab data={data} submitHandler={submitHandler} {...tabProps} />
  )
}
