import { AvailabilityBackendState } from "@/constants/AvailabilitySchema"
import {
  api,
  AuthHeader,
  keysToSnakeCase,
  StandardErrorHandler,
  StandardPushHandler
} from "../const"

export const UpdateSpotAvailabilityPath = (spotId: string) =>
  `/v1/spots/${spotId}/availability/`

export const UpdateSpotAvailability = (
  accessToken: string,
  id: string,
  data: AvailabilityBackendState
) =>
  api
    .put(
      UpdateSpotAvailabilityPath(id),
      keysToSnakeCase(data),
      AuthHeader(accessToken)
    )
    .then(StandardPushHandler())
    .catch(StandardErrorHandler)
