import {
  UpdateFormTabsWrapperChildren,
  UpdateFormTabWrapper
} from "@/components/custom/Forms/UpdateFormGenerics/UpdateFormTabWrapper"
import { ISpotDetailsSchema, SpotDetailsSchema } from "../../const"
import { useAuth } from "@/context/AuthContext"
import { useParams } from "react-router-dom"
import { useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { useCallback } from "react"
import { UpdateLot } from "@/api/lots/UpdateLot"
import _ from "lodash"
import { TypographyH3 } from "@/components/ui/typography/h3"
import {
  FormCheckbox,
  FormNumberInput,
  FormTextArea,
  FormTextInput
} from "@/components/custom/Forms/FormInputs"

export const EditSpotDetails = ({
  data,
  ...tabProps
}: UpdateFormTabsWrapperChildren<UnassignedZone, ISpotDetailsSchema>) => {
  const { accessToken } = useAuth()
  const { spotId } = useParams()
  const form = useForm<ISpotDetailsSchema>({
    resolver: zodResolver(SpotDetailsSchema),
    values: data
  })

  const submitHandler = useCallback(
    (result: ISpotDetailsSchema) => {
      if (spotId && accessToken) {
        return UpdateLot(
          accessToken,
          spotId,
          _.defaultsDeep({ facility: data.facility.id }, result, data)
        )
      }
    },
    [accessToken, spotId, data]
  )

  return (
    <UpdateFormTabWrapper
      {...tabProps}
      form={form}
      onDiscard={() => form.reset()}
      onSubmit={submitHandler}
      Render={({ control }) => (
        <div className="flex flex-col gap-6">
          <div className="flex flex-col gap-6">
            <TypographyH3 className="text-lg font-semibold">
              Details
            </TypographyH3>
            <FormTextInput control={control} name="name" label="Name *" />
            <FormTextArea
              control={control}
              name="description"
              label="Description"
            />
            <div className="grid gap-3 max-w-48">
              <FormNumberInput
                control={control}
                name="floor"
                label="Floor *"
                step="1"
              />
              <FormNumberInput
                control={control}
                name="maxHeightSupported"
                label="Max Height Supported *"
                step="0.01"
              />
              <FormCheckbox
                control={control}
                name="hasSpaceForBiggerVehicles"
                label="Space for bigger vehicles?"
              />
            </div>
            <FormTextArea
              control={control}
              name="parkerInstructions"
              label="Parker Instructions"
            />
          </div>
        </div>
      )}
    />
  )
}
