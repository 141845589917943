import { useMemo } from "react"
import {
  lastMonthsReservations,
  lastWeeksReservations,
  reservationNewerThanMonth,
  reservationNewerThanWeek,
  reservationProfitsReducer
} from "../const"

import type { Reservation } from "@/api/types/internal/Reservation"

export const useProfits = (allReservations?: Reservation[]) => {
  const weeklyProfit = useMemo(() => {
    if (!allReservations) {
      return 0
    }
    return allReservations
      ?.filter(reservationNewerThanWeek)
      .reduce(reservationProfitsReducer, 0)
  }, [allReservations])

  const lastWeekProfit = useMemo(() => {
    if (!allReservations) {
      return 0
    }
    return allReservations
      .filter(lastWeeksReservations)
      .reduce(reservationProfitsReducer, 0)
  }, [allReservations])

  const monthlyProfit = useMemo(() => {
    if (!allReservations) {
      return 0
    }
    return allReservations
      ?.filter(reservationNewerThanMonth)
      .reduce(reservationProfitsReducer, 0)
  }, [allReservations])

  const lastMonthProfit = useMemo(() => {
    if (!allReservations) {
      return 0
    }
    return allReservations
      .filter(lastMonthsReservations)
      .reduce(reservationProfitsReducer, 0)
  }, [allReservations])

  // console.log(weeklyProfit, lastWeekProfit)

  const weekChange = useMemo(() => {
    return (
      (weeklyProfit - lastWeekProfit) / ((lastWeekProfit + weeklyProfit) / 2)
    )
  }, [lastWeekProfit, weeklyProfit])

  const monthChange = useMemo(() => {
    return (
      (monthlyProfit - lastMonthProfit) /
      ((lastMonthProfit + monthlyProfit) / 2)
    )
  }, [lastMonthProfit, weeklyProfit])

  return {
    weeklyProfit,
    monthlyProfit,
    weekChange,
    monthChange
  }
}
