import { Card } from "@/components/ui/card"
import TypographyH1 from "@/components/ui/typography/h1"

export const ComingSoon = () => (
  <div className="flex flex-col gap-6 mx-auto max-w-4xl">
    <Card className="p-6 md:py-12">
      <p className="">
        Season packs will be coming soon to SpotSurfer. Check this page for
        updates in the future!
      </p>
    </Card>
  </div>
)
