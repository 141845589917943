import {
  api,
  AuthHeader,
  StandardApiResponse,
  StandardErrorHandler,
  StandardGetHandler
} from "../const"

export const GetParkerPath = "/v1/parker/"
export const GetParkerQueryKey = "parker"

export interface GetParkerResponse extends StandardApiResponse {
  parker?: Parker
}

export const GetParker = (accessToken: string): Promise<GetParkerResponse> =>
  api
    .get(GetParkerPath, AuthHeader(accessToken))
    .then(StandardGetHandler("parker"))
    .catch(StandardErrorHandler)
