import {
  api,
  AuthHeader,
  StandardErrorHandler,
  StandardPushHandler
} from "../const"

export const DeleteFacilityMediaPath = (id?: string) =>
  `/v1/facilitymedia/${id}/`

export const DeleteFacilityMedia = (accessToken: string, id: string) =>
  api
    .delete(DeleteFacilityMediaPath(id), AuthHeader(accessToken))
    .then(StandardPushHandler())
    .catch(StandardErrorHandler)
