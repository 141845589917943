import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from "@/components/ui/form"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "@/components/ui/select"
import TypographyH1 from "@/components/ui/typography/h1"
import { useForm } from "react-hook-form"
import { optional, z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import { requiredString } from "@/constants/formSchema"
import { Input } from "@/components/ui/input"
import { Button } from "@/components/ui/button"
import { Textarea } from "@/components/ui/textarea"
import { ownerInterest } from "@/api/post"
import { USStates } from "@/constants/constants"
import { useNavigate } from "react-router-dom"

const OwnerSignupPage = () => {
  const navigate = useNavigate()

  const ownerInterestSchema = z.object({
    firstName: requiredString,
    lastName: requiredString,
    address1: requiredString,
    city: requiredString,
    state: z.string().optional(),
    zipCode: requiredString,
    email: requiredString,
    phone: requiredString,
    propertyDescription: requiredString,
  })

  const defaultValues = {
    firstName: "",
    lastName: "",
    address1: "",
    city: "",
    state: "CO",
    zipCode: "",
    email: "",
    phone: "",
    propertyDescription: ""
  }

  const onSubmit = async (formData) => {
    const {
      firstName,
      lastName,
      phone,
      email,
      propertyDescription,
      address1,
      city,
      state,
      zipCode,
    } = formData

    const payload = {
      firstName,
      lastName,
      email,
      phone,
      propertyDescription,
      address: {
        address1,
        city,
        state,
        zipCode
      },
    }

    try {
      await ownerInterest(payload)
      navigate("/owner-signup-confirmation")
    } catch (error) {
      console.log('❌ Unable to submit PPO interest form', error)
    }
  }

  const { reset, control, handleSubmit, ...form } = useForm({
    resolver: zodResolver(ownerInterestSchema),
    defaultValues
  })

  return (
    <div className="flex flex-col p-2 lg:py-10 mx-auto max-w-7xl">
      <div className="mb-4">
        <TypographyH1>Become a Spotsurfer Partner</TypographyH1>
        <p className="mb-4">
          Our company, Spotsurfer, has developed a cutting-edge parking management system specifically designed to address the unique challenges faced by mountain resorts.  What sets Spotsurfer apart is our deep understanding of the mountain resort ecosystem. We've successfully partnered with the Town of Vail, local businesses and Vail Resorts, demonstrating our ability to integrate with local communities and industry leaders. Our solution transforms parking – traditionally a pain point – into a seamless part of the guest experience, while also unlocking new operational efficiencies and revenue streams for resorts, businesses, and homeowners.
        </p>
        <ol className="mb-4 list-decimal">
          Key benefits of the Spotsurfer platform include:
          <li className="pl-2 ml-8">Elevating the guest experience from the moment of arrival</li>
          <li className="pl-2 ml-8">Optimizing parking operations and increasing non-ski revenue</li>
          <li className="pl-2 ml-8">Providing valuable data insights for strategic decision-making</li>
          <li className="pl-2 ml-8">Aligning with a commitment to community and sustainability</li>
        </ol>
        <p>
          If you are interested in becoming a Spotsurfer parking partner, please fill out the information below and we will review your property details and set up a call to answer any questions you may have.
        </p>
      </div>
      <Form {...form}>
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-8">
          <div className="flex gap-6">
            <FormField
              control={control}
              name="firstName"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel>First Name <span className="inline-block align-text-top">*</span></FormLabel>
                  <FormControl>
                    <Input placeholder="Your First Name" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={control}
              name="lastName"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel>Last Name <span className="inline-block align-text-top">*</span></FormLabel>
                  <FormControl>
                    <Input placeholder="Your Last Name" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <FormField
            control={control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Email Address: <span className="inline-block align-text-top">*</span></FormLabel>
                <FormControl>
                  <Input placeholder="Email Address" {...field} />
                </FormControl>
                <FormMessage></FormMessage>
              </FormItem>
            )}
          />
          <FormField
            control={control}
            name="phone"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Phone: <span className="inline-block align-text-top">*</span></FormLabel>
                <FormControl>
                  <Input type="tel" placeholder="Phone" {...field} min="10" max="10" />
                </FormControl>
                <FormMessage></FormMessage>
              </FormItem>
            )}
          />
          <FormField
            control={control}
            name="address1"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Property Address <span className="inline-block align-text-top">*</span></FormLabel>
                <FormControl>
                  <Input placeholder="Property Address" {...field} />
                </FormControl>
                <FormMessage></FormMessage>
              </FormItem>
            )}
          />
          <div className="flex justify-start gap-6">
            <FormField
              control={control}
              name="state"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel>State</FormLabel>
                  <FormControl>
                    <Select
                      onValueChange={field.onChange}
                      value={field.value}>
                      <SelectTrigger>
                        <SelectValue placeholder={USStates[field.value].full}>
                          {USStates[field.value].full}
                        </SelectValue>
                      </SelectTrigger>
                      <SelectContent>
                        {Object.keys(USStates).map((state) => (
                          <SelectItem
                            key={USStates[state].abbreviation}
                            value={USStates[state].abbreviation}>
                            {USStates[state].full}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={control}
              name="city"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel>City <span className="inline-block align-text-top">*</span></FormLabel>
                  <FormControl>
                    <Input placeholder="City" {...field} />
                  </FormControl>
                  <FormMessage></FormMessage>
                </FormItem>
              )}
            />
            <FormField
              control={control}
              name="zipCode"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel>Zipcode <span className="inline-block align-text-top">*</span></FormLabel>
                  <FormControl>
                    <Input placeholder="Zipcode" {...field} />
                  </FormControl>
                  <FormMessage></FormMessage>
                </FormItem>
              )}
            />
          </div>
          <FormField
            control={control}
            name="propertyDescription"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Property Description <span className="inline-block align-text-top">*</span></FormLabel>
                <FormControl>
                  <Textarea
                    type="text"
                    placeholder="Your Property Description"
                    required
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <Button className="mt-6">Submit</Button>
        </form>
      </Form>
    </div>
  )
}

export default OwnerSignupPage
