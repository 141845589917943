import {
  api,
  AuthHeader,
  StandardErrorHandler,
  StandardPushHandler
} from "../const"

export const DeleteFacilityPath = (id: string) => `v1/facilities/${id}`

export interface DeleteFacilityResponse {
  isError?: boolean
  error?: string
}

export const DeleteFacility = async (
  accessToken: string,
  id: string
): Promise<DeleteFacilityResponse> =>
  api
    .delete(DeleteFacilityPath(id), AuthHeader(accessToken))
    .then(StandardPushHandler())
    .catch(StandardErrorHandler)
