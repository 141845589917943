import {
  api,
  AuthHeader,
  keysToSnakeCase,
  StandardApiResponse,
  StandardErrorHandler,
  StandardPushHandler
} from "../const"

export const UploadToSpotPath = (id?: string) => `/v1/spots/${id}/upload/`

export interface UploadToSpotData {
  name: string
  file: File
  isThumbnail?: boolean
}

export const UploadMediaToSpot = async (
  accessToken: string,
  id?: string,
  data?: UploadToSpotData
): Promise<StandardApiResponse> =>
  api
    .postForm(
      UploadToSpotPath(id),
      keysToSnakeCase(data),
      AuthHeader(accessToken)
    )
    .then(StandardPushHandler())
    .catch(StandardErrorHandler)

export const UploadMultipleMediaToSpot = async (
  accessToken: string,
  spotId: string,
  data: UploadToSpotData[]
): Promise<StandardApiResponse> => {
  const promises: Promise<StandardApiResponse>[] = []
  data.map((image) => {
    if (image.file) {
      promises.push(
        UploadMediaToSpot(accessToken, spotId, image as UploadToSpotData).then(
          (result) => {
            if (result.isError) {
              return {
                isError: true,
                error: `Error on uploading image "${image.name}" to spot:\n${result.error}`
              }
            }
            return {
              isError: false
            }
          }
        )
      )
    }
  })
  return Promise.all(promises).then(() => ({
    isError: false
  }))
}
