import { ChartContainer } from "@/components/ui/chart"
import { Skeleton } from "@/components/ui/skeleton"
import {
  add,
  areIntervalsOverlapping,
  eachHourOfInterval,
  eachMinuteOfInterval,
  endOfToday,
  format,
  startOfToday,
  sub
} from "date-fns"
import { useMemo } from "react"
import { Area, AreaChart, XAxis } from "recharts"

export interface SpotReservationsAreaChartProps {
  reservations?: Reservation[]
  period?: "DAILY"
  precision?: "MINUTELY"
}

const chartConfig = {
  count: {
    label: "Occupied",
    color: "hsl(var(--secondary-foreground))"
  }
}

export const SpotReservationsTimeChart = ({
  reservations
}: SpotReservationsAreaChartProps) => {
  const chartData = useMemo(
    () =>
      reservations ?
        reservationsToSpotHours(filterReservationsByDay(reservations))
      : [],
    [reservations]
  )

  const xTicks = useMemo(
    () =>
      eachHourOfInterval({ start: startOfToday(), end: endOfToday() }).map(
        (date) => date.getTime()
      ),
    []
  )

  if (reservations === undefined) {
    return <Skeleton className="rounded-lg min-h-16 max-h-24 h-full w-full" />
  }

  return (
    <ChartContainer config={chartConfig} className="min-h-16 max-h-24 w-full">
      <AreaChart accessibilityLayer data={chartData}>
        <Area dataKey="count" type="bump" />
        <XAxis
          ticks={xTicks}
          domain={[startOfToday().getTime(), endOfToday().getTime()]}
          scale="time"
          type="number"
          dataKey="time"
          tickFormatter={(tick) => format(new Date(tick), "hh:mm aaa")}
        />
      </AreaChart>
    </ChartContainer>
  )
}

const reservationsToSpotHours = (
  reservations: Reservation[]
): { time: number; count: number }[] =>
  eachMinuteOfInterval({
    start: startOfToday(),
    end: endOfToday()
  }).map((time) => ({
    time: time.getTime(),
    count:
      reservations.filter((reservation) =>
        reservationIsBetweenTimes(
          reservation,
          sub(time, { seconds: 30 }),
          add(time, { seconds: 30 })
        )
      ).length ?? null
  }))

const filterReservationsByDay = (reservations: Reservation[]): Reservation[] =>
  reservations.filter((reservation) =>
    reservationIsBetweenTimes(reservation, startOfToday(), endOfToday())
  )

export const reservationIsBetweenTimes = (
  reservation: Reservation,
  start: Date,
  end: Date
) => {
  const reservationStartDate = new Date(reservation.checkinDatetime)
  const reservationEndDate = new Date(reservation.checkoutDatetime)

  return areIntervalsOverlapping(
    {
      start: reservationStartDate,
      end: reservationEndDate
    },
    {
      start,
      end
    }
  )
}
