import { Link } from "react-router-dom"
import {
  Card,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle
} from "@/components/ui/card"
import { Button } from "@/components/ui/button"

const ReservationFailurePage = () => {
  return (
    <div className="px-10 py-6">
      <Card className="mt-4 mx-auto max-w-sm">
        <CardHeader>
          <CardTitle>Reservation not successful</CardTitle>
          <CardDescription>
            Please click the button below to search for a spot again
          </CardDescription>
        </CardHeader>
        <CardFooter>
          <Link to="/">
            <Button>Go to Dashboard</Button>
          </Link>
        </CardFooter>
      </Card>
    </div>
  )
}

export default ReservationFailurePage
