import { useState, useEffect } from "react"
import { Link, useSearchParams } from "react-router-dom"
import LoginForm from "@/components/custom/LoginForm"
import { TypographyP } from "@/components/ui/typography/p"
import SimpleDialog from "@/components/custom/SimpleDialog"
import { TypographyH3 } from "@/components/ui/typography/h3"
import { TypographyH4 } from "@/components/ui/typography/h4"
import { Button } from "@/components/ui/button"

const LoginPage = () => {
  const [searchParams] = useSearchParams()
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  useEffect(() => {
    if (!searchParams.size) return

    const query = {}
    searchParams.forEach((value, key) => {
      query[key] = value
    })

    query.banner && setIsDialogOpen(true)
  }, [searchParams])

  const handleDialogOpen = () => setIsDialogOpen((prev) => !prev)

  const emailLink = (
    <a className="hover:text-accent-green" href="mailto:info@spotsurfer.com">
      info@spotsurfer.com
    </a>
  )

  const dialogContent = (
    <div className="">
      <TypographyH3 className="text-lg font-bold -mb-4">
        Thanks for being a loyal Spotsurfer customer
      </TypographyH3>
      <TypographyP>
        While we work on a new iOS/Android app, your current Spotsurfer app is
        no longer supported, and should be deleted from your phone.
      </TypographyP>
      <TypographyH4 className="mt-4 font-semibold text-base">
        Here are the next steps:
      </TypographyH4>
      <div className="flex flex-col">
        <TypographyP className="">
          Use your previous email to log in. You will need to create a NEW
          password
        </TypographyP>
        <Link to="/reset-password" className="mt-2">
          <Button>Reset Password</Button>
        </Link>
      </div>
      <div className="flex flex-col">
        <TypographyP className="mt-4">
          Or you may simply create a new account
        </TypographyP>
        <Link to="/signup" className="mt-2">
          <Button>Sign Up</Button>
        </Link>
      </div>

      <TypographyP className="-mb-4">
        After you register, please check your spam / junk folder and mark{" "}
        {emailLink} as a known address.
      </TypographyP>
      <TypographyP className="-mb-4">
        We are excited to provide you with more parking options and an even
        better online experience!
      </TypographyP>
      <TypographyP className="-mb-4">
        As a loyal customer receive a FREE day of parking, valid for a
        reservation made any time in the next 6 months. Use code FREE100 at
        checkout.
      </TypographyP>
      <TypographyP className="-mb-2">
        As always you can reach out to us at {emailLink} if you need help.
      </TypographyP>
      <TypographyP>The Spotsurfer Team</TypographyP>
      <TypographyP className="[&:not(:first-child)]:mt-0">
        <a
          href="https://www.spotsurfer.com"
          target="_blank"
          rel="noopener noreferrer">
          spotsurfer.com
        </a>
      </TypographyP>
      <TypographyP className="[&:not(:first-child)]:mt-0">
        {emailLink}
      </TypographyP>
      <TypographyP className="[&:not(:first-child)]:mt-0 mb-0 pb-0">
        (970) 389-4230
      </TypographyP>
    </div>
  )

  return (
    <>
      <div className="flex flex-row justify-center items-center p-2 drop-shadow-md bg-accent-green">
        <TypographyP className="text-lg text-center text-pretty text-white">
          If you downloaded the Spotsurfer iOS/Android app prior to June 6,
          2024,{" "}
          <SimpleDialog
            isDialogOpen={isDialogOpen}
            setIsDialogOpen={setIsDialogOpen}
            dialogTrigger={
              <Link
                onClick={handleDialogOpen}
                className="text-accent-yellow font-semibold hover:text-muted-yellow text-lg transition-colors">
                CLICK HERE
              </Link>
            }>
            {dialogContent}
          </SimpleDialog>{" "}
          to get set up on the NEW desktop/mobile reservation platform.
        </TypographyP>
      </div>

      <div className="px-4 lg:px-10 py-6">
        <LoginForm />
      </div>
    </>
  )
}

export default LoginPage
