import { Button, ButtonProps } from "@/components/ui/button"
import { cn } from "@/lib/utils"
import { AlertCircle, Check, Loader2 } from "lucide-react"
import { useMemo } from "react"

export interface SmartSubmitButtonProps extends ButtonProps {
  className?: string
  isSubmitting?: boolean
  isSuccess?: boolean
  isFailure?: boolean
  disabled?: boolean
  submitText: string
  onSubmit?: () => void
}

export const SmartSubmitButton = ({
  className,
  disabled,
  submitText,
  isFailure,
  isSubmitting,
  isSuccess,
  onSubmit,
  ...props
}: SmartSubmitButtonProps) => {
  const icon = useMemo(() => {
    if (isSubmitting) return <Loader2 className="mr-2 h-4 w-4 animate-spin" />
    if (isSuccess) return <Check className="mr-2 h-4 w-4" />
    if (isFailure) return <AlertCircle className="mr-2 h-4 w-4" />
  }, [isSubmitting, isSuccess, isFailure])

  const colorClass = useMemo(() => {
    if (isSuccess) return "bg-accent-green"
    if (isFailure) return "bg-accent-red"
  }, [isSubmitting, isSuccess, isFailure])

  return (
    <Button
      className={cn("duration-500", colorClass, className)}
      type="submit"
      onSubmit={() => onSubmit?.()}
      disabled={disabled || isSubmitting || isSuccess || isFailure}
      {...props}>
      {icon} {submitText}
    </Button>
  )
}
