import {
  api,
  AuthHeader,
  keysToSnakeCase,
  StandardApiResponse,
  StandardErrorHandler,
  StandardPushHandler
} from "../const"

export const PutFacilityPath = (id: string) => `/v1/facilities/${id}/`

export const UpdateFacility = async (
  accessToken: string,
  id: string,
  data: Facility<string, number, number>
): Promise<StandardApiResponse> =>
  api
    .put(PutFacilityPath(id), keysToSnakeCase(data), AuthHeader(accessToken))
    .then(StandardPushHandler())
    .catch(StandardErrorHandler)
