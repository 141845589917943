import { Control, FieldValues, UseFormReturn } from "react-hook-form"
import { UpdateFormHeaderChildren } from "./UpdateFormHeader"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"
import { STANDARD_TAB_LIST, STANDARD_TAB_TRIGGER } from "../Styles"
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle
} from "@/components/ui/card"
import { Button } from "@/components/ui/button"
import { Form } from "@/components/ui/form"
import { TabsContentProps } from "@radix-ui/react-tabs"
import { useCallback, useEffect, useMemo, useState } from "react"
import _ from "lodash"
import { SmartSubmitButton } from "../SmartSubmitButton"
import { StandardApiResponse } from "@/api/const"

export interface UpdateFormTabsWrapperChildren<
  DataType,
  FormType extends FieldValues
> {
  title?: string
  subtitle?: string
  tabId: string
  data: DataType
  onSubmitted?: UpdateFormTabsWrapperProps<DataType, FormType>["onSubmitted"]
  onApiError?: UpdateFormTabsWrapperProps<DataType, FormType>["onApiError"]
  clearApiError?: UpdateFormTabsWrapperProps<
    DataType,
    FormType
  >["clearApiError"]
}

export interface UpdateFormTabsWrapperTab<
  DataType,
  FormType extends FieldValues
> {
  tabName: string
  tabId: string
  title?: UpdateFormTabWrapperProps<DataType, FormType>["title"]
  subtitle?: UpdateFormTabWrapperProps<DataType, FormType>["subtitle"]
  Render: (
    props: UpdateFormTabsWrapperChildren<DataType, FormType>
  ) => React.ReactNode
}

export interface UpdateFormTabsWrapperProps<
  DataType,
  FormType extends FieldValues
> {
  onSubmitted?: UpdateFormHeaderChildren<FormType>["onSubmit"]
  onApiError?: UpdateFormHeaderChildren<FormType>["onApiError"]
  clearApiError?: UpdateFormHeaderChildren<FormType>["clearApiError"]
  data: DataType
  tabs: UpdateFormTabsWrapperTab<DataType, FormType>[]
}

export const UpdateFormTabsWrapper = <DataType, FormType extends FieldValues>({
  tabs,
  data,
  ...propsForChildren
}: UpdateFormTabsWrapperProps<DataType, FormType>) => {
  return (
    <Tabs defaultValue={tabs[0].tabId}>
      <TabsList className={STANDARD_TAB_LIST(tabs.length)}>
        {tabs.map((tab) => (
          <TabsTrigger
            key={tab.tabId}
            className={STANDARD_TAB_TRIGGER}
            value={tab.tabId}>
            {tab.tabName}
          </TabsTrigger>
        ))}
      </TabsList>
      {tabs.map(({ Render, ...tab }) => {
        return (
          Render &&
          data && (
            <Render
              key={tab.tabId}
              data={data}
              {...propsForChildren}
              {...tab}
            />
          )
        )
      })}
    </Tabs>
  )
}

export interface UpdateFormTabWrapperProps<
  DataType,
  FormType extends FieldValues
> {
  tabId: TabsContentProps["value"]
  title?: string
  subtitle?: string
  form: UseFormReturn<FormType>
  onSubmit?: (data: FormType) => Promise<StandardApiResponse> | undefined
  onSubmitted?: UpdateFormTabsWrapperProps<DataType, FormType>["onSubmitted"]
  onDiscard?: () => void
  onApiError?: UpdateFormHeaderChildren<FormType>["onApiError"]
  clearApiError?: UpdateFormHeaderChildren<FormType>["clearApiError"]
  Render: (props: UpdateFormTabWrapperChild<FormType>) => React.ReactNode
}

export interface UpdateFormTabWrapperChild<FormType extends FieldValues> {
  control: Control<FormType>
}

export const UpdateFormTabWrapper = <DataType, FormType extends FieldValues>({
  Render,
  onApiError,
  clearApiError,
  form,
  onSubmit,
  onSubmitted,
  onDiscard,
  tabId,
  subtitle,
  title
}: UpdateFormTabWrapperProps<DataType, FormType>) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [valueAtSubmit, setValueAtSubmit] = useState<FormType | undefined>()
  const [isFailure, setIsFailure] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)

  const handleSubmitInternal = useCallback((data: FormType) => {
    clearApiError?.()
    setIsSubmitting(true)
    setValueAtSubmit(form.getValues())
    return onSubmit ?
        onSubmit(data)?.then((result) => {
          setIsSubmitting(false)
          if (result?.isError) {
            setIsFailure(true)
            onApiError?.(result.error)
          } else {
            setIsSuccess(true)
            onSubmitted?.()
            form.reset(data)
          }
          return result
        })
      : setIsSubmitting(false)
  }, [])

  useEffect(() => {
    if ((isFailure || isSuccess) && !_.isEqual(form.getValues(), valueAtSubmit)) {
      setIsFailure(false)
      setIsSuccess(false)
    }
  }, [form.watch()])

  return (
    <TabsContent value={tabId}>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(handleSubmitInternal, console.error)}>
          <Card>
            <CardHeader className="flex flex-col md:flex-row justify-between gap-6 items-start">
              <div className="grid gap-3">
                {title && <CardTitle>{title}</CardTitle>}
                {subtitle && <CardDescription>{subtitle}</CardDescription>}
              </div>
              <div className="flex gap-3 content-center">
                <Button
                  type="reset"
                  onClick={() => onDiscard?.()}
                  variant="outline"
                  size="sm">
                  Discard
                </Button>
                <SmartSubmitButton
                  size="sm"
                  submitText="Save Changes"
                  disabled={!form.formState.isDirty || !form.formState.isValid}
                  isFailure={isFailure}
                  isSubmitting={isSubmitting}
                  isSuccess={isSuccess}
                />
              </div>
            </CardHeader>
            <CardContent>{Render({ control: form.control })}</CardContent>
          </Card>
        </form>
      </Form>
    </TabsContent>
  )
}
