import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious
} from "@/components/ui/carousel"
import { cn } from "@/lib/utils"

const ImageCarousel = ({ images, className = "", itemClassName = "", arrowClasses = "", overflowOverride = ""}) => {
  if (!images.length) return null

  const imageCarousel = images.map(({ url, name }, i) => (
    <CarouselItem className="w-full" key={i} title={name}>
      <img
        src={url}
        className={cn(
          "object-cover object-center w-full no-repeat",
          itemClassName
        )}
      />
    </CarouselItem>
  ))

  return (
    <Carousel className={cn("w-full", className)}>
      <CarouselContent overflowOverride={overflowOverride}>{imageCarousel}</CarouselContent>
      <CarouselPrevious className={cn("absolute left-[4px]", arrowClasses)} />
      <CarouselNext className={cn("absolute right-[4px]", arrowClasses)} />
    </Carousel>
  )
}

export default ImageCarousel
