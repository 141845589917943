import { Card } from "@/components/ui/card"
import { Separator } from "@/components/ui/separator"
import { Skeleton } from "@/components/ui/skeleton"

export const SeasonPacksSkeleton = () => (
  <Card className="shadow-xl p-6">
    <div className="flex flex-col gap-6 md:grid md:grid md:grid-cols-3 lg:grid-cols-4">
      <div className="grid grid-cols-[1fr_auto] gap-6">
        <Skeleton className="h-80 w-70" />
        <Separator orientation="vertical" />
      </div>
      <Skeleton className="h-80 w-70" />
      <Skeleton className="h-80 w-70" />
      <Skeleton className="h-80 w-70" />
    </div>
  </Card>
)
