import {
  api,
  AuthHeader,
  keysToSnakeCase,
  StandardErrorHandler,
  StandardPushHandler
} from "../const"
import { Spot } from "../types/internal/Spot"

export const PostSpotPath = (facilityId: string) =>
  `/v1/facilities/${facilityId}/spot/`

export interface CreateSpotResponse {
  spot?: Spot<number, string>
  isError?: boolean
  error?: string
}

export const CreateSpot = (
  accessToken: string,
  facilityId: string,
  data: Spot<number, string>
): Promise<CreateSpotResponse> =>
  api
    .post(
      PostSpotPath(facilityId),
      keysToSnakeCase(data),
      AuthHeader(accessToken)
    )
    .then(StandardPushHandler("spot"))
    .catch(StandardErrorHandler)
