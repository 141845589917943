import { z } from "zod"

export const AddOnsSchema = z
  .record(z.string().uuid(), z.coerce.boolean())
  .optional()

export type AddOnsForm = z.infer<typeof AddOnsSchema>

export const addOnsToIdArray = (addOns: AddOnsForm) =>
  Object.keys(addOns ?? {}).reduce(
    (prev, current) => (addOns?.[current] ? prev.concat([current]) : prev),
    [] as string[]
  )
