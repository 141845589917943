import { cn } from "@/lib/utils"
import { Control, UseFormSetValue, UseFormWatch } from "react-hook-form"
import { AvailabilityCardHeader } from "./AvailabilityCardHeader"
import { AvailabilityForm, DaysOfWeek } from "@/constants/AvailabilitySchema"
import {
  FormDateInput,
  FormNumberInput,
  FormSelectInput,
  FormTimeInput
} from "../FormInputs"

export interface AvailabilityCardProps {
  className?: string
  onDelete?: () => void
  control: Control<AvailabilityForm>
  watch: UseFormWatch<AvailabilityForm>
  setValue: UseFormSetValue<AvailabilityForm>
  controlIndex: number
  isSpot?: boolean
}

export const AvailabilityCard = ({
  className,
  control,
  watch,
  setValue,
  controlIndex,
  onDelete,
  isSpot
}: AvailabilityCardProps) => {
  const baseWrapperClasses =
    "grid grid-rows-[auto_auto] gap-3 rounded-md border my-3 p-3 duration-500"
  const baseControlPath = "availability"

  const isActive = watch(`${baseControlPath}.${controlIndex}.active`)

  return (
    <div
      className={cn(
        className,
        baseWrapperClasses,
        isActive &&
          "outline outline-1 border-green-700 outline outline-green-700 outline-1"
      )}>
      <AvailabilityCardHeader
        className=""
        watch={watch}
        setValue={setValue}
        activeName={`${baseControlPath}.${controlIndex}.active`}
        titleName={`${baseControlPath}.${controlIndex}.description`}
        onDelete={onDelete}
      />
      <div className="grid gap-3">
        <div
          className={cn(
            className,
            "py-3 grid gap-3 grid-cols-[1.5fr_1fr_1fr_1fr_1fr]"
          )}>
          <FormSelectInput
            control={control}
            label="Day Of Week"
            options={DaysOfWeek.map((dow) => ({
              value: dow,
              label: dow
            }))}
            name={`availability.${controlIndex}.option`}
          />
          <FormTimeInput
            label="Opens at"
            control={control}
            name={`${baseControlPath}.${controlIndex}.openingTime`}
          />
          <FormTimeInput
            label="Closes at"
            control={control}
            name={`${baseControlPath}.${controlIndex}.closingTime`}
          />
          <FormNumberInput
            label="Price"
            control={control}
            name={`${baseControlPath}.${controlIndex}.price`}
          />
          {!isSpot && (
            <FormNumberInput
              label="Spots"
              control={control}
              name={`${baseControlPath}.${controlIndex}.spots`}
            />
          )}
        </div>
        <div className="py-3 flex gap-3">
          <FormDateInput
            label={"Lease starts"}
            control={control}
            name={`${baseControlPath}.${controlIndex}.leaseStartDate`}
          />
          <FormDateInput
            label={"Lease ends"}
            control={control}
            name={`${baseControlPath}.${controlIndex}.leaseEndDate`}
          />
        </div>
      </div>
    </div>
  )
}
