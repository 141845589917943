import { useEffect, useState } from 'react'
import { TypographyH2 } from "@/components/ui/typography/h2"
import { TypographyP } from "@/components/ui/typography/p"
import { useParams } from "react-router-dom"
import Loading from "@/components/custom/Loading"
import Fallback from "@/components/custom/Fallback"
import { getBlogPosts } from "@/api/getWP"
import { TypographyH4 } from "@/components/ui/typography/h4"
import ReactHtmlParser from "react-html-parser"

const BlogPostPage = () => {
  const [loading, setLoading] = useState(true)
  const [post, setPost] = useState()
  const { id } = useParams()

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getBlogPosts(id)
        setPost(data)
        setLoading(false)
      } catch (error) {
        console.log('Error loading blog post')
      }
    }
    fetchData()
  }, [])

  const yoastHeadJson = post?.yoastHeadJson
  const image = yoastHeadJson?.ogImage[0]

  const date = new Date(yoastHeadJson?.articlePublishedTime)
  const day = date.getDate()
  const year = date.getFullYear()
  const month = date.toLocaleString("default", { month: "long" })

  if (loading) return <Loading />

  return (
    <div className="px-4 mb-40 text-left flex flex-col items-center">
      <TypographyH2 className="text-center my-4 pt-10">
        News and Happenings 
      </TypographyH2>
      <div className="w-auto p-4 lg:px-40 text-center drop-shadow-[0_20px_20px_rgba(0,0,0,0.30)]">
        <img
          src={image?.url}
          className="h-full w-full object-contain rounded-lg"
          height={image?.height}
          width={image?.width}
        />
      </div>
      <TypographyH4 className="text-center my-4 pt-10 -mb-4">
        {post?.title?.rendered}
      </TypographyH4>
      <TypographyP className="mb-6">
        {month} {day}, {year}
      </TypographyP>
      <div className="p-4 lg:px-40 space-y-5">
        {ReactHtmlParser(post?.content?.rendered)}
      </div> 
      {/* <div className="grid grid-cols-4 gap-8 mt-10">
        {success && galleryImages}
      </div> */}
    </div>
  )
}

export default BlogPostPage
