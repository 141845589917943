import { ChevronDown, CircleXIcon, Edit } from "lucide-react"

import { Badge } from "@/components/ui/badge"
import { Button } from "@/components/ui/button"
import { TableCell, TableRow } from "@/components/ui/table"

import { Link } from "react-router-dom"
import { AddressBlock } from "@/components/custom/Address/AddressBlock"
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger
} from "@/components/ui/collapsible"
import { useMemo, useState } from "react"
import { FacilityDrawer } from "./FacilityDrawer/FacilityDrawer"
import { useAuth } from "@/context/AuthContext"
import { useQuery } from "@tanstack/react-query"
import {
  GetFacilitySpots,
  GetFacilitySpotsQuery,
  GetFacilitySpotsResponse
} from "@/api/facility/GetFacilitySpots"

export interface FacilitiesTableRowProps {
  facility: Facility
  onDelete?: (facility: Facility) => void
}

const badgeVariant: Record<string, string> = {
  active: "",
  draft: "secondary",
  inactive: "outline"
}

export const FacilitiesTableRow = ({
  facility,
  onDelete
}: FacilitiesTableRowProps) => {
  const [zoneDrawerIsOpen, setZoneDrawerOpen] = useState(false)
  const { accessToken } = useAuth()
  const { data } = useQuery<GetFacilitySpotsResponse>({
    queryKey: [GetFacilitySpotsQuery(facility.id)],
    queryFn: () => GetFacilitySpots(accessToken, facility.id)
  })

  const image = useMemo(
    () =>
      facility?.images?.length && facility?.images?.length > 0 ?
        <img
          alt={facility.images[0].name}
          className="aspect-square rounded-md object-cover"
          height="64"
          src={facility.images[0].file}
          width="64"
        />
      : <span />,
    []
  )

  const totalZones =
    (data?.spots?.length ?? 0) + (facility.unassignedZone?.length ?? 0)

  return (
    <Collapsible onOpenChange={setZoneDrawerOpen} asChild>
      <>
        <TableRow className={zoneDrawerIsOpen ? "border-b-0 my-4" : ""}>
          <TableCell className="hidden sm:table-cell">{image}</TableCell>
          <TableCell className="font-medium">{facility.name}</TableCell>
          <TableCell className="font-medium">
            <AddressBlock {...facility.address} />
          </TableCell>
          <TableCell>
            <Badge
              variant={badgeVariant[facility.status]}
              className="capitalize">
              {facility.status}
            </Badge>
          </TableCell>
          <CollapsibleTrigger asChild>
            <TableCell className="hidden md:table-cell">
              <Button variant="ghost">
                <span className="grid grid-cols-[auto_auto] items-center justify-start gap-2">
                  <p>{totalZones}</p>
                  <ChevronDown
                    className="duration-150"
                    style={{
                      transform: zoneDrawerIsOpen ? "rotate(180deg)" : "none"
                    }}
                  />
                </span>
              </Button>
            </TableCell>
          </CollapsibleTrigger>
          <TableCell>
            {facility.status !== "Deleted" && (
              <div className="flex gap-3 items-center justify-end">
                <Button variant="outline" asChild>
                  <Link to={`/owner-dashboard/facilities/${facility.id}/edit`}>
                    <Edit className="mr-2 h-4 w-4" /> Edit Facility
                  </Link>
                </Button>
                <Button
                  onClick={() => onDelete?.(facility)}
                  variant="destructive">
                  <CircleXIcon className="h-4 w-4 mr-2" /> Delete
                </Button>
              </div>
            )}
          </TableCell>
        </TableRow>
        <CollapsibleContent className="m-10" asChild>
          <FacilityDrawer
            facilityId={facility.id}
            unassignedZones={facility.unassignedZone}
            spots={data?.spots}
          />
        </CollapsibleContent>
      </>
    </Collapsible>
  )
}
