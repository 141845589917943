export const Now = new Date()
export const OneWeekAgo = (() => {
  let temp = new Date()
  temp.setDate(Now.getDate() - 7)
  return temp
})()
export const TwoWeeksAgo = (() => {
  let temp = new Date()
  temp.setDate(Now.getDate() - 14)
  return temp
})()
export const OneMonthAgo = (() => {
  let temp = new Date()
  temp.setMonth(Now.getMonth() - 1)
  return temp
})()
export const TwoMonthsAgo = (() => {
  let temp = new Date()
  temp.setMonth(Now.getMonth() - 2)
  return temp
})()
export const OneYearAgo = (() => {
  let temp = new Date()
  temp.setFullYear(Now.getFullYear() - 1)
  return temp
})()

export const reservationNewerThanWeek = (reservation: Reservation) => {
  const created = new Date(reservation.createdDatetime)
  return created < Now && created > OneWeekAgo
}

export const lastWeeksReservations = (reservation: Reservation) => {
  const created = new Date(reservation.createdDatetime)
  return created < OneWeekAgo && created > TwoWeeksAgo
}

export const reservationNewerThanMonth = (reservation: Reservation) => {
  const created = new Date(reservation.createdDatetime)
  return created < Now && created > OneMonthAgo
}

export const lastMonthsReservations = (reservation: Reservation) => {
  const created = new Date(reservation.createdDatetime)
  return created < OneMonthAgo && created > TwoMonthsAgo
}

export const reservationNewerThanYear = (reservation: Reservation) => {
  const created = new Date(reservation.createdDatetime)
  return created < Now && created > OneYearAgo
}

export const reservationProfitsReducer = (
  profit: number,
  currentValue: Reservation
) => profit + parseFloat(currentValue?.order?.finalAmount)
