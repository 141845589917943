import {
  api,
  AuthHeader,
  keysToSnakeCase,
  StandardApiResponse,
  StandardErrorHandler,
  StandardPushHandler
} from "../const"
import { Spot } from "../types/internal/Spot"

export const PutSpotPath = (id: string) => `/v1/spots/${id}/`

export const UpdateSpot = async (
  accessToken: string,
  id: string,
  data: Spot<number, string>
): Promise<StandardApiResponse> =>
  api
    .put(PutSpotPath(id), keysToSnakeCase(data), AuthHeader(accessToken))
    .then(StandardPushHandler())
    .catch(StandardErrorHandler)
