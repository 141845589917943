import { z } from "zod"

export const SpotStatus = ["Active", "Inactive", "Archived"] as const

export const DaysOfWeek = [
  "EVERYDAY",
  "SUNDAY",
  "MONDAY",
  "TUESDAY",
  "WEDNESDAY",
  "THURSDAY",
  "FRIDAY",
  "SATURDAY"
] as const

export const Billed = ["DAILY"] as const

export const DayOfWeekSchema = z.enum(DaysOfWeek)
export const TimeSchema = z
  .string()
  .time({ precision: 0 })
  .transform((result) => result.slice(0, 5))
export const DateSchema = z.string().date()
export const PriceSchema = z
  .string()
  .refine((str) => /^\d+(\.\d{0,2}){0,1}$/.test(str))

export const AvailabilityItemSchema = z.object({
  id: z.string().optional(),
  new: z.coerce.boolean(),
  active: z.coerce.boolean(),
  description: z.string(),
  billed: z.enum(Billed),
  spots: z.coerce.number().optional(),
  option: DayOfWeekSchema,
  openingTime: TimeSchema,
  closingTime: TimeSchema,
  leaseStartDate: DateSchema,
  leaseEndDate: DateSchema,
  price: PriceSchema
})
export type AvailabilityItemForm = z.infer<typeof AvailabilityItemSchema>

export const OverrideItemSchema = z.object({
  id: z.string().optional(),
  new: z.coerce.boolean(),
  active: z.coerce.boolean(),
  description: z.string(),
  isUnavailable: z.coerce.boolean(),
  spots: z.coerce.number().optional(),
  billed: z.enum(Billed),
  option: DayOfWeekSchema,
  openingTime: TimeSchema,
  closingTime: TimeSchema,
  startDate: DateSchema,
  endDate: DateSchema,
  price: PriceSchema
})
export type OverrideItemForm = z.infer<typeof OverrideItemSchema>

export const AvailabilitySchema = z.object({
  availability: z.array(AvailabilityItemSchema),
  override: z.array(OverrideItemSchema)
})
export type AvailabilityForm = z.infer<typeof AvailabilitySchema>

export interface AvailabilityBackendState {
  availability: {
    created?: AvailabilityItemForm[]
    updated?: AvailabilityItemForm[]
    deleted?: AvailabilityItemForm[]
  }
  override: {
    created?: OverrideItemForm[]
    updated?: OverrideItemForm[]
    deleted?: OverrideItemForm[]
  }
}
