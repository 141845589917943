import { ImageWithId, MediaSchema } from "@/constants/MediaSchema"
import { z } from "zod"

export const EditMediaSchema = z.object({
  images: MediaSchema
})
export type EditMediaForm = z.infer<typeof EditMediaSchema>

export const ImageToSrcUrl = (image: any): string => {
  if (image?.file instanceof File) {
    try {
      return URL.createObjectURL(image.file)
    } catch (e) {
      return ""
    }
  } else if (typeof image?.file === "string") {
    return image?.file
  } else {
    return ""
  }
}
