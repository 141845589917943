import { useRef, useState } from "react"
import { useSearchFlow } from "@/hooks/useSearchFlow"
import { useBreakpoint } from "@/hooks/useBreakpoint"
import MobileParkSearchForm from "@/components/custom/MobileParkSearchForm"
import ParkSearchForm from "@/components/custom/ParkSearchForm"
import ParkSearchResult from "@/components/custom/ParkSearchResult"
import ParkSearchResults from "@/components/custom/ParkSearchResults"
import ParkingSpotsMap from "@/components/custom/ParkingSpotsMap"
import Loading from "@/components/custom/Loading"
import { Card, CardContent, CardHeader } from "@/components/ui/card"
import { Carousel, CarouselContent, CarouselItem } from "@/components/ui/carousel"
import { Badge } from "@/components/ui/badge"
import { cn } from "@/lib/utils"
import InfoWindowContent from "@/components/custom/InfoWindowContent"
import { DialogTrigger } from "@radix-ui/react-dialog"
import { Button } from "@/components/ui/button"
import FacilityDetailsDialog from "@/components/custom/FacilityDetailsDialog"

const SearchPage = () => {
  const {
    facilities,
    facilitiesLoading,
    handleSearchQuery,
    searchQuery,
    searchLoading,
    searchSuccess,
    searchResults,
    markers,
    reserveSpot
  } = useSearchFlow()

  const [activeCardIndex, setActiveCardIndex] = useState(0)
  const [api, setApi] = useState(null)
  const [dialogOpen, setDialogOpen] = useState(false)
  const carouselWrapper = useRef(null)

  const desktopBreakpoints = ["lg", "xl", "2xl"]
  const breakpoint = useBreakpoint()
  const isDesktop = desktopBreakpoints.includes(breakpoint)

  const scrollToCarousel = (facilityId) => {
    const index = searchResults.findIndex(result => result.facility.id === facilityId)
    if (index !== -1 && api) {
      api.scrollTo(index)
      setActiveCardIndex(index)
    }
  }

  const onSelect = (api) => {
    if (!api) return
    const visibleSlides = api.slidesInView(true)
    setActiveCardIndex(api.selectedScrollSnap())
  }

  const badges = (tags) => {
    if (tags?.length === 0) return null
    const slicedTags = tags.slice(0, 2)
    return (
      <>
        {slicedTags?.map((tag, i) => (
          <Badge variant="" key={`${tag.name}-${i}`} className="capitalize mr-1 font-normal text-xs">{tag.name}</Badge>
        ))}
      </>
    )
  }

  const spotOrLot = (result) => {
    return result?.lot ? result?.lot : result?.spot
  }
 
  const renderSearchResults = () => {
    if (searchLoading) return <Loading className="h-full" />
    if (searchSuccess && searchResults.length > 0) {
      return (
        <Carousel
          opts={{
            align: "start",
            loop: true,
          }}
          setApi={setApi}
          onSelectProp={onSelect}
          className={cn("mx-auto", {
            "w-3/4": searchResults.length > 1,
            "w-full": searchResults.length === 1
          })}
        >
          <CarouselContent
            showOverflow={true}
            className={cn('ml-0 mr-2 block', {
              "-ml-4 flex": searchResults.length > 1,
            })}
          >
            {searchResults.map((result, index) => (
              <CarouselItem
                key={index}
                className="pl-2 basis-4/5 cursor-pointer"
                onClick={() => {
                  setDialogOpen(true)
                  scrollToCarousel(result.facility.id)}
                }>
                <div className="bg-white rounded flex h-full">
                  {
                    result?.facility?.images[0]?.file && (
                      <img 
                        className="h-28 w-28 square bg-contain rounded-l" 
                        src={result?.facility?.images[0]?.file} 
                        alt={result?.facility?.name}
                      />
                    )
                  }
                  <ul className="p-2 truncate w-full flex flex-col">
                    <li className="font-medium truncate">
                      {result?.facility?.name}
                    </li>
                    <li className="flex flex-wrap gap-1">
                      {badges(spotOrLot(result).tags)}
                    </li>
                    <li className="font-semibold mt-auto">
                      ${spotOrLot(result).pricing}
                      <span className="font-normal">/day</span>
                    </li>
                  </ul>
                </div>
              </CarouselItem>
            ))}
          </CarouselContent>
        </Carousel>
      )
    }
    if (searchResults && searchResults.length < 1) {
      return (
        <Card className="w-1/2 mx-auto">
          <CardContent className="p-4">
            This property is fully booked for the date you have chosen.
            Please try another date or an alternate property.
          </CardContent>
        </Card>
      )
    }
    return null
  }

  if (facilitiesLoading) {
    return <Loading />
  }

  if (isDesktop) {
    return (
      <div className="h-[calc(100vh-64px)] px-2 py-6 grid grid-cols-1 md:px-6 lg:grid-cols-6 gap-6">
        <Card className="col-span-2 flex flex-col gap-4 overflow-y-scroll lg:shadow-xl">
          <CardHeader>
            <ParkSearchForm
              searchQuery={searchQuery}
              handleSearchQuery={handleSearchQuery}
              facilities={facilities}
            />
          </CardHeader>
          <CardContent>
            {searchLoading && <Loading className="h-full" />}
            {searchSuccess && (
              <ParkSearchResults
                searchResults={searchResults}
                reserveSpot={reserveSpot}
              />
            )}
            {
              searchResults && searchResults.length < 1 && (
                <div className="rounded-lg border bg-card text-card-foreground col-span-2 flex flex-col gap-4 overflow-y-scroll p-4">
                  This property is fully booked for the date you have chosen.
                  Please try another date or an alternate property.
                </div>
              )
            }
          </CardContent>
        </Card>
        <div className="col-span-4">
          <div className="w-full h-full rounded-xl border overflow-hidden lg:shadow-xl">
            <ParkingSpotsMap markers={markers} />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="flex flex-col relative h-[calc(100vh-64px)]">
      {
        isDesktop && (
          <div className="h-[calc(100vh-64px)] flex flex-col">
            <div className="p-4">
              <ParkSearchForm
                searchQuery={searchQuery}
                handleSearchQuery={handleSearchQuery}
                facilities={facilities}
              />
            </div>
          </div>
        ) 
      }
      {
        !isDesktop && (
          <div className="flex absolute top-0 z-10 w-full">
            {
              markers && markers.length > 0 && (
                <FacilityDetailsDialog
                  data={searchResults[activeCardIndex]}
                  dialogOpen={dialogOpen}
                  closeDialog={() => setDialogOpen(false)}
                />
              )
            }
            <MobileParkSearchForm
              searchQuery={searchQuery}
              handleSearchQuery={handleSearchQuery}
              facilities={facilities}
            />
          </div>
        )
      }
      <div className="flex-grow relative">
        <ParkingSpotsMap markers={markers} scrollToCarousel={scrollToCarousel} activeCardIndex={activeCardIndex} />
      </div>
      <div ref={carouselWrapper} className="fixed bottom-0 left-0 right-0 py-4 overflow-x-hidden results-wrapper">
        {renderSearchResults()}
      </div>
    </div>
  )
}

export default SearchPage
