import { TypographyH2 } from "@/components/ui/typography/h2"
import { TypographyP } from "@/components/ui/typography/p"

const ContactPage = () => {
  return (
    <div className="m-auto px-4 max-w-screen-md mb-20 text-left h-screen">
      <TypographyH2 className="pt-10 text-center">
        {" "}
        Reach Out Anytime{" "}
      </TypographyH2>
      <TypographyP>
        Welcome to our Contact Us page at Spotsurfer! Whether you have
        questions, need assistance, or wish to explore partnership
        opportunities, we&apos;re here for you. Drop us a message, and
        let&apos;s make parking hassle-free together.
      </TypographyP>
      <TypographyP>216 Main Street #230 Edwards CO, 81632</TypographyP>
      <TypographyP>(970) 389-4230</TypographyP>
      <TypographyP>info@spotsurfer.com</TypographyP>
    </div>
  )
}

export default ContactPage
