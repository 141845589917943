import _ from "lodash"
import { deepMapKeys } from "@/lib/deepMapKeys"

const BACKEND_URL = import.meta.env.VITE_BACKEND_API_URL
const BASE_URL = BACKEND_URL + "/api/"

const defaultHeaders = {
  "Content-Type": "application/json",
  accept: "application/json"
}

const buildHeaders = (accessToken, useImageHeader) => ({
  ...defaultHeaders,
  ...(useImageHeader && { "Content-Type": "multipart/form-data" }),
  ...(accessToken && { Authorization: `Bearer ${accessToken}` })
})

export const buildRequest = (uri, accessToken, payload, dropTrailingSlash = false, useImageHeader = false) => {
  const trailingSlash = dropTrailingSlash ? "" : "/"
  const url = `${BASE_URL}${uri}${trailingSlash}`
  const headers = buildHeaders(accessToken, useImageHeader)
  const serializedPayload = payload && deepMapKeys(payload, _.snakeCase)

  return {
    url,
    headers,
    serializedPayload
  }
}
