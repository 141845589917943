import { z } from "zod"

export const SpotDetailsSchema = z.object({
  name: z.string(),
  description: z.string().optional(),
  floor: z.coerce.number().gt(0),
  maxHeightSupported: z.coerce.number().gt(0),
  hasSpaceForBiggerVehicles: z.coerce.boolean(),
  parkerInstructions: z.string().optional()
})

export type ISpotDetailsSchema = z.infer<typeof SpotDetailsSchema>
