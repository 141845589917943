import { useLabels } from "@/context/LabelContext"
import { useMemo } from "react"
import { InfoHover } from "./InfoHover"

export interface LabelInfoHoverProps {
  labelKey: string
  displayKeyOnError: boolean
  iconSize?: number
  className?: string
}

export const LabelledInfoHover = ({
  labelKey,
  displayKeyOnError,
  iconSize,
  className
}: LabelInfoHoverProps) => {
  const { getLabel } = useLabels()

  const content = useMemo(() => {
    let internalLabel = getLabel(labelKey)
    if (!internalLabel && displayKeyOnError) {
      return labelKey
    }
    return internalLabel ?? ""
  }, [labelKey])

  return (
    <InfoHover className={className} message={content} iconSize={iconSize} />
  )
}
