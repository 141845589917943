import { GetFacility, GetFacilityResponse } from "@/api/facility/GetFacility"
import Fallback from "@/components/custom/Fallback"
import Loading from "@/components/custom/Loading"
import { useAuth } from "@/context/AuthContext"
import { useQuery } from "@tanstack/react-query"
import { useParams } from "react-router-dom"
import { AddressRow } from "@/components/custom/Address/AddressRow"
import { EditFacilityTabs } from "./const"
import { UpdateFormHeader } from "@/components/custom/Forms/UpdateFormGenerics/UpdateFormHeader"
import { UpdateFormTabsWrapper } from "@/components/custom/Forms/UpdateFormGenerics/UpdateFormTabWrapper"

export const EditFacilityPage = () => {
  const { facilityId } = useParams()
  const { accessToken } = useAuth()
  const { data, isPending, isError, refetch } = useQuery<GetFacilityResponse>({
    queryKey: [`facility-${facilityId}`],
    queryFn: () => GetFacility(accessToken, facilityId ?? "")
  })

  if (isPending || !data || !data.facility) return <Loading className="" />
  if (isError || data.isError) return <Fallback />

  return (
    <UpdateFormHeader
      title={data.facility.name}
      subtitle={
        <AddressRow
          className="text-muted-foreground"
          address={data.facility.address}
        />
      }
      badge={data.facility.status}
      data={data.facility}
      deleteText="Delete Facility"
      onDeleteClicked={() => {}}
      onSubmit={() => refetch()}
      render={(header) => (
        <UpdateFormTabsWrapper {...header} tabs={EditFacilityTabs} />
      )}
    />
  )
}
