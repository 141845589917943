import { useState } from "react"
import { isNumber } from "lodash"

import CountdownTimer from "@/components/custom/CountdownTimer"
import ReservationExpiredAlert from "./ReservationExpiredAlert"
import { cn } from "@/lib/utils"
import { useMedia } from "react-use"

const ReservationLock = ({ lockExpiryInSeconds, handleRedirect }) => {
  const isMobile = useMedia("(max-width: 768px)")

  const [isExpired, setIsExpired] = useState(
    isNumber(lockExpiryInSeconds) && lockExpiryInSeconds <= 0
  )

  const handleOpenChange = () => setIsExpired((prev) => !prev)

  if (isExpired) {
    return (
      <ReservationExpiredAlert
        open={isExpired}
        handleOpenChange={handleOpenChange}
        redirectToSearch={handleRedirect}
      />
    )
  } else {
    return (
      <div
        className={cn(
          "absolute bg-transparent -top-6 -right-10 z-10 flex justify-end items-center",
          isMobile ? "right-4" : ""
        )}>
        <CountdownTimer
          expiryTimeInSeconds={lockExpiryInSeconds}
          setIsTerminated={setIsExpired}
        />
      </div>
    )
  }
}

export default ReservationLock
