import { SelectInputProps } from "@/components/custom/Forms/FormInputs"
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from "@/components/ui/form"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "@/components/ui/select"
import { useReservationForm } from "../../context/ReservationFormContext"
import { ReservationForm } from "../../context/const"
import React from "react"

export const ExistingVehicleDropdown = () => {
  const { control, parker } = useReservationForm()

  if (!control || !parker) {
    return <></>
  }

  return (
    <CustomSelect
      control={control}
      side="top"
      name="vehicle"
      label="Choose one of your vehicles"
      options={
        parker.vehicles.map((vehicle) => ({
          label: <VehicleDropdownLabel vehicle={vehicle} />,
          value: vehicle
        })) ?? []
      }
    />
  )
}

export const VehicleDropdownLabel = React.forwardRef<
  HTMLDivElement,
  { vehicle: Vehicle }
>(({ vehicle }, ref) => {
  return (
    <div
      ref={ref}
      className="w-full grid grid-cols-[1fr_auto_2fr] bg-red justify-center items-center gap-3 pr-4 py-2"
    >
      <p className="justify-self-end">{vehicle.licenseNumber.toUpperCase()}</p>
      <p> - </p>
      <p className="justify-self-start">{`${vehicle.color} ${vehicle.make} ${vehicle.model}`}</p>
    </div>
  )
})

VehicleDropdownLabel.displayName = 'VehicleDropdownLabel'

interface CustomSelectProps extends SelectInputProps<ReservationForm> {
  options: {
    label: JSX.Element
    value: Vehicle
  }[]
}

const CustomSelect = ({
  control,
  name,
  label,
  placeholder,
  options,
  open
}: CustomSelectProps) => {
  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem className="w-full">
          <FormMessage />
          <Select
            open={open}
            onValueChange={(value) => {
              field.onChange(
                options.find((item) => item.value.licenseNumber === value)
                  ?.value
              )
            }}
            value={(field.value as Vehicle)?.licenseNumber}>
            <FormControl>
              <SelectTrigger>
                <SelectValue placeholder={placeholder} />
              </SelectTrigger>
            </FormControl>
            <SelectContent position="popper" side="top">
              {options.map((option, index) => (
                <SelectItem
                  key={index}
                  value={option.value.licenseNumber}
                  children={option.label}
                  className="cursor-pointer"
                />
              ))}
            </SelectContent>
          </Select>
        </FormItem>
      )}
    />
  )
}
