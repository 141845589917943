export interface DiscountPriceProps {
  original: string
  reduced: string
  code: string
  isFreeVoucher?: boolean
}

export const DiscountPrice = ({
  original,
  reduced,
  code,
  isFreeVoucher
}: DiscountPriceProps) => {
  const reduction = (parseFloat(original) - parseFloat(reduced)).toFixed(2)
  return (
    <div className="grid gap-x-3 gap-y-1 text-right grid-cols-[max-content_max-content] content-end">
      <p className="col-start-1 font-normal">${original}</p>
      {isFreeVoucher ?
        <p className="col-start-1 font-normal">- ${original}</p>
      : <p className="col-start-1 font-normal">- ${reduction}</p>}
      <p className="border border-l-0 border-r-0 border-b-0 border-slate-700 col-start-1">
        ${reduced}
      </p>
      {code && <p className="font-light col-start-2 row-start-2">({code})</p>}
    </div>
  )
}
