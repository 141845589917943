import { UpdateLot } from "@/api/lots/UpdateLot"
import { ensureFloat } from "@/components/custom/Forms/DragMap"
import { EditLocationTab } from "@/components/custom/Forms/UpdateFormGenerics/EditLocationTab"
import { UpdateFormTabsWrapperChildren } from "@/components/custom/Forms/UpdateFormGenerics/UpdateFormTabWrapper"
import { ILatLongSchema } from "@/constants/LatLongSchema"
import { useAuth } from "@/context/AuthContext"
import _ from "lodash"
import { useCallback } from "react"
import { useParams } from "react-router-dom"

export const EditLotLocation = ({
  data,
  ...tabProps
}: UpdateFormTabsWrapperChildren<UnassignedZone, ILatLongSchema>) => {
  const { accessToken } = useAuth()
  const { lotId } = useParams()

  const submitHandler = useCallback(
    (result: ILatLongSchema) => {
      if (lotId) {
        return UpdateLot(accessToken, lotId, {
          ...data,
          ...result
        })
      }
    },
    [accessToken, lotId, data]
  )

  const numberData = {
    ...data,
    latitude: ensureFloat(data.latitude),
    longitude: ensureFloat(data.longitude)
  }

  return (
    <EditLocationTab
      data={numberData}
      submitHandler={submitHandler}
      {...tabProps}
    />
  )
}
