import { UpdateLotAvailability } from "@/api/lots/UpdateLotAvailability"
import { EditAvailabilityTab } from "@/components/custom/Forms/UpdateFormGenerics/EditAvailabilityTab"
import { UpdateFormTabsWrapperChildren } from "@/components/custom/Forms/UpdateFormGenerics/UpdateFormTabWrapper"
import { AvailabilityForm } from "@/constants/AvailabilitySchema"
import { useAuth } from "@/context/AuthContext"
import _ from "lodash"
import { useCallback } from "react"
import { useParams } from "react-router-dom"

export const EditLotAvailability = ({
  data,
  ...tabProps
}: UpdateFormTabsWrapperChildren<UnassignedZone, AvailabilityForm>) => {
  const { accessToken } = useAuth()
  const { lotId } = useParams()

  const submitHandler = useCallback(
    (result: any) => {
      const updated = result.availability.filter((avail: any) => !avail.new)
      const created = result.availability.filter((avail: any) => avail.new)
      const deleted = result.availabilityFieldsDeleted

      const overrideUpdated = result.override.filter((or: any) => !or.new)
      const overrideCreated = result.override.filter((or: any) => or.new)
      const overrideDeleted = result.overrideFieldsDeleted

      if (lotId) {
        return UpdateLotAvailability(accessToken, lotId, {
          availability: {
            updated,
            created,
            deleted,
          },
          override: {
            updated: overrideUpdated,
            created: overrideCreated,
            deleted: overrideDeleted,
          },
        })
      }
    }, [accessToken, lotId] // Ensure availabilityItemsToDelete is in the dependencies
  )

  return (
    <EditAvailabilityTab
      data={data}
      submitHandler={submitHandler}
      {...tabProps}
    />
  )
}

