import { Button } from "@/components/ui/button"
import { Badge } from "@/components/ui/badge"
import ImageCarousel from "./ImageCarousel"
import {
  DollarSign,
  ListPlus,
  MapPin,
  Store,
  TicketCheck,
  X
} from "lucide-react"
import { AddressRow } from "./Address/AddressRow"
import { Separator } from "../ui/separator"

const InfoWindowContent = ({ markerInfo, handleClose }) => {
  if (!markerInfo) return null

  const {
    name,
    price,
    reserveSpot,
    images,
    dialogElement,
    facility,
    location
  } = markerInfo

  const tags = location?.tags

  const badges = (tags) => {
    if (tags?.length === 0) return null

    return (
      <div className="flex flex-wrap">
        {tags?.map((tag, i) => (
          <Badge key={`${tag.name}-${i}`} className="capitalize mr-1">{tag.name}</Badge>
        ))}
      </div>
    )
  }

  return (
    <div className="flex flex-col gap-3">
      <div className="flex gap-3 justify-between items-center">
        <h2 className="text-lg">{facility?.name}</h2>
        <Button
          variant="link"
          size="icon"
          className="h-6 w-6"
          onClick={() => handleClose?.()}>
          <X className="h-6 w-6" />
        </Button>
      </div>
      {badges(tags)}
      <div className="flex flex-col gap-1">
        {facility?.address && (
          <div className="flex gap-1">
            <MapPin className="h-4 w-4" />
            <AddressRow
              className="text-xs"
              address={facility.address}
            />
          </div>
        )}
      </div>
      <div>
        {images.length > 0 && (
          <ImageCarousel
            images={images}
            className="w-full max-w-xs"
            itemClassName="max-h-[150px] rounded-lg"
          />
        )}
      </div>
      <div className="grid gap-1 items-center grid-cols-[1fr_auto_2fr]">
        <p className="text-center font-semibold text-lg">${price}</p>
        <Separator orientation="vertical" />
        <div className="flex flex-col gap-1 justify-self-center">
          {(facility?.amenities?.length ?? 0) > 0 && (
            <div className="flex gap-1">
              <ListPlus className="h-4 w-4 text-accent-green" />
              <p>Amenities Available</p>
            </div>
          )}
          {(facility?.paidAmenities?.length ?? 0) > 0 && (
            <div className="flex gap-1">
              <DollarSign className="h-4 w-4 text-accent-green" />
              <p>Add-ons Available</p>
            </div>
          )}
          {(facility?.parkingPacks?.length ?? 0) > 0 && (
            <div className="flex gap-1">
              <TicketCheck className="h-4 w-4 text-accent-green" />
              <p>Packs Available</p>
            </div>
          )}
        </div>
      </div>
      <div className="flex justify-between gap-2 mt-3">
        {dialogElement}
        <Button className="w-full" onClick={reserveSpot}>
          Book Spot
        </Button>
      </div>
    </div>
  )
}

export default InfoWindowContent
