import NotFound404Page from "@/pages/NotFound404Page"
import ParkingSpotInventoryPage from "@/pages/ParkingSpotInventoryPage"

export const SpotLotRoutes = (path: string) => {
  return {
    path,
    children: [
      {
        index: true,
        element: <ParkingSpotInventoryPage />
      },
      {
        path: "*",
        element: <NotFound404Page />
      }
    ]
  }
}
