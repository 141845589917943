import { Drawer, DrawerContent, DrawerTrigger } from "@/components/ui/drawer"
import { useReservationForm } from "../../context/ReservationFormContext"
import { useMemo } from "react"
import { getThumbnail } from "../../const"
import { TypographyH2 } from "@/components/ui/typography/h2"
import ReservationLock from "@/components/custom/ReservationLock"
import { Skeleton } from "@/components/ui/skeleton"
import { ReservationDetailsMobileDrawer } from "./ReservationDetailsMobile"

export const ReservationDetails = () => {
  return (
    <Drawer>
      <DrawerTrigger>
        <Popup />
      </DrawerTrigger>
      <DrawerContent>
        <ReservationDetailsMobileDrawer />
      </DrawerContent>
    </Drawer>
  )
}

const Popup = () => {
  const { pricing, priceIsLoading, reservation, reservationLock } = useReservationForm()

  const thumbnail = useMemo(() => getThumbnail(reservation), [reservation])

  return (
    <div className="mx-auto max-w-2xl fixed shadow-full-wrap bottom-4 left-4 right-4 h-16 z-50 bg-white rounded-xl flex items-center justify-between gap-6 p-6">
      <div className="flex gap-3 items-center relative">
        {thumbnail && (
          <img className="h-10 w-10 rounded square" src={thumbnail?.url} />
        )}
        <div className="flex flex-col text-left gap-1">
          <TypographyH2 className="text-md font-semibold p-0 leading-none">
            {reservation?.parkingLocationType === "LOT" ?
              reservation?.lot?.name
            : reservation?.spot?.name}
          </TypographyH2>
          <p className="text-sm text-muted-foreground leading-none">
            {reservation?.facility?.name}
          </p>
        </div>
      </div>
      <div className="flex flex-col justify-center">
        {priceIsLoading ?
          <Skeleton className="w-12 h-6" />
        : <p className="text-lg font-semibold">${pricing?.total}</p>}
      </div>
      {reservationLock && (
        <ReservationLock
          lockExpiryInSeconds={reservationLock?.expires}
          handleRedirect={reservationLock?.redirectHandler}
        />
      )}
    </div>
  )
}
