import { useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useMutation } from "@tanstack/react-query"
import { hoursToSeconds, intervalToDuration, minutesToSeconds } from "date-fns"
import { holdReservation } from "@/api/post"
import { useLock } from "@/context/LockContext"

export const useHoldReservation = () => {
  const navigate = useNavigate()
  const [error, setError] = useState(null)

  const { reservationLock, setReservationLock, removeReservationLock } =
    useLock()

  const holdReservationMutation = useMutation({
    mutationFn: (payload) => holdReservation(payload),
    onSuccess: ({ id: lockId, validUntilDatetime, parkingLocationId }) => {
      setReservationLock({
        lockId,
        lockExpiry: validUntilDatetime,
        parkingLocationId
      })
    },
    onError: () => {
      removeReservationLock()
      setError({ message: "Failed to hold reservation", redirectTo: "/" })
    }
  })

  const handleReservationHold = (payload) =>
    holdReservationMutation.mutate(payload)

  const handleRedirectToSearch = () => {
    removeReservationLock()
    return navigate("/", { replace: true })
  }

  const lockExpiryInSeconds = useMemo(() => {
    if (!reservationLock || !reservationLock.lockExpiry) return 0

    const duration = intervalToDuration({
      start: new Date(),
      end: new Date(reservationLock.lockExpiry)
    })

    // Not expecting years/months/days
    let result = 0
    if (duration.hours) result = result + hoursToSeconds(duration.hours)
    if (duration.minutes) result = result + minutesToSeconds(duration.minutes)
    if (duration.seconds) result = result + duration.seconds

    return result
  }, [reservationLock])

  return {
    error,
    reservationLock,
    handleReservationHold,
    handleRedirectToSearch,
    lockExpiryInSeconds
  }
}
