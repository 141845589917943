import NotFound404Page from "@/pages/NotFound404Page"
import { CreateSpotPage } from "./CreateSpotPage/CreateSpotPage"
import { EditSpotPage } from "./EditSpotPage/EditSpotPage"

export const SpotRoutes = (path: string) => {
  return {
    path,
    children: [
      {
        path: "new",
        element: <CreateSpotPage />
      },
      {
        path: ":spotId",
        children: [
          {
            path: "edit",
            element: <EditSpotPage />
          }
        ]
      },
      {
        path: "*",
        element: <NotFound404Page />
      }
    ]
  }
}
