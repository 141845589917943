import axios from "axios"
import { deepMapKeys } from "@/lib/deepMapKeys"
import { handleError, clearError } from '@/lib/errorHandler';

export const getData = async (url) => {
  try {
    clearError()
    const result = await axios.get(url)

    if (result.data && typeof result.data === "string") {
      return result.data.split(",")
    }

    if (result.data && result.data.length) {
      return result.data.map((item) => deepMapKeys(item))
    } else {
      return result.data && deepMapKeys(result.data)
    }
  } catch (error) {
    handleError({
      error: error.message
    })
    return error
  }
}

export const getBlogPosts = async (id = "") => {
  return await getData(`https://site.spotsurfer.com/wp-json/wp/v2/posts/${id}`)
}

export const getGalleryIds = async (id) =>
  await getData(
    `https://site.spotsurfer.com/wp-json/gallery_plugin/v1/post/${id}`
  )

export const getBlogMedia = async (id) =>
  await getData(`https://site.spotsurfer.com/wp-json/wp/v2/media/${id}`)
