import { useState } from "react"
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription
} from "@/components/ui/dialog"
import { Separator } from "@/components/ui/separator"
import { Button } from "@/components/ui/button"

import Icon from "@/components/custom/Icon"
import { DialogTrigger } from "@radix-ui/react-dialog"

export const GenericDialog = ({ facility }: { facility: Facility  }) => {
  const [open, setOpen] = useState(false)
  const amenities = facility.amenities as any[]

  const dialogTrigger = (
    <DialogTrigger asChild>
      <Button variant="outline">More Info</Button>
    </DialogTrigger>
  )

  const amenitiesBlock = (amenities: any) => amenities?.map((amenity: any, i: number) => (
    <div key={i} className="flex flex-row items-center">
      <Icon name={amenity.iconName} size={20} className="shrink-0" />
      <div className="ml-3">
        <p className="text-sm">{amenity.name}</p>
        <p className="text-sm">{amenity.description}</p>
      </div>
    </div>
  ))

  const facilityDescription = () => (
    <>
      { facility.description && <Separator /> }
      <DialogDescription className={`text-primary ${!facility.description && 'sr-only'}`}>
        {facility.description}
      </DialogDescription>
    </>
  )

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      {dialogTrigger}
      <DialogContent className="overflow-scroll max-h-full">
        <DialogHeader>
          <DialogTitle>
            {facility.name}
          </DialogTitle>
          <div className="grid grid-cols-2 gap-6">
            <div>
              <address className="grid gap-1 not-italic tracking-tight text-sm">
                <p>{facility.address.address1}</p>
                {facility.address.address2 && <p>{facility.address.address2}</p>}
                <p>
                  {facility.address.city}, {facility.address.state} {facility.address.zipCode}
                </p>
              </address>
            </div>
          </div>
          {amenities.length > 0 && (
            <>
              <Separator />
              <div className="grid gap-3">
                <p className="text-sm font-medium">Amenities</p>
                <div className="grid grid-cols-3 gap-2">{amenitiesBlock(facility.amenities)}</div>
              </div>
            </>
          )}
          {facilityDescription()}
        </DialogHeader>
      </DialogContent>
    </Dialog>
  )
}