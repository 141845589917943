import { ZipCodeSchema } from "@/constants/AddressSchema"
import { PhoneNumberSchema } from "@/constants/PhoneSchema"
import { z } from "zod"

export const ParkingPackSchema = z.object({
  name: z.string().max(150),
  email: z.string().email().max(254),
  phone: PhoneNumberSchema.optional().or(z.literal("")),
  zipCode: ZipCodeSchema.optional().or(z.literal(""))
})

export type ParkingPackForm = z.infer<typeof ParkingPackSchema>

export const DefaultValues: ParkingPackForm = {
  name: "",
  email: "",
  phone: "",
  zipCode: ""
}

export const TITLE =
  "Spotsurfer Parking Pass Program: Your Key to Hassle-Free Mountain Access "
export const SUBTITLE =
  "Unlock Convenience, Save Money, and Elevate Your Resort Experience."

export const IntroQuips = [
  "Are you tired of circling for parking spots during your resort and Colorado mountain adventures?",
  "Say goodbye to parking stress and hello to seamless mountain access with our new Exclusive Spotsurfer Pass Program!"
]

export const WhyTitle = "Why Choose a Spotsurfer Parking Pass?"
export const Why = [
  {
    header: "Guaranteed Parking",
    text: "No more early morning rushes or disappointments"
  },
  {
    header: "Significant Savings",
    text: "Up to 12.5% off daily rates with our multi-day packs"
  },
  {
    header: "Flexibility",
    text: "Choose the pack that fits your schedule, from 3 to 18 days"
  },
  {
    header: "Convenience",
    text: "Easy digital passes integrate with our state-of-the-art platform"
  },
  {
    highlight: false,
    header: "Premium Options",
    text: "Secure Spotsurfer Signature Club access for an elevated experience"
  }
]

export const PricingTitle = "Includes tiered pricing to fit your needs."
export const Pricing = [
  {
    packSize: "3 Days",
    discount: "5%",
    pricePerDay: "$66"
  },
  {
    packSize: "6 Days",
    discount: "7.5%",
    pricePerDay: "$64"
  },
  {
    packSize: "12 Days",
    discount: "10%",
    pricePerDay: "$63"
  },
  {
    highlight: false,
    packSize: "18 Days",
    discount: "12.5%",
    pricePerDay: "$61"
  }
]

export const AdditionalReasonsTitle =
  "Why else should you purchase a Spotsurfer Parking Pass?"

export const AdditionalReasons = [
  {
    header: "Early Bird Special",
    text: "Purchase before September 30 for exclusive rates."
  },
  {
    header: "No black-out days!"
  },
  {
    header: "Referral Rewards",
    text: "Earn free parking days by referring friends."
  },
  {
    header: "Bundle & Save",
    text: "Combine with lodging, dining, and mountain rentals for ultimate value."
  }
]

export const WhyActTitle = "Why Act Now?"
export const WhyAct = [
  {
    header: "Best Prices of the Season",
    text: "Rates increase as winter approaches."
  },
  {
    header: "Limited Availability",
    text: "Passes will sell out fast!"
  },
  {
    header: "Flexible Payment Options",
    text: "Spread the cost with our convenient payment plans."
  }
]

export const EndQuips = [
  "Don't miss out on this opportunity to transform your parking experience.",
  "Secure your opportunity to purchase a parking pass today and make every mountain day your best day!"
]

export const FinalTitle =
  "Spotsurfer Parking Pass Program - Your Gateway to Mountain Bliss"
