import * as LucideIcons from "lucide-react"

const fallback = <div style={{ background: "#ddd", width: 24, height: 24 }} />

const Icon = ({ name, ...props }) => {
  // Convert kebab-case or lowercase to PascalCase
  const pascalName = name
    .split(/[-_\s]/)
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join("")

  const LucideIcon = LucideIcons[pascalName]

  if (!LucideIcon) {
    console.warn(`Icon "${name}" not found`)
    return null
  }

  return <LucideIcon {...props} />
}

export default Icon
