import { z } from "zod"
import { AddOnsSchema } from "../sections/AddOnsAccordion/const"
import {
  ParkerVehicleSchema,
  VehicleForm
} from "../sections/ParkerVehicleAccordion/const"
import { PacksSchema } from "../sections/PacksAccordion/const"
import { Reservation } from "@/api/types/internal/Reservation"
import { CodeSchema } from "../sections/DiscountCodeAccordion/const"

export const ExtraReservationFields = z.object({
  checkinDate: z.string().refine((str) => /\d\d\/\d\d\/\d\d\d\d/.test(str)),
  payViaCredits: z.coerce.boolean().optional().or(z.literal("")),
})

export const ReservationSchema = ParkerVehicleSchema.merge(CodeSchema)
  .merge(
    z.object({
      addOns: AddOnsSchema
    })
  )
  .merge(PacksSchema)
  .merge(ExtraReservationFields)

export type ReservationForm = z.infer<typeof ReservationSchema>


export interface ReservationLock {
  id: string
  expires: number
  redirectHandler: () => void
  error?: any
}

export const routingStateIsValid = (
  data: any,
  searchQuery: any
): data is Reservation => {
  if (
    "parkingLocationType" in data &&
    ((data.parkingLocationType === "SPOT" && "spot" in data && data.spot.id) ||
      (data.parkingLocationType === "LOT" && "lot" in data && data.lot.id)) &&
    "facility" in data &&
    data.facility.id &&
    searchQuery.checkinDate &&
    z
      .string()
      .refine((str) => /\d\d\/\d\d\/\d\d\d\d/.test(str))
      .safeParse(searchQuery.checkinDate).success
  ) {
    return true
  } else {
    return false
  }
}

export const defaultValues = (
  init?: Partial<ReservationForm>
): ReservationForm => ({
  addOns: init?.addOns ?? {},
  email: init?.email ?? "",
  purchasingPack: init?.purchasingPack ?? "",
  usingPurchasedPack: init?.usingPurchasedPack ?? false,
  usingPack: init?.usingPack ?? "",
  phone: init?.phone ?? "",
  checkinDate: init?.checkinDate ?? "",
  payViaCredits: init?.payViaCredits ?? false,
  vehicle:
    init?.vehicle ??
    ({
      isEv: false,
      licenseNumber: "",
      make: "",
      model: "",
      color: ""
    } as VehicleForm)
})
