import {
  api,
  AuthHeader,
  keysToSnakeCase,
  StandardApiResponse,
  StandardErrorHandler,
  StandardPushHandler
} from "../const"

export interface CreateLotResponse extends StandardApiResponse {
  lot?: UnassignedZone
}

export const CreateLotPath = (facilityId: string) =>
  `/v1/facilities/${facilityId}/lot/`

export const CreateLot = (
  accesstoken: string,
  facilityId: string,
  data: UnassignedZone<undefined>
): Promise<CreateLotResponse> =>
  api
    .post(
      CreateLotPath(facilityId),
      keysToSnakeCase(data),
      AuthHeader(accesstoken)
    )
    .then(StandardPushHandler("lot"))
    .catch(StandardErrorHandler)
