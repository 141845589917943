import {
  AvailabilityItemForm,
  OverrideItemForm
} from "@/constants/AvailabilitySchema"

export const AvailabilityDefault = (): AvailabilityItemForm => ({
  new: true,
  active: true,
  description: "New Availability",
  option: "EVERYDAY",
  price: "25.00",
  billed: "DAILY",
  openingTime: "06:00:00",
  closingTime: "18:00:00",
  leaseStartDate: new Date().toISOString().split("T")[0],
  leaseEndDate: new Date().toISOString().split("T")[0]
})

export const OverrideDefault = (): OverrideItemForm => ({
  new: true,
  active: true,
  description: "New Availability",
  option: "EVERYDAY",
  price: "25.00",
  billed: "DAILY",
  openingTime: "06:00:00",
  closingTime: "18:00:00",
  startDate: new Date().toISOString().split("T")[0],
  endDate: new Date().toISOString().split("T")[0],
  isUnavailable: false
})
