import { Lock } from "lucide-react"
import { useState, useEffect } from "react"

const CountdownTimer = ({ expiryTimeInSeconds, setIsTerminated = null }) => {
  const [min, setMin] = useState(0)
  const [sec, setSec] = useState(0)
  const [timeLeft, setTimeLeft] = useState(expiryTimeInSeconds)

  const formatTime = (t) => (t < 10 ? "0" + t : t)

  useEffect(() => {
    const interval = setInterval(() => {
      const m = Math.floor(timeLeft / 60)
      const s = timeLeft - m * 60

      setMin(m)
      setSec(s)
      if (m <= 0 && s <= 0) {
        if (setIsTerminated) setIsTerminated(true)
        return () => clearInterval(interval)
      }

      setTimeLeft((t) => t - 1)
    }, 1000)

    return () => clearInterval(interval)
  }, [setIsTerminated, timeLeft])

  return (
    <div className="min-w-[80px] text-primary text-center text-sm p-2 rounded-full bg-slate-100 drop-shadow-md">
      <div className="flex gap-1 items-center">
        <Lock className="h-4 w-4 mr-1" />
        <span>{formatTime(min)}</span> : <span>{formatTime(sec)}</span>
      </div>
    </div>
  )
}

export default CountdownTimer
